import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import React, {
  Children,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import BaseFormBtn from "../components/common/BaseFormBtn";
import IntelliInfo from "../components/common/IntelliInfo";
import { formatMomentHoursSeconds } from "../components/timeline/constants";
import { getUrlPhoto } from "../config";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import IntelliButton from "../components/IntelliButton";
import utils from "../utils";
import { toCapitalCase } from "../utils";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Place = ({ data, left, top, selected = false }) => {
  const { t } = useTranslation();
  let tooltip = "";
  if (data.structure) tooltip += `${data.structure} \n`;
  if (
    Boolean(data.settings_structure) &&
    !!data.settings_structure.observation
  ) {
    tooltip += `${t("PersonScheduling.observations")}: ${toCapitalCase(
      data.settings_structure.observation
    )}`;
  }
  return (
    <div
      className={`itemAssingPlaceDrag ${selected ? "avaliable" : "default"}`}
      style={{ left, top }}
    >
      <Tooltip
        className="iconPointer"
        title={tooltip}
        placement="bottom"
        classes={{ tooltip: "tooltipCard_tooltip" }}
      >
        <div className="itemPlan">
          <div className="itemPlan_icon">
            <i
              className={`fas fa-${
                data.sub_type === "PT" ? "chair-office" : "users-class"
              } fa-lg`}
            />
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

const RegisteredPlaceForm = ({
  obj,
  onClose,
  openModal,
  auth,
  structures,
  location,
}) => {
  const { t } = useTranslation();

  const [places, setPlaces] = useState([]);
  const [viewPlaces, setViewPlaces] = useState(false);
  const [planWidth, setPlanWidth] = useState();
  const [planHeight, setPlanHeight] = useState();
  const [width, height] = useWindowSize();
  const [photoPlan, setPhotoPlan] = useState(null);

  const Ks = { left: planWidth / 970, top: planHeight / 460 };
  const ref = useRef(null);

  const {
    date_in,
    date_out,
    place,
    person,
    structure,
    ubicacion,
    ubicacionE,
    time_in,
    time_out,
  } = obj;

  const useStyles = makeStyles({
    MuiDialogPaper: {
      width: `${location === 456 ? "720px" : "fit-content"}`,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    if (!!openModal && !!obj && !!place) {
      console.log(obj);
      const settings_structure = structures.tree.find(
        (item) => item.id_structure === ubicacionE.id_structure
      ).settings_structure;

      const plan = settings_structure.photo_plan;
      setPhotoPlan(getUrlPhoto(plan));
      let allPlaces = obj.places.map((item) => {
        return {
          ...item,
          ...item.place,
        };
      });
      if (
        _.has(settings_structure, "only_pr") &&
        settings_structure.only_pr === 1
      ) {
        allPlaces = allPlaces.filter(
          (item) =>
            item.sub_type === "PR" ||
            item.id_structure === obj.place.id_structure
        );
      }
      setPlaces(allPlaces);
    }
  }, [openModal, obj]);

  useEffect(() => {
    setPlanWidth(ref.current ? ref.current.clientWidth : 0);
    setPlanHeight(ref.current ? ref.current.clientHeight : 0);
  }, [ref.current, height, width]);

  let minH = !!place ? 500 : 0;

  const setGoogleCalendar = () => {
    const DateIn = moment(`${date_in} ${time_in}`)
      .toISOString()
      .replace(/[&\/\\#, +()$~%-.'":*?<>{}]/g, "");
    const DateOut = moment(`${date_out} ${time_out}`)
      .toISOString()
      .replace(/[&\/\\#, +()$~%-.'":*?<>{}]/g, "");

    const item = {
      text: `Reunión | ${utils.capitalize(ubicacionE.structure)}`,
      dates: `${DateIn}/${DateOut}`,
      location: "4.6834938,-74.0457797",
      details: `Puesto: ${place.structure}`,
    };

    utils.openEventGoogleCalendar(item);
  };

  return (
    <Dialog
      open={openModal}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className="Intelli_modal"
      maxWidth={false}
      classes={{ paper: classes.MuiDialogPaper }}
    >
      <DialogTitle id="form-dialog-title">
        {t("PersonScheduling.title")}
      </DialogTitle>
      <DialogContent>
        <div
          className="formRow"
          style={{ paddingTop: 0, maxWidth: 1000, minHeight: minH }}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            wrap="wrap"
            spacing={1}
            style={{ marginBottom: "4px" }}
          >
            <Grid item sm={3}>
              <IntelliInfo
                name={t("PersonScheduling.id_person")}
                value={`${person.dui_person} - ${person.first_name} ${person.last_name1}`}
              />
            </Grid>
            <Grid item sm={3}>
              <IntelliInfo
                name={t("PersonScheduling.id_structure")}
                value={structure.structure}
              />
            </Grid>

            {!!ubicacion && (
              <Grid item sm={3}>
                <IntelliInfo
                  name={t("PersonScheduling.id_L")}
                  value={ubicacion.structure}
                />
              </Grid>
            )}

            {!!ubicacionE && (
              <Grid item sm={3}>
                <IntelliInfo
                  name={t("PersonScheduling.id_SL")}
                  value={ubicacionE.structure}
                />
              </Grid>
            )}
            {!!place && (
              <Grid item sm={3}>
                <IntelliInfo
                  name={t("PersonScheduling.selectedPlace")}
                  value={place.structure}
                />
              </Grid>
            )}

            {!!location && (
              <>
                <Grid item sm={2}>
                  <IntelliInfo
                    name={t("General.date")}
                    value={moment(date_in).format("DD-MM-YYYY")}
                  />
                </Grid>
                <Grid item sm={!!ubicacionE ? 1 : 2}>
                  <IntelliInfo
                    name={t("General.day")}
                    value={moment(date_in).format("dddd").toUpperCase()}
                  />
                </Grid>
              </>
            )}

            <Grid item sm={2}>
              <IntelliInfo
                name={t("PersonScheduling.id_schedule")}
                value={`${moment(time_in, formatMomentHoursSeconds).format(
                  auth.data.format_time
                )} - ${moment(time_out, formatMomentHoursSeconds).format(
                  auth.data.format_time
                )}`}
              />
            </Grid>
            {!!photoPlan && (
              <Grid sm={12} className="available-places">
                <>
                  <Grid className="plan-places-legend" style={{ width: "99%" }}>
                    <div className="legend-item">
                      <div className="legend-item-sub">
                        <i className="fas fa-chair-office fa-lg" />
                      </div>
                      <div className="legend-item-text">
                        {t("PersonScheduling.legend.working_place")}
                      </div>
                    </div>
                    <div className="legend-item">
                      <div className="legend-item-sub">
                        <i className="fas fa-users-class fa-lg" />
                      </div>
                      <div className="legend-item-text">
                        {t("PersonScheduling.legend.meet_place")}
                      </div>
                    </div>
                    <div className="legend-item">
                      <div className="legend-item-sub coworking-area" />
                      <div className="legend-item-text">
                        {t("PersonScheduling.legend.coworking_area")}
                      </div>
                    </div>
                    <div className="legend-item">
                      <div className="legend-item-sub meet-area" />
                      <div className="legend-item-text">
                        {t("PersonScheduling.legend.meet_area")}
                      </div>
                    </div>
                    <div className="legend-item">
                      <div className="legend-item-sub place-available" />
                      <div className="legend-item-text">
                        {t("PersonScheduling.selectedPlace")}
                      </div>
                    </div>
                    <div className="legend-item">
                      <div className="legend-item-sub place-others" />
                      <div className="legend-item-text">
                        {t("PersonScheduling.legend.other_places")}
                      </div>
                    </div>
                  </Grid>
                  <div className="plan">
                    <img
                      src={photoPlan}
                      ref={ref}
                      alt="plan"
                      width="100%"
                      onLoad={() => {
                        setPlanWidth(ref.current ? ref.current.clientWidth : 0);
                        setPlanHeight(
                          ref.current ? ref.current.clientHeight : 0
                        );
                        setViewPlaces(true);
                      }}
                    />
                    {Children.toArray(
                      places.map((place) => {
                        if (!viewPlaces) return null;
                        return (
                          <Place
                            data={place}
                            left={place.left * Ks.left}
                            top={place.top * Ks.top}
                            selected={
                              place.id_structure === obj.place.id_structure
                            }
                          />
                        );
                      })
                    )}
                  </div>
                </>
              </Grid>
            )}
          </Grid>
          <BaseFormBtn
            viewSave={false}
            onCloseModal={onClose}
            // actions={
            //   place &&
            //   places &&
            //   places.find((p) => p.id_structure === place.id_structure) &&
            //   places.find((p) => p.id_structure === place.id_structure)
            //     .sub_type === "PR" && (
            //     <Grid item className="actionsBtn">
            //       <IntelliButton
            //         aria-label="resendPassword"
            //         varTitle={t("General.scheduleInGoogleCalendar")}
            //         type="especial01"
            //         varIcon=" "
            //         onClick={setGoogleCalendar}
            //       />
            //     </Grid>
            //   )
            // }
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect((st) => st)(RegisteredPlaceForm);
