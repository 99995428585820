import React from "react";
import {
  TextField,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import PropTypes from "prop-types";

const IntelliTextField = (props) => {
  props.customProps["inputProps"] =
    "inputProps" in props.customProps ? props.customProps.inputProps : {};
  let label = props.customProps.label;
  if (props.customProps.required === true) {
    label = `${label} *`;
  }
  return props.customProps.variant === undefined ? (
    <FormControl
      fullWidth
      classes={{
        root: "intelliFormControl_root",
        marginNormal: "intelliFormControl_marginNormal",
        marginDense: "intelliFormControl_marginDense",
        fullWidth: "intelliFormControl_fullWidth",
      }}
    >
      <InputLabel
        htmlFor={props.customProps.id}
        variant="outlined"
        margin="dense"
        classes={{
          root: "intelliInputLabel_root",
          marginDense: "intelliInputLabel_marginDense",
          outlined: "intelliInputLabel_outlined",
          formControl: "intelliInputLabel_formControl",
          shrink: "intelliInputLabel_shrink",
          focused: "intelliInputLabel_focused",
        }}
      >
        {" "}
        {label}{" "}
      </InputLabel>
      <OutlinedInput
        // id='inputOutlined'
        disabled={props.disabled}
        autoComplete
        placeholder={props.customProps.placeholder}
        inputProps={{
          ...props.customProps.inputProps,
        }}
        {...props.basicProps}
        {...props.customProps}
        classes={{
          root: "intelliOutlinedInput_root",
          marginDense: "intelliOutlinedInput_marginDense",
          input: "intelliOutlinedInput_input",
          inputMarginDense: "intelliOutlinedInput_inputMarginDense",
          notchedOutline: "intelliOutlinedInput_notchedOutline",
          focused: "intelliOutlinedInput_focused",
        }}
      />
    </FormControl>
  ) : (
    <TextField
      inputProps={{ ...props.customProps.inputProps }}
      {...props.basicProps}
      {...props.customProps}
    />
  );
};
IntelliTextField.defaultProps = {
  basicProps: {
    variant: "outlined",
    margin: "dense",
    type: "text",
    fullWidth: true,
    autoComplete: "off",
    placeholder: "",
  },
  disabled: false,
};
IntelliTextField.propTypes = {
  // customProps: PropTypes.object
};

export default IntelliTextField;
