import { Fab, Grid, Tooltip } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const IntelliFab = ({
  name,
  icon,
  color,
  size,
  textColor,
  iconColor,
  fontSize,
  className,
  title,
  action,
  disabled,
  style,
  sizeFab = "small",
  attr
}) => {
  const { t } = useTranslation();
  let classColor = disabled ? "bg_colorDisabled" : color;
  return (
    <Grid item>
      <Tooltip
        title={t(title)}
        placement="bottom"
        classes={{ tooltip: "tooltipCard_tooltip" }}
        disableFocusListener={true}
      >
        <span>
          <Fab
            aria-label="itemChek"
            size={sizeFab}
            onClick={() => action()}
            className={`${size} ${attr} ${className} ${classColor} ${textColor}`}
            disabled={disabled}
            disableFocusRipple={true}
            style={style}
          >
            <i
              name={name}
              className={icon}
              style={{ color: iconColor, fontSize: fontSize }}
            ></i>
          </Fab>
        </span>
      </Tooltip>
    </Grid>
  );
};

IntelliFab.defaultProps = {
  title: "titulo",
  action: () => {},
  textColor: "font_colorff",
  attr: "",
  icon: "fas fa-user",
  size: "",
  name: "",
  disabled: false,
  style: {},
  className: "",
  fontSize: "",
  margin: "",
  color: "bg_colorAuxiliarPrimary",
};

export default IntelliFab;
