import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles,
  Grid,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import BaseFormBtn from "./BaseFormBtn";
import TableHeaderEvents from "./TableHeaderEvents";
import withFormatter from "../containers/FormatWrapper";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toCapitalCase } from "../../utils";
import { formatMomentHoursSeconds } from "../timeline/constants";

const ModalListUsedPlaces = ({
  title,
  openModal,
  onClose,
  obj,
  auth,
  ...props
}) => {
  const { t } = useTranslation();

  const { useds } = obj;

  const header = [
    { type: "cell", description: "PersonScheduling.dow", size: { xs: 4 } },
    { type: "cell", description: "PersonScheduling.date", size: { xs: 4 } },
    { type: "cell", description: "PersonScheduling.time", size: { xs: 4 } },
  ];

  const dataEx = [
    { time_in: "10:00:00", time_out: "17:00:00", date_in: "2022-10-01" },
    { time_in: "08:00:00", time_out: "11:00:00", date_in: "2022-11-01" },
    { time_in: "15:00:00", time_out: "19:00:00", date_in: "2022-12-01" },
  ];

  const renderRows = (row, i) => {
    const { time_in, time_out, date_in } = row;

    const tI = moment(time_in, formatMomentHoursSeconds).format(
      auth.data.format_time
    );
    const tO = moment(time_out, formatMomentHoursSeconds).format(
      auth.data.format_time
    );
    const dI = moment(date_in).format(auth.data.format_date);

    const dow = moment(date_in).day();

    const dows = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return (
      <Grid
        key={i}
        container
        spacing={0}
        className={"rowsGridA"}
        direction="row"
        justify="flex-start"
        alignItems="center"
        wrap="wrap"
      >
        <Grid item xs={4} className="item centerVertical">
          {toCapitalCase(t("Dow." + dows[dow]))}
        </Grid>
        <Grid item xs={4} className="item centerVertical">
          {dI}
        </Grid>
        <Grid item xs={4} className="item centerVertical">
          {`${tI} - ${tO}`}
        </Grid>
      </Grid>
    );
  };
  return (
    <Dialog
      open={openModal}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className="Intelli_modal"
      maxWidth={false}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div
          className="formRow"
          style={{ paddingTop: 0, width: 500, maxHeight: 500 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableHeaderEvents
                header={header}
                orderBy={["triggered_at", "DESC"]}
                settingHeader={true}
                checkCell={false}
                isOrderBy={false}
              />
              <div className="listMarks">
                {!!useds &&
                  !!useds.length &&
                  useds.map((item, i) => {
                    return renderRows(item, i);
                  })}
              </div>
            </Grid>
          </Grid>
          <BaseFormBtn
            viewSave={false}
            viewClose={true}
            onCloseModal={onClose}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(withFormatter(ModalListUsedPlaces));
