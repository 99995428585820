import { DatePicker } from "@material-ui/pickers";
import React from "react";

const IntelliMonthPicker = ({ value, handleDateChange, auth, label }) => {
	return (
		<DatePicker
			variant="inline"
			openTo="month"
			views={["year", "month"]}
			label={label}
			value={value}
			onChange={handleDateChange}
			inputVariant="outlined"
			className="Intelli_datepicker month"
			autoOk
		/>
	);
};

export default IntelliMonthPicker;
