import { Tooltip } from "@material-ui/core";
import React from "react";

const IntelliIcon = ({ icon, textTooltip, color }) => {
	return (
		<div className="Intelli_icon">
			<Tooltip
				title={textTooltip}
				open={!!textTooltip}
				classes={{ tooltip: "tooltipCard_tooltip" }}>
				<span className="icon" style={{ color: color }}>
					<i className={icon}></i>
				</span>
			</Tooltip>
		</div>
	);
};

IntelliIcon.defaultProps = {
	icon: "fas fa-user",
	textTooltip: "",
	color: "black",
};

export { IntelliIcon };
