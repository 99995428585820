import { Checkbox, Tooltip, Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

const TableHeaderEvents = ({
  orderBy,
  header,
  settingHeader,
  checkCell,
  isOrderBy,
  isCheckAll,
  onClickCheckAll,
  onClickHeader,
  setValueItemOpen,
}) => {
  const { t } = useTranslation();
  let check = header.filter((item) => item.type === "check");

  const handleAllChecked = (event) => {
    const check = event.target.checked;
    onClickCheckAll(check);
  };

  const ClickHeader = (header) => {
    let OBY = [];
    let field = header.field;
    if (header.alterOrderBy) {
      field = header.alterOrderBy;
    }
    if (orderBy[0] == field) {
      OBY = [field, orderBy[1] == "ASC" ? "DESC" : "ASC"];
    } else {
      OBY = [field, "ASC"];
    }
    onClickHeader(OBY);
    setValueItemOpen([]);
  };
  return (
    <Grid
      container
      direction="row"
      spacing={0}
      className={settingHeader ? "headerTable_setting" : "headerTable"}
    >
      {checkCell === true ? (
        <Grid
          item
          xs={1}
          className={
            settingHeader
              ? "Intelli-Setting-MuiGrid-grid-xs-1 centerVertical"
              : "Intelli-MuiGrid-grid-xs-1 centerVertical"
          }
        >
          <Grid key={uid()} item {...check.size}>
            <Checkbox
              color={"primary"}
              checked={isCheckAll}
              onClick={(event) => handleAllChecked(event)}
              className="headerTable_checkboxList"
            />
          </Grid>
        </Grid>
      ) : null}
      {/* <Grid item xs={checkCell?11:12} className={ settingHeader ? 'Intelli-Setting-MuiGrid-grid-xs-11 centerVertical' : 'Intelli-MuiGrid-grid-xs-11 centerVertical' } > */}
      {header.map((header) => {
        if (header.type == "cell") {
          return (
            <Grid
              key={uid()}
              item
              {...header.size}
              className="headerTable_colLeft"
            >
              <div
                className="headerTable_button"
                onClick={() => {
                  if (isOrderBy === true && header.notOrderBy !== true) {
                    ClickHeader(header);
                  }
                }}
              >
                <Tooltip
                  title={t(header.description)}
                  placement="bottom-start"
                  classes={{
                    tooltip: "tooltipTitleColumn",
                  }}
                >
                  <div className="headerTable_item">
                    {t(header.description)}
                  </div>
                </Tooltip>
                {isOrderBy === true && header.notOrderBy !== true ? (
                  orderBy[0] == header.field ||
                  orderBy[0] == header.alterOrderBy ? (
                    <span>
                      <i
                        className={
                          orderBy[1] == "ASC"
                            ? "fas fa-chevron-up headerTable_iconOrderByA"
                            : "fas fa-chevron-down headerTable_iconOrderByA"
                        }
                      ></i>
                    </span>
                  ) : null
                ) : null}
              </div>
            </Grid>
          );
        } else if (header.type == "img") {
          return (
            <Grid
              key={uid()}
              item
              {...header.size}
              className="headerTable_colLeft"
            >
              <span className="headerTable_item">{t(header.description)}</span>
            </Grid>
          );
        }
      })}
      {/* </Grid> */}
    </Grid>
  );
};

export default TableHeaderEvents;
