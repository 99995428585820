import i18next from "i18next";
import React from "react";
import { toast } from "react-toastify";

const typeToast = {
  info: "info",
  warning: "warning",
  error: "error",
  success: "success",
};

const IntelliToastMsg = ({
  type = typeToast.info,
  title,
  text = "",
  options = { autoClose: 5000 },
}) => {
  switch (type) {
    case typeToast.info:
      return toast.info(
        <div className="toast_msg">
          <span className="icon">
            <i className="fas fa-info"></i>
          </span>
          <div className="data">
            <span className="title">
              {!!title ? title : i18next.t("General.info")}
            </span>
            <span className="text">{text}</span>
          </div>
        </div>,
        options
      );
    case typeToast.warning:
      return toast.warning(
        <div className="toast_msg">
          <span className="icon">
            <i className="fas fa-exclamation-triangle"></i>
          </span>
          <div className="data">
            <span className="title">
              {!!title ? title : i18next.t("General.warning")}
            </span>
            <span className="text">{text}</span>
          </div>
        </div>,
        options
      );
    case typeToast.error:
      return toast.error(
        <div className="toast_msg">
          <span className="icon">
            <i className="far fa-exclamation-circle"></i>
          </span>
          <div className="data">
            <span className="title">
              {!!title ? title : i18next.t("General.error")}
            </span>
            <span className="text">{text}</span>
          </div>
        </div>,
        options
      );
    case typeToast.success:
      return toast.success(
        <div className="toast_msg">
          <span className="icon">
            <i className="far fa-check-circle"></i>
          </span>
          <div className="data">
            <span className="title">
              {!!title ? title : i18next.t("General.titleSuccess")}
            </span>
            <span className="text">{text}</span>
          </div>
        </div>,
        options
      );
    default:
      return toast(
        <div className="toast_msg">
          <span>
            <i className="far fa-comment  "></i>
          </span>
          <span className="title">
            {!!title ? title : i18next.t("General.info")}
          </span>
          <span className="text">{text}</span>
        </div>,
        options
      );
  }
};

export { IntelliToastMsg, typeToast };
