import { REHYDRATE } from "redux-persist/es/constants";
import utils from "../../../../utils";
import {
  DOCUMENT_TYPES_FETCH,
  DOCUMENT_TYPES_FETCH_PENDING,
  DOCUMENT_TYPES_REJECTED,
} from "../../constants";

const initialState = {
  data: {
    results: [],
  },
  isFetching: false,
};

export function documentTypesReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      return utils.actionRehydrate(state, action.payload, "documentTypes");
    case DOCUMENT_TYPES_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case DOCUMENT_TYPES_REJECTED:
      return {
        ...state,
        isFetching: false,
      };
    case DOCUMENT_TYPES_FETCH:
      return {
        data: action.payload.data.data,
        isFetching: false,
      };

    default:
      return state;
  }
}
