import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { uid } from "uid";
import IntelliChips from "./IntelliChips";
import IntelliPlaceholder from "./IntelliPlaceholder";

function MultiValue(props) {
	return <IntelliChips values={props.children} />;
}

const Input = (props) => {
	// if (props.isHidden) {
	// 	return <Component.Input {...props} />;
	// }
	return (
		<div style={{ width: "100%" }}>
			<input
				className="inputSelecSearch"
				ref={props.innerRef}
				id={props.id}
				onChange={props.onChange}
				onBlur={props.onBlur}
			/>
		</div>
	);
};

const ValueContainer = (props) => {
	return <div className="intelliTruncate_default">{props.children}</div>;
};

const IntelliSelect = ({
	place,
	selected,
	isClearable,
	isDisabled,
	placeholder,
	items,
	onChange,
	styles,
	view = false,
	required = false,
	label = true,
	isMulti = false,
	classes,
}) => {
	const [open, setOpen] = useState(false);
	const [componentsSelect, setComponentSelect] = useState({
		MultiValue,
		ValueContainer,
	});
	const { t } = useTranslation();

	let inputId = uid();

	return (
		<div className={`Intelli_select`}>
			{!!placeholder && placeholder != "" && label && (
				<IntelliPlaceholder
					view={view}
					open={open}
					selected={selected}
					text={placeholder}
					isDisabled={isDisabled}
					type="time"
					isRequired={required}
				/>
			)}
			{isMulti ? (
				<Select
					inputProps={{
						autoComplete: "off",
						autoCorrect: "off",
						spellCheck: "off",
					}}
					components={componentsSelect}
					closeMenuOnSelect={false}
					hideSelectedOptions={false}
					classes={classes}
					placeholder={!open ? placeholder : ""}
					isDisabled={isDisabled ? isDisabled : false}
					className="select_container select_container--is-multi"
					classNamePrefix="select"
					value={selected}
					isClearable={isClearable}
					isMulti={true}
					inputId={inputId}
					isSearchable={open}
					maxMenuHeight={150}
					name={placeholder}
					options={items}
					onChange={(val) => {
						const items =
							val === null
								? []
								: val.map((item) => {
										return item.value;
								  });
						onChange(items);
					}}
					onMenuOpen={() => {
						document.getElementById(inputId).focus();
						setOpen(true);
						setComponentSelect((state) => {
							return {
								...state,
								Input,
							};
						});
					}}
					onMenuClose={() => {
						setOpen(false);
						setComponentSelect((state) => {
							return {
								MultiValue,
								ValueContainer,
							};
						});
					}}
					styles={{
						menu: (pro) => ({
							...pro,
							marginTop: "45px",
						}),
						valueContainer: (c) => {
							return {
								...c,
								overflow: "inherit",
								maxHeight: "43px",
							};
						},
					}}
					noOptionsMessage={() => t("General.noOptions")}
				/>
			) : (
				<Select
					placeholder={placeholder}
					className="select_container"
					classNamePrefix="select"
					value={selected}
					isClearable={isClearable}
					isSearchable={true}
					isDisabled={isDisabled}
					name={placeholder}
					options={items}
					onChange={(val) => {
						onChange(val == null ? { value: null, label: "" } : val);
					}}
					onMenuOpen={() => {
						setOpen(true);
					}}
					onMenuClose={() => {
						setOpen(false);
					}}
					noOptionsMessage={() => t("General.noOptions")}
					styles={{
						menu: (styles) => ({
							...styles,
						}),
					}}
				/>
			)}
		</div>
	);
};

export default IntelliSelect;
