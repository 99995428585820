import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";


const Preloader = () => {
  return (
    <div
      className={"content_preloader"}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "100vh",
      }}
    >
      <CircularProgress size={25} />
      <div className="header__postlogo">
        <img
          className="header__postlogo--logo right  logoemp"
          alt="IntelliNext"
        />
      </div>
    </div>
  );
};

export default Preloader;
