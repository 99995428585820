import { DialogActions, Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import utils from "../../utils";
import IntelliButton from "../IntelliButton";

const BaseFormBtn = (props) => {
  const {
    onCloseModal,
    onOk,
    viewSave,
    viewClose,
    viewCancel,
    actions,
    disableSave,
    otherButtons,
    textok
  } = props;
  const { t } = useTranslation();

  return (
    <DialogActions className="baseformbtn">
      {!!actions && <Grid container>{actions}</Grid>}
      {!!otherButtons && <>{otherButtons}</>}
      {viewSave && (
        <IntelliButton
          onClick={onOk}
          varTitle={!!textok ? textok :  t("General.btnOk")}
          typeIcon="light"
          varIcon="check"
          color={"colorSuccess"}
          disabled={disableSave}
          type={utils.TYPE_BUTTONS.WHITE}
        />
      )}
      {viewClose && (
        <IntelliButton
          onClick={onCloseModal}
          varTitle={viewCancel ? t("General.btnCancel") : t("General.btnClose")}
          typeIcon="light"
          varIcon="times"
          color={"colorAlert"}
          type={utils.TYPE_BUTTONS.WHITE}
        />
      )}
    </DialogActions>
  );
};

BaseFormBtn.defaultProps = {
  viewSave: false,
  viewClose: true,
  viewCancel: false,
  onOk: () => {},
  onCloseModal: () => {},
  actions: null,
};

export default BaseFormBtn;
