import {
  STRUCTURES_FETCH,
  STRUCTURES_FETCH_MORE,
  STRUCTURES_FETCH_PENDING,
  STRUCTURES_REJECTED,
  TREE_FETCH,
  TREE_FETCH_PENDING,
} from "../../constants";
import { REHYDRATE } from "redux-persist";
import utils from "../../../../utils";

const initialState = {
  data: {
    results: [],
  },
  tree: [],
  isFetching: false,
};

export default function structuresReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      return utils.actionRehydrate(state, action.payload, "structures");
    case STRUCTURES_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case STRUCTURES_REJECTED:
      return {
        ...state,
        isFetching: false,
      };
    case STRUCTURES_FETCH:
      return {
        data: action.payload.data.data,
        isFetching: false,
      };
    case STRUCTURES_FETCH_MORE:
      return {
        ...state,
        isFetching: false,
        data: {
          ...action.payload.data.data,
          results: utils.mergeObjet(
            action.payload.data.data.results,
            state.data.results,
            "id_structure"
          ),
        },
      };
    case TREE_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case TREE_FETCH:
      return {
        ...state,
        isFetching: false,
        tree: action.payload.data.data.results,
      };
    default:
      return state;
  }
}
