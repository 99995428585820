import React from "react";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@material-ui/core";
import { PROCESSING, PROCESS_COMPLETE } from "../services/redux/constants";
import BaseFormBtn from "../components/common/BaseFormBtn";
const DeleteFrom = ({
	session,
	module,
	onCloseModal,
	onCallBack,
	// itemCheckLength,
	funDelete,
	// itemCheck,
	funGetItem,
	dispatch,
	// checkedItem,
	componentProps,
}) => {
	const { t } = useTranslation();

	const funDeleteItem = async () => {
		dispatch({ type: PROCESSING });
		for (const id of componentProps.itemCheck) {
			await funDelete(id);
		}
		dispatch({ type: PROCESS_COMPLETE });
		onCloseModal();
		if (funGetItem) funGetItem();
	};
	return (
		<div className="formRow">
			<Grid
				container
				spacing={0}
				direction="row"
				justify="flex-start"
				alignItems="center"
				wrap="wrap">
				<Grid item xs={12}>
					<Typography variant="h6" classes={{ h6: "intelliTypography_h6" }}>
						{t("General.messageDeleteRecord")}
					</Typography>
				</Grid>
			</Grid>
			<BaseFormBtn
				viewSave={true}
				onCloseModal={onCloseModal}
				onOk={funDeleteItem}
				textok={t("General.delete")}
			/>
		</div>
	);
};
const mapStateToProps = ({ auth, session }) => {
	return { auth, session };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps)(DeleteFrom);
