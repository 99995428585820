import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { uid } from "uid";
import RowData from "./RowData";
import PropTypes from "prop-types";

const TableRow = ({
  checkedItem,
  openItem,
  setValueItemOpen,
  data,
  itemUpdate,
  header,
  checkeds,
  componentForm,
  funGetItem,
  onCallBack,
  funDelete,
  funUpdate,
  blackList,
  onClickSearch,
  favorite,
  actDelete,
  settingHeader,
  checkCell,
  onUpdateSearch,
  deviceType,
  module,
  clearItemUpdate,
  actDetails,
  onViewDetails
}) => {
  const [dataRender, setDataRender] = useState([]);

  useEffect(() => {
    setDataRender(
      data.map((item, index) => {
        const primaryKey = item.id_person_scheduling;
        // const primaryKey = item[header[0].field];
        let divKey = primaryKey || uid();
        if (Array.isArray(itemUpdate)) {
          if (itemUpdate.includes(divKey)) divKey = uid();
        } else {
          if (itemUpdate == divKey) {
            divKey = uid();
          }
        }
        return {
          ...item,
          primaryKey,
          divKey,
        };
      })
    );
  }, [data]);

  const onChecked = (event, field) => {
    if (event.target.checked) {
      checkeds.push(field);
      checkedItem(checkeds);
    } else {
      checkedItem(checkeds.filter((item) => item !== field));
    }
  };

  const clickItem = (value) => {
    if (!openItem.includes(value)) {
      openItem.push(value);
      setValueItemOpen(openItem);
    } else {
      openItem = openItem.filter((item) => item !== value);
    }
    setValueItemOpen(openItem);
  };
  if (header.length === 0) return null;

  return dataRender.map((item, index) => (
    <RowData
      key={item.divKey}
      data={item}
      header={header}
      primaryKey={item.primaryKey}
      checked={checkeds.includes(item.primaryKey)}
      onChecked={onChecked}
      toggleCard={clickItem}
      openItem={openItem}
      ComponentForm={componentForm}
      funGetItem={funGetItem}
      onCallBack={onCallBack}
      formIndex={index}
      funDelete={funDelete}
      checkedItem={checkedItem}
      addFavorite={funUpdate}
      blackList={blackList}
      onClickSearch={onClickSearch}
      favorite={favorite}
      actDelete={actDelete}
      actDetails={actDetails}
      settingHeader={settingHeader}
      checkCell={checkCell}
      onUpdateSearch={onUpdateSearch}
      deviceType={deviceType}
      module={module}
      clearItemUpdate={clearItemUpdate}
      onViewDetails={onViewDetails}
    />
  ));
};

TableRow.defaultProps = {
  alertType: false,
};

TableRow.propTypes = {
  itemUpdate: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  header: PropTypes.array,
  funDelete: PropTypes.func,
  funUpdate: PropTypes.func,
  onClickSearch: PropTypes.func,
  funGetItem: PropTypes.func,
  onCallBack: PropTypes.func,
};

const mapStateToProps = ({ process }) => {
  return { process };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TableRow);
