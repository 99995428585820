import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import IntelliModal from "./common/IntelliModal";
import BaseFormBtn from "./common/BaseFormBtn";
import IntelliInfo from "./common/IntelliInfo";
import IntelliDivider from "./common/IntelliDivider";

const versiones = [
  "Core",
  "Report",
  "Front",
  "Oauth",
  "ZKTeco",
  "SekureTime",
  "Alocity",
  "Assa abloy",
];
const ModalAboutForm = ({ componentProps: { t, onCloseModal } }) => {
  return (
    <div className="formRow">
      <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-start"
        alignItems="center"
        wrap="wrap"
      >
        <IntelliDivider title={t("About.versions")} />
        {versiones.map((version, idx) => {
          return (
            <Grid item sm={3} style={{ margin: "5px 0" }} key={idx}>
              <IntelliInfo
                name={`${version} `}
                value={`V 1.0.0`}
                direction="row"
              />
            </Grid>
          );
        })}
        <IntelliDivider title={t("About.politics")} />
        <Typography variant="body2" gutterBottom>
          <a
            href="https://www.intelli-next.com/politicas-de-privacidad/"
            target="_blank"
          >
            {t("About.usPolitics")}
          </a>
        </Typography>
      </Grid>
      <BaseFormBtn
        actions={
          <Typography variant="body2" gutterBottom>
            {t("About.copyright")}
          </Typography>
        }
        viewClose={true}
        viewSave={false}
        FormDelete={true}
        onCloseModal={onCloseModal}
      />
    </div>
  );
};

const ModalAbout = (props) => {
  const { openModal, onCloseModal, componentProps, auth, t } = props;

  return (
    <IntelliModal
      openModal={openModal}
      onCloseModal={onCloseModal}
      title={t("About.about")}
      modalContent={ModalAboutForm}
      componentProps={{
        ...componentProps,
        t,
        onCloseModal,
        auth,
      }}
      modalSize={7}
      preventClose={false}
    />
  );
};

ModalAbout.defaultProps = {
  openModal: true,
  onClose: () => console.log("SIN FUNCION DE onClose"),
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(
  mapStateToProps,
  {}
)(withTranslation("translations")(ModalAbout));
