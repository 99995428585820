import axios from "axios";
import utils from "../../../../utils";
import {
	PROCESSING,
	PROCESS_COMPLETE,
	SCHEDULINGS_FETCH,
	SCHEDULINGS_FETCH_MORE,
	SCHEDULE_INSERT,
	SCHEDULES_FETCH,
	SCHEDULES_FETCH_MORE,
} from "../../constants";
import checkTokenAndClient from "./correctUrlToken";
import { url } from "../../../../config";
import _ from "lodash";

export const schedulingsFetch = (Options = {}, callback = null) => {
	return async function (dispatch) {
		if (!checkTokenAndClient(dispatch)) return false;
		let headers = utils.headerAxios();
		Options = _.extend(
			{
				limit: 20,
				offSet: 0,
				search: "",
				where: "",
				orderBy: "date_time_in DESC",
				allItem: false,
				dispatch: true,
				id_novelty_permission: "",
			},
			Options
		);

		let search = "";

		let urlApi = `keralty/schedules`;

		const type = Options.offSet == 0 ? SCHEDULES_FETCH : SCHEDULES_FETCH_MORE;
		const request = axios.get(`${url}/${urlApi}` + search, { headers });
		request.catch((err) => utils.errorHandling(dispatch, err));
		request.then((res) => {
			// utils.errorHandling(dispatch, {
			// 	response: {
			// 		data: {
			// 			status: "et401",
			// 		},
			// 	},
			// });
			if (callback != null) {
				callback(res.data.data.results);
			}
			dispatch({
				type,
				payload: res,
			});
		});
	};
};

export const schedulesLocationFetch = (Options = {}, callback = null) => {
	return async function (dispatch) {
		if (!checkTokenAndClient(dispatch)) return false;
		let headers = utils.headerAxios();
		Options = _.extend(
			{
				limit: 20,
				offSet: 0,
				search: "",
				where: "",
				orderBy: "date_in DESC",
				allItem: false,
				dispatch: true,
				id_novelty_permission: "",
			},
			Options
		);

		let search = "";
		if (!!Options.search) {
			search += `&${Options.search}`;
		}

		let urlApi = `keralty/person_schedules?limit=${Options.limit}&offset=${Options.offSet}&orderby=${Options.orderBy}`;

		if (!!Options.date) {
			urlApi += `&where[date_in]=${Options.date}`;
		}

		if (!!Options.date) {
			urlApi += `&where[date_out]=${Options.date}`;
		}

		if (!!Options.used && !!Options.used.length) {
			let searchUsed = "";
			Options.used.forEach((l, idx) => {
				searchUsed += `${l}`;
				if (idx < Options.used.length - 1) {
					searchUsed += ",";
				}
			});
			urlApi += `&where[used]=${searchUsed}`;
		}

		if (!!Options.location) {
			urlApi += `&where[id_structure]=${Options.location}`;
		}

		const type =
			Options.offSet == 0 ? SCHEDULINGS_FETCH : SCHEDULINGS_FETCH_MORE;

		dispatch({ type: PROCESSING });

		const request = axios.get(`${url}/${urlApi}` + search, { headers });

		request
			.then((res) => {
				dispatch({ type: PROCESS_COMPLETE });

				if (callback != null) {
					callback(res.data.data.results);
				}
			})
			.catch((err) => {
				utils.errorHandling(dispatch, err);
			});
	};
};

export const schedulesInsert =
	(data, callback = null) =>
	(dispatch) => {
		if (!checkTokenAndClient(dispatch)) return false;
		let headers = utils.headerAxios();
		dispatch({ type: PROCESSING });
		const request = axios
			.post(`${url}/keralty/person_schedules`, data, {
				headers,
			})
			.then((res) => {
				dispatch({ type: PROCESS_COMPLETE });
				utils.insertSuccess();
				if (callback != null) {
					callback();
				}
			})
			.catch((error) => {
				dispatch({ type: PROCESS_COMPLETE });
				utils.errorHandling(dispatch, error, "PersonScheduling");
			});
		dispatch({
			type: SCHEDULE_INSERT,
			payload: request,
		});
	};

export const personScheduleDelete =
	(id_person_scheduling, callback = null) =>
	(dispatch) => {
		if (!checkTokenAndClient(dispatch)) return false;
		let headers = utils.headerAxios();
		dispatch({ type: PROCESSING });
		const request = axios
			.delete(`${url}/keralty/person_schedules/${id_person_scheduling}`, {
				headers,
			})
			.then((res) => {
				dispatch({ type: PROCESS_COMPLETE });
				if (callback != null) {
					callback();
				}
			})
			.catch((error) => {
				dispatch({ type: PROCESS_COMPLETE });
				utils.errorHandling(dispatch, error, "scheduling");
			});
	};

export const personScheduleUpdate =
	(data, id_person_scheduling, callback = null) =>
	(dispatch) => {
		if (!checkTokenAndClient(dispatch)) return false;
		let headers = utils.headerAxios();
		dispatch({ type: PROCESSING });
		const request = axios
			.put(`${url}/keralty/person_schedules/${id_person_scheduling}`, data, {
				headers,
			})
			.then((res) => {
				dispatch({ type: PROCESS_COMPLETE });
				// dispatch({
				//   type: ACTIONS_FORM_ADD,
				//   payload: { id: idEntityPermission },
				// });
				utils.updateSuccess();
				if (callback != null) {
					// console.info("callback");
					callback();
				}
			})
			.catch((error) => {
				dispatch({ type: PROCESS_COMPLETE });
				utils.errorHandling(dispatch, error, "Scheduling");
			});
	};
