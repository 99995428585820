import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { withStyles, Grid } from "@material-ui/core";

import { uid } from "uid";
import { userUpdateProfile } from "../services/redux/actions/common/auth";
import BaseFormBtn from "../components/common/BaseFormBtn";
import IntelliTextField from "../components/common/IntelliTextField";

const styles = (theme) => ({});

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password_current: "",
      password: "",
      password_repeat: "",
    };
  }

  handleCancel() {
    this.setState({
      password_current: "",
      password: "",
      password_repeat: "",
    });
    this.props.onCloseModal();
  }

  handleChange = (level1, level2, level3) => (event) => {
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      value = event.target.checked ? 1 : 0;
    }
    if (level3) {
      this.setState({
        [level1]: {
          [level2]: {
            ...this.state[level1][level2],
            [level3]: value,
          },
        },
      });
    } else if (level2) {
      this.setState({
        [level1]: {
          ...this.state[level1],
          [level2]: value,
        },
      });
    } else {
      this.setState({ [level1]: value });
    }
  };

  onUpdate() {
    const { t } = this.props;
    const { id_user } = this.props.auth.data.user;
    const { password_current, password, password_repeat } = this.state;
    if (this.state.change_password == 1) {
      if (password_current != null) {
        if (password_current == "") {
          toast.info(t(`FormErrors.if111`), t("User.password_current"));
          return null;
        }
      }
      if (password == "") {
        toast.info(t(`FormErrors.if111`), t("User.password"));
        return null;
      }
      if (password_repeat == "") {
        toast.info(t(`FormErrors.if111`), t("User.password_repeat"));
        return null;
      }
      if (password != password_repeat) {
        toast.info(t(`General.attention`), t(`General.passwordNotMatch`));
        return null;
      }
      if (password.length < 6) {
        toast.info(t(`FormErrors.if115`), t("User.password"));
        return null;
      }
    }
    this.props.userUpdateProfile(
      id_user,
      { password_current, password, password_repeat },
      uid(),
      () => this.handleCancel()
    );
  }
  render() {
    const { auth, t } = this.props;

    return (
      <div className="formRow" style={{ paddingBottom: "0px" }}>
        <form autoComplete="off">
          <Grid
            container
            spacing={1}
            wrap="wrap"
            style={{ paddingBottom: "5px" }}
          >
            <Grid item md={12}>
              <IntelliTextField
                customProps={{
                  id: "password_current",
                  value: this.state.password_current,
                  label: t("User.passwordCurrent"),
                  type: "password",
                  onChange: this.handleChange("password_current"),
                  required: true
                }}
              />
            </Grid>
            <Grid item md={12}>
              <IntelliTextField
                customProps={{
                  id: "password",
                  value: this.state.password,
                  label: t("User.passwordNew"),
                  type: "password",
                  onChange: this.handleChange("password"),
                  required: true
                }}
              />
            </Grid>
            <Grid item md={12}>
              <IntelliTextField
                customProps={{
                  id: "password_repeat",
                  value: this.state.password_repeat,
                  label: t("User.plcPswConfirm"),
                  type: "password",
                  onChange: this.handleChange("password_repeat"),
                  required: true
                }}
              />
            </Grid>
          </Grid>
        </form>

        <BaseFormBtn
          viewSave={true}
          onCloseModal={this.handleCancel.bind(this)}
          onOk={this.onUpdate.bind(this)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = { userUpdateProfile };

const mapStateToProps = ({
  actionsForm,
  timeZones,
  auth,
  structures,
  session,
  documentTypes,
}) => {
  return { actionsForm, timeZones, auth, structures, session, documentTypes };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("translations")(withStyles(styles)(ChangePasswordForm)));
