import i18next from "i18next";
import { toast } from "react-toastify";
import _ from "lodash";
import { uriBase } from "../config";
import photoMale from "../assets/photo_client/icon_person_male.png";
import photoFemale from "../assets/photo_client/icon_person_female.png";
import {
  IntelliToastMsg,
  typeToast,
} from "../components/common/IntelliToastMsg";
import moment from "moment";
import { formatMomentfull } from "../components/timeline/constants";

function headerAxios(addToken = true) {
  const header = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (addToken) {
    const token = `Bearer ${localStorage.token}`;
    return token === undefined ? false : { ...header, Authorization: token };
  }
  return header;
}
const jsonToQueryString = (params) =>
  Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

export const formatNumeric = [
  { id: "999.99", value: "999.99" },
  { id: "999,99", value: "999,99" },
];

export const formatTemperature = [
  { id: "F", value: "FAHRENHEIT" },
  { id: "C", value: "CELCIUS" },
  { id: "K", value: "KELVIN" },
];

export const formatTimes = [
  { id: "HH:mm", value: "24 H" },
  { id: "hh:mm a", value: "12 H" },
];

async function getMedia(constraints) {
  let stream = null;

  try {
    stream = await navigator.mediaDevices.getUserMedia(constraints);
    return stream;
  } catch (err) {
    throw err;
  }
}

export const formatsDates = [
  { id: "YYYY-MM-DD", value: "2021-05-20", mask: "yyyy-MM-dd" },
  { id: "DD-MM-YYYY", value: "20-05-2021", mask: "dd-MM-yyyy" },
  { id: "MM-DD-YYYY", value: "05-20-2021 ", mask: "MM-dd-yyyy" },
  { id: "YYYY/MM/DD", value: "2021/05/20", mask: "yyyy/MM/dd" },
  { id: "DD/MM/YYYY", value: "20/05/2021", mask: "dd/MM/yyyy" },
  { id: "MM/DD/YYYY", value: "05/20/2021 ", mask: "MM/dd/yyyy" },
];

export const languajes = [
  { id: "es-CO", value: "Español CO" },
  { id: "es-DO", value: "Español DO" },
  { id: "es-VE", value: "Español VE" },
  { id: "en-US", value: "English US" },
];

export const hoursDecimalToFractions = (time) => {
  const hours = Math.floor(time);
  const minutes = (time - hours) * 60;
  const seconds = (minutes - Math.floor(minutes)) * 60;
  return { hours, minutes: Math.floor(minutes), seconds: Math.floor(seconds) };
};

export const toCapitalCase = (text) => {
  return text
    .split(" ")
    .map((word) =>
      word
        .split("")
        .map((letter, idx) => {
          if (idx === 0) {
            return letter.toUpperCase();
          } else {
            return letter.toLowerCase();
          }
        })
        .join("")
    )
    .join(" ");
};

function getDOWList() {
  return [
    { id: 0, value: i18next.t(`Dow.Sunday`) },
    { id: 1, value: i18next.t(`Dow.Monday`) },
    { id: 2, value: i18next.t(`Dow.Tuesday`) },
    { id: 3, value: i18next.t(`Dow.Wednesday`) },
    { id: 4, value: i18next.t(`Dow.Thursday`) },
    { id: 5, value: i18next.t(`Dow.Friday`) },
    { id: 6, value: i18next.t(`Dow.Saturday`) },
  ];
}

function getItemsDOW() {
  return [
    { value: 0, label: i18next.t(`Dow.Sunday`) },
    { value: 1, label: i18next.t(`Dow.Monday`) },
    { value: 2, label: i18next.t(`Dow.Tuesday`) },
    { value: 3, label: i18next.t(`Dow.Wednesday`) },
    { value: 4, label: i18next.t(`Dow.Thursday`) },
    { value: 5, label: i18next.t(`Dow.Friday`) },
    { value: 6, label: i18next.t(`Dow.Saturday`) },
  ];
}

function getTypeAccess() {
  return [
    { value: 0, label: i18next.t(`General.entryExit`) },
    { value: 1, label: i18next.t(`General.entry`) },
    { value: 2, label: i18next.t(`General.exit`) },
  ];
}

function getItemsMonths() {
  return [
    { value: 0, label: i18next.t(`Company.months.jan`) },
    { value: 1, label: i18next.t(`Company.months.feb`) },
    { value: 2, label: i18next.t(`Company.months.mar`) },
    { value: 3, label: i18next.t(`Company.months.apr`) },
    { value: 4, label: i18next.t(`Company.months.may`) },
    { value: 5, label: i18next.t(`Company.months.jun`) },
    { value: 6, label: i18next.t(`Company.months.july`) },
    { value: 7, label: i18next.t(`Company.months.aug`) },
    { value: 8, label: i18next.t(`Company.months.sept`) },
    { value: 9, label: i18next.t(`Company.months.oct`) },
    { value: 10, label: i18next.t(`Company.months.nov`) },
    { value: 11, label: i18next.t(`Company.months.dec`) },
  ];
}

const typesModesIcons = [
  { type: "FINGERPRINT", icon: "far fa-fingerprint" },
  { type: "CARD", icon: "fas fa-id-card" },
  { type: "PIN", icon: "fas fa-keyboard" },
  { type: "FACIAL", icon: "intelli_face" },
  { type: "EYES", icon: "fas fa-eye" },
  { type: "IRIS", icon: "fas fa-eye" },
  { type: "PLATE", icon: "fas fa-car" },
  { type: "USER", icon: "fas fa-desktop" },
  { type: "PC", icon: "fas fa-desktop" },
  { type: "MOBILE", icon: "fas fa-mobile-android-alt" },
];

function setName(data, user) {
  const formatName =
    user.id_format_name !== undefined ? user.id_format_name : 1;
  const { first_name, middle_name, last_name1, last_name2 } = data;
  const fName = first_name != null ? first_name : "";
  const mName = middle_name != null ? middle_name : "";
  const lName = last_name1 != null ? last_name1 : "";
  const lName2 = last_name2 != null ? last_name2 : "";
  let name = "";
  switch (formatName) {
    case 0: //FnLn1
      name = `${fName} ${lName}`;
      break;
    case 1: //FnLn1Ln2
      name = `${fName} ${lName} ${lName2}`;
      break;
    case 2: //FnMnLn1Ln2
      name = `${fName} ${mName} ${lName} ${lName2}`;
      break;
    case 3: //FnMnLn1L
      name = `${fName} ${mName} ${lName}`;
      break;
    case 4: //Ln1Fn
      name = `${lName} ${fName} `;
      break;
    case 5: //Ln1Ln2Fn
      name = `${lName} ${lName2} ${fName}`;
      break;
    case 6: //Ln1Ln2FnMn
      name = `${lName} ${lName2} ${fName} ${mName}`;
      break;
    case 7: //Ln1FnMn
      name = `${lName} ${fName} ${mName}`;
      break;
  }
  return name;
}

function statusApprovalItems() {
  return [
    { value: 1, label: i18next.t("Permission.level1") },
    { value: 2, label: i18next.t("Permission.level2") },
    { value: 3, label: i18next.t("Permission.level3") },
    { value: 4, label: i18next.t("Permission.level4") },
    { value: 5, label: i18next.t("Permission.level5") },
    { value: 6, label: i18next.t("Permission.level6") },
  ];
}

function getCutItems() {
  return [
    { value: 0, label: i18next.t("General.daily").toUpperCase() },
    { value: 1, label: i18next.t("General.weekly").toUpperCase() },
    { value: 2, label: i18next.t("General.biweekly").toUpperCase() },
    { value: 3, label: i18next.t("General.monthly").toUpperCase() },
  ];
}

const TYPE_BUTTONS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  WARNING: "warning",
  ALERT: "alert",
  SUCCESS: "success",
  WHITE: "white",
};

function actionRehydrate(state, payload, key) {
  if (payload) {
    if (payload[key]) {
      if (payload[key].data) {
        if (payload[key].data.results) {
          return payload[key];
        }
      }
    }
  }
  return state;
}

function errorHandlingAttributeModel(messages, model) {
  messages.map((error) => {
    Object.keys(error).forEach(function (code) {
      const value = error[code];
      IntelliToastMsg({
        type: typeToast.info,
        title: i18next.t(`General.titleSuccess`),
        text: `${i18next.t(`${model}.${code}`)} : ${i18next.t(
          `FormErrors.${value}`
        )}`,
      });
    });
  });
}

function errorHandlingCodes(dispatch, error, model) {
  if (!!error.response.data.status) {
    const codError = error.response.data.status.toString();
    // console.info("info: " + error.response.data.status.toString());
    switch (error.response.data.status.toString()) {
      case "452": {
        IntelliToastMsg({
          type: typeToast.info,
          title: i18next.t(`General.titleSuccess`),
          text: i18next.t("PermissionError.452"),
        });
        break;
      }
      case "432":
        IntelliToastMsg({
          type: typeToast.info,
          title: i18next.t(`General.titleSuccess`),
          text: i18next.t("PermissionError.432"),
        });
        break;
      case "433":
        IntelliToastMsg({
          type: typeToast.info,
          title: i18next.t(`General.titleSuccess`),
          text: i18next.t("PermissionError.433"),
        });
        break;
      case "480":
        IntelliToastMsg({
          type: typeToast.info,
          title: i18next.t("General.warning"),
          text: i18next.t("timeAttendance.errors.ENTITY_NOT_FOUND"),
        });
        break;
      case "481":
        IntelliToastMsg({
          type: typeToast.warning,
          title: i18next.t("General.warning"),
          text: i18next.t("timeAttendance.errors.ENTITY_DONT_HAVE_CONTRACT"),
        });
        break;
      case "482":
        IntelliToastMsg({
          type: typeToast.warning,
          title: i18next.t("General.warning"),
          text: i18next.t("timeAttendance.errors.INVALID_DATE_FOR_PERIOD"),
        });
        break;
      case "483":
        IntelliToastMsg({
          type: typeToast.warning,
          title: i18next.t("General.warning"),
          text: i18next.t("timeAttendance.errors.INVALID_DATE_IN"),
        });
        break;
      case "484":
        IntelliToastMsg({
          type: typeToast.info,
          title: i18next.t(`General.titleSuccess`),
          text: i18next.t(
            "timeAttendance.errors.INVALID_DATE_ENTITY_HAVE_PERMISSION"
          ),
        });
        break;
      case "485":
        IntelliToastMsg({
          type: typeToast.info,
          title: i18next.t(`General.titleSuccess`),
          text: i18next.t(
            "timeAttendance.errors.INVALID_DATE_ENTITY_HAVE_SCHEDULE"
          ),
        });
        break;
      case "er500":
        IntelliToastMsg({
          type: typeToast.error,
          title: codError,
          text: i18next.t("ServerError.RedisError"),
        });
        break;
      case "491":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("ServerError.491"),
        });
        break;
      case "494":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("General.inUseParking"),
        });
        break;
      case "rp506" /** Error restaurando contraseña  */:
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("Login.passwordResetedFailed"),
        });
        break;
      case "va507" /** Error de validacion de campo */:
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("Login.validationError"),
        });
        break;
      case "203":
        IntelliToastMsg({
          type: typeToast.info,
          title: codError,
          text: i18next.t("PermissionError.203"),
        });
        break;
      case "cl404":
        IntelliToastMsg({
          type: typeToast.error,
          title: codError,
          text: i18next.t("Login.clientError"),
        });
        break;
      case "400": {
        if (setError400(error.response.data.message)) {
          break;
        }
        IntelliToastMsg({ type: typeToast.error, title: "400", text: "400" });
        break;
      }
      case "404":
        if (model === "Device") {
          IntelliToastMsg({
            type: typeToast.error,
            title: "404",
            text: i18next.t("Device.homologateNotFound"),
          });
        }
        break;
      case "em500":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("ServerError.500", {
            code: error.response.data.code,
          }),
        });
        break;
      case "401": {
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("Login.userError"),
        });
        break;
      }
      case "et472":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("Login.disabled_user"),
        });
        break;
      case "et473":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("Login.user_blocked"),
        });
        break;
      case "et401":
        {
          dispatch({ type: "PROCESS_COMPLETE" });
          dispatch({
            type: "USER_LOGOUT",
          });
          IntelliToastMsg({
            type: typeToast.warning,
            title: codError,
            text: i18next.t("Token.expired"),
            options: { toastId: "logout_id" },
          });
        }
        break;
      case "ec460":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("User.recoveryError"),
        });
        break;
      case "et402":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("Token.invalid"),
        });
        break;
      case "et403":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("Token.dismissed"),
        });
        break;
      case "409":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("ServerError.409"),
        });
        break;
      case "440":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("ServerError.440"),
        });
        break;
      case "441":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("ServerError.441"),
        });
        break;
      case "403":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("PermissionError.403"),
        });
        break;
      case "410":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("General.invalidKey"),
        });
        break;
      case "498":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t(
            `License.${Object.values(error.response.data.message)}`
          ),
        });
        break;
      case "499":
        IntelliToastMsg({
          type: typeToast.info,
          title: codError,
          text: i18next.t(
            `License.${Object.values(error.response.data.message)}`
          ),
        });
        break;
      case "500":
        IntelliToastMsg({
          type: typeToast.warning,
          title: codError,
          text: i18next.t("ServerError.500", {
            code: error.response.data.code,
          }),
        });
        break;
      case "110":
        errorHandlingAttributeModel(error.response.data.message, model);
        break;
      case "490":
        IntelliToastMsg({
          type: typeToast.error,
          title: i18next.t(`General.titleSuccess`),
          text: i18next.t(`General.invalidPassword`),
        });
        break;
      case "493":
        IntelliToastMsg({
          type: typeToast.error,
          title: i18next.t(`General.titleSuccess`),
          text: i18next.t(`General.notDeletedEntity`),
        });
        break;
      case "741":
        IntelliToastMsg({
          type: typeToast.error,
          title: i18next.t(`Error`),
          text: i18next.t(`FormErrors.entity_inactive`),
        });
        break;
      case "748":
        IntelliToastMsg({
          type: typeToast.error,
          title: i18next.t(`Error`),
          text: i18next.t(`FormErrors.notBelongto`),
        });
        break;
      case "747":
        IntelliToastMsg({
          type: typeToast.error,
          title: i18next.t(`Error`),
          text: i18next.t(`FormErrors.photoExists`),
        });
        break;
      case "746":
        IntelliToastMsg({
          type: typeToast.error,
          title: i18next.t(`Error`),
          text: i18next.t(`FormErrors.noFace`),
        });
        break;
      case "745":
        IntelliToastMsg({
          type: typeToast.error,
          title: i18next.t(`Error`),
          text: i18next.t(`FormErrors.moreThanOne`),
        });
        break;

      case "22001":
        IntelliToastMsg({
          type: typeToast.error,
          title: i18next.t(`Error`),
          text: i18next.t(`FormErrors.serialLengthBigger`),
        });
        break;
      default: {
        IntelliToastMsg({
          type: typeToast.error,
          title: i18next.t(`General.titleSuccess`),
          text: `updateHandling`,
        });
        // `updateHandling ${status}`
      }
    }
  } else {
    const codError = error.message;
    return IntelliToastMsg({
      type: typeToast.error,
      title: `Error`,
      text: codError,
    });
  }
}

function setError400(obj) {
  const errMssgCode = Object.keys(obj)[0];
  let seted = false;
  switch (errMssgCode) {
    case "K461":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.invalidDateLocationHaveScheduling`),
      });
      seted = true;
      break;
    case "K462":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.totalCannotBeZero`),
      });
      seted = true;
      break;
    case "K467":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.scheduleNoAvalible`),
      });
      seted = true;
      break;
    case "K468":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.cannotDeleteResourceOld`),
      });
      seted = true;
      break;
    case "K469":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.cannotDeleteResourceUsed`),
      });
      seted = true;
      break;
    case "K470":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.invalidDateInt`),
      });
      seted = true;
      break;
    case "K471":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.locationNotFoud`),
      });
      seted = true;
      break;
    case "K472":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.isNotLocation`),
      });
      seted = true;
      break;
    case "K473":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.invalidScheduleForLocation`),
      });
      seted = true;
      break;
    case "K474":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.personHasScheduling`),
      });
      seted = true;
      break;
    case "K475":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.cannotDeleteResourceUsed`),
      });
      seted = true;
      break;
    case "K476":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.cannotDeleteResourceOld`),
      });
      seted = true;
      break;
    case "K477":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.schedulingNotFound`),
      });
      seted = true;
      break;
    case "K478":
      IntelliToastMsg({
        type: typeToast.warning,
        title: i18next.t(`General.warning`),
        text: i18next.t(`logicErrors.schedulingNotAvailables`),
      });
      seted = true;
      break;

    default:
      seted = false;
      break;
  }
  return seted;
}

function setDetailsVerificationMode(verification_mode, id_verification_mode) {
  let types = _.split(verification_mode, "_")
    .map((item) => {
      let text = "";
      switch (item) {
        case "FINGERPRINT":
          text = " " + i18next.t("Graphics.FINGERPRINT");
          break;
        case "CARD":
          text = " " + i18next.t("Graphics.CARD");
          break;
        case "PIN":
          text = " " + i18next.t("Graphics.PIN");
          break;
        case "FACIAL":
          text = " " + i18next.t("Graphics.FACIAL");
          break;
        case "EYES":
        case "IRIS":
          text = " " + i18next.t("Graphics.IRIS");
          break;
        case "PLATE":
          text = " " + i18next.t("Graphics.PLATE");
          break;
        case "USER":
          text = " " + i18next.t("Graphics.USER");
          break;
        case "PC":
          text = " " + i18next.t("Graphics.PC");
          break;
        case "MOBILE":
          text = " " + i18next.t("Graphics.MOBILE");
          break;
        default:
          break;
      }
      return text;
    })
    .toString();
  types = [9, 10, 11, 12, 13, 14].includes(id_verification_mode)
    ? _.replace(types, ",", i18next.t("General.since").toUpperCase())
    : types;
  return types;
}

function errorHandling(dispatch, error, model) {
  if (error.response) {
    errorHandlingCodes(dispatch, error, model);
  } else if (error.request) {
    if (error.request.status === 0) {
      toast.error(i18next.t("Login.serverDown"));
    } else {
      toast.error("The message");
    }
  } else {
    return false;
  }
  return true;
}

function mergeObjet(a, b, prop) {
  return b.concat(
    a.filter((aitem) => !b.find((bitem) => aitem[prop] === bitem[prop]))
  );
}

function FileToBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    return cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}

function MergeRecursive(obj1, obj2) {
  for (var p in obj2) {
    try {
      if (obj1[p].constructor == Object) {
        obj1[p] = MergeRecursive(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch (e) {
      obj1[p] = obj2[p];
    }
  }
  return obj1;
}

function updateSuccess() {
  IntelliToastMsg({
    type: typeToast.success,
    title: i18next.t("General.titleSuccess"),
    text: i18next.t("General.updateSuccess"),
  });
}

const typesNoveltyPermissions = () => [
  { value: 1, label: i18next.t("noveltyPermission.type_partial") },
  { value: 2, label: i18next.t("noveltyPermission.type_daily") },
];

const getRangeHours = (date_time_in, date_time_out, format_time) => {
  return `${moment(date_time_in, formatMomentfull).format(
    format_time
  )} - ${moment(date_time_out, formatMomentfull).format(format_time)}`;
};

function getStructureInfoEntity(structuresReducer, structures) {
  let paths = structuresReducer
    .filter((i) => structures.includes(i.id_structure))
    .map((i) => {
      return i.path.split(".");
    });
  let ids = _.uniq(paths.flat(), true);
  const location = structuresReducer.find(
    (s) => ids.includes(s.id_structure.toString()) && s.id_structure_type == 3
  );
  const position = structuresReducer.find(
    (s) => ids.includes(s.id_structure.toString()) && s.id_structure_type == 5
  );
  let jobName = "N/A";
  let id_job = null;

  if (
    position != undefined &&
    position.default_job != null &&
    position.jobsInfo &&
    position.jobsInfo.length > 0
  ) {
    let defaultsJob = position.jobsInfo.find(
      (j) => j.id_job == position.default_job
    );
    if (defaultsJob != undefined) {
      jobName = defaultsJob.job_name;
      id_job = defaultsJob.id_job;
    }
  }

  return { location, position, job: { id_job, jobName } };
}

function insertSuccess() {
  IntelliToastMsg({
    type: typeToast.success,
    title: i18next.t("General.titleSuccess"),
    text: i18next.t("General.insertSuccess"),
  });
}

function setPhoto(personPhoto, gender) {
  let photo = photoMale;
  if (personPhoto !== null && personPhoto !== "") {
    photo = `${uriBase}/file/${personPhoto}`;
  } else if (gender === 2) {
    photo = photoFemale;
  }
  return photo;
}

export const styleMaps = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  {
    elementType: "labels.text.stroke",
    stylers: [{ color: "#242f3e" }],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];

const roundNdec = (number, n_decimals = 1) => {
  return Math.floor(number * 10 ** n_decimals) * (1 / 10 ** n_decimals);
};

function setDateUTC(user, date, eventTimezonePg) {
  const { id_event_hour, timezone_pg } = user;
  var dateUtc = moment.tz(date, "UTC");
  dateUtc.tz(id_event_hour == 0 ? timezone_pg : eventTimezonePg);
  return dateUtc;
}

export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

export const formatHours = [
  { value: 1, label: "00:30 Hrs" },
  { value: 2, label: "0.5 Hrs" },
];

export const formattedCalculation = (format_calculation, time_quantity) => {
  if (isNaN(time_quantity)) return "N/A";
  if (format_calculation === 1) {
    //fractions
    const t = hoursDecimalToFractions(time_quantity);
    return `${t.hours} hrs ${t.minutes} min`;
  }
  return `${time_quantity.toFixed(2)} hrs`; //decimal
};

export const getPhoneFromLang = (lang) => {
  if (lang === "es-CO" || lang === "es-DO")
    return { text: "+57 (1) 695-6100", phone: "+5716956100" };
  if (lang === "es-VE")
    return { text: "+58 (243) 248-0373", phone: "+582432480373" };
  if (lang === "en-US")
    return { text: "+1 (305) 456-9220", phone: "+13054569220" };
  
  return { text: "+57 (1) 695-6100", phone: "+5716956100" };
};


const openEventGoogleCalendar = (obj) => {
  window.open(`https://calendar.google.com/calendar/u/0/r/eventedit?${jsonToQueryString(obj)}`, '_blank').focus();
}

const capitalize = (val) => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();

export default {
  headerAxios,
  jsonToQueryString,
  getItemsDOW,
  getItemsMonths,
  setName,
  getDOWList,
  statusApprovalItems,
  TYPE_BUTTONS,
  actionRehydrate,
  errorHandling,
  getCutItems,
  setDetailsVerificationMode,
  typesModesIcons,
  mergeObjet,
  languajes,
  formatsDates,
  formatNumeric,
  formatTemperature,
  formatTimes,
  FileToBase64,
  MergeRecursive,
  getTypeAccess,
  insertSuccess,
  updateSuccess,
  getStructureInfoEntity,
  setPhoto,
  styleMaps,
  roundNdec,
  typesNoveltyPermissions,
  getRangeHours,
  setDateUTC,
  toCapitalCase,
  hoursDecimalToFractions,
  getMedia,
  getQueryStringParams,
  formatHours,
  formattedCalculation,
  getPhoneFromLang,
  openEventGoogleCalendar,
  capitalize
};
