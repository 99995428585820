import React, { Component } from "react";

class IntelliPlaceholder extends Component {
  render() {
    const {
      view,
      open,
      focus,
      selected,
      text,
      type,
      isDisabled,
      isRequired,
      empyt,
      style,
    } = this.props;
    const value = isRequired ? text + " *" : text;

    switch (type) {
      case "select":
        return (
          <div
            className={
              open || view
                ? "sel_placeholder"
                : !open && !selected
                ? "IntelliHidden"
                : isDisabled
                ? (focus ? "focusedLabel " : "unFocusedLabel ") +
                  "sel_placeholder_disable"
                : "sel_placeholderA"
            }
          >
            {value}
          </div>
        );
      case "time":
        return (
          <div
            className={
              open
                ? "sel_placeholder"
                : isDisabled
                ? (focus ? "focusedLabel " : "unFocusedLabel ") +
                  "sel_placeholder_disable"
                : "sel_placeholderB"
            }
          >
            {value}
          </div>
        );
      case "date":
        return (
          <div
            className={
              open
                ? "sel_placeholder"
                : isDisabled
                ? (focus ? "focusedLabel " : "unFocusedLabel ") +
                  "sel_placeholder_disable"
                : "sel_placeholderB"
            }
          >
            {value}
          </div>
        );
      case "number":
        return (
          <div
            className={
              open
                ? isDisabled
                  ? (focus ? "focusedLabel " : "unFocusedLabel ") +
                    "sel_placeholder_disable"
                  : "sel_placeholder"
                : empyt !== ""
                ? "sel_placeholderA"
                : "IntelliHidden"
            }
          >
            {value}
          </div>
        );
      case "tagInput":
        return (
          <div
            style={{ ...style }}
            className={
              open
                ? isDisabled
                  ? (focus ? "focusedLabel " : "unFocusedLabel ") +
                    "sel_placeholder_disable"
                  : "sel_placeholder sel_tags_input"
                : empyt !== ""
                ? "sel_placeholderA"
                : "IntelliHidden"
            }
          >
            {value}
          </div>
        );
      case "tagPhone":
        return (
          <div
            style={{ ...style }}
            className={
              open
                ? isDisabled
                  ? (focus ? "focusedLabel " : "unFocusedLabel ") +
                    "sel_placeholder_disable"
                  : "sel_placeholder sel_tags_phone"
                : empyt !== ""
                ? "sel_placeholderA"
                : "IntelliHidden"
            }
          >
            {value}
          </div>
        );
    }
  }
}
IntelliPlaceholder.defaultProps = {
  selected: [],
};
export default IntelliPlaceholder;
