import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import utils from "../../../utils";
import DayMonth from "./DayMonth";

const MonthComponent = ({
  data,
  dates,
  auth,
  jobs,
  structures,
  stateCalendar,
  location,
  vPlace,
  ...props
}) => {
  const DOWS = utils.getItemsDOW();

  return (
    <div className="month-component">
      <div className="titles">
        {DOWS.map((d) => {
          if (d.value === 0) {
            return { ...d, value: 6 };
          } else {
            return { ...d, value: d.value - 1 };
          }
        })
          .sort((a, b) => {
            if (a.value < b.value) {
              return -1;
            } else {
              return 1;
            }
          })
          .map((d, idx) => (
            <div className="title" key={idx}>
              <span>{d.label}</span>
            </div>
          ))}
      </div>
      <div className="calendar-month">
        {stateCalendar.map((d, idx) => (
          <DayMonth
            key={idx}
            day={d}
            height={`${100 / (stateCalendar.length / 7)}%`}
            structures={structures}
            modalState={props.modalState}
            vPlace={vPlace}
            auth={auth}
            funDelete={props.funDelete}
            mouseState={props.mouseState}
            location={location}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, structures }) => {
  return {
    auth,
    structures,
  };
};

export default connect(mapStateToProps)(MonthComponent);

function getObjectDiff(obj1, obj2) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}
