import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogActions, makeStyles } from "@material-ui/core";

const IntelliModal = (props) => {
  const { openModal, onCloseModal, title, modalSize, preventClose, module, onCallBack, funGetItem, funDelete } = props;

  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    MuiDialogPaper: {
      width: `${modalSize * 100}px`,
    },
  });

  const classes = useStyles();

  return (
    <Dialog
      open={openModal}
      onClose={onCloseModal}
      aria-labelledby="form-dialog-title"
      className="Intelli_modal"
      maxWidth={false}
      classes={{ root: classes.root, paper: classes.MuiDialogPaper }}
      disableEscapeKeyDown={preventClose}
      disableBackdropClick={preventClose}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <props.modalContent
          module = {module}
          onCloseModal={onCloseModal}
          onCallBack={onCallBack}
          funGetItem={funGetItem}
          funDelete={funDelete}          
          componentProps={props.componentProps}
        />
      </DialogContent>
    </Dialog>
  );
};

IntelliModal.defaultProps = {
  preventClose: false,
  onCallBack: () => console.info("sin funcion onCallback"),
  funGetItem: () => console.info("sin funcion funGetItem"),
  funDelete: () => console.info("sin funcion funDelete"),
}

export default IntelliModal;
