import { REHYDRATE } from "redux-persist";
import utils from "../../../../utils";
import {
	CONCEPT_FETCH,
	CONCEPT_FETCH_MORE,
	CONCEPT_FETCH_PENDING,
	CONCEPT_REJECTED,
} from "../../constants";
const initialState = {
	data: {
		results: [],
	},
	isFetching: false,
};

export default function conceptReducer(state = initialState, action) {
	switch (action.type) {
		case REHYDRATE:
			return utils.actionRehydrate(state, action.payload, "concepts");
		case CONCEPT_FETCH_PENDING:
			return {
				...state,
				isFetching: true,
			};
		case CONCEPT_REJECTED:
			return {
				...state,
				isFetching: false,
			};
		case CONCEPT_FETCH:
			return {
				data: action.payload.data.data,
				isFetching: false,
			};
		case CONCEPT_FETCH_MORE:
			return {
				...state,
				isFetching: false,
				data: {
					...action.payload.data.data,
					results: utils.mergeObjet(
						action.payload.data.data.results,
						state.data.results,
						"id_concept"
					),
				},
			};

		default:
			return state;
	}
}
