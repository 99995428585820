import {
	USER_LOGOUT,
	USER_INITIAL,
	USER_LOGIN,
	CHECK_RECOVERY_TOKEN,
	CHECK_RECOVERY_TOKEN_REJECTED,
	SET_MODE_RECOVERY_PASSWORD,
	USER_PASSWORD_RESET,
	USER_PASSWORD_RESET_REJECTED,
	MODIFY_USER_SETTING,
} from "../../constants";
import { REHYDRATE } from "redux-persist";
import utils from "../../../../utils";

const initialState = {
	data: [],
	isFetching: false,
	error: false,
	tokenVerified: false,
	recoveringPassword: false,
};

export default function userLoginReducers(state = initialState, action) {
	switch (action.type) {
		case USER_INITIAL:
			return {
				...state,
				isFetching: true,
			};
		case REHYDRATE:
			return utils.actionRehydrate(state, action.payload, "auth");
		case SET_MODE_RECOVERY_PASSWORD:
			return {
				...state,
				recoveringPassword: action.payload.mode,
			};
		case USER_LOGIN:
			return {
				...state,
				isFetching: false,
				data: { ...action.payload },
				tokenVerified: false,
			};
		case CHECK_RECOVERY_TOKEN:
			return {
				...state,
				tokenVerified: true,
				recoveringPassword: true,
			};
		case CHECK_RECOVERY_TOKEN_REJECTED:
			return {
				...state,
				tokenVerified: false,
				recoveringPassword: false,
			};
		case USER_PASSWORD_RESET:
			return {
				...state,
				tokenVerified: false,
				recoveringPassword: false,
			};
		case USER_PASSWORD_RESET_REJECTED:
			return {
				...state,
				recoveringPassword: true,
				tokenVerified: true,
			};
		case MODIFY_USER_SETTING:
			return {
				...action.payload,
			};
		case USER_LOGOUT:
			localStorage.removeItem("email");
			localStorage.removeItem("token");
			localStorage.removeItem("persist:root");
			localStorage.removeItem("id_client");
			localStorage.removeItem("sessions");
			localStorage.setItem("id_registration_station", "-1");
			return {
				...state,
				isFetching: false,
				data: [],
				tokenVerified: false,
			};
		default:
			return state;
	}
}
