import { TREE_FETCH, PROCESSING, PROCESS_COMPLETE } from "../../constants";
import _ from "lodash";
import axios from "axios";
import { url } from "../../../../config/index";
import utils from "../../../../utils";
import checkTokenAndClient from "./correctUrlToken";

// export const structureDelete = (id) => (dispatch) => {
//   if (!checkTokenAndClient(dispatch)) return false;
//   const headers = utils.headerAxios();
//   return axios
//     .delete(`${url}/structures/${id}`, { headers })
//     .then(() => {
//       return true;
//     })
//     .catch((error) => {
//       utils.errorHandling(dispatch, error, "Structure");
//       return false;
//     });
// };

// export const structureUpdate = (id, data, formId) => async (dispatch) => {
//   if (!checkTokenAndClient(dispatch)) return false;
//   let headers = utils.headerAxios();
//   dispatch({ type: PROCESSING });
//   try {
//     await axios.put(`${url}/structures/${id}`, data, { headers });
//     dispatch({ type: PROCESS_COMPLETE });
//     dispatch({
//       type: ACTIONS_FORM_ADD,
//       payload: { id: formId },
//     });
//     utils.updateSuccess();
//     return true;
//   } catch (error) {
//     dispatch({ type: PROCESS_COMPLETE });
//     utils.errorHandling(dispatch, error, "Structure");
//     return false;
//   }
// };

// export const structureInsert = (data, formId) => (dispatch) => {
//   if (!checkTokenAndClient(dispatch)) return false;
//   let headers = utils.headerAxios();
//   dispatch({ type: PROCESSING });
//   const request = axios
//     .post(`${url}/structures`, data, { headers })
//     .then((res) => {
//       dispatch({ type: PROCESS_COMPLETE });
//       dispatch({
//         type: ACTIONS_FORM_ADD,
//         payload: { id: formId },
//       });
//       utils.insertSuccess();
//     })
//     .catch((error) => {
//       dispatch({ type: PROCESS_COMPLETE });
//       utils.errorHandling(dispatch, error, "Structure");
//     });
//   dispatch({
//     type: STRUCTURES_INSERT,
//     payload: request,
//   });
//   return request;
// };
// export const structureFetch =
//   (Options = {}) =>
//   (dispatch) => {
//     if (!checkTokenAndClient(dispatch)) return false;
//     let headers = utils.headerAxios();
//     Options = _.extend(
//       {
//         limit: 20,
//         offSet: 0,
//         search: "",
//         where: "",
//         orderBy: "",
//       },
//       Options
//     );

//     let search = "";
//     if (Options.search !== "") {
//       search = `&${Options.search}`;
//     }

//     const type = Options.offSet == 0 ? STRUCTURES_FETCH : STRUCTURES_FETCH_MORE;
//     const request = axios.get(
//       `${url}/structures?limit=${Options.limit}&offset=${Options.offSet}&orderby=${Options.orderBy}` +
//         search,
//       { headers }
//     );

//     request.catch((err) => utils.errorHandling(dispatch, err));
//     request.then((res) => {
//       dispatch({
//         type,
//         payload: res,
//       });
//     });
//   };

export const getTree =
  (Options = {}) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!checkTokenAndClient(dispatch)) reject(false);
      let headers = utils.headerAxios();
      let fil = 0;
      let all = 0;
      if (Options.filter === 1) {
        fil = 1;
      }
      if (Options.all === 1) {
        all = 1;
      }
      dispatch({ type: PROCESSING });
      const request = axios.get(`${url}/tree?filter=${fil}&all=${all}`, {
        headers,
      });
      request.then((res) => {
        dispatch({ type: PROCESS_COMPLETE });
        dispatch({
          type: TREE_FETCH,
          payload: res,
        });
        resolve(true);
      });
    });
  };

export const getAvailablePlaces = (Options = {}, callback = null) => {
  return async function (dispatch) {
    if (!checkTokenAndClient(dispatch)) return false;
    let headers = utils.headerAxios();

    let query = {};

    if (!!Options.dates) {
      query = { ...query, "where[date_in]": Options.dates };
    }
    if (!!Options.dows) {
      query.dows = Options.dows;
    }
    if (!!Options.times) {
      query.times = Options.times;
    }
    dispatch({ type: PROCESSING });

    try {
      const request = await axios.get(
        `${url}/keralty/availables/${
          Options.id_structure
        }/places?${utils.jsonToQueryString(query)}`,
        {
          headers,
        }
      );
      dispatch({ type: PROCESS_COMPLETE });

      return request;
    } catch (e) {
      dispatch({ type: PROCESS_COMPLETE });

      utils.errorHandling(dispatch, e);
      return false;
    }
  };
};

export const getAvailablePlacesCalendar = (Options = {}, callback = null) => {
  return async function (dispatch) {
    if (!checkTokenAndClient(dispatch)) return false;

    let headers = utils.headerAxios();

    let query = {};

    if (!!Options.dates) {
      query = { ...query, "where[date_in]": Options.dates };
    }
    if (!!Options.id_structure) {
      query = { ...query, "where[id_structure]": Options.id_structure };
    }
    dispatch({ type: PROCESSING });

    try {
      const request = await axios.get(
        `${url}/keralty/get_availables_places?${utils.jsonToQueryString(
          query
        )}`,
        {
          headers,
        }
      );
      dispatch({ type: PROCESS_COMPLETE });

      callback(request);

      return request;
    } catch (e) {
      dispatch({ type: PROCESS_COMPLETE });

      utils.errorHandling(dispatch, e);
      return false;
    }
  };
};
