/* GLOBAL TYPES*/

export const PROCESSING = "PROCESSING";
export const PROCESS_COMPLETE = "PROCESS_COMPLETE";

/*
 * USER
 */
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_INITIAL = "USER_INITIAL";
export const USER_RECOVER = "USER_RECOVER";
export const USER_PASSWORD_RESET = "USER_PASSWORD_RESET";
export const USER_PASSWORD_RESET_REJECTED = "USER_PASSWORD_RESET_REJECTED";
export const MODIFY_USER_SETTING = "MODIFY_USER_SETTING";
export const PASSWORD_SEND_EMAIL = "PASSWORD_SEND_EMAIL";
export const CHECK_RECOVERY_TOKEN = "CHECK_RECOVERY_TOKEN";
export const CHECK_RECOVERY_TOKEN_REJECTED = "CHECK_RECOVERY_TOKEN_REJECTED";
export const SET_MODE_RECOVERY_PASSWORD = "SET_MODE_RECOVERY_PASSWORD";

/*
 * STRUCTURES
 */
export const STRUCTURES_FETCH = "STRUCTURES_FETCH";
export const STRUCTURES_FETCH_MORE = "STRUCTURES_FETCH_MORE";
export const STRUCTURES_FETCH_PENDING = "STRUCTURES_FETCH_PENDING";
export const STRUCTURES_REJECTED = "STRUCTURES_REJECTED";
export const STRUCTURES_DELETE = "STRUCTURES_DELETE";
export const STRUCTURES_UPDATE = "STRUCTURES_UPDATE";
export const STRUCTURES_INSERT = "STRUCTURES_INSERT";
export const TREE_FETCH_PENDING = "TREE_FETCH_PENDING";
export const TREE_FETCH = "TREE_FETCH";
export const TREE_CONFIG_FETCH = "TREE_CONFIG_FETCH";
export const TREE_FETCH_REJECTED = "TREE_FETCH_REJECTED";
export const TREE_CONFIG_FETCH_REJECTED = "TREE_CONFIG_FETCH_REJECTED";

/*
 * STRUCTURE TYPES
 */
export const STRUCTURE_TYPES_FETCH = "STRUCTURE_TYPES_FETCH";
export const STRUCTURE_TYPES_FETCH_MORE = "STRUCTURE_TYPES_FETCH_MORE";
export const STRUCTURE_TYPES_FETCH_PENDING = "STRUCTURE_TYPES_FETCH_PENDING";
export const STRUCTURE_TYPES_REJECTED = "STRUCTURE_TYPES_REJECTED";
export const STRUCTURE_TYPES_DELETE = "STRUCTURE_TYPES_DELETE";
export const STRUCTURE_TYPES_UPDATE = "STRUCTURE_TYPES_UPDATE";
export const STRUCTURE_TYPES_INSERT = "STRUCTURE_TYPES_INSERT";

/*
 * JOB
 */

export const JOB_FETCH = "JOB_FETCH";
export const JOB_FETCH_MORE = "JOB_FETCH_MORE";
export const JOB_FETCH_PENDING = "JOB_FETCH_PENDING";
export const JOB_REJECTED = "JOB_REJECTED";
export const JOB_UPDATE = "JOB_UPDATE";
export const JOB_INSERT = "JOB_INSERT";
export const JOB_DELETE = "JOB_DELETE";

/**
 * TIME PLANNING
 */

export const TIME_PLANNING_FETCH = "TIME_PLANNING_FETCH";
export const TIME_PLANNING_FETCH_MORE = "TIME_PLANNING_FETCH_MORE";
/**
 * SESSION
 */

export const SET_CAMERA_DEVICE = "SET_CAMERA_DEVICE";
export const SET_ID_ENTITY = "SET_ID_ENTITY";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const SET_ENROLL = "SET_ENROLL";

/*
 * VERIFICATION_MODES
 */
export const VERIFICATION_MODES_FETCH = "VERIFICATION_MODES_FETCH";
export const VERIFICATION_MODES_FETCH_MORE = "VERIFICATION_MODES_FETCH_MORE";
export const VERIFICATION_MODES_FETCH_PENDING =
	"VERIFICATION_MODES_FETCH_PENDING";
export const VERIFICATION_MODES_REJECTED = "VERIFICATION_MODES_REJECTED";
export const VERIFICATION_MODES_UPDATE = "VERIFICATION_MODES_UPDATE";
export const VERIFICATION_MODES_INSERT = "VERIFICATION_MODES_INSERT";
export const VERIFICATION_MODES_DELETE = "VERIFICATION_MODES_DELETE";

/*
 * ENTITY TYPE
 */
export const ENTITIES_TYPES_FETCH = "ENTITIES_TYPES_FETCH";
export const ENTITIES_TYPES_REJECTED = "ENTITIES_TYPES_REJECTED";
export const ENTITIES_TYPES_FETCH_MORE = "ENTITIES_TYPES_FETCH_MORE";
export const ENTITIES_TYPES_FETCH_PENDING = "ENTITIES_TYPES_FETCH_PENDING";
export const ENTITIES_TYPES_DELETE = "ENTITIES_TYPES_DELETE";
export const ENTITIES_TYPES_UPDATE = "ENTITIES_TYPES_UPDATE";
export const ENTITIES_TYPES_INSERT = "ENTITIES_TYPES_INSERT";

/*
 * CONCEPT
 */

export const CONCEPT_FETCH = "CONCEPT_FETCH";
export const CONCEPT_FETCH_MORE = "CONCEPT_FETCH_MORE";
export const CONCEPT_FETCH_PENDING = "CONCEPT_FETCH_PENDING";
export const CONCEPT_REJECTED = "CONCEPT_REJECTED";
export const CONCEPT_UPDATE = "CONCEPT_UPDATE";
export const CONCEPT_INSERT = "CONCEPT_INSERT";
export const CONCEPT_DELETE = "CONCEPT_DELETE";

/*
 * CONCEPT_MASTER_FETCH
 */
export const CONCEPT_MASTER_FETCH = "CONCEPT_MASTER_FETCH";
export const CONCEPT_MASTER_FETCH_MORE = "CONCEPT_MASTER_FETCH_MORE";
export const CONCEPT_MASTER_FETCH_PENDING = "CONCEPT_MASTER_FETCH_PENDING";
export const CONCEPT_MASTER_FETCH_REJECTED = "CONCEPT_MASTER_FETCH_REJECTED";
export const CONCEPT_MASTER_FETCH_UPDATED = "CONCEPT_MASTER_FETCH_UPDATED";
export const CONCEPT_MASTER_FETCH_DELETE = "CONCEPT_MASTER_FETCH_DELETE";
export const CONCEPT_MASTER_FETCH_INSERT = "CONCEPT_MASTER_FETCH_INSERT";
/*
 * ENTITIES PERMISSION
 */
export const ENTITIES_PERMISSIONS_FETCH = "ENTITIES_PERMISSIONS_FETCH";
export const ENTITIES_PERMISSIONS_REJECTED = "ENTITIES_PERMISSIONS_REJECTED";
export const ENTITIES_PERMISSIONS_FETCH_MORE =
	"ENTITIES_PERMISSIONS_FETCH_MORE";
export const ENTITIES_PERMISSIONS_FETCH_PENDING =
	"ENTITIES_PERMISSIONS_FETCH_PENDING";
export const ENTITIES_PERMISSIONS_DELETE = "ENTITIES_PERMISSIONS_DELETE";
export const ENTITIES_PERMISSIONS_UPDATE = "ENTITIES_PERMISSIONS_UPDATE";
export const ENTITIES_PERMISSIONS_INSERT = "ENTITIES_PERMISSIONS_INSERT";

/*
 * ENTITIES PERMISSION
 */
export const NOVELTY_PERMISSION_FETCH = "NOVELTY_PERMISSION_FETCH";
export const NOVELTY_PERMISSION_REJECTED = "NOVELTY_PERMISSION_REJECTED";
export const NOVELTY_PERMISSION_FETCH_MORE = "NOVELTY_PERMISSION_FETCH_MORE";
export const NOVELTY_PERMISSION_FETCH_PENDING =
	"NOVELTY_PERMISSION_FETCH_PENDING";

/*
 * TIME ZONE
 */
export const TIME_ZONE_FETCH = "TIME_ZONE_FETCH";
export const TIME_ZONE_REJECTED = "TIME_ZONE_REJECTED";
export const TIME_ZONE_FETCH_MORE = "TIME_ZONE_FETCH_MORE";
export const TIME_ZONE_FETCH_PENDING = "TIME_ZONE_FETCH_PENDING";

/*
 * DOCUMENT_TYPES
 */
export const DOCUMENT_TYPES_FETCH = "DOCUMENT_TYPES_FETCH";
export const DOCUMENT_TYPES_FETCH_MORE = "DOCUMENT_TYPES_FETCH_MORE";
export const DOCUMENT_TYPES_FETCH_PENDING = "DOCUMENT_TYPES_FETCH_PENDING";
export const DOCUMENT_TYPES_REJECTED = "DOCUMENT_TYPES_REJECTED";
export const DOCUMENT_TYPES_UPDATE = "DOCUMENT_TYPES_UPDATE";
export const DOCUMENT_TYPES_INSERT = "DOCUMENT_TYPES_INSERT";
export const DOCUMENT_TYPES_DELETE = "DOCUMENT_TYPES_DELETE";

/**
 * SCHEDULING
 */

export const SCHEDULINGS_FETCH = "SCHEDULINGS_FETCH";
export const SCHEDULINGS_FETCH_MORE = "SCHEDULINGS_FETCH_MORE";
export const SCHEDULE_INSERT = "SCHEDULE_INSERT";
export const SCHEDULES_FETCH_PENDING = "SCHEDULES_FETCH_PENDING";
export const SCHEDULES_FETCH = "SCHEDULES_FETCH";
export const SCHEDULES_FETCH_MORE = "SCHEDULES_FETCH_MORE";
export const SCHEDULE_REJECTED = "SCHEDULE_REJECTED";
