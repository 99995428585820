import { Chip } from "@material-ui/core";
import React from "react";

const IntelliChip = ({
  onClick,
  onDelete,
  icon,
  variant,
  size,
  label,
  colorClass,
}) => {
  return (
    <div style={{paddingRight: '5px'}} >
      <Chip
        icon={icon !== null ? <i className={icon}></i> : icon}
        label={label}
        onClick={onClick}
        onDelete={onDelete}
        variant={variant}
        size={size}
        className={`Intelli_chip ${colorClass}`}
        // color="primary"
      />
    </div>
  );
};

IntelliChip.defaultProps = {
  icon: null,
  variant: "default",
  size: "small",
  onClick: null,
  onDelete: null,
  label: "",
  colorClass: "colorPrimary",
};

export default IntelliChip;
