import { REHYDRATE } from "redux-persist/es/constants";
import utils from "../../../../utils";
import {
	VERIFICATION_MODES_FETCH,
	VERIFICATION_MODES_FETCH_PENDING,
	VERIFICATION_MODES_REJECTED,
} from "../../constants";

const initialState = {
	data: {
		results: [],
	},
	isFetching: false,
};

export function verificationModesReducer(state = initialState, action) {
	switch (action.type) {
		case REHYDRATE:
			return utils.actionRehydrate(state, action.payload, "verificationModes");
		case VERIFICATION_MODES_FETCH_PENDING:
			return {
				...state,
				isFetching: true,
			};
		case VERIFICATION_MODES_REJECTED:
			return {
				...state,
				isFetching: false,
			};
		case VERIFICATION_MODES_FETCH:
			return {
				data: action.payload.data.data,
				isFetching: false,
			};
		default:
			return state;
	}
}
