import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Langs from "../resources/Langs";

let lang = localStorage.intl || "es-CO";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: Langs,
    lng: lang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: "es-CO",
    ns: ["translations"], //nameSpace
    defaultNS: "translations", //default NameSpace
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
    },
  });

export default i18n;
