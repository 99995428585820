import { Tooltip } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GRID_OPTIONS } from "../../timeline/constants";
import SchedulingItemDropped from "../SchedulingItemDropped";

const SpanQuantity = ({ data, label, color = "#cfd8dc" }) => {
  return (
    <Tooltip title={label}>
      <span
        style={{
          color: color,
        }}
      >
        {data}
      </span>
    </Tooltip>
  );
};

const setColor = (proportion) => {
  if (0 <= proportion && proportion < 0.2) {
    return "#ffcdd2"; //claro
    // return "#e57373"//oscuro;
  }
  if (0.2 <= proportion && proportion < 0.6) {
    return "#fff3e0"; //claro
    // return "#ffe0b2" //oscuro;
  }
  if (0.6 <= proportion && proportion <= 1) {
    return "#f1f8e9"; //claro
    // return "#dcedc8"; //oscuro
  }
};

const WaterMarkComponent = (props) => {
  const { location = "all", day } = props;

  const { t } = useTranslation();

  const mssg = t("FormErrors.schedulingNotAvailable");
  return (
    <div className={`water-mark ${location || "all"}`}>
      {!day.data ? (
        mssg.split(" ").map((w) => {
          return (
            <span
              style={{ color: "#cfd8dc", margin: "0 3px", fontSize: "20px" }}
            >
              {w}
            </span>
          );
        })
      ) : (
        <>
          <div className="title">
            <span style={{ color: "#cfd8dc" }}>
              {location === false
                ? t("FormErrors.availability")
                : `${t("FormErrors.availability")
                    .split("")
                    .slice(0, 4)
                    .join("")}.`}
            </span>
          </div>
          <div className="quantity">
            <SpanQuantity
              data={day.data? day.data.employees_availables:0}
              label={day.data? day.data.employees_availables:0}
              // color={color}
            />
          </div>
        </>
      )}
    </div>
  );
};

const DayMonth = ({
  day,
  height,
  auth,
  structures,
  modalState,
  location,
  vPlace,
  ...props
}) => {
  const { items, date, ownMonth, selected } = day;
  const [menuOpen, setMenuOpen] = useState(null);
  const [onHover, setOnHover] = useState(false);

  if(day.data == undefined){
    return null
  }

  const disabledDate =
    moment(date).format("YYYY-MM-DD") >=
    moment().add(14, "d").format("YYYY-MM-DD");

  const disableWatermark =
    (!!location && location === 455) ||
    (!!day.data && day.data.employees_availables === 0 && day.data.employees_total === 0);
  const color = !!day.data
    ? setColor(
        !disableWatermark
          ? day.data.employees_availables / day.data.employees_total
          : day.data.employees_availables / day.data.employees_total
      )
    : null;
  return (
    <div
      className="calendar-month__day noselect"
      style={{
        cursor: disabledDate && "not-allowed",
        height: height,
        backgroundColor: disabledDate
          ? "#eaeaea"
          : !!selected
          ? "#f0f4c3"
          : color
          ? `${color}`
          : !ownMonth
          ? "#efefef"
          : "",
      }}
      onMouseDown={(e) => {
        if (onHover || disabledDate) return;
        e.preventDefault();
        e.stopPropagation();
        props.mouseState.setMouseDown({ date });
      }}
      onMouseUp={(e) => {
        if (onHover || disabledDate) return;
        e.preventDefault();
        e.stopPropagation();
        props.mouseState.setMouseUp({ date });
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        className={`date ${
          moment().format("YYYY-MM-DD") === date ? "today" : ""
        } `}
      >
        <span>{date.split("-")[2]}</span>
      </div>
      <div
        className="items"
        onMouseDown={() => !!items.length && setOnHover(true)}
        onMouseUp={() => !!items.length && setOnHover(false)}
      >
        {!!items.length &&
          items.map((i, idx) => {
            return (
              <SchedulingItemDropped
                key={idx}
                index={idx}
                item={i}
                gridOptions={GRID_OPTIONS.WEEK}
                // FORMAT={auth.data.user.settings_user.format_time}
                menuOpen={{ menuOpen, setMenuOpen }}
                onClick={() => vPlace.set({ open: true, obj: i })}
                auth={auth}
                structures={structures}
                onHover={setOnHover}
                handleModal={() => {
                  modalState.setOpenModal((s) => {
                    return { ...s, item: i, open: true, new: false };
                  });
                }}
                funDelete={() => {
                  props.funDelete(i.id_person_scheduling);
                }}
                preventDelete={day.date <= moment().format("YYYY-MM-DD")}
                date={date}
                selected={selected}
              />
            );
          })}
      </div>
      <WaterMarkComponent
        // data={{
        // 	employees_availables: day.data.employees_availables,
        // 	employees_total: day.data.employees_total,
        // }}
        day={day}
        location={!!items.length && "up"}
        noSchedulings={!!day.data}
      />
    </div>
  );
};

export default DayMonth;
