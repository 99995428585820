import React from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";

const IntelliLoading = ({ process }) => {
  if (!process.mode) return null;
  return (
    <div className="Intelli_loading">
      <Loader
        type="ThreeDots"
        color="#4276b9"
        height={80}
        width={80}
        timeout={10000}
      />
    </div>
  );
};

const mapStateToProps = ({ process }) => ({ process });

export default connect(mapStateToProps)(IntelliLoading);
