import { Tooltip } from "@material-ui/core";
import React from "react";
import { IntelliIcon } from "./IntelliIcon";

const IntelliInfo = ({ name, value, direction, icon = null }) => {
  return (
    <div className="Intelli_info" style={{ flexDirection: direction }}>
      {!!icon && <IntelliIcon icon={icon.icon} color={icon.color} />}
      <Tooltip title={name} classes={{ tooltip: "tooltipCard_tooltip" }}>
        <span className="name">{name}:</span>
      </Tooltip>
      <Tooltip title={value} classes={{ tooltip: "tooltipCard_tooltip" }}>
        <span className="value">{value}</span>
      </Tooltip>
    </div>
  );
};

IntelliInfo.defaultProps = {
  direction: "column",
};

export default IntelliInfo;
