import { TextField } from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

const IntelliTimePicker = connect(({ auth }) => ({ auth }))(
	({ value, handleDateChange, auth, label }) => {
		const _value = moment(value, "HH:mm:ss");
		const mask =
			auth.data.format_time === "hh:mm a"
				? "__:__ _m"
				: "__:__";
		return (
			<KeyboardTimePicker
				label={label}
				placeholder={
					auth.data.format_time === "hh:mm a"
						? "08:00 am"
						: " 08:00"
				}
				mask={mask}
				format={auth.data.format_time}
				maskChar="_"
				value={_value}
				onChange={handleDateChange}
				className="Intelli_datepicker time"
				inputVariant="outlined"
				variant="inline"
				autoOk
				invalidDateMessage={null}
				ampm={auth.data.format_time === "hh:mm a"}
			/>
		);
	}
);

export default IntelliTimePicker;
