import utils from "../../../../utils";

export default function (dispatch) {
	let headers = utils.headerAxios();
	if (!headers || !localStorage.id_client || !localStorage.token) {
		dispatch({ type: "USER_LOGOUT" });
		return false;
	} else {
		return true;
	}
}
