import React, { Component } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import PropTypes from "prop-types";

const typesIcons = {
  solid: "fas",
  regular: "far",
  light: "fal",
  duotone: "fad"
};

const IntelliButton = (props) => {
  let intelliIcon = props.intelliIcon;
  let type = props.type ? props.type : "primary";
  let color = props.disabled
    ? "colorDisabled"
    : props.color
    ? props.color
    : "primary";
  let typeIcon = props.typeIcon ? typesIcons[props.typeIcon] : "fal";
  let varIcon = props.varIcon ? props.varIcon : "scrubber";
  let radius = props.radius ? props.radius : 8;
  varIcon = intelliIcon || intelliIcon != null ? varIcon : `fa-${varIcon}`;

  return (
    <ButtonBase
      {...props.customProps}
      onClick={props.onClick}
      className={`
          intelliButtonBase
          ${type}
          ${color} 
          ${props.customProps.disabled ? "blocked" : ""}
      `}
      classes={{ root: "intelliButtonBase" }}
      style={props.style}
      disableRipple
      disabled={props.disabled}
    >
      <div className="text">{props.varTitle + (props.required ? " *" : "")}</div>
      <div className="icon">
        <i
          className={`${
            !!intelliIcon ? intelliIcon : typeIcon + " " + varIcon
          }`}
        ></i>
      </div>
    </ButtonBase>
  );
};

IntelliButton.propTypes = {
  customProps: PropTypes.object,
  onClick: PropTypes.func,
  varTitle: PropTypes.string,
};

IntelliButton.defaultProps = {
  customProps: {},
  disabled: false,
  required: false,
  style: {},
};
export default IntelliButton;
