const Langs = {
  "en-US": {
    translations: {
      About: {
        about: "About MyIntelli",
        copyright: "Copyright Intellinext-Corp 2021 ©",
        versions: "Microservices versions",
        politics: "Data processing policy",
        usPolitics: "To learn about treatment policies, click on this link",
        recover: "Password recovery",
      },
      Login: {
        username: "Username",
        password: "Password",
        login: "Login",
        rights: "All rights reserved - intelli next 2021 ©",
        btnRecover: "Forgot your password?",
        email: "Email",
        invalidUrl: "Invalid Url",
        newKey: "Enter PIN",
        key: "Key",
        title: "Welcome",
        btnLog: "Enter",
        plcIdc: "ID client",
        plcUsr: "Username",
        plcPsw: "Password",
        plcPswConfirm: "Confirm password",
        plcEmail: "E-Mail",
        language: "Language",
        clientError: "Client code not found!",
        userError: "Incorrect type and/or document",
        process: "Recover",
        titleRecover: "Forgot your password?",
        placeholderRecoverEmail: "example@domain.com",
        placeholderRecoverIdClient: "1",
        passwordRecoveryTitle: "Recovering password",
        passwordResetedSuccesfully: "Password reseted succesfully.",
        passwordResetedFailed: "Could not restore the password!",
        serverDown: "Connection failed!",
        validationError: "Invalided email!",
        btnBackLogin: "Homepage",
        passwordEmpty: "Password empty",
        newPasswordSuccess: "New password generated successfully",
        newPassword: "Enter new password",
        disabled_user: "The user is inactive",
        user_blocked: "User is blocked, try to recover password",
        cameraNotFound: "Camera device not found",
        confirmPassword: "Confirm password",
      },
      Token: {
        expired: "Session has expired",
        invalid: "Invalid token",
        dismissed: "Dismissed token",
        verified: "Verified token",
      },
      General: {
        accept: "Accept",
        overwrite: "Overwrite",
        info: "Information",
        documentType: "Document type",
        typesDocument: {
          1: "ID",
          2: "FOREIGN ID",
          3: "PERMANENT ID",
          4: "PASSPORT",
          5: "TAX ID",
          6: "CIVIL REGISTRATION",
          7: "SINGLE TAX REGISTRY",
        },
        warning: "Warning",
        user: "User and IP address",
        principal: "Dashboard",
        notActiveService: "MyIntelli service is not active. ",
        downloadHere: "Download here",
        titleModalSchedule: "Schedule details",
        insertSuccess: "Inserted successfully",
        selectCamera: "Camera",
        name: "Name",
        mail: "Email",
        phone: "Phone",
        toolbarReminder: "Remember you can also schedule from Google Calendar",
        birthDate: "Birth date",
        locationDetails: "Location details",
        timelogDetails: "Timelog details",
        timelogSuccess: "Timelog success",
        newTimelog: "New timelog",
        newTimelogIn: "New timelog in",
        newTimelogOut: "New timelog out",
        details: "Details",
        viewDetails: "View details",
        pair: "Timelog pair",
        days: "Days",
        hours: "Ordinary hours",
        extra: "Extra hours",
        absences: "Absences",
        search: "Search",
        today: "Today",
        month: "Month",
        fabNew: "New",
        fabMatrix: "Matrix ",
        fabTimeline: "Time line",
        btnOk: "Save",
        btnCancel: "Cancel",
        btnClose: "Close",
        btnSubmit: "Submit",
        btnBack: "Back",
        btnNext: "Next",
        Schedules: "Schedules",
        Schedule: "Schedule",
        Position: "Position",
        Job: "Job",
        Location: "Location",
        activeEntity: "Active",
        notActiveEntity: "No active",
        toActivate: "Activate",
        Start: "Start",
        End: "End",
        Time: "Time",
        Type: "Type",
        Description: "Description",
        Status: "Status",
        permission: "Permits",
        Workshift: "Workshift",
        permissions: "Permits",
        Workshifts: "Workshifts",
        Enrolling: "Enrolling",
        selectEntity: "Select Entity",
        entity: "Entity",
        in: "Entry",
        out: "Exit",
        capture: "Capture",
        date: "Date",
        day: "Day",
        typeTimelog: "Type timelog",
        TTymelog: "Timelog",
        timelogs: "Timelogs",
        times: "Times",
        newRegister: "Insert new record",
        messageDeleteRecord: "Are you sure you want to delete this record?",
        entryExit: "Entry or Exit",
        entry: "Entry",
        exit: "Exit",
        kilometres: "Kilometers",
        miles: "Miles",
        selectCut: "Cut",
        weekly: "Weekly",
        biweekly: "Biweekly",
        monthly: "Monthly",
        attention: "Attention",
        delete: "Delete",
        verificationMode: "Verification mode",
        titleSuccess: "Operations",
        updateSuccess: "Updated successfully",
        document: "Document",
        typeDocument: "Document type",
        first_timelog: "First",
        last_timelog: "Last",
        planifiedHours: "Planified hours",
        workedUpHours: "Worked hours",
        settings_device: {
          serial: "Key",
        },
        generateSerial: "Generate key",
        workstationErrorTitle: "Error generating key",
        workstationError: "Contact to support",
        enrollComputerSuccess: "Computer successfully enrolled",
        enrollPhotoSuccess: "Photo successfully enrolled",
        enrollDevice: "Enroll device",
        enrollPhoto: "Enroll photo",
        noOptions: "No options",
        location: "Location",
        used: "Used",
        notUsed: "Not used",
        scheduleInGoogleCalendar: "Schedule in Google Calendar",
        rememberText: "Remember Select the Room on Google Calendar",
        goToCalendar: "Go to Calendar"
      },
      Dow: {
        Sunday: "SUNDAY",
        Monday: "MONDAY",
        Tuesday: "TUESDAY",
        Wednesday: "WEDNESDAY",
        Thursday: "THURSDAY",
        Friday: "FRIDAY",
        Saturday: "SATURDAY",
      },
      Header: {
        info: "User settings",
        configuration: "Configuration",
        notifications: "Notifications",
        exit: "Exit",
        deviceRole: "Enroll device",
        support: "Help",
      },
      User: {
        user_name: "User",
        password: "Password",
        passwordNew: "New Password",
        password_current: "Actual Password",
        typeThePassword: "Type your current password",
        passwordConfirm: "Confirm password",
        roles: "User roles",
        timeLocal: "LOCAL TIME",
        timeEvent: "EVENT TIME",
        settings_user_format_date: "Date format",
        settings_user_format_date_short: "Short date format",
        settings_user_format_hour: "Time format",
        settings_user_format_numeric: "Number format",
        settings_user_event_time: "Event time",
        settings_user_format_name: "Name format",
        settings_user_format_temperature: "Temperature format",
        settings_user_format_length: "Measure format",
        settings_user_status_approval: "Level approval",
        settings_user_format_calculation: "Calculation format",
        password_repeat: "Enter the password again",
        change_password: "Change password",
        phone: "Phone",
        email: "Email",
        status: "Status",
        person_status: "Person status",
        active_person: "Only active",
        user: "Name",
        active: "Active",
        inactive: "Inactive",
        structure: "Permits on organizational structure",
        recoveryError: "Email no found",
        recoverySuccess: "An email with the current password was sent",
        change_data: "User configuration",
        allPermissionTooltip: "Assign all permits",
        allPermission: "All permits",
        permission: "Permits",
        passwordCurrent: "Current password",
        receiveVisits: "Receive visits?",
        visits: "Visits",
        FnLn1: "FIRST NAME / LAST NAME",
        FnLn1Ln2: "FIRST NAME / LAST NAME / SECOND LAST NAME",
        FnMnLn1Ln2: "FIRST NAME / MIDDLE NAME / LAST NAME / SECOND LAST NAME",
        FnMnLn1: "FIRST NAME / MIDDLE NAME / LAST NAME",
        Ln1Fn: "LAST NAME / FIRST NAME",
        Ln1Ln2Fn: "LAST NAME / SECOND LAST NAME / FIRST NAME",
        Ln1Ln2FnMn: "LAST NAME / SECOND LAST NAME / FIRST NAME / MIDDLE NAME",
        Ln1FnMn: "LAST NAME / FIRST NAME / MIDDLE NAME",
        work_stations: "Workstations",
        create_visit: "Register visit?",
        create_visit_tooltip: "Register visit",
        timeZone: "Time zone",
        language: "Language",
        plcPswConfirm: "Confirm password",
        infoPerson: "Personal information",
      },
      verificationModes: {
        FINGERPRINT: "FINGERPRINT",
        IRIS: "IRIS",
        VOICE: "VOICE",
        PIN: "PIN",
        PIN_NUMBER: "PIN NUMBER",
        PIN_ALPHANUMERIC: "ALPHANUMERIC PIN",
        CARD: "CARD",
        FACIAL: "FACIAL",
        PHOTO: "PICTURE",
        PALM: "PALM",
        USER: "USER CREATED",
        FACIAL_MOBILE: "FACIAL MOBILE",
        FACIAL_PC: "FACIAL COMPUTER",
        FINGERPRINT_PC: "FINGERPRINT COMPUTER",
        PIN_PC: "PIN COMPUTER",
        PIN_MOBILE: "PIN MOBILE",
        FINGERPRINT_CARD: "FINGERPRINT, CARD",
        FINGERPRINT_CARD_PIN: "FINGERPRINT, CARD AND PIN",
        PC: "COMPUTER",
        MOBILE: "MOBILE",
      },
      timeplanning: {
        time: "Time",
        matrix: {
          concept: "Concept",
        },
      },
      timeAttendance: {
        status_approval: "Level of approval",
        modalLocalization: "Location",
      },
      ConceptMaster: {
        ABSENCE: "ABSENCES",
        A_ABSENCE: "ABSOLUTE ABSENCES",
        BREAK: "BREAK",
        COMPENSATORY: "COMPENSATION",
        DELAY: "DELAY",
        EXTRA: "EXTRA HOURS",
        HOUR: "HOURS",
        PERMISSION: "PERMIT",
        HOURS: "HOURS",
        HOURS_NIGHT: "NIGHTLY HOURS",
        HOURS_SUNDAY: "SUNDAY HOURS",
        HOURS_SUNDAY_NIGHT: "SUNDAY NIGHTLY HOURS",
        HOURS_HOLIDAY: "HOLIDAY HOURS",
        HOURS_HOLIDAY_NIGHT: "HOLIDAY NIGHTLY HOURS",
        HOURS_WITHOUT_SCHEDULE: "HOURS WITHOUT SCHEDULE",
        HOURS_WITHOUT_SCHEDULE_NIGHT: "HOURS WITHOUT NIGHTLY SCHEDULE",
        HOURS_WITHOUT_SCHEDULE_SUNDAY: "HOURS WITHOUT SUNDAY SCHEDULE",
        HOURS_WITHOUT_SCHEDULE_SUNDAY_NIGHT:
          "HOURS WITHOUT SCHEDULE SUNDAY NIGHT",
        HOURS_WITHOUT_SCHEDULE_HOLIDAY: "HOURS WITHOUT SCHEDULE HOLIDAY",
        HOURS_WITHOUT_SCHEDULE_HOLIDAY_NIGHT:
          "HOURS WITHOUT SCHEDULE HOLIDAY NIGHT",
        EXTRA_NIGHT: "EXTRA NIGHT",
        EXTRA_SUNDAY: "EXTRA SUNDAY",
        EXTRA_SUNDAY_NIGHT: "EXTRA SUNDAY NIGHT",
        EXTRA_HOLIDAY: "EXTRA HOLIDAY",
        EXTRA_HOLIDAY_NIGHT: "EXTRA HOLIDAY NIGHT",
        DELAY_NIGHT: "DELAY NIGHT",
        DELAY_SUNDAY: "DELAY SUNDAY",
        DELAY_SUNDAY_NIGHT: "DELAY SUNDAY NIGHT",
        DELAY_HOLIDAY: "DELAY HOLIDAY",
        DELAY_HOLIDAY_NIGHT: "DELAY HOLIDAY NIGHT",
        BREAK_NIGHT: "BREAK NIGHT",
        BREAK_SUNDAY: "BREAK SUNDAY",
        BREAK_SUNDAY_NIGHT: "BREAK SUNDAY NIGHT",
        BREAK_HOLIDAY: "BREAK HOLIDAY",
        BREAK_HOLIDAY_NIGHT: "BREAK HOLIDAY NIGHT",
        BREAK_LUNCH: "BREAK LUNCH",
        BREAK_LUNCH_NIGHT: "BREAK LUNCH NIGHT",
        BREAK_LUNCH_SUNDAY: "BREAK LUNCH SUNDAY",
        BREAK_LUNCH_SUNDAY_NIGHT: "BREAK LUNCH SUNDAY NIGHT",
        BREAK_LUNCH_HOLIDAY: "BREAK LUNCH HOLIDAY",
        BREAK_LUNCH_HOLIDAY_NIGHT: "BREAK LUNCH HOLIDAY NIGHT",
        COMPENSATORY_NIGHT: "COMPENSATORY NIGHT",
        COMPENSATORY_SUNDAY: "COMPENSATORY SUNDAY",
        COMPENSATORY_SUNDAY_NIGHT: "COMPENSATORY SUNDAY NIGHT",
        COMPENSATORY_HOLIDAY: "COMPENSATORY HOLIDAY",
        COMPENSATORY_HOLIDAY_NIGHT: "COMPENSATORY HOLIDAY NIGHT",
        DAY_OFF: "DAY OFF",
        ABSENCE_NIGHT: "ABSENCE NIGHT",
        ABSENCE_SUNDAY: "ABSENCE SUNDAY",
        ABSENCE_SUNDAY_NIGHT: "ABSENCE SUNDAY NIGHT",
        ABSENCE_HOLIDAY: "ABSENCE HOLIDAY",
        ABSENCE_HOLIDAY_NIGHT: "ABSENCE HOLIDAY NIGHT",
        A_ABSENCE_SUNDAY: "ABSOLUTE ABSENCE SUNDAY",
        A_ABSENCE_HOLIDAY: "ABSOLUTE ABSENCE HOLIDAY",
        HOURS_BEFORE_SCHEDULE: "HOURS BEFORE SCHEDULE",
        HOURS_BEFORE_SCHEDULE_NIGHT: "HOURS BEFORE SCHEDULE NIGHT",
        HOURS_BEFORE_SCHEDULE_SUNDAY: "HOURS BEFORE SCHEDULE SUNDAY",
        HOURS_BEFORE_SCHEDULE_SUNDAY_NIGHT:
          "HOURS BEFORE SCHEDULE SUNDAY NIGHT",
        HOURS_BEFORE_SCHEDULE_HOLIDAY: "HOURS BEFORE SCHEDULE HOLIDAY",
        HOURS_BEFORE_SCHEDULE_HOLIDAY_NIGHT:
          "HOURS BEFORE SCHEDULE HOLIDAY NIGHT",
        HOURS_AFTER_SCHEDULE: "HOURS AFTER SCHEDULE",
        HOURS_AFTER_SCHEDULE_NIGHT: "HOURS AFTER SCHEDULE NIGHT",
        HOURS_AFTER_SCHEDULE_SUNDAY: "HOURS AFTER SCHEDULE SUNDAY",
        HOURS_AFTER_SCHEDULE_SUNDAY_NIGHT: "HOURS AFTER SCHEDULE SUNDAY NIGHT",
        HOURS_AFTER_SCHEDULE_HOLIDAY: "HOURS AFTER SCHEDULE HOLIDAY",
        HOURS_AFTER_SCHEDULE_HOLIDAY_NIGHT:
          "HOURS AFTER SCHEDULE HOLIDAY NIGHT",
        DAILY_NOVELTY_PAYMENT: "DAILY NOVELTY PAYMENT",
        DAILY_NOVELTY_PAYMENT_CONFLICT: "DAILY NOVELTY PAYMENT CONFLICT",
        DAILY_NOVELTY_WITHOUT_PAYMENT: "DAILY NOVELTY WITHOUT PAYMEN",
        DAILY_NOVELTY_WITHOUT_PAYMENT_CONFLICT:
          "DAILY NOVELTY WITHOUT PAYMENT CONFLICT",
        HOUR_NOVELTY_PAYMENT: "HOUR NOVELTY PAYMENT",
        HOUR_NOVELTY_PAYMENT_CONFLICT: "HOUR NOVELTY PAYMENT CONFLICT",
        HOUR_NOVELTY_WITHOUT_PAYMENT: "HOUR NOVELTY WITHOUT PAYMENT",
        HOUR_NOVELTY_WITHOUT_PAYMENT_CONFLICT:
          "HOUR NOVELTY WITHOUT PAYMENT CONFLICT",
      },
      FormErrors: {
        if111: "Field required",
        if112: "Unique Field",
        if112B: "The registry is being used",
        if113: "Numeric field type",
        if114: "Maximum length exceeded",
        if115: "Minimum worked hours not reached",
        if116: "Invalid email",
        if117: "Array field type",
        if118: "Ivalid value",
        if119: "String field type",
        if120: "Already registered",
        if121: "Field must be integer",
        if122: "The structure must be a door or an access control",
        if123: "Blanks are not allowed",
        if124: "The structure must allow parking",
        if130: "The new added type already exists for the person",
        if132: "Dates/hours exceed range",
        if404: "Field does not exist",
        STATUS_CONFLICT:
          "Organizational structure type cannot contain physical structures",
        if492: "Cannot be organizational structure, 1 or more assigned devices",
        if133: "Still has 1 unassigned position",
        if134: "Quantity must be greater than 0",
        if135: "Invalid reCaptcha",
        notBelongto: "Person photo not belong to",
        photoExists: "Person photo exists",
        noFace: "Person photo has not any face",
        moreThanOne: "Exists more than one element",
        entity_inactive: "Entity inactive",
        sameMonth: "no more than 30 days",
        noSchedulings: "Days without schedules",
        schedulingNotAvailable: "Schedule not available",
        availability: "Availability",
      },
      noveltyPermission: {
        type_partial: "Partial",
        type_daily: "Daily",
      },
      TimeLog: {
        date: "Date",
        hour: "Hour",
        device: "Biometric",
        structure: "Structure location",
        access_type: "Action",
        access_type_timelog: "Action",
        timelogs: "Timelogs",
        accessType: {
          0: "Entry or exit",
          1: "Entry",
          2: "Exit",
        },
        result: {
          0: "Not verified",
          1: "Verified",
          2: "Unauthorized",
        },
        verificationType: "Verification type",
        verificationResult: "Result",
        details: "Details",
        method: "Method",
        infoModal: "Timelog",
        info: "Marking data",
      },
      TextTemplate: {
        type: "Template type",
        EMAIL: "Email",
        SMS: "SMS",
        name_template: "Template name",
        text_template: "Text",
        time: "Time",
        date: "Date",
        device: "Device",
        person: "Person",
        structure: "Structure",
        dui_person: "Document Num.",
      },
      Graphics: {
        Delays: "Delays",
        WorkingWithoutSchedule: "Working without schedule",
        UnTimelyExit: "Untimely exit",
        YesterdayUnEvenTimelog: "Yesterdays uneven timelogs",
        YesterdayUnapprovedTimelog: "Yesterdays unapproved timelogs",
        WorkingPeople: "Working people",
        AbsentPeople: "Absent people",
        PermitPeople: "On permit people",
        PermitsPendingApproval: "Permits pending approval",

        StationsByLocations: "Stations by location",
        VisitsScheduledByLocations: "Visits scheduled by location",
        VisitsByLocations: "Visits by location",
        workshitTypeBreak: "Break type",
        loading: "Loading Chart ",
        visitActives: "Active visits",
        devicesTypes: "Device type",
        entityDevices: "Persons devices",
        devicesModels: "Device models",
        rolesUser: "User roles",
        operationUser: "Operations",
        eventsTypes: "Event types",
        eventsActions: "Event actions",
        accessSchedule: "Access times",
        accessStructures: "Access structures",
        category: "Logical structure",
        structureTypes: "Structure use",
        entityTypes: "Person type",
        entityTypesGroup: "Entity type",
        vehiclesTypes: "Vehicle type",
        accessRoles: "Access profiles",
        actives: "Active",
        connected: "Connected devices",
        visitedStructures: "Visited structures",
        listVisited: "Visited",
        listAccessSchedule: "Access schedule",
        blackList: "Blacklist",
        inactives: "Inactive",
        typesBiometrics: "Biometric type",
        listStatus: "Visits status",
        visitScheduledStatus: "Visits scheduled status",
        activas: "Active",
        timeloVerified: "Verified timelog",
        timeloNoVerified: "Unverified timelog",
        porcentajeTimeloVerified: "% Verified timelog",
        currentStructures: "Persons in current structures",
        currentStructuresGuest: "Guest in current structures",
        listTypesTimeLogs: "Timelog type",
        listTypesTimeLogsGuest: "Timelog type",
        in: "ENTRY",
        out: "EXIT",
        inOut: "ENTRY/EXIT",
        personInt: "Persons inside",
        personOut: "Persons outside",
        vehicleIn: "Vehicles inside",
        vehicleOut: "Vehicles outside",
        visitInt: "Visits inside",
        visitOut: "Visits outside",
        porcentajePersonInt: "% Persons inside",
        noData: "No data",
        Sunday: "Sun",
        Monday: "Mon",
        Tuesday: "Tue",
        Wednesday: "Wed",
        Thursday: "Thu",
        Friday: "Fri",
        Saturday: "Sat",
        others: "OTHERS",
        FINGERPRINT: "FINGERPRINT",
        IRIS: "IRIS",
        VOICE: "VOICE",
        PIN: "PIN",
        PIN_NUMBER: "PIN NUMBER",
        PIN_ALPHANUMERIC: "ALPHANUMERIC PIN",
        CARD: "CARD",
        FACIAL: "FACIAL",
        PHOTO: "PICTURE",
        PHOTO_ZKTECO: "PICTURE - ZKTECO",
        PALM: "PALM",
        USER: "USER CREATED",
        FACIAL_MOBILE: "FACIAL MOBILE",
        FACIAL_PC: "FACIAL COMPUTER",
        FINGERPRINT_PC: "FINGERPRINT COMPUTER",
        PIN_PC: "PIN COMPUTER",
        PIN_MOBILE: "PIN MOBILE",
        FINGERPRINT_CARD: "FINGERPRINT, CARD",
        FINGERPRINT_CARD_PIN: "FINGERPRINT, CARD AND PIN",
        PC: "COMPUTER",
        MOBILE: "MOBILE",
        usingStation: "Stations in use",
        usingClosingRules: "Closing visit rules in use",
        defaultClosingRules: "Default closing visit rules",
        estructure: "STRUCTURE",
        PLATE: "LICENSE PLATE",
        typesBiometricsVehicle: "Biometric type - Vehicle",
        visitScheduledActives: "Active scheduled visits",
        lastStructure: "Actual Structure by persons",
        entityTypesInSide: "Quantity of persons inside",
        LastStructure: "Actual structures",
        periodAutoClose: "Auto closing period",
        TypeRegularHours: "time limit Management",
        IgnoreAbsences: "Ignore absences",
        conceptUnit: "Unit use",
        conceptRounding: "Rounding use",
        noveltyType: "Permit type",
        noveltyActives: "Active permit",
        contractTypeActives: "Active contract type",
        periodActives: "Active periods",
        holidayActives: "Active holidays",
        conceptActives: "Active concepts ",
        workshiftActives: "Active workshifts",
        workshitBreakConcepts: "Use concepts in breaks",
        workshiftAutoAprovalExtra: "Auto aproval extra time - Workshifts ",
        workOrderCreated: "Work orders created",
        workOrderStatus: "Work order status",
        workOrderPosition: "Work order positions",
        templateTypesTA: "Biometric type  - Time Attendance",
        accessRolesTA: "Marking role - Time Attendance",
        entityTypesTA: "Person type - Time Attendance",
        workshifts: "Workshifts",
      },
      EventTask: {
        text_to_send: "Text to send",
        eventTypeCommon: "Common",
        // Paginas
        event: "Event",
        actions: "Actions",
        newAction: "New Action",

        // Modulos
        general: "General",
        access_control: "Intelli Access",
        visits: "Intelli Guest",
        devices: "Devices",
        users: "Users",
        notifications: "Notifications",
        system: "Systems",
        time_attendance: "Intelli Time",

        // Etiquetas/Placeholders
        event_name: "Event name",
        type_event_monitor: "What type of event do you want to monitor?",
        want_monitor: "What do you want to monitor?",
        select_type_filters: "Select the type of filters",
        select_module: "Select the module",
        type_action_do: "What type of action do you want to take?",
        select_parameters: "Select action parameters",

        // Eventos/Acciones
        ip: "IP address",
        action_scheduled: "Action Scheduled",
        scheduled_action: "Action scheduled",
        timelog: "Timelog",
        timelog_ac_accepted: "Timelog Verified - Intelli Access",
        timelog_ta_accepted: "Timelog Verified - Intelli Time",
        inTimeLog: "IN TIMELOG",
        outTimeLog: "OUT TIMELOG",
        inAndOutTimelog: "TIMELOG IN/OUT",
        disabledTimelog: "UNAUTHORIZED TIMELOG",
        firstTimeLog: "FIRST TIMELOG",
        timelog_ac_not_verified: "Not Verified Timelog - Intelli Access",
        timelog_ta_not_verified: "Not Verified Timelog - Intelli Time",
        invalidTimelog: "INVALID TIMELOG",
        delayTimeLog: "DELAY TIMELOG",
        open_exit_button: "Opening with Exit Button",
        access_scheduled: "Access Schedule",
        scheduled_access: "Access Schedule",
        change_status_door_remote: "Remote Door Status Change",
        change_status_door_manual: "Manual Door Status Change",
        visit_new: "New Visit",
        visit_new_scheduled: "New Scheduled Visit",
        visit_closed: "Closed Visit",
        visit_change_status_scheduled: "Change of Status in Scheduled Visit",
        change_access_profile_persons: "Change people access profile",
        change_access_profile_vehicles: "Change vehicle access profile",
        add_to_blacklist: "Add to blacklist",
        remove_from_blacklist: "Remove from blacklist",
        connection_change: "Change Connection",
        user_login: "User Login",
        user_creation: "User Creation",
        user_blocked: "User blocked",
        password_change: "Change of Password",
        reports: "Reports",
        open_door: "Open Doors",
        close_door: "Closed Doors",
        lock_door: "Lock Doors",
        access_scheduled_enabled: "Enable Access Schedules",
        access_scheduled_disabled: "Disable Access Schedules",
        inactivate_person: "Inactivate people",
        activate_person: "Activate people",
        inactivate_vehicles: "Inactivate vehicles",
        activate_vehicles: "Activate vehicles",
        change_access_role: "Change Access Profile",
        persons_vehicles_disabled: "Disable Person / Vehicles",
        inactivate_registration_station: "Inactivate Workstations",
        activate_registration_station: "Activate Workstations",
        blacklist_goto: "Go to Blacklist",
        inactivate_device: "Inactivate devices",
        activate_device: "Activate devices",
        alerts: "Alerts",
        widgets: "Widgets",
        text_messages: "Text Messages",
        email_messages: "Mail Messages",
        plans: "Blueprints",
        battery_status_change: "Battery status change",

        // Filtros y Parámetros
        all_person: "All Persons",
        all_vehicle: "All Vehicle",
        entities: "Persons",
        entity: "Entity",
        entities_types: "Persons type",
        device: "Device",
        structures: "Structure",
        time: "Time",
        vehicles: "Vehicles",
        vehicle_type: "Vehicle type",
        biometric_models: "Biometric models",
        verification_modes: "Verification modes",
        start: "Start",
        end: "End",
        start_end: "At the time of?",
        user: "User",
        visitants: "Visitors",
        visitors: "Visitor",
        visited: "Visited",
        access_roles: "Access profile",
        work_stations: "Workstations",
        report_templates: "Report templates",
        execution_time: "Execution time",
        weekdays: "Weekdays",
        devices_types: "Device type",
        device_status: "Device status",
        door_status: "State of doors",
        scheduled_visit_status: "Scheduled visit status",
        closing_mode_status: "Closing mode",
        connected: "Connected",
        disconnected: "Disconnected",
        open: "Open",
        close: "Close",
        lock: "Lock",
        executed: "Executed",
        cancelled: "Cancelled",
        automatic: "Automatic",
        manual: "Manual",
        user_defined: "Defined user",
        emails: "Emails",
        emailsPlaceholder: "example@email.com",
        recurrence_value: "Recurrence value",
        text_messaging_templates: "SMS templates",
        // text_messaging_templates: 'Text Messaging Templates',
        based_on_event: "Based event",
        access_types: "Access type",
        verification_results: "Verification results",
        persons_groups: "Person group",
        devices_groups: "Device group",
        vehicles_groups: "Vehicle group",
        battery_status: "Battery Status",
        based_event: "Based event",
        create_update_user: "User modifications",

        // Campos
        field_in_date: "Start date",
        field_out_date: "End date ",
        field_in_hour: "Start time",
        field_out_hour: "End time",
        field_status: "Status",
        field_event_name: "Event name",
        field_settings_type: "Settings type",
        field_supervise: "Supervising",
        field_filters: "Filters",
        field_entities: "Entities",
        field_entities_types: "Persons type",
        field_devices: "Devices",
        field_structures: "Structure",
        field_dow: "Days of week",
        field_vehicles: "Vehicles",
        field_plate: "License plate",
        field_users: "Users",
        field_visitants: "Visitors",
        field_devices_models_biometrics: "Biometric models",
        field_verification_modes: "Verification modes",
        field_access_scheduled: "Access times",
        field_start_end: "At the time of?",
        field_access_roles: "Access roles",
        field_work_stations: "Workstations",
        field_execute_hour: "Execution time",
        field_device_types: "Device type",
        field_access_type: "Access type",
        field_visiteds: "Visited",
        field_verification_result: "Verification Result",
        field_vehicles_types: "Vehicle type",
        field_filter_status: "Devices status",
        field_common_action: "Common action",
        field_module: "Module",
        field_icons_groups: "Icon group",
        field_entities_groups: "Person group",
        field_devices_groups: "Device group",
        field_vehicles_groups: "Vehicle group",
        field_based_on_event: "Based on event",
        field_repetitions: "Repetitions",
        field_recurrence: "Recurrence",
        field_parameters: "Parameters",
        field_phones: "Phones",
        field_emails: "Emails",
        field_time_delay: "Delay time",
        field_access_profiles: "Access profiles",
        field_time_hour: "Hour",
        field_content_text: "Contained text",
        field_id_text_template: "Id text template",

        // triggered_at: 'Event time',
        // event_description: 'Event description',
        eventTypes: "Event type",
        // eventAction: 'Action',
        // eventHeld: 'Action performed',
        persons_event: "Select the involved persons",
        // devices_event: 'Devices',
        // device: 'Device',
        // structure: 'Structure',
        // settingsTypeIn: 'Since',
        // settingsTypeOut: 'Until',
        // type: 'Type',
        // category: 'Category',
        // typeNotification: 'NOTIFICATION TYPE',
        // actionDevices: 'Actions devices',
        // sendType: 'Priority send',
        // email: 'Emails',
        // phone: 'Phones',
        // hourAction: 'Hour send',
        // status: 'Status',
        // sendMoment: 'SEND AT THE MOMENT',
        // visitTimelogs: 'VISITS TIMELOGS',
        // sendSpecificTime: 'SEND IN A SPECIFIC TIME',
        // notificationBar: 'NOTIFICATION IN THE SUPERIOR BAR',
        // alertMessage: 'Alert message',
        // simpleNotification: 'SIMPLE NOTIFICATION',
        // activeAlarm: 'ACTIVE ALARM',
        // turnOnLight: 'TURN ON LIGHT',
        // turnOffLight: 'TURN OFF LIGHT',
        // sendEmail: 'SEND EMAIL',
        // sendSms: 'SEND SMS',
        // openDoor: 'OPEN DOOR',
        // widgetNotification: 'WIDGET NOTIFICATION',
        // soundedAlarm: 'INTRUSION IN AREA',
        // anyone: 'ANYONE',
        // emergencyLight: 'EMERGENCY LIGHT',
        // nightLight: 'NIGHT LIGHT',
        // backupLight: 'BACKUP LIGHT',
        // Entities_types: 'Types person',
        // emails: 'Emails',
        phones: "Phones",
        // dashboardTimelogs: 'DASHBOARD INTELLI ACCESS',
        // dashboardVisit: 'DASHBOARD GUEST ',

        createOrUpdateUser: {
          2: "NEW USER",
          3: "UPDATE USER",
        },

        //LIST
        label_module: "Module",
        label_event_type: "Event type",
        label_supervising: "supervising",
        label_filters: "Filters",
        label_parameters: "Parameters",
        label_event_action: "Action",
        label_structures: "Structure",
        label_devices: "Devices",
        label_devices_models: "Device models",
        label_not_has_elements: "Has no information",
        infoEvent: "Event information",
        infoActions: "Event actions",
        infoAction: "Action information",
        recurenceDaily: "Daily",
        recurenceWeekly: "Weekly",
        label_execute_time: "Execution time",
        label_minutes: "Minutes",
        label_elements: "Elements",
        label_select_recurrence: "Recurrence",
        label_repetitions: "Repetitions",
        label_async_entities: "Which people?",
        label_async_entities_types: "What are the people types?",
        label_async_vehicles: "Which vehicles?",
        label_async_vehicles_types: "What are the vehicle types?",
        label_async_access_scheduled: "What access time?",
        label_async_access_profiles: "What access profile?",
        label_async_work_stations: "In which workstations?",
        label_async_users: "Which users?",
        label_async_visitants: "Which visitors?",
        label_async_Visiteds: "Which ones visited?",
        label_async_devices: "On what devices?",
        label_async_content_report:
          "What type of content will the report carry?",
        label_async_content_text:
          "What type of content will the message carry?",
        label_async_persons_groups: "In which group of people?",
        label_async_devices_groups: "In which group of devices?",
        label_async_vehicles_groups: "In which group of vehicles?",
        label_devices_types: "What are the device types?",
        label_access_types: "What are the access types?",
        label_verifications_modes: "Select verification modes",
        label_verifications_result: "Select the verification result",
        label_close_modes: "Define the way of closing the visit",
        label_what_time: "At what time?",
        label_defined_status: "Define the status",
        label_set_time: "When it occurs between",
        label_structure_event: "In what structures?",
        label_structure_action: "In what structures?",
        label_recurrence: "Recurrence value",
        limitActions: "Cannot delete, minimum limit of 1 action per event",
        visit_timelogs: "Visitor timelog",
        plan_notification: "Plan notification",
        change_status_message:
          "Are you sure you want to change the status of the registry?",
        change_status: "Change status",

        //Items
        executeTime: {
          1: "Righ now",
          2: "Delay",
          3: "Specific",
        },
        recurrenceType: {
          1: "Daily",
          2: "Weekly",
        },
        result: {
          0: "Successful",
          1: "Invalid",
        },
        495: "Cannot deleted a common event",
      },
      logicErrors: {
        invalidDateInt: "Invalid date, end date prior to the initial",
        invalidTimeInt: "Invalid Time, time out prior to time in",
        locationNotFoud: "Location not found",
        isNotLocation: "Is not a location",
        invalidScheduleForLocation: "Invalid schedule for this location",
        personHasScheduling: "Person has sheduling for this day",
        cannotDeleteResourceUsed: "You cannot delete schedulings used",
        cannotDeleteResourceOld: "You cannot delete schedules started",
        cannotUpdateResourceUsed: "You cannot update schedulings used",
        cannotUpdateResourceOld: "You cannot update schedules prior to today",
        scheduleNoAvalible:
          "Schedule not available for scheduling on that date",
        schedulingNotFound: "No schedules found for the days indicated",
        schedulingNotAvailables:
          "Full quotas for the scheduling of the indicated days",
        invalidDateLocationHaveScheduling:
          "Invalid date, location have scheduling",
        totalCannotBeZero: "Total cannot be zero",
      },
      PersonScheduling: {
        title: "Capacity",
        date_in: "Date in",
        date_out: "Date out",
        used: "Used",
        status: "Status",
        id_person: "Person",
        id_schedule: "Schedule",
        id_structure: "Location",
        id_L: "Ubicación",
        id_SL: "Ubicación Específica",
        dows: "Days",
        time_in: "Time in",
        time_out: "Time out",
        placeNoAvailable: "Place not available",
        selectedPlace: "Selected place",
        placePartiallyUsed: "Place partially used",
        datesUsed: "Dates Used",
        dow: "Day",
        date: "Date",
        time: "time",
        place: "Place",
        schedules: "Schedules",
        observations: "Observations",
        calendarSchedule: "Click to schedule room",
        legend: {
          working_place: "Working pace",
          meet_place: "Meet place",
          place_available: "Available place",
          place_no_available: "Unavailable place",
          other_places: "Other places",
          coworking_area: "Coworking area",
          meet_area: "Meet Area",
        },
      },
    },
  },
  "es-CO": {
    translations: {
      About: {
        about: "Acerca de MyIntelli",
        copyright: "Derechos reservados Intellinext-Corp 2021 ©",
        versions: "Versiones de micros servicios",
        politics: "Política de tratamiento de datos",
        usPolitics:
          "Para conocer nuestras políticas de tratamiento de datos, haga clic en este link",
        recover: "Recuperación de contraseña",
      },
      Login: {
        username: "Nombre de usuairo",
        password: "Contraseña",
        login: "Ingresar",
        rights: "Todos los derechos reservados - Intelli Next 2021 ©",
        btnRecover: "¿Olvidó su contraseña?",
        email: "Correo electrónico",
        invalidUrl: "Url inválida",
        newKey: "Ingrese PIN de soporte",
        key: "Clave",
        title: "Bienvenidos",
        btnLog: "Ingresar",
        plcIdc: "ID del cliente",
        plcUsr: "Usuario",
        plcPsw: "Contraseña",
        plcPswConfirm: "Confirmar contraseña",
        plcEmail: "Correo Electrónico",
        language: "Lenguaje",
        clientError: "Cliente no encontrado",
        userError: "Tipo y/o número de documento incorrecto",
        process: "Procesar",
        titleRecover: "¿Olvidó contraseña?",
        placeholderRecoverEmail: "ejemplo@correo.com",
        placeholderRecoverIdClient: "1",
        passwordRecoveryTitle: "Recuperando contraseña",
        passwordEmpty: "La clave no puede estar en blanco",
        passwordResetedSuccesfully: "Contraseña recuperada exitosamente!",
        passwordResetedFailed:
          "No se pudo enviar el correo de restablecimiento",
        serverDown: "Conexión fallida!",
        validationError: "Correo inválido!",
        btnBackLogin: "Página Inicial",
        passwordRequired: "Contraseña vacía",
        newPasswordSuccess: "Nueva contraseña generada exitosamente",
        newPassword: "Ingrese nueva contraseña",
        disabled_user: "El usuario se encuentra inactivo",
        user_blocked:
          "El usuario se encuentra bloqueado, intente recuperar contraseña",
        cameraNotFound: "Cámara no encontrada",
        confirmPassword: "Confirmar contraseña",
      },
      Token: {
        expired: "Su sesión ha expirado",
        invalid: "Token inválido",
        dismissed: "No hay token",
        verified: "Token verificado!",
      },
      General: {
        accept: "Aceptar",
        overwrite: "Sobreescribir",
        info: "Información",
        documentType: "Tipo de documento",
        typesDocument: {
          1: "CÉDULA",
          2: "CÉDULA DE EXTRANJERÍA",
          3: "PERMISO DE PERMANENCIA",
          4: "PASAPORTE",
          5: "NIT",
          6: "REGISTRO CIVIL",
          7: "REGISTRO ÚNICO TRIBUTARIO",
        },
        warning: "Advertencia",
        user: "Usuario y dirección IP",
        principal: "Panel principal",
        notActiveService: "El servicio de MyIntelli no está activo",
        downloadHere: "Descarga aquí",
        titleModalSchedule: "Detalles del horario",
        insertSuccess: "Insertado exitosamente",
        selectCamera: "Cámara",
        name: "Nombre",
        mail: "Correo",
        phone: "Teléfono",
        toolbarReminder:
          "Recuerde que también puede realizar su agendamiento desde el calendario de Google",
        birthDate: "Fecha de nacimiento",
        locationDetails: "Detalles de localización",
        timelogDetails: "Detalles del marcaje",
        timelogSuccess: "Marcaje generado de forma exitosa",
        newTimelog: "Nuevo marcaje",
        newTimelogIn: "Nuevo marcaje de entrada",
        newTimelogOut: "Nuevo marcaje de salida",
        details: "Detalles",
        viewDetails: "Ver detalles",
        pair: "Par marcaje",
        days: "Dìas",
        hours: "Horas ordinarias",
        extra: "Horas extras",
        absences: "Ausencias",
        search: "Buscar",
        today: "Hoy",
        month: "Mes",
        fabNew: "Nuevo",
        fabMatrix: "Matriz",
        fabTimeline: "Línea de tiempo",
        btnOk: "Guardar",
        btnCancel: "Cancelar",
        btnClose: "Cerrar",
        btnSubmit: "Enviar",
        btnBack: "Regresar",
        btnNext: "Siguiente",
        Schedules: "Horarios",
        Schedule: "Horario",
        Position: "Cargo",
        Job: "Trabajo",
        Location: "Localización",
        activeEntity: "Activa",
        notActiveEntity: "No activa",
        toActivate: "Activar",
        Start: "Inicio",
        End: "Final",
        Time: "Hora",
        Type: "Tipo",
        Description: "Descripción",
        Status: "Estatus",
        permission: "Permiso",
        Workshift: "Turno",
        permissions: "Permisos",
        Workshifts: "Turnos",
        Enrolling: "Enrolamiento",
        selectEntity: "Seleccionar la entidad",
        entity: "Entidad",
        in: "Entrada",
        out: "Salida",
        capture: "Capturar",
        date: "Fecha",
        day: "Día",
        typeTimelog: "Tipo de marcaje",
        TTymelog: "T. marcaje",
        timelogs: "Marcajes",
        times: "Tiempos",
        newRegister: "Nuevo registro",
        messageDeleteRecord:
          "¿Estás seguro de que quieres eliminar este registro?",
        entryExit: "Entrada o salida",
        entry: "Entrada",
        exit: "Salida",
        kilometres: "Kilómetros",
        miles: "Millas",
        selectCut: "Corte",
        weekly: "Semanal",
        biweekly: "Quincenal",
        monthly: "Mensual",
        attention: "Atención",
        delete: "Eliminar",
        verificationMode: "Modo de verificación",
        titleSuccess: "Operación",
        updateSuccess: "Actualizado exitosamente",
        document: "Documento",
        typeDocument: "Tipo de documento",
        first_timelog: "Primer",
        last_timelog: "Último",
        planifiedHours: "Horas planificadas",
        workedUpHours: "Horas trabajadas",
        settings_device: {
          serial: "Key",
        },
        generateSerial: "Generar key",
        workstationErrorTitle: "Error al generar la key",
        workstationError: "Contacte al administrador",
        enrollComputerSuccess: "Computadora enrolada satisfactoriamente",
        enrollPhotoSuccess: "Rostro enrolado satisfactoriamente",
        enrollDevice: "Enrolar dispositivo",
        enrollPhoto: "Enrolar foto",
        noOptions: "Sin opciones",
        location: "Sede",
        used: "Usado",
        notUsed: "No usado",
        scheduleInGoogleCalendar: "Agendar en Google Calendar",
        rememberText: "Recuerda seleccionar la Sala en el Calendario de Google",
        goToCalendar: "Ir al Calendario"
      },
      Dow: {
        Sunday: "DOMINGO",
        Monday: "LUNES",
        Tuesday: "MARTES",
        Wednesday: "MIÉRCOLES",
        Thursday: "JUEVES",
        Friday: "VIERNES",
        Saturday: "SÁBADO",
      },
      Header: {
        info: "Ajustes de usuario",
        configuration: "Configuración",
        notifications: "Notificaciones",
        exit: "Salir",
        deviceRole: "Enrolar equipo",
        support: "Asistencia",
      },

      User: {
        user_name: "Usuario",
        password: "Contraseña",
        passwordNew: "Nueva contraseña",
        password_current: "Contraseña Actual",
        typeThePassword: "Ingrese su contraseña actual",
        passwordConfirm: "Confirmar contraseña",
        roles: "Roles del usuario",
        timeLocal: "HORA LOCAL",
        timeEvent: "HORA DEL EVENTO",
        settings_user_format_date: "Formato de fechas",
        settings_user_format_date_short: "Formato de fechas cortas",
        settings_user_format_hour: "Formato de horas",
        settings_user_format_numeric: "Formato númerico",
        settings_user_event_time: "Hora en eventos",
        settings_user_format_name: "Formato de nombres",
        settings_user_format_temperature: "F. de temperatura",
        settings_user_format_length: "Formato de longitud",
        settings_user_status_approval: "Nivel de aprobación",
        settings_user_format_calculation: "Formato de cálculos",
        password_repeat: "Ingrese la contraseña nuevamente",
        change_password: "Cambiar contraseña",
        phone: "Télefono",
        email: "Correo electronico",
        status: "Estatus",
        active_person: "Solo activas",
        user: "Nombre",
        active: "Activa",
        inactive: "Inactiva",
        structure: "Permisos sobre la estructura organizativa",
        recoveryError: "Correo no encontrado",
        recoverySuccess: "Enviado enlace de restablecimiento de contraseña",
        change_data: "Configuración de usuario",
        allPermissionTooltip: "Dar todos los permisos",
        allPermission: "Todos los permisos",
        permission: "Permisos",
        passwordCurrent: "Contraseña actual",
        receiveVisits: "Recibe visitas?",
        visits: "Visitas",
        FnLn1: "PRIMER NOMBRE / PRIMER APELLIDO",
        FnLn1Ln2: "PRIMER NOMBRE / PRIMER APELLIDO / SEGUNDOAPELLIDO",
        FnMnLn1Ln2:
          "PRIMER NOMBRE / SEGUNDO NOMBRE / PRIMER APELLIDO / SEGUNDO APELLIDO",
        FnMnLn1: "PRIMER NOMBRE / SEGUNDO NOMBRE / PRIMER APELLIDO",
        Ln1Fn: "PRIMER APELLIDO / PRIMER NOMBRE",
        Ln1Ln2Fn: "PRIMER APELLIDO / SEGUNDO APELLIDO / PRIMER NOMBRE",
        Ln1Ln2FnMn:
          "PRIMER APELLIDO / SEGUNDO APELLIDO / PRIMER NOMBRE / SEGUNDO NOMBRE",
        Ln1FnMn: "PRIMER APELLIDO / PRIMER NOMBRE / SEGUNDO NOMBRE",
        work_stations: "Estaciones de trabajo",
        create_visit: "¿Registra visitas?",
        create_visit_tooltip: "Registra visitas",
        timeZone: "Zona horaria",
        language: "Lenguaje",
        plcPswConfirm: "Confirmar contraseña",
        infoPerson: "Datos personales",
      },
      verificationModes: {
        FINGERPRINT: "HUELLA",
        IRIS: "IRIS",
        VOICE: "VOZ",
        PIN: "CLAVE",
        PIN_NUMBER: "PIN NUMÉRICO",
        PIN_ALPHANUMERIC: "PIN ALFANUMÉRICO",
        CARD: "TARJETA DE PROXIMIDAD",
        FACIAL: "ROSTRO",
        PHOTO: "FOTO",
        PALM: "PALMA",
        USER: "CREADO POR USUARIO",
        FACIAL_MOBILE: "ROSTRO POR TELÉFONO",
        FACIAL_PC: "ROSTRO POR COMPUTADORA",
        FINGERPRINT_PC: "HUELLA POR COMPUTADORA",
        PIN_PC: "PIN POR COMPUTADORA",
        PIN_MOBILE: "PIN POR TELÉFONO",
        FINGERPRINT_CARD: "HUELLA, TARJETA DE PROXIMIDAD",
        FINGERPRINT_CARD_PIN: "HUELLA, TARJETA DE PROXIMIDAD Y CLAVE",
        PC: "COMPUTADORA",
        MOBILE: "TELÉFONO",
      },
      timeplanning: {
        time: "Tiempo",
        matrix: {
          concept: "Concepto",
        },
      },
      timeAttendance: {
        status_approval: "Nivel de aprobacion",
        modalLocalization: "Localización",
      },
      ConceptMaster: {
        ABSENCE: "AUSENCIA",
        A_ABSENCE: "AUSENCIA ABSOLUTA",
        BREAK: "DESCANSO",
        COMPENSATORY: "COMPENSATORIA",
        DELAY: "RETRASO",
        EXTRA: "HORAS EXTRA",
        HOUR: "HORA",
        PERMISSION: "PERMISOS",
        HOURS: "HORAS",
        HOURS_NIGHT: "HORAS NOCTURNAS",
        HOURS_SUNDAY: "HORAS DOMINICALES",
        HOURS_SUNDAY_NIGHT: "HORAS DOMINICALES NOCTURNAS",
        HOURS_HOLIDAY: "HORAS DÍA FERIADO",
        HOURS_HOLIDAY_NIGHT: "HORAS DÍA FERIADO NOCTURNA",
        HOURS_WITHOUT_SCHEDULE: "HORAS FUERA DE HORARIO",
        HOURS_WITHOUT_SCHEDULE_NIGHT: "HORAS FUERA DE HORARIO NOCTURNA",
        HOURS_WITHOUT_SCHEDULE_SUNDAY: "HORAS FUERA DE HORARIO DOMINICALES",
        HOURS_WITHOUT_SCHEDULE_SUNDAY_NIGHT:
          "HORAS FUERA DE HORARIO DOMINICALES NOCTURNA",
        HOURS_WITHOUT_SCHEDULE_HOLIDAY: "HORAS FUERA DE HORARIO DÍA FERIADO",
        HOURS_WITHOUT_SCHEDULE_HOLIDAY_NIGHT:
          "HORAS FUERA DE HORARIO DÍA FERIADO NOCTURNA",
        EXTRA_NIGHT: "HORAS EXTRA NOCTURNA",
        EXTRA_SUNDAY: "HORAS EXTRA DOMINICALES",
        EXTRA_SUNDAY_NIGHT: "HORAS EXTRA DOMINICALES NOCTURNAS",
        EXTRA_HOLIDAY: "HORAS EXTRA DÍA FERIADO",
        EXTRA_HOLIDAY_NIGHT: "HORAS EXTRA DÍA FERIADO NOCTURNA",
        DELAY_NIGHT: "RETRASO NOCTURNO",
        DELAY_SUNDAY: "RETRASO DOMINICAL",
        DELAY_SUNDAY_NIGHT: "RETRASO DOMINICAL NOCTURNO",
        DELAY_HOLIDAY: "RETRASO DÍA FERIADO",
        DELAY_HOLIDAY_NIGHT: "RETRASO DÍA FERIADO NOCTURNO",
        BREAK_NIGHT: "DESCANSO NOCTURNO",
        BREAK_SUNDAY: "DESCANSO DOMINICAL",
        BREAK_SUNDAY_NIGHT: "DESCANSO DOMINICAL NOCTURNO",
        BREAK_HOLIDAY: "DESCANSO DÍA FERIADO",
        BREAK_HOLIDAY_NIGHT: "DESCANSO DÍA FERIADO NOCTURNO",
        BREAK_LUNCH: "DESCANSO COMIDA",
        BREAK_LUNCH_NIGHT: "DESCANSO COMIDA NOCTURNA",
        BREAK_LUNCH_SUNDAY: "DESCANSO COMIDA DOMINICAL",
        BREAK_LUNCH_SUNDAY_NIGHT: "DESCANSO COMIDA DOMINICAL NOCTURNA",
        BREAK_LUNCH_HOLIDAY: "DESCANSO COMIDA DÍA FERIADO",
        BREAK_LUNCH_HOLIDAY_NIGHT: "DESCANSO COMIDA DÍA FERIADO NOCTURNO",
        COMPENSATORY_NIGHT: "COMPENSATORIAS NOCTURNAs",
        COMPENSATORY_SUNDAY: "COMPENSATORIAS DOMINICALES",
        COMPENSATORY_SUNDAY_NIGHT: "COMPENSATORIAS DOMINICALES NOCTURNOS",
        COMPENSATORY_HOLIDAY: "COMPENSATORIAS DÍA FERIADO",
        COMPENSATORY_HOLIDAY_NIGHT: "COMPENSATORIAS DÍA FERIADO NOCTURNO",
        DAY_OFF: "DÍA LIBRE",
        ABSENCE_NIGHT: "AUSENCIA NOCTURNA",
        ABSENCE_SUNDAY: "AUSENCIA DOMINICAL",
        ABSENCE_SUNDAY_NIGHT: "AUSENCIA DOMINICAL NOCTURNA",
        ABSENCE_HOLIDAY: "AUSENCIA DÍA FERIADO",
        ABSENCE_HOLIDAY_NIGHT: "AUSENCIA DÍA FERIADO NOCTURNO",
        A_ABSENCE_SUNDAY: "AUSENCIA ABSOLUTA DOMINICAL",
        A_ABSENCE_HOLIDAY: "AUSENCIA ABSOLUTA DÍA FERIADO",
        HOURS_BEFORE_SCHEDULE: "HORAS ANTES DE HORARIO",
        HOURS_BEFORE_SCHEDULE_NIGHT: "HORAS ANTES DE HORARIO NOCTURNO",
        HOURS_BEFORE_SCHEDULE_SUNDAY: "HORAS ANTES DE HORARIO DOMINICAL",
        HOURS_BEFORE_SCHEDULE_SUNDAY_NIGHT:
          "HORAS ANTES DE HORARIO DOMINICAL NOCTURNO",
        HOURS_BEFORE_SCHEDULE_HOLIDAY: "HORAS ANTES DE HORARIO DÍA FERIADO",
        HOURS_BEFORE_SCHEDULE_HOLIDAY_NIGHT:
          "HORAS ANTES DE HORARIO DÍA FERIADO NOCTURNO",
        HOURS_AFTER_SCHEDULE: "HORAS DESPUÉS DE HORARIO",
        HOURS_AFTER_SCHEDULE_NIGHT: "HORAS DESPUÉS DE HORARIO NOCTURNO",
        HOURS_AFTER_SCHEDULE_SUNDAY: "HORAS DESPUÉS DE HORARIO DOMINICAL",
        HOURS_AFTER_SCHEDULE_SUNDAY_NIGHT:
          "HORAS DESPUÉS DE HORARIO DOMINICAL NOCTURNO",
        HOURS_AFTER_SCHEDULE_HOLIDAY: "HORAS DESPUÉS DE HORARIO DÍA FERIADO",
        HOURS_AFTER_SCHEDULE_HOLIDAY_NIGHT:
          "HORAS DESPUÉS DE HORARIO DÍA FERIADO NOCTURNO",
        DAILY_NOVELTY_PAYMENT: "PAGO DIARIO DE NOVEDADES",
        DAILY_NOVELTY_PAYMENT_CONFLICT: "CONFICTO DE PAGO DIARIO DE NOVEDADES",
        DAILY_NOVELTY_WITHOUT_PAYMENT: "NOVEDAD DIARIA SIN PAGO",
        DAILY_NOVELTY_WITHOUT_PAYMENT_CONFLICT:
          "CONFICTO DE NOVEDAD DIARIA SIN PAGO",
        HOUR_NOVELTY_PAYMENT: "PAGO POR HORA DE NOVEDAD",
        HOUR_NOVELTY_PAYMENT_CONFLICT: "CONFICTO DE PAGO POR HORA DE NOVEDAD",
        HOUR_NOVELTY_WITHOUT_PAYMENT: "HORA DE NOVEDAD SIN PAGAR",
        HOUR_NOVELTY_WITHOUT_PAYMENT_CONFLICT:
          "CONFICTO DE HORA DE NOVEDAD SIN PAGAR",
      },
      FormErrors: {
        if111: "Campo requerido",
        if112: "Campo único",
        if112B: "El registro esta siendo utilizado",
        if113: "Campo de tipo entero",
        if114: "Longitud máxima del campo excedida",
        if115: "Longitud mínima del campo no alcanzada",
        if116: "Correo inválido",
        if117: "Campo de tipo arreglo",
        if118: "Valor inválido",
        if119: "Campo de tipo caracter",
        if120: "Ya se encuentra registrado",
        if121: "Campo debe ser entero",
        if122: "La estructura debe ser una puerta o un control de acceso",
        if123: "No se permite espacios en blanco",
        if124: "La estructura debe permitir parqueaderos",
        if130: "El nuevo tipo agregado ya existe para la persona",
        if132: "Fecha/hora exceden el rango",
        if404: "Campo no existe",
        if492:
          "No puede ser estructura organizactiva, 1 o más dispositivos asignados",
        STATUS_CONFLICT:
          "Estructura de tipo organizacional no puede contener estructuras físicas",
        if133: "Aun tiene 1 cargo sin asignar",
        if134: "Cantidad debe ser mayor a 0",
        if135: "ReCaptcha invalido",
        notBelongto: "Foto inválida",
        photoExists: "Ya existe la foto de la persona",
        noFace: "No hay rostro en la foto",
        moreThanOne: "Hay más de un rostro en la foto",
        entity_inactive: "Entidad inactiva",
        sameMonth: "No más de 30 días",
        noSchedulings: "Días sin agendamientos",
        schedulingNotAvailable: "Agenda no disponible",
        availability: "Disponibilidad",
      },
      noveltyPermission: {
        type_partial: "Parcial",
        type_daily: "Diaria",
      },
      TimeLog: {
        date: "Día",
        hour: "Hora",
        device: "Biométrico",
        structure: "Ubicación estructural",
        access_type: "Acción",
        access_type_timelog: "Acción",
        timelogs: "Marcajes",
        accessType: {
          0: "Entrada o Salida",
          1: "Entrada",
          2: "Salida",
        },
        result: {
          0: "No Verficado",
          1: "Verificado",
          2: "No Autorizado",
        },
        verificationType: "Tipo de verificación",
        verificationResult: "Resultado",
        details: "Detalles",
        method: "Método",
        infoModal: "Marcaje",
        info: "Datos del marcaje",
      },
      TextTemplate: {
        type: "Tipo de plantilla",
        EMAIL: "Correo",
        SMS: "SMS",
        name_template: "Nombre de plantilla",
        text_template: "Texto",
        time: "Hora",
        date: "Fecha",
        device: "Dispositivo",
        person: "Persona",
        structure: "Estructura",
        dui_person: "Num. documento",
      },
      Graphics: {
        Delay: "Tardanzas",
        WorkingWithoutSchedule: "Trabajando sin horario",
        UnTimelyExit: "Salida intempestiva",
        YesterdayUnEvenTimelog: "Marcajes impares de ayer",
        YesterdayUnapprovedTimelog: "Marcajes no aprobados de ayer",
        WorkingPeople: "Personas Trabajando",
        AbsentPeople: "Personal ausente",
        PermitPeople: "Personal de permiso",
        PermitsPendingApproval: "Permisos pendientes por aprobación",

        StationsByLocations: "Estaciones por sede",
        VisitsScheduledByLocations: "Visitas programadas por sede",
        VisitsScheduledByLocations: "Visitas programadas por sede",
        VisitsByLocations: "Visitas por sede",
        workshitTypeBreak: "Tipo de descanso",
        loading: "Cargando Métrica ",
        visitActives: "Visitas activas",
        devicesTypes: "Tipos de dispositivos",
        entityDevices: "Personas por dispositivos",
        devicesModels: "Módelos de dispositivos",
        rolesUser: "Roles de usuario",
        operationUser: "Operaciones",
        eventsTypes: "Tipos de eventos",
        eventsActions: "Tipos de acciones",
        accessSchedule: "Horarios de acceso",
        accessStructures: "Estructuras de acceso",
        category: "Lógica de la estructura",
        structureTypes: "Uso de la estructura",
        entityTypes: "Tipos de personas",
        entityTypesGroup: "Tipos de entidades",
        vehiclesTypes: "Tipos de vehículos",
        accessRoles: "Perfiles de acceso",
        actives: "Activos",
        connected: "Dispositivos conectados",
        visitedStructures: "Estructuras visitadas",
        listVisited: "Visitados",
        listAccessSchedule: "Horarios de acceso",
        blackList: "Lista Negra",
        inactives: "inactivos",
        typesBiometrics: "Tipos de biometría",
        listStatus: "Estatus de visitas",
        visitScheduledStatus: "Estatus de visitas programadas",
        activas: "Activas",
        timeloVerified: "Marcajes verificados",
        timeloNoVerified: "Marcajes no verificados",
        porcentajeTimeloVerified: "% Marcajes verificados",
        currentStructures: "Estructuras actuales - Personas ",
        currentStructuresGuest: "Estructuras actuales - Visitantes",
        listTypesTimeLogs: "Tipos de marcajes",
        listTypesTimeLogsGuest: "Tipos de marcajes - Visitantes",
        in: "ENTRADA",
        out: "SALIDA",
        inOut: "ENTRADA / SALIDA",
        personInt: "Personas dentro",
        personOut: "Personas fuera",
        vehicleIn: "Vehículos dentro",
        vehicleOut: "Vehiculos fuera",
        visitInt: "Visitantes dentro",
        visitOut: "Visitantes fuera",
        porcentajePersonInt: "% Personas dentro",
        noData: "No hay datos",
        Sunday: "Dom",
        Monday: "Lun",
        Tuesday: "Mar",
        Wednesday: "Mie",
        Thursday: "Jue",
        Friday: "Vie",
        Saturday: "Sab",
        others: "OTROS",
        FINGERPRINT: "HUELLA",
        IRIS: "IRIS",
        VOICE: "VOZ",
        PIN: "CLAVE",
        PIN_NUMBER: "PIN NUMÉRICO",
        PIN_ALPHANUMERIC: "PIN ALFANUMÉRICO",
        CARD: "TARJETA DE PROXIMIDAD",
        FACIAL: "ROSTRO",
        PHOTO: "FOTO",
        PHOTO_ZKTECO: "FOTO - ZKTECO",
        PALM: "PALMA",
        USER: "CREADA POR USUARIO",
        FACIAL_MOBILE: "ROSTRO POR TELÉFONO",
        FACIAL_PC: "ROSTRO POR COMPUTADORA",
        FINGERPRINT_PC: "HUELLA POR COMPUTADORA",
        PIN_PC: "PIN POR COMPUTADORA",
        PIN_MOBILE: "PIN POR TELÉFONO",
        FINGERPRINT_CARD: "HUELLA, TARJETA DE PROXIMIDAD",
        FINGERPRINT_CARD_PIN: "HUELLA, TARJETA DE PROXIMIDAD Y CLAVE",
        PC: "COMPUTADORA",
        MOBILE: "TELÉFONO",
        usingStation: "Estaciones de trabajo en uso",
        usingClosingRules: "Reglas de cierre de visitas en uso",
        defaultClosingRules: "Reglas de cierre predeterminadas",
        estructure: "ESTRUCTURA",
        PLATE: "PLACA",
        typesBiometricsVehicle: "Tipos de biometría - Vehículo",
        visitScheduledActives: "Visitas programadas activas",
        lastStructure: "Ubicación actual por personas",
        entityTypesInSide: "Relación de personas dentro",
        LastStructure: "Estructuras actuales",
        periodAutoClose: "Períodos con cierre automático",
        TypeRegularHours: "Tipos de límites de horas a pagar",
        IgnoreAbsences: "Ignora ausencias",
        conceptUnit: "Uso de unidad",
        conceptRounding: "Uso de redondeo",
        noveltyType: "Tipo de permiso",
        noveltyActives: "Permisos activos ",
        contractTypeActives: "Tipo de contrato activos",
        periodActives: "Períodos activos",
        holidayActives: "Días festivos activos",
        conceptActives: "Conceptos activos",
        workshiftActives: "Turnos activos",
        workshitBreakConcepts: "Uso de conceptos en descansos",
        workshiftAutoAprovalExtra: "Auto aprobación de tiempo extra - Turnos ",
        workOrderCreated: "Órdenes de trabajo creadas",
        workOrderStatus: "Órdenes de trabajo estatus",
        workOrderPosition: "Órdenes de trabajo cargos",
        templateTypesTA: "Tipos de biometría - Tiempo y Asistencia",
        accessRolesTA: "Roles de acceso - Tiempo y Asistencia",
        entityTypesTA: "Tipos de personas - Tiempo y Asistencia",
        workshifts: "Turnos de trabajo",
      },
      EventTask: {
        text_to_send: "Texto a enviar",
        eventTypeCommon: "Común",
        // Páginas
        event: "Evento",
        actions: "Acciones",
        newAction: "Nueva acción",

        // Módulos
        general: "General",
        access_control: "Intelli Access",
        visits: "Intelli Guest",
        devices: "Dispositivos",
        users: "Usuarios",
        notifications: "Notificaciones",
        system: "Sistema",
        time_attendance: "Intelli Time",

        // Etiquetas/Placeholders
        event_name: "Nombre del evento",
        type_event_monitor: "¿Qué tipo de evento desea supervisar?",
        want_monitor: "¿Qué desea supervisar?",
        select_type_filters: "Seleccione los tipos de filtros",
        select_module: "Seleccione el módulo",
        type_action_do: "¿Qué tipo de acción quiere realizar?",
        select_parameters: "Seleccionar parámetros de acción",

        // Eventos/Acciones
        ip: "Dirección IP",

        action_scheduled: "Acción Programada",
        scheduled_action: "Acción programada",
        timelog: "Marcaje",
        timelog_ac_accepted: "Marcaje Verificado - Intelli Access",
        timelog_ta_accepted: "Marcaje Verificado - Intelli Time",
        inTimeLog: "MARCAJE DE ENTRADA",
        outTimeLog: "MARCAJE DE SALIDA",
        inAndOutTimelog: "MARCAJE DE ENTRADA / SALIDA",
        disabledTimelog: "MARCAJE NO AUTORIZADO",
        firstTimeLog: "PRIMER MARCAJE",
        timelog_ac_not_verified: "Marcaje No Verificado - Intelli Access",
        timelog_ta_not_verified: "Marcaje No Verificado - Intelli Time",
        invalidTimelog: "MARCAJE INVALIDO",
        delayTimeLog: "MARCAJE CON TARDANZA",
        open_exit_button: "Apertura con botón de salida",
        access_scheduled: "Horario de acceso",
        scheduled_access: "Horario de acceso",
        change_status_door_remote: "Cambio remoto de estado en puerta",
        change_status_door_manual: "Cambio manual de estado en puerta",
        visit_new: "Nueva visita",
        visit_new_scheduled: "Nueva visita programada",
        visit_closed: "Visita cerrada",
        visit_change_status_scheduled: "Cambio de Estado en Visita Programada",
        change_access_profile_persons: "Cambiar perfil de acceso de personas",
        change_access_profile_vehicles: "Cambiar perfil de acceso de vehículos",
        add_to_blacklist: "Agregar a lista negra",
        remove_from_blacklist: "Remover de lista negra",
        connection_change: "Cambio de conexión",
        user_login: "Inicio de sesión de usuario",
        user_creation: "Creación de usuario",
        user_blocked: "Bloqueo de Usuario",
        password_change: "Cambio de contraseña",
        reports: "Reportes",
        open_door: "Abrir puertas",
        close_door: "Cerrar puertas",
        lock_door: "Bloquear puertas",
        access_scheduled_enabled: "Habilitar horarios de acceso",
        access_scheduled_disabled: "Deshabilitar horarios de acceso",
        inactivate_person: "Inactivar personas",
        activate_person: "Activar personas",
        inactivate_vehicles: "Inactivar vehículos",
        activate_vehicles: "Activar vehículos",
        change_access_role: "Deshabilitar Personas/Vehículos",
        persons_vehicles_disabled: "Cambio Perfil de acceso",
        inactivate_registration_station: "Inactivar estaciones de trabajo",
        activate_registration_station: "Activar estaciones de trabajo",
        blacklist_goto: "Pasar a lista negra",
        inactivate_device: "Inactivar dispositivos",
        activate_device: "Activar dispositivos",
        alerts: "Alertas",
        widgets: "Widgets",
        text_messages: "Mensajes de texto",
        email_messages: "Mensajes de correo",
        plans: "Planos",
        battery_status_change: "Cambio de estado de batería",
        based_event: "Basado en el evento",
        create_update_user: "Modificaciones de usuario",

        // Filtros y Parámetros
        all_person: "Todas las personas",
        all_vehicle: "Todos los vehículos",
        entities: "Personas",
        entity: "Entidad",
        entities_types: "Tipos de personas",
        device: "Dispositivo",
        structures: "Estructuras",
        time: "Tiempo",
        vehicles: "Vehículos",
        vehicle_type: "Tipo de vehículo",
        biometric_models: "Módelos de biométricos",
        verification_modes: "Modos de verificación",
        start: "Inicio",
        end: "Final",
        start_end: "¿En el momento de?",
        user: "Usuario",
        visitants: "Visitantes",
        visitors: "Visitantes",
        visited: "Visitados",
        access_roles: "Perfil de acceso",
        work_stations: "Estaciones de trabajo",
        report_templates: "Plantillas de reportes",
        execution_time: "Momento de ejecución",
        weekdays: "Días de la semana",
        devices_types: "Tipos de dispositivos",
        device_status: "Estado de dispositivos",
        door_status: "Estado de puertas",
        scheduled_visit_status: "Estado de visitas programadas",
        closing_mode_status: "Modo de cierre",
        connected: "Conectado",
        disconnected: "Desconectado",
        open: "Abrir",
        close: "Cerrar",
        lock: "Bloquear",
        executed: "Ejecutada",
        cancelled: "Cancelada",
        automatic: "Automática",
        manual: "Manual",
        report_templates: "Plantillas de reportes",
        user_defined: "Definido por usuario",
        emails: "Correos",
        emailsPlaceholder: "ejemplo@correo.com",
        recurrence_value: "Valor de recurrencia",
        text_messaging_templates: "Plantillas SMS",
        // text_messaging_templates: 'Plantillas de Mensajes de Texto',
        based_on_event: "Basado en el evento",
        access_types: "Tipos de accesos",
        verification_results: "Resultados de verificación",
        persons_groups: "Grupos de personas",
        devices_groups: "Grupos de dispositivos",
        vehicles_groups: "Grupos de vehículos",
        battery_status: "Estado de batería",

        // Campos
        field_in_date: "Fecha inicial",
        field_out_date: "Fecha final",
        field_in_hour: "Hora inicial",
        field_out_hour: "Hora final",
        field_status: "Estatus",
        field_event_name: "Nombre del evento",
        field_settings_type: "Tipo de configuración",
        field_supervise: "Supervisando",
        field_filters: "Filtros",
        field_entities: "Personas",
        field_entities_types: "Tipos de personas",
        field_devices: "Dispositivos",
        field_structures: "Estructuras",
        field_dow: "Días de la semana",
        field_in_hour: "Hora inicial",
        field_out_hour: "Hora final",
        field_status: "Estatus",
        field_vehicles: "Vehículos",
        field_plate: "Placa",
        field_users: "Usuarios",
        field_visitants: "Visitantes",
        field_devices_models_biometrics: "Módelos de biométricos",
        field_verification_modes: "Modos de verificación",
        field_access_scheduled: "Horario de Acceso",
        field_start_end: "¿En el momento de?",
        field_access_roles: "Roles de acceso",
        field_work_stations: "Estaciones de trabajo",
        field_execute_hour: "Hora de ejecución",
        field_device_types: "Tipos de dispositivos",
        field_access_type: "Tipos de acceso",
        field_visiteds: "Visitados",
        field_verification_result: "Resultado de verificación",
        field_vehicles_types: "Tipos de vehículos",
        field_filter_status: "Estados de dispositivos",
        field_common_action: "Accion común",
        field_module: "Módulo",
        field_icons_groups: "Grupos de iconos",
        field_entities_groups: "Grupos de personas",
        field_devices_groups: "Grupos de dispositivos",
        field_vehicles_groups: "Grupos de vehículos",
        field_based_on_event: "Basado en el evento",
        field_repetitions: "Repeticiones",
        field_recurrence: "Recurrencia",
        field_parameters: "Parámetros",
        field_phones: "Teléfonos",
        field_emails: "Correos electrónicos",
        field_time_delay: "Tiempo de retardo",
        field_access_profiles: "Perfiles de acceso",
        field_time_hour: "Hora",
        field_content_text: "Texto contenido",
        field_id_text_template: "Id plantilla de texto",

        // triggered_at: 'Hora del evento',
        // event_description: 'Descripcion del evento',
        eventTypes: "Tipo de evento",
        // eventAction: 'Acción a realizar',
        // eventHeld: 'Acción realizada',
        // events: 'Eventos',
        persons_event: "Seleccione las personas involucradas",
        // devices_event: 'Dispositivos',
        // device: 'Dispositivo',
        // structure: 'Estructura',
        // settingsTypeIn: 'Desde',
        // settingsTypeOut: 'Hasta',
        // type: 'Tipo',
        // category: 'Categoria',
        // actions: 'Acciones',
        // typeNotification: 'Tipo de notificación',
        // actionDevices: 'Acciones del dispositivo',
        // sendType: 'Prioridad del envio',
        // email: 'Correos',
        // phone: 'Teléfonos',
        // hourAction: 'Hora de envío',
        status: "Estado",
        // emergencyAlarm: 'ALARMA DE EMERGENCIA',
        // fireAlarm: 'ALARMA DE INCENDIO',
        // securityAlarm: 'ALARMA DE SEGURIDAD',
        // sysmicAlarm: 'ALARMA SISMICA',
        // sendMoment: 'ENVIAR AL MOMENTO',
        // sendSpecificTime: 'ENVIAR EN UN TIEMPO DETERMINADO',
        // notificationBar: 'NOTIFICACIÓN EN BARRA SUPERIOR',
        // alertMessage: 'Mensaje de alerta',
        // devices: 'Dispositivo a accionar',
        // simpleNotification: 'NOTIFICACIÓN SIMPLE',
        // activeAlarm: 'ACTIVAR ALARMA',
        // turnOnLight: 'ENCENDER LUZ',
        // turnOffLight: 'APAGAR LUZ',
        // sendEmail: 'ENVIAR CORREO ELECTRÓNICO',
        // sendSms: 'ENVIAR SMS',
        // openDoor: 'APERTURAR PUERTA',
        // widgetNotification: 'WIDGET DE NOTIFICACIÓN',
        // soundedAlarm: 'INTRUSIÓN DE ZONA',
        // anyone: 'CUALQUIERA',
        // emergencyLight: 'LUZ DE EMERGENCIA',
        // nightLight: 'LUZ NOCTURNA',
        // backupLight: 'LUZ DE RESPALDO',
        // Entities_types: 'Tipos de persona',
        // emails: 'Correos',
        phones: "Teléfonos",
        // dashboardTimelogs: 'DASHBOARD INTELLI ACCESS',
        // dashboardVisit: 'DASHBOARD INTELLI GUEST',
        // visitTimelogs: 'MARCAJES DE VISITANTES',
        createOrUpdateUser: {
          2: "NUEVO USUARIO",
          3: "ACTUALIZACIÓN DE USUARIO",
        },

        //LIST
        label_module: "Módulo",
        label_event_type: "Tipo de evento",
        label_supervising: "Supervisando",
        label_filters: "Filtros",
        label_parameters: "Parámetros",
        label_event_action: "Acción",
        label_structures: "Estructuras",
        label_devices: "Dispositivos",
        label_devices_models: "Modelos de dispositivos",
        label_not_has_elements: "No tiene información",
        infoEvent: "Información del evento",
        infoActions: "Acciones del evento",
        infoAction: "Información de la acción",
        recurenceDaily: "Diaria",
        recurenceWeekly: "Semanal",
        label_execute_time: "Tiempo de ejecución",
        label_minutes: "Minutos",
        label_elements: "Elementos",
        label_select_recurrence: "Recurrencia",
        label_repetitions: "Repeticiones",

        label_async_entities: "¿Cuáles personas?",
        label_async_entities_types: "¿Cuáles son los tipos de personas?",
        label_async_vehicles: "¿Cuáles vehículos?",
        label_async_vehicles_types: "¿Cuáles son los tipos de vehículos?",
        label_async_access_scheduled: "¿Cuáles horarios de acceso?",
        label_async_access_profiles: "¿Cuáles perfiles de acceso?",
        label_async_work_stations: "¿En qué Estaciones de trabajo?",
        label_async_users: "¿Cuáles usuarios?",
        label_async_visitants: "¿Cuáles visitantes?",
        label_async_Visiteds: "¿Cuáles visitados?",
        label_async_devices: "¿En qué dispositivos?",
        label_async_content_report:
          "¿Qué tipo de contenido llevara el reporte?",
        label_async_content_text: "¿Qué tipo de contenido llevara el mensaje?",
        label_async_persons_groups: "¿En qué grupo de personas?",
        label_async_devices_groups: "¿En qué grupo de dispositivos?",
        label_async_vehicles_groups: "¿En qué grupo de vehículos?",
        label_devices_types: "¿Cuáles son los tipos de dispositivos?",
        label_devices_models: "¿Cuáles modelos de dispositivos?",
        label_access_types: "¿Cuáles son los tipos de acceso?",
        label_verifications_modes: "Seleccione los modos de verificación",
        label_verifications_result: "Seleccione el resultado de verificación",
        label_close_modes: "Defina el modo de cierre de la visita",
        label_what_time: "¿En qué momento?",
        label_defined_status: "Defina el estado",
        label_set_time: "Cuando ocurre entre",
        label_structure_event: "¿En qué estructuras?",
        label_structure_action: "¿En qué estructuras?",
        label_recurrence: "Valor de recurrencia",
        limitActions: "No puede eliminar, limite mínimo de 1 acción por evento",
        visit_timelogs: "Marcajes de visitantes",
        plan_notification: "Notificaciones en plano",
        change_status_message:
          "¿Está seguro de que desea cambiar el estado del registro?",
        change_status: "Cambiar estatus",

        //Items
        executeTime: {
          1: "Inmediato",
          2: "Retardo",
          3: "Específico",
        },
        recurrenceType: {
          1: "Diario",
          2: "Semanal",
        },
        result: {
          0: "Exitoso",
          1: "Inválido",
        },
        495: "No puede eliminar eventos comunes",
      },
      logicErrors: {
        invalidDateInt: "Fecha inválida - Fecha final menor a la inicial",
        invalidTimeInt: "Tiempos inválidos - Tiempo final menor al inicial",
        locationNotFoud: "Sede seleccionada no encontrada",
        isNotLocation: "No es una sede",
        invalidScheduleForLocation: "Horario invalido para sede",
        personHasScheduling:
          "Ya esa persona tiene un agendamiento la fecha indicada",
        cannotDeleteResourceUsed: "No puede eliminar agendamientos usados ",
        cannotDeleteResourceOld: "No puede eliminar agendamientos ya iniciados",
        cannotUpdateResourceUsed: "No puede editar agendamientos usados ",
        cannotUpdateResourceOld:
          "No puede editar agendamientos anteriores a hoy",
        scheduleNoAvalible: "Horario no disponible para aforos en esa fecha",
        schedulingNotFound: "No se encontró aforo para los dias indicados",
        schedulingNotAvailables:
          "Cupos completos para el aforo de los dias indicados",
        invalidDateLocationHaveScheduling:
          "Ya hay aforo registrado para esa fecha",
        totalCannotBeZero: "Total no puede ser cero",
      },
      PersonScheduling: {
        title: "Aforo",
        date_in: "Fecha inicial",
        date_out: "Fecha final",
        used: "Usado",
        status: "Estatus",
        id_person: "Persona",
        id_schedule: "Horario",
        id_structure: "Sede",
        id_L: "Ubicación",
        id_SL: "Ubicación Específica",
        dows: "Días",
        time_in: "Hora inicial",
        time_out: "Hora final",
        placeNoAvailable: "Puesto no disponible",
        selectedPlace: "Puesto seleccionado",
        placePartiallyUsed: "Puesto parcialmente ocupado",
        datesUsed: "Fechas Ocupadas",
        dow: "Día",
        date: "Fecha",
        time: "Hora",
        place: "Puesto",
        schedules: "Agendamientos",
        observations: "Observaciones",
        calendarSchedule: "Hacer clic para agendar sala",
        legend: {
          working_place: "Puesto de trabajo",
          meet_place: "Puesto de reunión",
          place_available: "Puesto disponible",
          place_no_available: "Puesto no disponible",
          other_places: "Otros puestos",
          coworking_area: "Área de coworking",
          meet_area: "Área de reuniones",
        },
      },
    },
    DocumentType: {
      1: "CÉDULA",
      2: "CÉDULA DE EXTRANJERÍA",
      3: "PERMISO DE PERMANENCIA",
      4: "PASAPORTE",
      5: "NIT",
      6: "REGISTRO CIVIL",
      7: "REGISTRO ÚNICO TRIBUTARIO",
    },
  },
  "es-VE": {
    translations: {
      About: {
        about: "Acerca de MyIntelli",
        copyright: "Derechos reservados Intellinext-Corp 2021 ©",
        versions: "Versiones de micros servicios",
        politics: "Política de tratamiento de datos",
        usPolitics:
          "Para conocer nuestras políticas de tratamiento de datos, haga clic en este link",
        recover: "Recuperación de contraseña",
      },
      Login: {
        username: "Nombre de usuairo",
        password: "Contraseña",
        login: "Ingresar",
        rights: "Todos los derechos reservados - Intelli Next 2021 ©",
        btnRecover: "¿Olvidó su contraseña?",
        email: "Correo electrónico",
        invalidUrl: "Url inválida",
        newKey: "Ingrese PIN de soporte",
        key: "Clave",
        title: "Bienvenidos",
        btnLog: "Ingresar",
        plcIdc: "ID del cliente",
        plcUsr: "Usuario",
        plcPsw: "Contraseña",
        plcPswConfirm: "Confirmar contraseña",
        plcEmail: "Correo Electrónico",
        language: "Lenguaje",
        clientError: "Cliente no encontrado",
        userError: "Tipo y/o número de documento incorrecto",
        process: "Procesar",
        titleRecover: "¿Olvidó contraseña?",
        placeholderRecoverEmail: "ejemplo@correo.com",
        placeholderRecoverIdClient: "1",
        passwordRecoveryTitle: "Recuperando contraseña",
        passwordEmpty: "La clave no puede estar en blanco",
        passwordResetedSuccesfully: "Contraseña recuperada exitosamente!",
        passwordResetedFailed:
          "No se pudo enviar el correo de restablecimiento",
        serverDown: "Conexión fallida!",
        validationError: "Correo inválido!",
        btnBackLogin: "Página Inicial",
        passwordRequired: "Contraseña vacía",
        newPasswordSuccess: "Nueva contraseña generada exitosamente",
        newPassword: "Ingrese nueva contraseña",
        disabled_user: "El usuario se encuentra inactivo",
        user_blocked:
          "El usuario se encuentra bloqueado, intente recuperar contraseña",
        cameraNotFound: "Cámara no encontrada",
        confirmPassword: "Confirmar contraseña",
      },
      Token: {
        expired: "Su sesión ha expirado",
        invalid: "Token inválido",
        dismissed: "No hay token",
        verified: "Token Verificado!",
      },
      General: {
        accept: "Aceptar",
        overwrite: "Sobreescribir",
        info: "Información",
        documentType: "Tipo de documento",
        typesDocument: {
          1: "CÉDULA",
          2: "CÉDULA DE EXTRANJERÍA",
          3: "PERMISO DE PERMANENCIA",
          4: "PASAPORTE",
          5: "NIT",
          6: "REGISTRO CIVIL",
          7: "REGISTRO ÚNICO TRIBUTARIO",
        },
        warning: "Advertencia",
        user: "Usuario y dirección IP",
        principal: "Panel principal",
        notActiveService: "El servicio de MyIntelli no está activo",
        downloadHere: "Descarga aquí",
        titleModalSchedule: "Detalles del horario",
        insertSuccess: "Insertado exitosamente",
        selectCamera: "Cámara",
        name: "Nombre",
        mail: "Correo",
        phone: "Teléfono",
        toolbarReminder:
          "Recuerde que también puede realizar su agendamiento desde el calendario de Google",
        birthDate: "Fecha de nacimiento",
        locationDetails: "Detalles de localización",
        timelogDetails: "Detalles del marcaje",
        timelogSuccess: "Marcaje generado de forma exitosa",
        newTimelog: "Nuevo marcaje",
        newTimelogIn: "Nuevo marcaje de entrada",
        newTimelogOut: "Nuevo marcaje de salida",
        details: "Detalles",
        viewDetails: "Ver detalles",
        pair: "Par marcaje",
        days: "Dìas",
        hours: "Horas ordinarias",
        extra: "Horas extras",
        absences: "Ausencias",
        search: "Buscar",
        today: "Hoy",
        month: "Mes",
        fabNew: "Nuevo",
        fabMatrix: "Matriz",
        fabTimeline: "Línea de tiempo",
        btnOk: "Guardar",
        btnCancel: "Cancelar",
        btnClose: "Cerrar",
        btnSubmit: "Enviar",
        btnBack: "Regresar",
        btnNext: "Siguiente",
        Schedules: "Horarios",
        Schedule: "Horario",
        Position: "Cargo",
        Job: "Trabajo",
        Location: "Localización",
        activeEntity: "Activa",
        notActiveEntity: "No activa",
        toActivate: "Activar",
        Start: "Inicio",
        End: "Final",
        Time: "Hora",
        Type: "Tipo",
        Description: "Descripción",
        Status: "Estatus",
        permission: "Permiso",
        Schedule: "Horario",
        Workshift: "Turno",
        permissions: "Permisos",
        Workshifts: "Turnos",
        Enrolling: "Enrolamiento",
        selectEntity: "Seleccionar la entidad",
        entity: "Entidad",
        in: "Entrada",
        out: "Salida",
        capture: "Capturar",
        date: "Fecha",
        day: "Día",
        typeTimelog: "Tipo de marcaje",
        TTymelog: "T. marcaje",
        timelogs: "Marcajes",
        times: "Tiempos",
        details: "Details",
        newRegister: "Nuevo registro",
        messageDeleteRecord:
          "¿Estás seguro de que quieres eliminar este registro?",
        entryExit: "Entrada o salida",
        entry: "Entrada",
        exit: "Salida",
        kilometres: "Kilómetros",
        miles: "Millas",
        selectCut: "Corte",
        weekly: "Semanal",
        biweekly: "Quincenal",
        monthly: "Mensual",
        attention: "Atención",
        delete: "Eliminar",
        verificationMode: "Modo de verificación",
        titleSuccess: "Operación",
        updateSuccess: "Actualizado exitosamente",
        document: "Documento",
        typeDocument: "Tipo de documento",
        first_timelog: "Primero",
        last_timelog: "Último",
        planifiedHours: "Horas planificadas",
        workedUpHours: "Horas trabajadas",
        settings_device: {
          serial: "Key",
        },
        generateSerial: "Generar key",
        workstationErrorTitle: "Error al generar la key",
        workstationError: "Contacte al administrador",
        enrollComputerSuccess: "Computadora enrolada satisfactoriamente",
        enrollPhotoSuccess: "Rostro enrolado satisfactoriamente",
        enrollDevice: "Enrolar dispositivo",
        enrollPhoto: "Enrolar foto",
        noOptions: "Sin opciones",
        location: "Sede",
        used: "Usado",
        notUsed: "No usado",
        scheduleInGoogleCalendar: "Agendar en Google Calendar",
        rememberText: "Recuerda seleccionar la Sala en el Calendario de Google",
        goToCalendar: "Ir al Calendario"
      },
      Dow: {
        Sunday: "DOMINGO",
        Monday: "LUNES",
        Tuesday: "MARTES",
        Wednesday: "MIÉRCOLES",
        Thursday: "JUEVES",
        Friday: "VIERNES",
        Saturday: "SÁBADO",
      },
      Header: {
        info: "Ajustes de usuario",
        configuration: "Configuración",
        notifications: "Notificaciones",
        exit: "Salir",
        deviceRole: "Enrolar equipo",
        support: "Asistencia",
      },

      User: {
        user_name: "Usuario",
        password: "Contraseña",
        passwordNew: "Nueva contraseña",
        password_current: "Contraseña Actual",
        typeThePassword: "Ingrese su contraseña actual",
        passwordConfirm: "Confirmar contraseña",
        roles: "Roles del usuario",
        timeLocal: "HORA LOCAL",
        timeEvent: "HORA DEL EVENTO",
        settings_user_format_date: "Formato de fechas",
        settings_user_format_date_short: "Formato de fechas cortas",
        settings_user_format_hour: "Formato de horas",
        settings_user_format_numeric: "Formato númerico",
        settings_user_event_time: "Hora en eventos",
        settings_user_format_name: "Formato de nombres",
        settings_user_format_temperature: "F. de temperatura",
        settings_user_format_length: "Formato de longitud",
        settings_user_status_approval: "Nivel de aprobación",
        settings_user_format_calculation: "Formato de cálculos",
        password_repeat: "Ingrese la contraseña nuevamente",
        change_password: "Cambiar contraseña",
        phone: "Télefono",
        email: "Correo electronico",
        status: "Estatus",
        active_person: "Solo activas",
        user: "Nombre",
        active: "Activa",
        inactive: "Inactiva",
        structure: "Permisos sobre la estructura organizativa",
        recoveryError: "Correo no encontrado",
        recoverySuccess: "Enviado enlace de restablecimiento de contraseña",
        change_data: "Configuración de usuario",
        allPermissionTooltip: "Dar todos los permisos",
        allPermission: "Todos los permisos",
        permission: "Permisos",
        passwordCurrent: "Contraseña actual",
        receiveVisits: "Recibe visitas?",
        visits: "Visitas",
        FnLn1: "PRIMER NOMBRE / PRIMER APELLIDO",
        FnLn1Ln2: "PRIMER NOMBRE / PRIMER APELLIDO / SEGUNDOAPELLIDO",
        FnMnLn1Ln2:
          "PRIMER NOMBRE / SEGUNDO NOMBRE / PRIMER APELLIDO / SEGUNDO APELLIDO",
        FnMnLn1: "PRIMER NOMBRE / SEGUNDO NOMBRE / PRIMER APELLIDO",
        Ln1Fn: "PRIMER APELLIDO / PRIMER NOMBRE",
        Ln1Ln2Fn: "PRIMER APELLIDO / SEGUNDO APELLIDO / PRIMER NOMBRE",
        Ln1Ln2FnMn:
          "PRIMER APELLIDO / SEGUNDO APELLIDO / PRIMER NOMBRE / SEGUNDO NOMBRE",
        Ln1FnMn: "PRIMER APELLIDO / PRIMER NOMBRE / SEGUNDO NOMBRE",
        work_stations: "Estaciones de trabajo",
        create_visit: "¿Registra visitas?",
        create_visit_tooltip: "Registra visitas",
        timeZone: "Zona horaria",
        language: "Lenguaje",
        plcPswConfirm: "Confirmar contraseña",
        infoPerson: "Datos personales",
      },
      verificationModes: {
        FINGERPRINT: "HUELLA",
        IRIS: "IRIS",
        VOICE: "VOZ",
        PIN: "CLAVE",
        PIN_NUMBER: "PIN NUMÉRICO",
        PIN_ALPHANUMERIC: "PIN ALFANUMÉRICO",
        CARD: "TARJETA DE PROXIMIDAD",
        FACIAL: "ROSTRO",
        PHOTO: "FOTO",
        PALM: "PALMA",
        USER: "CREADO POR USUARIO",
        FACIAL_MOBILE: "ROSTRO POR TELÉFONO",
        FACIAL_PC: "ROSTRO POR COMPUTADORA",
        FINGERPRINT_PC: "HUELLA POR COMPUTADORA",
        PIN_PC: "PIN POR COMPUTADORA",
        PIN_MOBILE: "PIN POR TELÉFONO",
        FINGERPRINT_CARD: "HUELLA, TARJETA DE PROXIMIDAD",
        FINGERPRINT_CARD_PIN: "HUELLA, TARJETA DE PROXIMIDAD Y CLAVE",
        PC: "COMPUTADORA",
        MOBILE: "TELÉFONO",
      },
      timeplanning: {
        time: "Tiempo",
        matrix: {
          concept: "Concepto",
        },
      },
      timeAttendance: {
        status_approval: "Nivel de aprobacion",
        modalLocalization: "Localización",
      },
      ConceptMaster: {
        ABSENCE: "AUSENCIA",
        A_ABSENCE: "AUSENCIA ABSOLUTA",
        BREAK: "DESCANSO",
        COMPENSATORY: "COMPENSATORIA",
        DELAY: "RETRASO",
        EXTRA: "HORAS EXTRA",
        HOUR: "HORA",
        PERMISSION: "PERMISOS",
        HOURS: "HORAS",
        HOURS_NIGHT: "HORAS NOCTURNAS",
        HOURS_SUNDAY: "HORAS DOMINICALES",
        HOURS_SUNDAY_NIGHT: "HORAS DOMINICALES NOCTURNAS",
        HOURS_HOLIDAY: "HORAS DÍA FERIADO",
        HOURS_HOLIDAY_NIGHT: "HORAS DÍA FERIADO NOCTURNA",
        HOURS_WITHOUT_SCHEDULE: "HORAS FUERA DE HORARIO",
        HOURS_WITHOUT_SCHEDULE_NIGHT: "HORAS FUERA DE HORARIO NOCTURNA",
        HOURS_WITHOUT_SCHEDULE_SUNDAY: "HORAS FUERA DE HORARIO DOMINICALES",
        HOURS_WITHOUT_SCHEDULE_SUNDAY_NIGHT:
          "HORAS FUERA DE HORARIO DOMINICALES NOCTURNA",
        HOURS_WITHOUT_SCHEDULE_HOLIDAY: "HORAS FUERA DE HORARIO DÍA FERIADO",
        HOURS_WITHOUT_SCHEDULE_HOLIDAY_NIGHT:
          "HORAS FUERA DE HORARIO DÍA FERIADO NOCTURNA",
        EXTRA_NIGHT: "HORAS EXTRA NOCTURNA",
        EXTRA_SUNDAY: "HORAS EXTRA DOMINICALES",
        EXTRA_SUNDAY_NIGHT: "HORAS EXTRA DOMINICALES NOCTURNAS",
        EXTRA_HOLIDAY: "HORAS EXTRA DÍA FERIADO",
        EXTRA_HOLIDAY_NIGHT: "HORAS EXTRA DÍA FERIADO NOCTURNA",
        DELAY_NIGHT: "RETRASO NOCTURNO",
        DELAY_SUNDAY: "RETRASO DOMINICAL",
        DELAY_SUNDAY_NIGHT: "RETRASO DOMINICAL NOCTURNO",
        DELAY_HOLIDAY: "RETRASO DÍA FERIADO",
        DELAY_HOLIDAY_NIGHT: "RETRASO DÍA FERIADO NOCTURNO",
        BREAK_NIGHT: "DESCANSO NOCTURNO",
        BREAK_SUNDAY: "DESCANSO DOMINICAL",
        BREAK_SUNDAY_NIGHT: "DESCANSO DOMINICAL NOCTURNO",
        BREAK_HOLIDAY: "DESCANSO DÍA FERIADO",
        BREAK_HOLIDAY_NIGHT: "DESCANSO DÍA FERIADO NOCTURNO",
        BREAK_LUNCH: "DESCANSO COMIDA",
        BREAK_LUNCH_NIGHT: "DESCANSO COMIDA NOCTURNA",
        BREAK_LUNCH_SUNDAY: "DESCANSO COMIDA DOMINICAL",
        BREAK_LUNCH_SUNDAY_NIGHT: "DESCANSO COMIDA DOMINICAL NOCTURNA",
        BREAK_LUNCH_HOLIDAY: "DESCANSO COMIDA DÍA FERIADO",
        BREAK_LUNCH_HOLIDAY_NIGHT: "DESCANSO COMIDA DÍA FERIADO NOCTURNO",
        COMPENSATORY_NIGHT: "COMPENSATORIAS NOCTURNAs",
        COMPENSATORY_SUNDAY: "COMPENSATORIAS DOMINICALES",
        COMPENSATORY_SUNDAY_NIGHT: "COMPENSATORIAS DOMINICALES NOCTURNOS",
        COMPENSATORY_HOLIDAY: "COMPENSATORIAS DÍA FERIADO",
        COMPENSATORY_HOLIDAY_NIGHT: "COMPENSATORIAS DÍA FERIADO NOCTURNO",
        DAY_OFF: "DÍA LIBRE",
        ABSENCE_NIGHT: "AUSENCIA NOCTURNA",
        ABSENCE_SUNDAY: "AUSENCIA DOMINICAL",
        ABSENCE_SUNDAY_NIGHT: "AUSENCIA DOMINICAL NOCTURNA",
        ABSENCE_HOLIDAY: "AUSENCIA DÍA FERIADO",
        ABSENCE_HOLIDAY_NIGHT: "AUSENCIA DÍA FERIADO NOCTURNO",
        A_ABSENCE_SUNDAY: "AUSENCIA ABSOLUTA DOMINICAL",
        A_ABSENCE_HOLIDAY: "AUSENCIA ABSOLUTA DÍA FERIADO",
        HOURS_BEFORE_SCHEDULE: "HORAS ANTES DE HORARIO",
        HOURS_BEFORE_SCHEDULE_NIGHT: "HORAS ANTES DE HORARIO NOCTURNO",
        HOURS_BEFORE_SCHEDULE_SUNDAY: "HORAS ANTES DE HORARIO DOMINICAL",
        HOURS_BEFORE_SCHEDULE_SUNDAY_NIGHT:
          "HORAS ANTES DE HORARIO DOMINICAL NOCTURNO",
        HOURS_BEFORE_SCHEDULE_HOLIDAY: "HORAS ANTES DE HORARIO DÍA FERIADO",
        HOURS_BEFORE_SCHEDULE_HOLIDAY_NIGHT:
          "HORAS ANTES DE HORARIO DÍA FERIADO NOCTURNO",
        HOURS_AFTER_SCHEDULE: "HORAS DESPUÉS DE HORARIO",
        HOURS_AFTER_SCHEDULE_NIGHT: "HORAS DESPUÉS DE HORARIO NOCTURNO",
        HOURS_AFTER_SCHEDULE_SUNDAY: "HORAS DESPUÉS DE HORARIO DOMINICAL",
        HOURS_AFTER_SCHEDULE_SUNDAY_NIGHT:
          "HORAS DESPUÉS DE HORARIO DOMINICAL NOCTURNO",
        HOURS_AFTER_SCHEDULE_HOLIDAY: "HORAS DESPUÉS DE HORARIO DÍA FERIADO",
        HOURS_AFTER_SCHEDULE_HOLIDAY_NIGHT:
          "HORAS DESPUÉS DE HORARIO DÍA FERIADO NOCTURNO",
        DAILY_NOVELTY_PAYMENT: "PAGO DIARIO DE NOVEDADES",
        DAILY_NOVELTY_PAYMENT_CONFLICT: "CONFICTO DE PAGO DIARIO DE NOVEDADES",
        DAILY_NOVELTY_WITHOUT_PAYMENT: "NOVEDAD DIARIA SIN PAGO",
        DAILY_NOVELTY_WITHOUT_PAYMENT_CONFLICT:
          "CONFICTO DE NOVEDAD DIARIA SIN PAGO",
        HOUR_NOVELTY_PAYMENT: "PAGO POR HORA DE NOVEDAD",
        HOUR_NOVELTY_PAYMENT_CONFLICT: "CONFICTO DE PAGO POR HORA DE NOVEDAD",
        HOUR_NOVELTY_WITHOUT_PAYMENT: "HORA DE NOVEDAD SIN PAGAR",
        HOUR_NOVELTY_WITHOUT_PAYMENT_CONFLICT:
          "CONFICTO DE HORA DE NOVEDAD SIN PAGAR",
      },
      FormErrors: {
        if111: "Campo requerido",
        if112: "Campo único",
        if112B: "El registro esta siendo utilizado",
        if113: "Campo de tipo entero",
        if114: "Longitud máxima del campo excedida",
        if115: "Longitud mínima del campo no alcanzada",
        if116: "Correo inválido",
        if117: "Campo de tipo arreglo",
        if118: "Valor inválido",
        if119: "Campo de tipo caracter",
        if120: "Ya se encuentra registrado",
        if121: "Campo debe ser entero",
        if122: "La estructura debe ser una puerta o un control de acceso",
        if123: "No se permite espacios en blanco",
        if124: "La estructura debe permitir estacionamientos",
        if130: "El nuevo tipo agregado ya existe para la persona",
        if132: "Fecha/hora exceden el rango",
        if404: "Campo no existe",
        if492:
          "No puede ser estructura organizactiva, 1 o más dispositivos asignados",
        STATUS_CONFLICT:
          "Estructura de tipo organizacional no puede contener estructuras físicas",
        if133: "Aun tiene 1 cargo sin asignar",
        if134: "Cantidad debe ser mayor a 0",
        if135: "ReCaptcha invalido",
        notBelongto: "Foto inválida",
        photoExists: "Ya existe la foto de la persona",
        noFace: "No hay rostro en la foto",
        moreThanOne: "Hay más de un rostro en la foto",
        entity_inactive: "Entidad inactiva",
        sameMonth: "No más de 30 días",
        noSchedulings: "Días sin agendamientos",
        schedulingNotAvailable: "Agenda no disponible",
        availability: "Disponibilidad",
      },
      noveltyPermission: {
        type_partial: "Parcial",
        type_daily: "Diaria",
      },
      TimeLog: {
        date: "Día",
        hour: "Hora",
        device: "Biométrico",
        structure: "Ubicación estructural",
        access_type: "Acción",
        access_type_timelog: "Acción",
        timelogs: "Marcajes",
        accessType: {
          0: "Entrada o Salida",
          1: "Entrada",
          2: "Salida",
        },
        result: {
          0: "No Verficado",
          1: "Verificado",
          2: "No Autorizado",
        },
        verificationType: "Tipo de verificación",
        verificationResult: "Resultado",
        details: "Detalles",
        method: "Método",
        infoModal: "Marcaje",
        info: "Datos del marcaje",
      },
      TextTemplate: {
        type: "Tipo de plantilla",
        EMAIL: "Correo",
        SMS: "SMS",
        name_template: "Nombre de plantilla",
        text_template: "Texto",
        time: "Hora",
        date: "Fecha",
        device: "Dispositivo",
        person: "Persona",
        structure: "Estructura",
        dui_person: "Num. documento",
      },
      Graphics: {
        Delay: "Tardanzas",
        WorkingWithoutSchedule: "Trabajando sin horario",
        UnTimelyExit: "Salida intempestiva",
        YesterdayUnEvenTimelog: "Marcajes impares de ayer",
        YesterdayUnapprovedTimelog: "Marcajes no aprobados de ayer",
        WorkingPeople: "Personas Trabajando",
        AbsentPeople: "Personal ausente",
        PermitPeople: "Personal de permiso",
        PermitsPendingApproval: "Permisos pendientes por aprobación",

        StationsByLocations: "Estaciones por sede",
        VisitsScheduledByLocations: "Visitas programadas por sede",
        VisitsScheduledByLocations: "Visitas programadas por sede",
        VisitsByLocations: "Visitas por sede",
        workshitTypeBreak: "Tipo de descanso",
        loading: "Cargando Métrica ",
        visitActives: "Visitas activas",
        devicesTypes: "Tipos de dispositivos",
        entityDevices: "Personas por dispositivos",
        devicesModels: "Módelos de dispositivos",
        rolesUser: "Roles de usuario",
        operationUser: "Operaciones",
        eventsTypes: "Tipos de eventos",
        eventsActions: "Tipos de acciones",
        accessSchedule: "Horarios de acceso",
        accessStructures: "Estructuras de acceso",
        category: "Lógica de la estructura",
        structureTypes: "Uso de la estructura",
        entityTypes: "Tipos de personas",
        entityTypesGroup: "Tipos de entidades",
        vehiclesTypes: "Tipos de vehículos",
        accessRoles: "Perfiles de acceso",
        actives: "Activos",
        connected: "Dispositivos conectados",
        visitedStructures: "Estructuras visitadas",
        listVisited: "Visitados",
        listAccessSchedule: "Horarios de acceso",
        blackList: "Lista Negra",
        inactives: "inactivos",
        typesBiometrics: "Tipos de biometría",
        listStatus: "Estatus de visitas",
        visitScheduledStatus: "Estatus de visitas programadas",
        activas: "Activas",
        timeloVerified: "Marcajes verificados",
        timeloNoVerified: "Marcajes no verificados",
        porcentajeTimeloVerified: "% Marcajes verificados",
        currentStructures: "Estructuras actuales - Personas ",
        currentStructuresGuest: "Estructuras actuales - Visitantes",
        listTypesTimeLogs: "Tipos de marcajes",
        listTypesTimeLogsGuest: "Tipos de marcajes - Visitantes",
        in: "ENTRADA",
        out: "SALIDA",
        inOut: "ENTRADA / SALIDA",
        personInt: "Personas dentro",
        personOut: "Personas fuera",
        vehicleIn: "Vehículos dentro",
        vehicleOut: "Vehiculos fuera",
        visitInt: "Visitantes dentro",
        visitOut: "Visitantes fuera",
        porcentajePersonInt: "% Personas dentro",
        noData: "No hay datos",
        Sunday: "Dom",
        Monday: "Lun",
        Tuesday: "Mar",
        Wednesday: "Mie",
        Thursday: "Jue",
        Friday: "Vie",
        Saturday: "Sab",
        others: "OTROS",
        FINGERPRINT: "HUELLA",
        IRIS: "IRIS",
        VOICE: "VOZ",
        PIN: "CLAVE",
        PIN_NUMBER: "PIN NUMÉRICO",
        PIN_ALPHANUMERIC: "PIN ALFANUMÉRICO",
        CARD: "TARJETA DE PROXIMIDAD",
        FACIAL: "ROSTRO",
        PHOTO: "FOTO",
        PHOTO_ZKTECO: "FOTO - ZKTECO",
        PALM: "PALMA",
        USER: "CREADA POR USUARIO",
        FACIAL_MOBILE: "ROSTRO POR TELÉFONO",
        FACIAL_PC: "ROSTRO POR COMPUTADORA",
        FINGERPRINT_PC: "HUELLA POR COMPUTADORA",
        PIN_PC: "PIN POR COMPUTADORA",
        PIN_MOBILE: "PIN POR TELÉFONO",
        FINGERPRINT_CARD: "HUELLA, TARJETA DE PROXIMIDAD",
        FINGERPRINT_CARD_PIN: "HUELLA, TARJETA DE PROXIMIDAD Y CLAVE",
        PC: "COMPUTADORA",
        MOBILE: "TELÉFONO",
        usingStation: "Estaciones de trabajo en uso",
        usingClosingRules: "Reglas de cierre de visitas en uso",
        defaultClosingRules: "Reglas de cierre predeterminadas",
        estructure: "ESTRUCTURA",
        PLATE: "PLACA",
        typesBiometricsVehicle: "Tipos de biometría - Vehículo",
        visitScheduledActives: "Visitas programadas activas",
        lastStructure: "Ubicación actual por personas",
        entityTypesInSide: "Relación de personas dentro",
        LastStructure: "Estructuras actuales",
        periodAutoClose: "Períodos con cierre automático",
        TypeRegularHours: "Tipos de límites de horas a pagar",
        IgnoreAbsences: "Ignora ausencias",
        conceptUnit: "Uso de unidad",
        conceptRounding: "Uso de redondeo",
        noveltyType: "Tipo de permiso",
        noveltyActives: "Permisos activos ",
        contractTypeActives: "Tipo de contrato activos",
        periodActives: "Períodos activos",
        holidayActives: "Días festivos activos",
        conceptActives: "Conceptos activos",
        workshiftActives: "Turnos activos",
        workshitBreakConcepts: "Uso de conceptos en descansos",
        workshiftAutoAprovalExtra: "Auto aprobación de tiempo extra - Turnos ",
        workOrderCreated: "Órdenes de trabajo creadas",
        workOrderStatus: "Órdenes de trabajo estatus",
        workOrderPosition: "Órdenes de trabajo cargos",
        templateTypesTA: "Tipos de biometría - Tiempo y Asistencia",
        accessRolesTA: "Roles de acceso - Tiempo y Asistencia",
        entityTypesTA: "Tipos de personas - Tiempo y Asistencia",
        workshifts: "Turnos de trabajo",
      },
      EventTask: {
        text_to_send: "Texto a enviar",
        eventTypeCommon: "Común",
        // Páginas
        event: "Evento",
        actions: "Acciones",
        newAction: "Nueva acción",

        // Módulos
        general: "General",
        access_control: "Intelli Access",
        visits: "Intelli Guest",
        devices: "Dispositivos",
        users: "Usuarios",
        notifications: "Notificaciones",
        system: "Sistema",
        time_attendance: "Intelli Time",

        // Etiquetas/Placeholders
        event_name: "Nombre del evento",
        type_event_monitor: "¿Qué tipo de evento desea supervisar?",
        want_monitor: "¿Qué desea supervisar?",
        select_type_filters: "Seleccione los tipos de filtros",
        select_module: "Seleccione el módulo",
        type_action_do: "¿Qué tipo de acción quiere realizar?",
        select_parameters: "Seleccionar parámetros de acción",

        // Eventos/Acciones
        ip: "Dirección IP",

        action_scheduled: "Acción Programada",
        scheduled_action: "Acción programada",
        timelog: "Marcaje",
        timelog_ac_accepted: "Marcaje Verificado - Intelli Access",
        timelog_ta_accepted: "Marcaje Verificado - Intelli Time",
        inTimeLog: "MARCAJE DE ENTRADA",
        outTimeLog: "MARCAJE DE SALIDA",
        inAndOutTimelog: "MARCAJE DE ENTRADA / SALIDA",
        disabledTimelog: "MARCAJE NO AUTORIZADO",
        firstTimeLog: "PRIMER MARCAJE",
        timelog_ac_not_verified: "Marcaje No Verificado - Intelli Access",
        timelog_ta_not_verified: "Marcaje No Verificado - Intelli Time",
        invalidTimelog: "MARCAJE INVALIDO",
        delayTimeLog: "MARCAJE CON TARDANZA",
        open_exit_button: "Apertura con botón de salida",
        access_scheduled: "Horario de acceso",
        scheduled_access: "Horario de acceso",
        change_status_door_remote: "Cambio remoto de estado en puerta",
        change_status_door_manual: "Cambio manual de estado en puerta",
        visit_new: "Nueva visita",
        visit_new_scheduled: "Nueva visita programada",
        visit_closed: "Visita cerrada",
        visit_change_status_scheduled: "Cambio de Estado en Visita Programada",
        change_access_profile_persons: "Cambiar perfil de acceso de personas",
        change_access_profile_vehicles: "Cambiar perfil de acceso de vehículos",
        add_to_blacklist: "Agregar a lista negra",
        remove_from_blacklist: "Remover de lista negra",
        connection_change: "Cambio de conexión",
        user_login: "Inicio de sesión de usuario",
        user_creation: "Creación de usuario",
        user_blocked: "Bloqueo de Usuario",
        password_change: "Cambio de contraseña",
        reports: "Reportes",
        open_door: "Abrir puertas",
        close_door: "Cerrar puertas",
        lock_door: "Bloquear puertas",
        access_scheduled_enabled: "Habilitar horarios de acceso",
        access_scheduled_disabled: "Deshabilitar horarios de acceso",
        inactivate_person: "Inactivar personas",
        activate_person: "Activar personas",
        inactivate_vehicles: "Inactivar vehículos",
        activate_vehicles: "Activar vehículos",
        change_access_role: "Deshabilitar Personas/Vehículos",
        persons_vehicles_disabled: "Cambio Perfil de acceso",
        inactivate_registration_station: "Inactivar estaciones de trabajo",
        activate_registration_station: "Activar estaciones de trabajo",
        blacklist_goto: "Pasar a lista negra",
        inactivate_device: "Inactivar dispositivos",
        activate_device: "Activar dispositivos",
        alerts: "Alertas",
        widgets: "Widgets",
        text_messages: "Mensajes de texto",
        email_messages: "Mensajes de correo",
        plans: "Planos",
        battery_status_change: "Cambio de estado de batería",
        based_event: "Basado en el evento",
        create_update_user: "Modificaciones de usuario",

        // Filtros y Parámetros
        all_person: "Todas las personas",
        all_vehicle: "Todos los vehículos",
        entities: "Personas",
        entity: "Entidad",
        entities_types: "Tipos de personas",
        device: "Dispositivo",
        structures: "Estructuras",
        time: "Tiempo",
        vehicles: "Vehículos",
        vehicle_type: "Tipo de vehículo",
        biometric_models: "Módelos de biométricos",
        verification_modes: "Modos de verificación",
        start: "Inicio",
        end: "Final",
        start_end: "¿En el momento de?",
        user: "Usuario",
        visitants: "Visitantes",
        visitors: "Visitantes",
        visited: "Visitados",
        access_roles: "Perfil de acceso",
        work_stations: "Estaciones de trabajo",
        report_templates: "Plantillas de reportes",
        execution_time: "Momento de ejecución",
        weekdays: "Días de la semana",
        devices_types: "Tipos de dispositivos",
        device_status: "Estado de dispositivos",
        door_status: "Estado de puertas",
        scheduled_visit_status: "Estado de visitas programadas",
        closing_mode_status: "Modo de cierre",
        connected: "Conectado",
        disconnected: "Desconectado",
        open: "Abrir",
        close: "Cerrar",
        lock: "Bloquear",
        executed: "Ejecutada",
        cancelled: "Cancelada",
        automatic: "Automática",
        manual: "Manual",
        report_templates: "Plantillas de reportes",
        user_defined: "Definido por usuario",
        emails: "Correos",
        emailsPlaceholder: "ejemplo@correo.com",
        recurrence_value: "Valor de recurrencia",
        text_messaging_templates: "Plantillas SMS",
        // text_messaging_templates: 'Plantillas de Mensajes de Texto',
        based_on_event: "Basado en el evento",
        access_types: "Tipos de accesos",
        verification_results: "Resultados de verificación",
        persons_groups: "Grupos de personas",
        devices_groups: "Grupos de dispositivos",
        vehicles_groups: "Grupos de vehículos",
        battery_status: "Estado de batería",

        // Campos
        field_in_date: "Fecha inicial",
        field_out_date: "Fecha final",
        field_in_hour: "Hora inicial",
        field_out_hour: "Hora final",
        field_status: "Estatus",
        field_event_name: "Nombre del evento",
        field_settings_type: "Tipo de configuración",
        field_supervise: "Supervisando",
        field_filters: "Filtros",
        field_entities: "Personas",
        field_entities_types: "Tipos de personas",
        field_devices: "Dispositivos",
        field_structures: "Estructuras",
        field_dow: "Días de la semana",
        field_in_hour: "Hora inicial",
        field_out_hour: "Hora final",
        field_status: "Estatus",
        field_vehicles: "Vehículos",
        field_plate: "Placa",
        field_users: "Usuarios",
        field_visitants: "Visitantes",
        field_devices_models_biometrics: "Módelos de biométricos",
        field_verification_modes: "Modos de verificación",
        field_access_scheduled: "Horario de Acceso",
        field_start_end: "¿En el momento de?",
        field_access_roles: "Roles de acceso",
        field_work_stations: "Estaciones de trabajo",
        field_execute_hour: "Hora de ejecución",
        field_device_types: "Tipos de dispositivos",
        field_access_type: "Tipos de acceso",
        field_visiteds: "Visitados",
        field_verification_result: "Resultado de verificación",
        field_vehicles_types: "Tipos de vehículos",
        field_filter_status: "Estados de dispositivos",
        field_common_action: "Accion común",
        field_module: "Módulo",
        field_icons_groups: "Grupos de iconos",
        field_entities_groups: "Grupos de personas",
        field_devices_groups: "Grupos de dispositivos",
        field_vehicles_groups: "Grupos de vehículos",
        field_based_on_event: "Basado en el evento",
        field_repetitions: "Repeticiones",
        field_recurrence: "Recurrencia",
        field_parameters: "Parámetros",
        field_phones: "Teléfonos",
        field_emails: "Correos electrónicos",
        field_time_delay: "Tiempo de retardo",
        field_access_profiles: "Perfiles de acceso",
        field_time_hour: "Hora",
        field_content_text: "Texto contenido",
        field_id_text_template: "Id plantilla de texto",

        // triggered_at: 'Hora del evento',
        // event_description: 'Descripcion del evento',
        eventTypes: "Tipo de evento",
        // eventAction: 'Acción a realizar',
        // eventHeld: 'Acción realizada',
        // events: 'Eventos',
        persons_event: "Seleccione las personas involucradas",
        // devices_event: 'Dispositivos',
        // device: 'Dispositivo',
        // structure: 'Estructura',
        // settingsTypeIn: 'Desde',
        // settingsTypeOut: 'Hasta',
        // type: 'Tipo',
        // category: 'Categoria',
        // actions: 'Acciones',
        // typeNotification: 'Tipo de notificación',
        // actionDevices: 'Acciones del dispositivo',
        // sendType: 'Prioridad del envio',
        // email: 'Correos',
        // phone: 'Teléfonos',
        // hourAction: 'Hora de envío',
        status: "Estado",
        // emergencyAlarm: 'ALARMA DE EMERGENCIA',
        // fireAlarm: 'ALARMA DE INCENDIO',
        // securityAlarm: 'ALARMA DE SEGURIDAD',
        // sysmicAlarm: 'ALARMA SISMICA',
        // sendMoment: 'ENVIAR AL MOMENTO',
        // sendSpecificTime: 'ENVIAR EN UN TIEMPO DETERMINADO',
        // notificationBar: 'NOTIFICACIÓN EN BARRA SUPERIOR',
        // alertMessage: 'Mensaje de alerta',
        // devices: 'Dispositivo a accionar',
        // simpleNotification: 'NOTIFICACIÓN SIMPLE',
        // activeAlarm: 'ACTIVAR ALARMA',
        // turnOnLight: 'ENCENDER LUZ',
        // turnOffLight: 'APAGAR LUZ',
        // sendEmail: 'ENVIAR CORREO ELECTRÓNICO',
        // sendSms: 'ENVIAR SMS',
        // openDoor: 'APERTURAR PUERTA',
        // widgetNotification: 'WIDGET DE NOTIFICACIÓN',
        // soundedAlarm: 'INTRUSIÓN DE ZONA',
        // anyone: 'CUALQUIERA',
        // emergencyLight: 'LUZ DE EMERGENCIA',
        // nightLight: 'LUZ NOCTURNA',
        // backupLight: 'LUZ DE RESPALDO',
        // Entities_types: 'Tipos de persona',
        // emails: 'Correos',
        phones: "Teléfonos",
        // dashboardTimelogs: 'DASHBOARD INTELLI ACCESS',
        // dashboardVisit: 'DASHBOARD INTELLI GUEST',
        // visitTimelogs: 'MARCAJES DE VISITANTES',
        createOrUpdateUser: {
          2: "NUEVO USUARIO",
          3: "ACTUALIZACIÓN DE USUARIO",
        },

        //LIST
        label_module: "Módulo",
        label_event_type: "Tipo de evento",
        label_supervising: "Supervisando",
        label_filters: "Filtros",
        label_parameters: "Parámetros",
        label_event_action: "Acción",
        label_structures: "Estructuras",
        label_devices: "Dispositivos",
        label_devices_models: "Modelos de dispositivos",
        label_not_has_elements: "No tiene información",
        infoEvent: "Información del evento",
        infoActions: "Acciones del evento",
        infoAction: "Información de la acción",
        recurenceDaily: "Diaria",
        recurenceWeekly: "Semanal",
        label_execute_time: "Tiempo de ejecución",
        label_minutes: "Minutos",
        label_elements: "Elementos",
        label_select_recurrence: "Recurrencia",
        label_repetitions: "Repeticiones",

        label_async_entities: "¿Cuáles personas?",
        label_async_entities_types: "¿Cuáles son los tipos de personas?",
        label_async_vehicles: "¿Cuáles vehículos?",
        label_async_vehicles_types: "¿Cuáles son los tipos de vehículos?",
        label_async_access_scheduled: "¿Cuáles horarios de acceso?",
        label_async_access_profiles: "¿Cuáles perfiles de acceso?",
        label_async_work_stations: "¿En qué Estaciones de trabajo?",
        label_async_users: "¿Cuáles usuarios?",
        label_async_visitants: "¿Cuáles visitantes?",
        label_async_Visiteds: "¿Cuáles visitados?",
        label_async_devices: "¿En qué dispositivos?",
        label_async_content_report:
          "¿Qué tipo de contenido llevara el reporte?",
        label_async_content_text: "¿Qué tipo de contenido llevara el mensaje?",
        label_async_persons_groups: "¿En qué grupo de personas?",
        label_async_devices_groups: "¿En qué grupo de dispositivos?",
        label_async_vehicles_groups: "¿En qué grupo de vehículos?",
        label_devices_types: "¿Cuáles son los tipos de dispositivos?",
        label_devices_models: "¿Cuáles modelos de dispositivos?",
        label_access_types: "¿Cuáles son los tipos de acceso?",
        label_verifications_modes: "Seleccione los modos de verificación",
        label_verifications_result: "Seleccione el resultado de verificación",
        label_close_modes: "Defina el modo de cierre de la visita",
        label_what_time: "¿En qué momento?",
        label_defined_status: "Defina el estado",
        label_set_time: "Cuando ocurre entre",
        label_structure_event: "¿En qué estructuras?",
        label_structure_action: "¿En qué estructuras?",
        label_recurrence: "Valor de recurrencia",
        limitActions: "No puede eliminar, limite mínimo de 1 acción por evento",
        visit_timelogs: "Marcajes de visitantes",
        plan_notification: "Notificaciones en plano",
        change_status_message:
          "¿Está seguro de que desea cambiar el estado del registro?",
        change_status: "Cambiar estatus",

        //Items
        executeTime: {
          1: "Inmediato",
          2: "Retardo",
          3: "Específico",
        },
        recurrenceType: {
          1: "Diario",
          2: "Semanal",
        },
        result: {
          0: "Exitoso",
          1: "Inválido",
        },
        495: "No puede eliminar eventos comunes",
      },
      logicErrors: {
        invalidDateInt: "Fecha inválida - Fecha final menor a la inicial",
        invalidTimeInt: "Tiempos inválidos - Tiempo final menor al inicial",
        locationNotFoud: "Sede seleccionada no encontrada",
        isNotLocation: "No es una sede",
        invalidScheduleForLocation: "Horario invalido para sede",
        personHasScheduling:
          "Ya esa persona tiene un agendamiento la fecha indicada",
        cannotDeleteResourceUsed: "No puede eliminar agendamientos usados ",
        cannotDeleteResourceOld: "No puede eliminar agendamientos ya iniciados",
        cannotUpdateResourceUsed: "No puede editar agendamientos usados ",
        cannotUpdateResourceOld:
          "No puede editar agendamientos anteriores a hoy",
        scheduleNoAvalible: "Horario no disponible para aforos en esa fecha",
        schedulingNotFound: "No se encontró aforo para los dias indicados",
        schedulingNotAvailables:
          "Cupos completos para el aforo de los dias indicados",
        invalidDateLocationHaveScheduling:
          "Ya hay aforo registrado para esa fecha",
        totalCannotBeZero: "Total no puede ser cero",
      },
      PersonScheduling: {
        title: "Aforo",
        date_in: "Fecha inicial",
        date_out: "Fecha final",
        used: "Usado",
        status: "Estatus",
        id_person: "Persona",
        id_schedule: "Horario",
        id_structure: "Sede",
        id_L: "Ubicación",
        id_SL: "Ubicación Específica",
        dows: "Días",
        time_in: "Hora inicial",
        time_out: "Hora final",
        placeNoAvailable: "Puesto no disponible",
        selectedPlace: "Puesto seleccionado",
        placePartiallyUsed: "Puesto parcialmente ocupado",
        datesUsed: "Fechas Ocupadas",
        dow: "Día",
        date: "Fecha",
        time: "Hora",
        place: "Puesto",
        schedules: "Agendamientos",
        observations: "Observaciones",
        calendarSchedule: "Hacer clic para agendar sala",
        legend: {
          working_place: "Puesto de trabajo",
          meet_place: "Puesto de reunión",
          place_available: "Puesto disponible",
          place_no_available: "Puesto no disponible",
          other_places: "Otros puestos",
          coworking_area: "Área de coworking",
          meet_area: "Área de reuniones",
        },
      },
    },
    DocumentType: {
      1: "CÉDULA",
      2: "CÉDULA DE EXTRANJERÍA",
      3: "PERMISO DE PERMANENCIA",
      4: "PASAPORTE",
      5: "NIT",
      6: "REGISTRO CIVIL",
      7: "REGISTRO ÚNICO TRIBUTARIO",
    },
  },
  "es-DO": {
    translations: {
      About: {
        about: "Acerca de MyIntelli",
        copyright: "Derechos reservados Intellinext-Corp 2021 ©",
        versions: "Versiones de micros servicios",
        politics: "Política de tratamiento de datos",
        usPolitics:
          "Para conocer nuestras políticas de tratamiento de datos, haga clic en este link",
        recover: "Recuperación de contraseña",
      },
      Login: {
        username: "Nombre de usuairo",
        password: "Contraseña",
        login: "Ingresar",
        rights: "Todos los derechos reservados - Intelli Next 2021 ©",
        btnRecover: "¿Olvidó su contraseña?",
        email: "Correo electrónico",
        invalidUrl: "Url inválida",
        newKey: "Ingrese PIN de soporte",
        key: "Clave",
        title: "Bienvenidos",
        btnLog: "Ingresar",
        plcIdc: "ID del cliente",
        plcUsr: "Usuario",
        plcPsw: "Contraseña",
        plcPswConfirm: "Confirmar contraseña",
        plcEmail: "Correo Electrónico",
        language: "Lenguaje",
        clientError: "Cliente no encontrado",
        userError: "Tipo y/o número de documento incorrecto",
        process: "Procesar",
        titleRecover: "¿Olvidó contraseña?",
        placeholderRecoverEmail: "ejemplo@correo.com",
        placeholderRecoverIdClient: "1",
        passwordRecoveryTitle: "Recuperando contraseña",
        passwordEmpty: "La clave no puede estar en blanco",
        passwordResetedSuccesfully: "Contraseña recuperada exitosamente!",
        passwordResetedFailed:
          "No se pudo enviar el correo de restablecimiento",
        serverDown: "Conexión fallida!",
        validationError: "Correo inválido!",
        btnBackLogin: "Página Inicial",
        passwordRequired: "Contraseña vacía",
        newPasswordSuccess: "Nueva contraseña generada exitosamente",
        newPassword: "Ingrese nueva contraseña",
        disabled_user: "El usuario se encuentra inactivo",
        user_blocked:
          "El usuario se encuentra bloqueado, intente recuperar contraseña",
        cameraNotFound: "Cámara no encontrada",
        confirmPassword: "Confirmar contraseña",
      },
      Token: {
        expired: "Su sesión ha expirado",
        invalid: "Token inválido",
        dismissed: "No hay token",
        verified: "Token verificado!",
      },
      General: {
        accept: "Aceptar",
        overwrite: "Sobreescribir",
        info: "Información",
        documentType: "Tipo de documento",
        typesDocument: {
          1: "CÉDULA",
          2: "CÉDULA DE EXTRANJERÍA",
          3: "PERMISO DE PERMANENCIA",
          4: "PASAPORTE",
          5: "NIT",
          6: "REGISTRO CIVIL",
          7: "REGISTRO ÚNICO TRIBUTARIO",
        },
        warning: "Advertencia",
        user: "Usuario y dirección IP",
        principal: "Panel principal",
        notActiveService: "El servicio de MyIntelli no está activo",
        downloadHere: "Descarga aquí",
        titleModalSchedule: "Detalles del horario",
        insertSuccess: "Insertado exitosamente",
        selectCamera: "Cámara",
        name: "Nombre",
        mail: "Correo",
        phone: "Teléfono",
        toolbarReminder:
          "Recuerde que también puede realizar su agendamiento desde el calendario de Google",
        birthDate: "Fecha de nacimiento",
        locationDetails: "Detalles de localización",
        timelogDetails: "Detalles del marcaje",
        timelogSuccess: "Marcaje generado de forma exitosa",
        newTimelog: "Nuevo marcaje",
        newTimelogIn: "Nuevo marcaje de entrada",
        newTimelogOut: "Nuevo marcaje de salida",
        details: "Detalles",
        viewDetails: "Ver detalles",
        pair: "Par marcaje",
        days: "Dìas",
        hours: "Horas ordinarias",
        extra: "Horas extras",
        absences: "Ausencias",
        search: "Buscar",
        today: "Hoy",
        month: "Mes",
        fabNew: "Nuevo",
        fabMatrix: "Matriz",
        fabTimeline: "Línea de tiempo",
        btnOk: "Guardar",
        btnCancel: "Cancelar",
        btnClose: "Cerrar",
        btnSubmit: "Enviar",
        btnBack: "Regresar",
        btnNext: "Siguiente",
        Schedules: "Horarios",
        Schedule: "Horario",
        Position: "Cargo",
        Job: "Trabajo",
        Location: "Localización",
        activeEntity: "Activa",
        notActiveEntity: "No activa",
        toActivate: "Activar",
        Start: "Inicio",
        End: "Final",
        Time: "Hora",
        Type: "Tipo",
        Description: "Descripción",
        Status: "Estatus",
        permission: "Permiso",
        Schedule: "Horario",
        Workshift: "Turno",
        permissions: "Permisos",
        Workshifts: "Turnos",
        Enrolling: "Enrolamiento",
        selectEntity: "Seleccionar la entidad",
        entity: "Entidad",
        in: "Entrada",
        out: "Salida",
        capture: "Capturar",
        date: "Fecha",
        day: "Día",
        typeTimelog: "Tipo de marcaje",
        TTymelog: "T. marcaje",
        timelogs: "Marcajes",
        times: "Tiempos",
        details: "Detalles",
        newRegister: "Nuevo registro",
        messageDeleteRecord:
          "¿Estás seguro de que quieres eliminar este registro?",
        entryExit: "Entrada o salida",
        entry: "Entrada",
        exit: "Salida",
        kilometres: "Kilómetros",
        miles: "Millas",
        selectCut: "Corte",
        weekly: "Semanal",
        biweekly: "Quincenal",
        monthly: "Mensual",
        attention: "Atención",
        delete: "Eliminar",
        verificationMode: "Modo de verificación",
        titleSuccess: "Operación",
        updateSuccess: "Actualizado exitosamente",
        document: "Documento",
        typeDocument: "Tipo de documento",
        first_timelog: "Primer",
        last_timelog: "Último",
        planifiedHours: "Horas planificadas",
        workedUpHours: "Horas trabajadas",
        settings_device: {
          serial: "Key",
        },
        generateSerial: "Generar key",
        workstationErrorTitle: "Error al generar la key",
        workstationError: "Contacte al administrador",
        enrollComputerSuccess: "Computadora enrolada satisfactoriamente",
        enrollPhotoSuccess: "Rostro enrolado satisfactoriamente",
        enrollDevice: "Enrolar dispositivo",
        enrollPhoto: "Enrolar foto",
        noOptions: "Sin opciones",
        location: "Sede",
        used: "Usado",
        notUsed: "No usado",
        scheduleInGoogleCalendar: "Agendar en Google Calendar",
        rememberText: "Recuerda seleccionar la Sala en el Calendario de Google",
        goToCalendar: "Ir al Calendario"
      },
      Dow: {
        Sunday: "DOMINGO",
        Monday: "LUNES",
        Tuesday: "MARTES",
        Wednesday: "MIÉRCOLES",
        Thursday: "JUEVES",
        Friday: "VIERNES",
        Saturday: "SÁBADO",
      },
      Header: {
        info: "Ajustes de usuario",
        configuration: "Configuración",
        notifications: "Notificaciones",
        exit: "Salir",
        deviceRole: "Enrolar equipo",
        support: "Asistencia",
      },
      User: {
        user_name: "Usuario",
        password: "Contraseña",
        passwordNew: "Nueva contraseña",
        password_current: "Contraseña Actual",
        typeThePassword: "Ingrese su contraseña actual",
        passwordConfirm: "Confirmar contraseña",
        roles: "Roles del usuario",
        timeLocal: "HORA LOCAL",
        timeEvent: "HORA DEL EVENTO",
        settings_user_format_date: "Formato de fechas",
        settings_user_format_date_short: "Formato de fechas cortas",
        settings_user_format_hour: "Formato de horas",
        settings_user_format_numeric: "Formato númerico",
        settings_user_event_time: "Hora en eventos",
        settings_user_format_name: "Formato de nombres",
        settings_user_format_temperature: "F. de temperatura",
        settings_user_format_length: "Formato de longitud",
        settings_user_status_approval: "Nivel de aprobación",
        settings_user_format_calculation: "Formato de cálculos",
        password_repeat: "Ingrese la contraseña nuevamente",
        change_password: "Cambiar contraseña",
        phone: "Télefono",
        email: "Correo electronico",
        status: "Estatus",
        active_person: "Solo activas",
        user: "Nombre",
        active: "Activa",
        inactive: "Inactiva",
        structure: "Permisos sobre la estructura organizativa",
        recoveryError: "Correo no encontrado",
        recoverySuccess: "Enviado enlace de restablecimiento de contraseña",
        change_data: "Configuración de usuario",
        allPermissionTooltip: "Dar todos los permisos",
        allPermission: "Todos los permisos",
        permission: "Permisos",
        passwordCurrent: "Contraseña actual",
        receiveVisits: "Recibe visitas?",
        visits: "Visitas",
        FnLn1: "PRIMER NOMBRE / PRIMER APELLIDO",
        FnLn1Ln2: "PRIMER NOMBRE / PRIMER APELLIDO / SEGUNDOAPELLIDO",
        FnMnLn1Ln2:
          "PRIMER NOMBRE / SEGUNDO NOMBRE / PRIMER APELLIDO / SEGUNDO APELLIDO",
        FnMnLn1: "PRIMER NOMBRE / SEGUNDO NOMBRE / PRIMER APELLIDO",
        Ln1Fn: "PRIMER APELLIDO / PRIMER NOMBRE",
        Ln1Ln2Fn: "PRIMER APELLIDO / SEGUNDO APELLIDO / PRIMER NOMBRE",
        Ln1Ln2FnMn:
          "PRIMER APELLIDO / SEGUNDO APELLIDO / PRIMER NOMBRE / SEGUNDO NOMBRE",
        Ln1FnMn: "PRIMER APELLIDO / PRIMER NOMBRE / SEGUNDO NOMBRE",
        work_stations: "Estaciones de trabajo",
        create_visit: "¿Registra visitas?",
        create_visit_tooltip: "Registra visitas",
        timeZone: "Zona horaria",
        language: "Lenguaje",
        plcPswConfirm: "Confirmar contraseña",
        infoPerson: "Datos personales",
      },
      verificationModes: {
        FINGERPRINT: "HUELLA",
        IRIS: "IRIS",
        VOICE: "VOZ",
        PIN: "CLAVE",
        PIN_NUMBER: "PIN NUMÉRICO",
        PIN_ALPHANUMERIC: "PIN ALFANUMÉRICO",
        CARD: "TARJETA DE PROXIMIDAD",
        FACIAL: "ROSTRO",
        PHOTO: "FOTO",
        PALM: "PALMA",
        USER: "CREADO POR USUARIO",
        FACIAL_MOBILE: "ROSTRO POR TELÉFONO",
        FACIAL_PC: "ROSTRO POR COMPUTADORA",
        FINGERPRINT_PC: "HUELLA POR COMPUTADORA",
        PIN_PC: "PIN POR COMPUTADORA",
        PIN_MOBILE: "PIN POR TELÉFONO",
        FINGERPRINT_CARD: "HUELLA, TARJETA DE PROXIMIDAD",
        FINGERPRINT_CARD_PIN: "HUELLA, TARJETA DE PROXIMIDAD Y CLAVE",
        PC: "COMPUTADORA",
        MOBILE: "TELÉFONO",
      },
      timeplanning: {
        time: "Tiempo",
        matrix: {
          concept: "Concepto",
        },
      },
      timeAttendance: {
        status_approval: "Nivel de aprobacion",
        modalLocalization: "Localización",
      },
      ConceptMaster: {
        ABSENCE: "AUSENCIA",
        A_ABSENCE: "AUSENCIA ABSOLUTA",
        BREAK: "DESCANSO",
        COMPENSATORY: "COMPENSATORIA",
        DELAY: "RETRASO",
        EXTRA: "HORAS EXTRA",
        HOUR: "HORA",
        PERMISSION: "PERMISOS",
        HOURS: "HORAS",
        HOURS_NIGHT: "HORAS NOCTURNAS",
        HOURS_SUNDAY: "HORAS DOMINICALES",
        HOURS_SUNDAY_NIGHT: "HORAS DOMINICALES NOCTURNAS",
        HOURS_HOLIDAY: "HORAS DÍA FERIADO",
        HOURS_HOLIDAY_NIGHT: "HORAS DÍA FERIADO NOCTURNA",
        HOURS_WITHOUT_SCHEDULE: "HORAS FUERA DE HORARIO",
        HOURS_WITHOUT_SCHEDULE_NIGHT: "HORAS FUERA DE HORARIO NOCTURNA",
        HOURS_WITHOUT_SCHEDULE_SUNDAY: "HORAS FUERA DE HORARIO DOMINICALES",
        HOURS_WITHOUT_SCHEDULE_SUNDAY_NIGHT:
          "HORAS FUERA DE HORARIO DOMINICALES NOCTURNA",
        HOURS_WITHOUT_SCHEDULE_HOLIDAY: "HORAS FUERA DE HORARIO DÍA FERIADO",
        HOURS_WITHOUT_SCHEDULE_HOLIDAY_NIGHT:
          "HORAS FUERA DE HORARIO DÍA FERIADO NOCTURNA",
        EXTRA_NIGHT: "HORAS EXTRA NOCTURNA",
        EXTRA_SUNDAY: "HORAS EXTRA DOMINICALES",
        EXTRA_SUNDAY_NIGHT: "HORAS EXTRA DOMINICALES NOCTURNAS",
        EXTRA_HOLIDAY: "HORAS EXTRA DÍA FERIADO",
        EXTRA_HOLIDAY_NIGHT: "HORAS EXTRA DÍA FERIADO NOCTURNA",
        DELAY_NIGHT: "RETRASO NOCTURNO",
        DELAY_SUNDAY: "RETRASO DOMINICAL",
        DELAY_SUNDAY_NIGHT: "RETRASO DOMINICAL NOCTURNO",
        DELAY_HOLIDAY: "RETRASO DÍA FERIADO",
        DELAY_HOLIDAY_NIGHT: "RETRASO DÍA FERIADO NOCTURNO",
        BREAK_NIGHT: "DESCANSO NOCTURNO",
        BREAK_SUNDAY: "DESCANSO DOMINICAL",
        BREAK_SUNDAY_NIGHT: "DESCANSO DOMINICAL NOCTURNO",
        BREAK_HOLIDAY: "DESCANSO DÍA FERIADO",
        BREAK_HOLIDAY_NIGHT: "DESCANSO DÍA FERIADO NOCTURNO",
        BREAK_LUNCH: "DESCANSO COMIDA",
        BREAK_LUNCH_NIGHT: "DESCANSO COMIDA NOCTURNA",
        BREAK_LUNCH_SUNDAY: "DESCANSO COMIDA DOMINICAL",
        BREAK_LUNCH_SUNDAY_NIGHT: "DESCANSO COMIDA DOMINICAL NOCTURNA",
        BREAK_LUNCH_HOLIDAY: "DESCANSO COMIDA DÍA FERIADO",
        BREAK_LUNCH_HOLIDAY_NIGHT: "DESCANSO COMIDA DÍA FERIADO NOCTURNO",
        COMPENSATORY_NIGHT: "COMPENSATORIAS NOCTURNAs",
        COMPENSATORY_SUNDAY: "COMPENSATORIAS DOMINICALES",
        COMPENSATORY_SUNDAY_NIGHT: "COMPENSATORIAS DOMINICALES NOCTURNOS",
        COMPENSATORY_HOLIDAY: "COMPENSATORIAS DÍA FERIADO",
        COMPENSATORY_HOLIDAY_NIGHT: "COMPENSATORIAS DÍA FERIADO NOCTURNO",
        DAY_OFF: "DÍA LIBRE",
        ABSENCE_NIGHT: "AUSENCIA NOCTURNA",
        ABSENCE_SUNDAY: "AUSENCIA DOMINICAL",
        ABSENCE_SUNDAY_NIGHT: "AUSENCIA DOMINICAL NOCTURNA",
        ABSENCE_HOLIDAY: "AUSENCIA DÍA FERIADO",
        ABSENCE_HOLIDAY_NIGHT: "AUSENCIA DÍA FERIADO NOCTURNO",
        A_ABSENCE_SUNDAY: "AUSENCIA ABSOLUTA DOMINICAL",
        A_ABSENCE_HOLIDAY: "AUSENCIA ABSOLUTA DÍA FERIADO",
        HOURS_BEFORE_SCHEDULE: "HORAS ANTES DE HORARIO",
        HOURS_BEFORE_SCHEDULE_NIGHT: "HORAS ANTES DE HORARIO NOCTURNO",
        HOURS_BEFORE_SCHEDULE_SUNDAY: "HORAS ANTES DE HORARIO DOMINICAL",
        HOURS_BEFORE_SCHEDULE_SUNDAY_NIGHT:
          "HORAS ANTES DE HORARIO DOMINICAL NOCTURNO",
        HOURS_BEFORE_SCHEDULE_HOLIDAY: "HORAS ANTES DE HORARIO DÍA FERIADO",
        HOURS_BEFORE_SCHEDULE_HOLIDAY_NIGHT:
          "HORAS ANTES DE HORARIO DÍA FERIADO NOCTURNO",
        HOURS_AFTER_SCHEDULE: "HORAS DESPUÉS DE HORARIO",
        HOURS_AFTER_SCHEDULE_NIGHT: "HORAS DESPUÉS DE HORARIO NOCTURNO",
        HOURS_AFTER_SCHEDULE_SUNDAY: "HORAS DESPUÉS DE HORARIO DOMINICAL",
        HOURS_AFTER_SCHEDULE_SUNDAY_NIGHT:
          "HORAS DESPUÉS DE HORARIO DOMINICAL NOCTURNO",
        HOURS_AFTER_SCHEDULE_HOLIDAY: "HORAS DESPUÉS DE HORARIO DÍA FERIADO",
        HOURS_AFTER_SCHEDULE_HOLIDAY_NIGHT:
          "HORAS DESPUÉS DE HORARIO DÍA FERIADO NOCTURNO",
        DAILY_NOVELTY_PAYMENT: "PAGO DIARIO DE NOVEDADES",
        DAILY_NOVELTY_PAYMENT_CONFLICT: "CONFICTO DE PAGO DIARIO DE NOVEDADES",
        DAILY_NOVELTY_WITHOUT_PAYMENT: "NOVEDAD DIARIA SIN PAGO",
        DAILY_NOVELTY_WITHOUT_PAYMENT_CONFLICT:
          "CONFICTO DE NOVEDAD DIARIA SIN PAGO",
        HOUR_NOVELTY_PAYMENT: "PAGO POR HORA DE NOVEDAD",
        HOUR_NOVELTY_PAYMENT_CONFLICT: "CONFICTO DE PAGO POR HORA DE NOVEDAD",
        HOUR_NOVELTY_WITHOUT_PAYMENT: "HORA DE NOVEDAD SIN PAGAR",
        HOUR_NOVELTY_WITHOUT_PAYMENT_CONFLICT:
          "CONFICTO DE HORA DE NOVEDAD SIN PAGAR",
      },
      FormErrors: {
        if111: "Campo requerido",
        if112: "Campo único",
        if112B: "El registro esta siendo utilizado",
        if113: "Campo de tipo entero",
        if114: "Longitud máxima del campo excedida",
        if115: "Longitud mínima del campo no alcanzada",
        if116: "Correo inválido",
        if117: "Campo de tipo arreglo",
        if118: "Valor inválido",
        if119: "Campo de tipo caracter",
        if120: "Ya se encuentra registrado",
        if121: "Campo debe ser entero",
        if122: "La estructura debe ser una puerta o un control de acceso",
        if123: "No se permite espacios en blanco",
        if124: "La estructura debe permitir parqueaderos",
        if130: "El nuevo tipo agregado ya existe para la persona",
        if132: "Fecha/hora exceden el rango",
        if404: "Campo no existe",
        if492:
          "No puede ser estructura organizactiva, 1 o más dispositivos asignados",
        STATUS_CONFLICT:
          "Estructura de tipo organizacional no puede contener estructuras físicas",
        if133: "Aun tiene 1 cargo sin asignar",
        if134: "Cantidad debe ser mayor a 0",
        if135: "ReCaptcha invalido",
        notBelongto: "Foto inválida",
        photoExists: "Ya existe la foto de la persona",
        noFace: "No hay rostro en la foto",
        moreThanOne: "Hay más de un rostro en la foto",
        entity_inactive: "Entidad inactiva",
        sameMonth: "No más de 30 días",
        noSchedulings: "Días sin agendamientos",
        schedulingNotAvailable: "Agenda no disponible",
        availability: "Disponibilidad",
      },
      noveltyPermission: {
        type_partial: "Parcial",
        type_daily: "Diaria",
      },
      TimeLog: {
        date: "Día",
        hour: "Hora",
        device: "Biométrico",
        structure: "Ubicación estructural",
        access_type: "Acción",
        access_type_timelog: "Acción",
        timelogs: "Marcajes",
        accessType: {
          0: "Entrada o Salida",
          1: "Entrada",
          2: "Salida",
        },
        result: {
          0: "No Verficado",
          1: "Verificado",
          2: "No Autorizado",
        },
        verificationType: "Tipo de verificación",
        verificationResult: "Resultado",
        details: "Detalles",
        method: "Método",
        infoModal: "Marcaje",
        info: "Datos del marcaje",
      },
      TextTemplate: {
        type: "Tipo de plantilla",
        EMAIL: "Correo",
        SMS: "SMS",
        name_template: "Nombre de plantilla",
        text_template: "Texto",
        time: "Hora",
        date: "Fecha",
        device: "Dispositivo",
        person: "Persona",
        structure: "Estructura",
        dui_person: "Num. documento",
      },
      Graphics: {
        Delay: "Tardanzas",
        WorkingWithoutSchedule: "Trabajando sin horario",
        UnTimelyExit: "Salida intempestiva",
        YesterdayUnEvenTimelog: "Marcajes impares de ayer",
        YesterdayUnapprovedTimelog: "Marcajes no aprobados de ayer",
        WorkingPeople: "Personas Trabajando",
        AbsentPeople: "Personal ausente",
        PermitPeople: "Personal de permiso",
        PermitsPendingApproval: "Permisos pendientes por aprobación",

        StationsByLocations: "Estaciones por sede",
        VisitsScheduledByLocations: "Visitas programadas por sede",
        VisitsScheduledByLocations: "Visitas programadas por sede",
        VisitsByLocations: "Visitas por sede",
        workshitTypeBreak: "Tipo de descanso",
        loading: "Cargando Métrica ",
        visitActives: "Visitas activas",
        devicesTypes: "Tipos de dispositivos",
        entityDevices: "Personas por dispositivos",
        devicesModels: "Módelos de dispositivos",
        rolesUser: "Roles de usuario",
        operationUser: "Operaciones",
        eventsTypes: "Tipos de eventos",
        eventsActions: "Tipos de acciones",
        accessSchedule: "Horarios de acceso",
        accessStructures: "Estructuras de acceso",
        category: "Lógica de la estructura",
        structureTypes: "Uso de la estructura",
        entityTypes: "Tipos de personas",
        entityTypesGroup: "Tipos de entidades",
        vehiclesTypes: "Tipos de vehículos",
        accessRoles: "Perfiles de acceso",
        actives: "Activos",
        connected: "Dispositivos conectados",
        visitedStructures: "Estructuras visitadas",
        listVisited: "Visitados",
        listAccessSchedule: "Horarios de acceso",
        blackList: "Lista Negra",
        inactives: "inactivos",
        typesBiometrics: "Tipos de biometría",
        listStatus: "Estatus de visitas",
        visitScheduledStatus: "Estatus de visitas programadas",
        activas: "Activas",
        timeloVerified: "Marcajes verificados",
        timeloNoVerified: "Marcajes no verificados",
        porcentajeTimeloVerified: "% Marcajes verificados",
        currentStructures: "Estructuras actuales - Personas ",
        currentStructuresGuest: "Estructuras actuales - Visitantes",
        listTypesTimeLogs: "Tipos de marcajes",
        listTypesTimeLogsGuest: "Tipos de marcajes - Visitantes",
        in: "ENTRADA",
        out: "SALIDA",
        inOut: "ENTRADA / SALIDA",
        personInt: "Personas dentro",
        personOut: "Personas fuera",
        vehicleIn: "Vehículos dentro",
        vehicleOut: "Vehiculos fuera",
        visitInt: "Visitantes dentro",
        visitOut: "Visitantes fuera",
        porcentajePersonInt: "% Personas dentro",
        noData: "No hay datos",
        Sunday: "Dom",
        Monday: "Lun",
        Tuesday: "Mar",
        Wednesday: "Mie",
        Thursday: "Jue",
        Friday: "Vie",
        Saturday: "Sab",
        others: "OTROS",
        FINGERPRINT: "HUELLA",
        IRIS: "IRIS",
        VOICE: "VOZ",
        PIN: "CLAVE",
        PIN_NUMBER: "PIN NUMÉRICO",
        PIN_ALPHANUMERIC: "PIN ALFANUMÉRICO",
        CARD: "TARJETA DE PROXIMIDAD",
        FACIAL: "ROSTRO",
        PHOTO: "FOTO",
        PHOTO_ZKTECO: "FOTO - ZKTECO",
        PALM: "PALMA",
        USER: "CREADA POR USUARIO",
        FACIAL_MOBILE: "ROSTRO POR TELÉFONO",
        FACIAL_PC: "ROSTRO POR COMPUTADORA",
        FINGERPRINT_PC: "HUELLA POR COMPUTADORA",
        PIN_PC: "PIN POR COMPUTADORA",
        PIN_MOBILE: "PIN POR TELÉFONO",
        FINGERPRINT_CARD: "HUELLA, TARJETA DE PROXIMIDAD",
        FINGERPRINT_CARD_PIN: "HUELLA, TARJETA DE PROXIMIDAD Y CLAVE",
        PC: "COMPUTADORA",
        MOBILE: "TELÉFONO",
        usingStation: "Estaciones de trabajo en uso",
        usingClosingRules: "Reglas de cierre de visitas en uso",
        defaultClosingRules: "Reglas de cierre predeterminadas",
        estructure: "ESTRUCTURA",
        PLATE: "PLACA",
        typesBiometricsVehicle: "Tipos de biometría - Vehículo",
        visitScheduledActives: "Visitas programadas activas",
        lastStructure: "Ubicación actual por personas",
        entityTypesInSide: "Relación de personas dentro",
        LastStructure: "Estructuras actuales",
        periodAutoClose: "Períodos con cierre automático",
        TypeRegularHours: "Tipos de límites de horas a pagar",
        IgnoreAbsences: "Ignora ausencias",
        conceptUnit: "Uso de unidad",
        conceptRounding: "Uso de redondeo",
        noveltyType: "Tipo de permiso",
        noveltyActives: "Permisos activos ",
        contractTypeActives: "Tipo de contrato activos",
        periodActives: "Períodos activos",
        holidayActives: "Días festivos activos",
        conceptActives: "Conceptos activos",
        workshiftActives: "Turnos activos",
        workshitBreakConcepts: "Uso de conceptos en descansos",
        workshiftAutoAprovalExtra: "Auto aprobación de tiempo extra - Turnos ",
        workOrderCreated: "Órdenes de trabajo creadas",
        workOrderStatus: "Órdenes de trabajo estatus",
        workOrderPosition: "Órdenes de trabajo cargos",
        templateTypesTA: "Tipos de biometría - Tiempo y Asistencia",
        accessRolesTA: "Roles de acceso - Tiempo y Asistencia",
        entityTypesTA: "Tipos de personas - Tiempo y Asistencia",
        workshifts: "Turnos de trabajo",
      },
      EventTask: {
        text_to_send: "Texto a enviar",
        eventTypeCommon: "Común",
        // Páginas
        event: "Evento",
        actions: "Acciones",
        newAction: "Nueva acción",

        // Módulos
        general: "General",
        access_control: "Intelli Access",
        visits: "Intelli Guest",
        devices: "Dispositivos",
        users: "Usuarios",
        notifications: "Notificaciones",
        system: "Sistema",
        time_attendance: "Intelli Time",

        // Etiquetas/Placeholders
        event_name: "Nombre del evento",
        type_event_monitor: "¿Qué tipo de evento desea supervisar?",
        want_monitor: "¿Qué desea supervisar?",
        select_type_filters: "Seleccione los tipos de filtros",
        select_module: "Seleccione el módulo",
        type_action_do: "¿Qué tipo de acción quiere realizar?",
        select_parameters: "Seleccionar parámetros de acción",

        // Eventos/Acciones
        ip: "Dirección IP",

        action_scheduled: "Acción Programada",
        scheduled_action: "Acción programada",
        timelog: "Marcaje",
        timelog_ac_accepted: "Marcaje Verificado - Intelli Access",
        timelog_ta_accepted: "Marcaje Verificado - Intelli Time",
        inTimeLog: "MARCAJE DE ENTRADA",
        outTimeLog: "MARCAJE DE SALIDA",
        inAndOutTimelog: "MARCAJE DE ENTRADA / SALIDA",
        disabledTimelog: "MARCAJE NO AUTORIZADO",
        firstTimeLog: "PRIMER MARCAJE",
        timelog_ac_not_verified: "Marcaje No Verificado - Intelli Access",
        timelog_ta_not_verified: "Marcaje No Verificado - Intelli Time",
        invalidTimelog: "MARCAJE INVALIDO",
        delayTimeLog: "MARCAJE CON TARDANZA",
        open_exit_button: "Apertura con botón de salida",
        access_scheduled: "Horario de acceso",
        scheduled_access: "Horario de acceso",
        change_status_door_remote: "Cambio remoto de estado en puerta",
        change_status_door_manual: "Cambio manual de estado en puerta",
        visit_new: "Nueva visita",
        visit_new_scheduled: "Nueva visita programada",
        visit_closed: "Visita cerrada",
        visit_change_status_scheduled: "Cambio de Estado en Visita Programada",
        change_access_profile_persons: "Cambiar perfil de acceso de personas",
        change_access_profile_vehicles: "Cambiar perfil de acceso de vehículos",
        add_to_blacklist: "Agregar a lista negra",
        remove_from_blacklist: "Remover de lista negra",
        connection_change: "Cambio de conexión",
        user_login: "Inicio de sesión de usuario",
        user_creation: "Creación de usuario",
        user_blocked: "Bloqueo de Usuario",
        password_change: "Cambio de contraseña",
        reports: "Reportes",
        open_door: "Abrir puertas",
        close_door: "Cerrar puertas",
        lock_door: "Bloquear puertas",
        access_scheduled_enabled: "Habilitar horarios de acceso",
        access_scheduled_disabled: "Deshabilitar horarios de acceso",
        inactivate_person: "Inactivar personas",
        activate_person: "Activar personas",
        inactivate_vehicles: "Inactivar vehículos",
        activate_vehicles: "Activar vehículos",
        change_access_role: "Deshabilitar Personas/Vehículos",
        persons_vehicles_disabled: "Cambio Perfil de acceso",
        inactivate_registration_station: "Inactivar estaciones de trabajo",
        activate_registration_station: "Activar estaciones de trabajo",
        blacklist_goto: "Pasar a lista negra",
        inactivate_device: "Inactivar dispositivos",
        activate_device: "Activar dispositivos",
        alerts: "Alertas",
        widgets: "Widgets",
        text_messages: "Mensajes de texto",
        email_messages: "Mensajes de correo",
        plans: "Planos",
        battery_status_change: "Cambio de estado de batería",
        based_event: "Basado en el evento",
        create_update_user: "Modificaciones de usuario",

        // Filtros y Parámetros
        all_person: "Todas las personas",
        all_vehicle: "Todos los vehículos",
        entities: "Personas",
        entity: "Entidad",
        entities_types: "Tipos de personas",
        device: "Dispositivo",
        structures: "Estructuras",
        time: "Tiempo",
        vehicles: "Vehículos",
        vehicle_type: "Tipo de vehículo",
        biometric_models: "Módelos de biométricos",
        verification_modes: "Modos de verificación",
        start: "Inicio",
        end: "Final",
        start_end: "¿En el momento de?",
        user: "Usuario",
        visitants: "Visitantes",
        visitors: "Visitantes",
        visited: "Visitados",
        access_roles: "Perfil de acceso",
        work_stations: "Estaciones de trabajo",
        report_templates: "Plantillas de reportes",
        execution_time: "Momento de ejecución",
        weekdays: "Días de la semana",
        devices_types: "Tipos de dispositivos",
        device_status: "Estado de dispositivos",
        door_status: "Estado de puertas",
        scheduled_visit_status: "Estado de visitas programadas",
        closing_mode_status: "Modo de cierre",
        connected: "Conectado",
        disconnected: "Desconectado",
        open: "Abrir",
        close: "Cerrar",
        lock: "Bloquear",
        executed: "Ejecutada",
        cancelled: "Cancelada",
        automatic: "Automática",
        manual: "Manual",
        report_templates: "Plantillas de reportes",
        user_defined: "Definido por usuario",
        emails: "Correos",
        emailsPlaceholder: "ejemplo@correo.com",
        recurrence_value: "Valor de recurrencia",
        text_messaging_templates: "Plantillas SMS",
        // text_messaging_templates: 'Plantillas de Mensajes de Texto',
        based_on_event: "Basado en el evento",
        access_types: "Tipos de accesos",
        verification_results: "Resultados de verificación",
        persons_groups: "Grupos de personas",
        devices_groups: "Grupos de dispositivos",
        vehicles_groups: "Grupos de vehículos",
        battery_status: "Estado de batería",

        // Campos
        field_in_date: "Fecha inicial",
        field_out_date: "Fecha final",
        field_in_hour: "Hora inicial",
        field_out_hour: "Hora final",
        field_status: "Estatus",
        field_event_name: "Nombre del evento",
        field_settings_type: "Tipo de configuración",
        field_supervise: "Supervisando",
        field_filters: "Filtros",
        field_entities: "Personas",
        field_entities_types: "Tipos de personas",
        field_devices: "Dispositivos",
        field_structures: "Estructuras",
        field_dow: "Días de la semana",
        field_in_hour: "Hora inicial",
        field_out_hour: "Hora final",
        field_status: "Estatus",
        field_vehicles: "Vehículos",
        field_plate: "Placa",
        field_users: "Usuarios",
        field_visitants: "Visitantes",
        field_devices_models_biometrics: "Módelos de biométricos",
        field_verification_modes: "Modos de verificación",
        field_access_scheduled: "Horario de Acceso",
        field_start_end: "¿En el momento de?",
        field_access_roles: "Roles de acceso",
        field_work_stations: "Estaciones de trabajo",
        field_execute_hour: "Hora de ejecución",
        field_device_types: "Tipos de dispositivos",
        field_access_type: "Tipos de acceso",
        field_visiteds: "Visitados",
        field_verification_result: "Resultado de verificación",
        field_vehicles_types: "Tipos de vehículos",
        field_filter_status: "Estados de dispositivos",
        field_common_action: "Accion común",
        field_module: "Módulo",
        field_icons_groups: "Grupos de iconos",
        field_entities_groups: "Grupos de personas",
        field_devices_groups: "Grupos de dispositivos",
        field_vehicles_groups: "Grupos de vehículos",
        field_based_on_event: "Basado en el evento",
        field_repetitions: "Repeticiones",
        field_recurrence: "Recurrencia",
        field_parameters: "Parámetros",
        field_phones: "Teléfonos",
        field_emails: "Correos electrónicos",
        field_time_delay: "Tiempo de retardo",
        field_access_profiles: "Perfiles de acceso",
        field_time_hour: "Hora",
        field_content_text: "Texto contenido",
        field_id_text_template: "Id plantilla de texto",

        // triggered_at: 'Hora del evento',
        // event_description: 'Descripcion del evento',
        eventTypes: "Tipo de evento",
        // eventAction: 'Acción a realizar',
        // eventHeld: 'Acción realizada',
        // events: 'Eventos',
        persons_event: "Seleccione las personas involucradas",
        // devices_event: 'Dispositivos',
        // device: 'Dispositivo',
        // structure: 'Estructura',
        // settingsTypeIn: 'Desde',
        // settingsTypeOut: 'Hasta',
        // type: 'Tipo',
        // category: 'Categoria',
        // actions: 'Acciones',
        // typeNotification: 'Tipo de notificación',
        // actionDevices: 'Acciones del dispositivo',
        // sendType: 'Prioridad del envio',
        // email: 'Correos',
        // phone: 'Teléfonos',
        // hourAction: 'Hora de envío',
        status: "Estado",
        // emergencyAlarm: 'ALARMA DE EMERGENCIA',
        // fireAlarm: 'ALARMA DE INCENDIO',
        // securityAlarm: 'ALARMA DE SEGURIDAD',
        // sysmicAlarm: 'ALARMA SISMICA',
        // sendMoment: 'ENVIAR AL MOMENTO',
        // sendSpecificTime: 'ENVIAR EN UN TIEMPO DETERMINADO',
        // notificationBar: 'NOTIFICACIÓN EN BARRA SUPERIOR',
        // alertMessage: 'Mensaje de alerta',
        // devices: 'Dispositivo a accionar',
        // simpleNotification: 'NOTIFICACIÓN SIMPLE',
        // activeAlarm: 'ACTIVAR ALARMA',
        // turnOnLight: 'ENCENDER LUZ',
        // turnOffLight: 'APAGAR LUZ',
        // sendEmail: 'ENVIAR CORREO ELECTRÓNICO',
        // sendSms: 'ENVIAR SMS',
        // openDoor: 'APERTURAR PUERTA',
        // widgetNotification: 'WIDGET DE NOTIFICACIÓN',
        // soundedAlarm: 'INTRUSIÓN DE ZONA',
        // anyone: 'CUALQUIERA',
        // emergencyLight: 'LUZ DE EMERGENCIA',
        // nightLight: 'LUZ NOCTURNA',
        // backupLight: 'LUZ DE RESPALDO',
        // Entities_types: 'Tipos de persona',
        // emails: 'Correos',
        phones: "Teléfonos",
        // dashboardTimelogs: 'DASHBOARD INTELLI ACCESS',
        // dashboardVisit: 'DASHBOARD INTELLI GUEST',
        // visitTimelogs: 'MARCAJES DE VISITANTES',
        createOrUpdateUser: {
          2: "NUEVO USUARIO",
          3: "ACTUALIZACIÓN DE USUARIO",
        },

        //LIST
        label_module: "Módulo",
        label_event_type: "Tipo de evento",
        label_supervising: "Supervisando",
        label_filters: "Filtros",
        label_parameters: "Parámetros",
        label_event_action: "Acción",
        label_structures: "Estructuras",
        label_devices: "Dispositivos",
        label_devices_models: "Modelos de dispositivos",
        label_not_has_elements: "No tiene información",
        infoEvent: "Información del evento",
        infoActions: "Acciones del evento",
        infoAction: "Información de la acción",
        recurenceDaily: "Diaria",
        recurenceWeekly: "Semanal",
        label_execute_time: "Tiempo de ejecución",
        label_minutes: "Minutos",
        label_elements: "Elementos",
        label_select_recurrence: "Recurrencia",
        label_repetitions: "Repeticiones",

        label_async_entities: "¿Cuáles personas?",
        label_async_entities_types: "¿Cuáles son los tipos de personas?",
        label_async_vehicles: "¿Cuáles vehículos?",
        label_async_vehicles_types: "¿Cuáles son los tipos de vehículos?",
        label_async_access_scheduled: "¿Cuáles horarios de acceso?",
        label_async_access_profiles: "¿Cuáles perfiles de acceso?",
        label_async_work_stations: "¿En qué Estaciones de trabajo?",
        label_async_users: "¿Cuáles usuarios?",
        label_async_visitants: "¿Cuáles visitantes?",
        label_async_Visiteds: "¿Cuáles visitados?",
        label_async_devices: "¿En qué dispositivos?",
        label_async_content_report:
          "¿Qué tipo de contenido llevara el reporte?",
        label_async_content_text: "¿Qué tipo de contenido llevara el mensaje?",
        label_async_persons_groups: "¿En qué grupo de personas?",
        label_async_devices_groups: "¿En qué grupo de dispositivos?",
        label_async_vehicles_groups: "¿En qué grupo de vehículos?",
        label_devices_types: "¿Cuáles son los tipos de dispositivos?",
        label_devices_models: "¿Cuáles modelos de dispositivos?",
        label_access_types: "¿Cuáles son los tipos de acceso?",
        label_verifications_modes: "Seleccione los modos de verificación",
        label_verifications_result: "Seleccione el resultado de verificación",
        label_close_modes: "Defina el modo de cierre de la visita",
        label_what_time: "¿En qué momento?",
        label_defined_status: "Defina el estado",
        label_set_time: "Cuando ocurre entre",
        label_structure_event: "¿En qué estructuras?",
        label_structure_action: "¿En qué estructuras?",
        label_recurrence: "Valor de recurrencia",
        limitActions: "No puede eliminar, limite mínimo de 1 acción por evento",
        visit_timelogs: "Marcajes de visitantes",
        plan_notification: "Notificaciones en plano",
        change_status_message:
          "¿Está seguro de que desea cambiar el estado del registro?",
        change_status: "Cambiar estatus",

        //Items
        executeTime: {
          1: "Inmediato",
          2: "Retardo",
          3: "Específico",
        },
        recurrenceType: {
          1: "Diario",
          2: "Semanal",
        },
        result: {
          0: "Exitoso",
          1: "Inválido",
        },
        495: "No puede eliminar eventos comunes",
      },
      logicErrors: {
        invalidDateInt: "Fecha inválida - Fecha final menor a la inicial",
        invalidTimeInt: "Tiempos inválidos - Tiempo final menor al inicial",
        locationNotFoud: "Sede seleccionada no encontrada",
        isNotLocation: "No es una sede",
        invalidScheduleForLocation: "Horario invalido para sede",
        personHasScheduling:
          "Ya esa persona tiene un agendamiento la fecha indicada",
        cannotDeleteResourceUsed: "No puede eliminar agendamientos usados ",
        cannotDeleteResourceOld: "No puede eliminar agendamientos ya iniciados",
        cannotUpdateResourceUsed: "No puede editar agendamientos usados ",
        cannotUpdateResourceOld:
          "No puede editar agendamientos anteriores a hoy",
        scheduleNoAvalible: "Horario no disponible para aforos en esa fecha",
        schedulingNotFound: "No se encontró aforo para los dias indicados",
        schedulingNotAvailables:
          "Cupos completos para el aforo de los dias indicados",
        invalidDateLocationHaveScheduling:
          "Ya hay aforo registrado para esa fecha",
        totalCannotBeZero: "Total no puede ser cero",
      },
      PersonScheduling: {
        title: "Aforo",
        date_in: "Fecha inicial",
        date_out: "Fecha final",
        used: "Usado",
        status: "Estatus",
        id_person: "Persona",
        id_schedule: "Horario",
        id_structure: "Sede",
        id_L: "Ubicación",
        id_SL: "Ubicación Específica",
        dows: "Días",
        time_in: "Hora inicial",
        time_out: "Hora final",
        placeNoAvailable: "Puesto no disponible",
        selectedPlace: "Puesto seleccionado",
        placePartiallyUsed: "Puesto parcialmente ocupado",
        datesUsed: "Fechas Ocupadas",
        dow: "Día",
        date: "Fecha",
        time: "Hora",
        place: "Puesto",
        schedules: "Agendamientos",
        observations: "Observaciones",
        calendarSchedule: "Hacer clic para agendar sala",
        legend: {
          working_place: "Puesto de trabajo",
          meet_place: "Puesto de reunión",
          place_available: "Puesto disponible",
          place_no_available: "Puesto no disponible",
          other_places: "Otros puestos",
          coworking_area: "Área de coworking",
          meet_area: "Área de reuniones",
        },
      },
    },
  },
};

export default Langs;
