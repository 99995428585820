import {
	USER_LOGIN,
	USER_LOGOUT,
	USER_INITIAL,
	USER_PASSWORD_RESET,
	PASSWORD_SEND_EMAIL,
	CHECK_RECOVERY_TOKEN,
	SET_MODE_RECOVERY_PASSWORD,
	MODIFY_USER_SETTING,
	PROCESSING,
	PROCESS_COMPLETE,
	CLEAR_SESSION,
} from "../../constants";

import axios from "axios";
import { url } from "../../../../config";
import utils from "../../../../utils";
// import { toastr } from "react-redux-toastr";
import i18next from "i18next";
import checkTokenAndClient from "./correctUrlToken";
import {
	IntelliToastMsg,
	typeToast,
} from "../../../../components/common/IntelliToastMsg";
export const loginSuccess = (data) => {
	return {
		type: USER_LOGIN,
		payload: data,
	};
};

export const logOut = () => {
	return async function (dispatch) {
		// await axios
		// 	.post(`${url}/logout`,{
		// 		headers: util.headerAxios()
		// 	});
		dispatch({ type: PROCESS_COMPLETE });

		dispatch({
			type: USER_LOGOUT,
		});
		// dispatch({
		//   type: CLEAR_SESSION,
		// });
	};
};

export const loginInitial = () => {
	return {
		type: USER_INITIAL,
	};
};

export const loginRecover = () => {
	return async function (dispatch) {
		dispatch(loginRecover());
	};
};

export const verifiedToken = (token) => (dispatch) => {
	let headers = utils.headerAxios(false);
	return axios.get(`${url}/verify_token_forgot_password/${token}`, { headers });
};

export const changePassword = (data) => (dispatch) => {
	let headers = utils.headerAxios(false);
	return axios.post(`${url}/change_password`, data, { headers });
};

export const userRecovery = (data) => (dispatch) => {
	dispatch({ type: PROCESSING });
	const request = axios
		.post(`${url}/send_email_reset`, data, {
			headers: utils.headerAxios(false),
		})
		.then((res) => {
			dispatch({ type: PROCESS_COMPLETE });
			IntelliToastMsg({
				type: typeToast.success,
				title: i18next.t(`General.titleSuccess`),
				text: i18next.t("User.recoverySuccess"),
			});
		})
		.catch((error) => {
			dispatch({ type: PROCESS_COMPLETE });
			utils.errorHandling(dispatch, error, "User");
		});
	dispatch({
		type: PASSWORD_SEND_EMAIL,
		payload: request,
	});
};

export const userLogin = (login) => async (dispatch) => {
	dispatch({ type: PROCESSING });
	try {
		const { data } = await axios.post(`${url}/login`, login, {
			headers: utils.headerAxios(false),
		});
		dispatch({ type: PROCESS_COMPLETE });
		localStorage.setItem("id_client", 154);
		// localStorage.setItem("id_client", data.id_client);
		localStorage.setItem("token", data.token);
		localStorage.setItem("id_registration_station", "-1");
		// localStorage.setItem("intl", "en-US");
		localStorage.setItem("intl", data.languaje? data.languaje:"es-Co");
		dispatch(loginSuccess(data));
		return true;
	} catch (error) {
		dispatch({ type: PROCESS_COMPLETE });
		utils.errorHandling(dispatch, error, "Login");
		return false;
	}
};

export const updateUserSetting = (user) => (dispatch) => {
	dispatch({
		type: MODIFY_USER_SETTING,
		payload: user,
	});
};

export const setRecoveryPasswordMode =
	(mode = false) =>
	(dispatch) => {
		dispatch({
			type: SET_MODE_RECOVERY_PASSWORD,
			payload: { mode },
		});
	};

export const checkRecoveryToken = (data) => (dispatch) => {
	if (data.id_client === undefined) return false;
	const request = axios
		.post(`${url}/${data.id_client}/verify_reset_token`, data, {
			headers: utils.headerAxios(false),
		})
		.then((res) => {
			// toastr.success(
			//   i18next.t("Login.passwordRecoveryTitle"),
			//   i18next.t("Token.verified")
			// );
		})
		.catch((error) => {
			utils.errorHandling(dispatch, error);
		});
	dispatch({
		type: CHECK_RECOVERY_TOKEN,
		payload: request,
	});
};

export const restorePassword = (data) => (dispatch) => {
	const request = axios
		.post(`${url}/reset_email`, data, {
			headers: utils.headerAxios(false),
		})
		.then((res) => {
			// toastr.success(
			//   i18next.t("Login.passwordRecoveryTitle"),
			//   i18next.t("Login.passwordResetedSuccesfully")
			// );
		})
		.catch((error) => {
			utils.errorHandling(dispatch, error);
		});
	dispatch({
		type: USER_PASSWORD_RESET,
		payload: request,
	});
};

export const userUpdateProfile =
	(id, data, formId, done = null) =>
	(dispatch) => {
		if (!checkTokenAndClient(dispatch)) return false;
		let headers = utils.headerAxios();
		dispatch({ type: PROCESSING });
		axios
			.put(`${url}/users_update/${id}`, data, { headers })
			.then((res) => {
				if (done !== null) {
					done();
				}
				dispatch({ type: PROCESS_COMPLETE });
				// dispatch({
				//   type: ACTIONS_FORM_ADD,
				//   payload: { id: formId },
				// });
				utils.updateSuccess();
			})
			.catch((error) => {
				dispatch({ type: PROCESS_COMPLETE });
				utils.errorHandling(dispatch, error, "User");
			});
	};

export const logoutPrueba = () => (dispatch) => {
	utils.errorHandling(
		dispatch,
		{
			response: {
				data: {
					status: "et401",
				},
			},
		},
		"User"
	);
};
