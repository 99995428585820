import React, { useEffect, useRef, useState } from "react";
import chroma from "chroma-js";
import { withTranslation } from "react-i18next";
import { Menu, Tooltip } from "@material-ui/core";
import moment from "moment";
import ItemMenuStyled from "../itemMenuStyled";

const formatDateMoment = (date, format) => {
  return moment(date).format(format);
};
const formatTimeMoment = (time, format) => {
  return moment(time, "HH:mm:ss").format(format);
};

const truncateTime = (date) => {
  const _date = date.split(":");
  if (date.includes(":00")) {
    if (_date[1].includes("m")) {
      return `${
        _date[0].includes("00") ? "0" : _date[0].split("0")[1] || _date[0]
      } ${_date[1].split(" ")[1]}`;
    } else {
      return _date[0].includes("00") ? "0" : _date[0].split("0")[1] || _date[0];
    }
  }
  return `${
    _date[0].includes("00") ? "0" : _date[0].split("0")[1] || _date[0]
  }:${_date[1]}`;
};

const enterDelaysTooltip = {
  enterDelay: 500,
  enterNextDelay: 300,
  enterTouchDelay: 1000,
};

const SchedulingItemDropped = ({
  onChange,
  onDelete,
  index,
  idFather,
  item,
  idPerson,
  gridOptions,
  t,
  onHover,
  onClick,
  ...props
}) => {
  const ref = useRef(null);

  const {
    schedule,
    date_in,
    date_out,
    time_in,
    time_out,
    used,
    place,
    ubicacionE,
    structure,
  } = item;
  let strInfo = place == null ? structure : ubicacionE;
  const [mouseState, setMouseState] = useState({ mouseX: null, mouseY: null });

  const [hover, setHover] = useState(false);

  const handleCloseMenu = () => {
    setMouseState({ mouseY: null, mouseX: null });
    props.menuOpen.setMenuOpen(false);
  };

  const FORMAT = props.auth.data.format_date;
  const FORMAT_TIME = props.auth.data.format_time;

  let color = "#69b5e7";
  let backgroundColor =
    props.date > moment().format("YYYY-MM-DD") ? "#69b5e7" : "#b4b4b4";

  let fullPlace = null;

  if (place) {
    fullPlace = item.places.find(
      (item) => item.id_structure === place.id_structure
    );
  }

  if (fullPlace) {
    color = fullPlace.sub_type === "PT" ? "#69b5e7" : "#e3d75f";
    if (props.date > moment().format("YYYY-MM-DD")) {
      backgroundColor = fullPlace.sub_type === "PT" ? "#69b5e7" : "#e3d75f";
    }
  }

  // let tooltipMessage = `${formatTimeMoment(
  //   time_in,
  //   FORMAT_TIME
  // )} - ${formatTimeMoment(time_out, FORMAT_TIME)}\n${ubicacionE.structure}\n${
  //   place.structure
  // }`;

  //   let tooltipMessage = "FIRST LINE \n SECOND LINE";

  //   tooltipMessage += `${formatDateMoment(date_in, FORMAT)} `;

  return (
    <div
      className="item_dropped"
      ref={ref}
      style={{
        color: "black",
      }}
      onContextMenu={(e) => {
        e.stopPropagation();
        e.preventDefault();

        if (props.menuOpen.menuOpen) {
          handleCloseMenu();
        } else {
          setMouseState({ mouseX: e.clientX, mouseY: e.clientY });
          props.menuOpen.setMenuOpen(true);
        }
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        // if (!mouseState.mouseX) {
        //   props.handleModal();
        // }
      }}
      onMouseEnter={(e) => {
        setHover(true);
        if (!props.preventDelete) {
          onHover(true);
        }
      }}
      onMouseLeave={(e) => {
        setHover(false);
        if (!props.preventDelete) {
          onHover(false);
        }
      }}
    >
      <div
        className="siglas"
        style={{
          backgroundColor: color,
          flex: gridOptions.type !== "week" ? "0 1 100%" : "",
        }}
      >
        {props.date > moment().format("YYYY-MM-DD") ? (
          <i className="far fa-circle" />
        ) : used ? (
          <i className="far fa-check" />
        ) : (
          <i className="far fa-times" />
        )}
      </div>
      <Tooltip
        className="iconPointer"
        title={
          <div style={{ whiteSpace: "pre-line", textAlign: "center" }}>
            <div style={{ fontSize: "1em" }}>{`${strInfo.structure}`}</div>
            {!!place && (
              <div style={{ fontSize: "0.9em" }}>{`${place.structure}`}</div>
            )}
            {!!place &&
              !!place.settings_structure &&
              !!place.settings_structure.observation && (
                <div
                  style={{ fontSize: "0.7em" }}
                >{`${place.settings_structure.observation}`}</div>
              )}
            <div style={{ fontSize: "0.8em" }}>
              {`${formatTimeMoment(time_in, FORMAT_TIME)} - ${formatTimeMoment(
                time_out,
                FORMAT_TIME
              )}`}
            </div>
          </div>
        }
        placement="top"
        classes={{ tooltip: "tooltipCard_tooltip" }}
      >
        <div
          className="time"
          style={{
            backgroundColor: chroma(backgroundColor).alpha(0.6),
          }}
          onClick={() => {
            onClick();
          }}
        >
          {!!hover && !props.preventDelete && (
            <div className="icon">
              <i
                className="fas fa-trash"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  props.funDelete();
                }}
              />
            </div>
          )}
          <div className="time">
            <div className="text">
              <span
                style={{ fontSize: "0.75rem", fontWeight: "bold" }}
              >{`${strInfo.structure}`}</span>
            </div>
            {!!place && (
              <div className="place">
                {fullPlace && (
                  <i
                    className={`fas fa-${
                      fullPlace.sub_type === "PT"
                        ? "chair-office"
                        : "users-class"
                    }`}
                  />
                )}
                <span
                  style={{
                    fontSize: "0.65rem",
                    fontWeight: "bold",
                  }}
                >{`${place.structure}`}</span>
              </div>
            )}
            <div className="text">
              <span
                style={{
                  fontSize: "0.65rem",
                  opacity: 0.7,
                  fontWeight: "bold",
                }}
              >
                {`${formatTimeMoment(
                  time_in,
                  FORMAT_TIME
                )} - ${formatTimeMoment(time_out, FORMAT_TIME)}`}
              </span>
            </div>
          </div>
        </div>
      </Tooltip>
      {/* <Menu
				id="simple-menu"
				anchorReference="anchorPosition"
				anchorPosition={
					mouseState.mouseY !== null
						? { top: mouseState.mouseY, left: mouseState.mouseX }
						: undefined
				}
				open={props.menuOpen.menuOpen !== false && mouseState.mouseX !== null}
				onClose={() => {
					setMouseState({ mouseX: null, mouseY: null });
				}}
				className="item-dropped-menu">
				<ItemMenuStyled
					iconName="trash"
					enterDelaysTooltip={enterDelaysTooltip}
					onClick={(e) => {
						// props.handleModal();
						props.funDelete();
						handleCloseMenu();
					}}
					titleTooltip={t("General.delete")}
					disable={props.preventDelete}
				/>
			</Menu> */}
    </div>
  );
};

export default withTranslation("translations")(SchedulingItemDropped);
