import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { uriBase } from "../../config";
import utils from "../../utils";
import BaseFormBtn from "../common/BaseFormBtn";
import IntelliChip from "../common/IntelliChip";
import IntelliDivider from "../common/IntelliDivider";
import IntelliInfo from "../common/IntelliInfo";
import IntelliModal from "../common/IntelliModal";
import IntelliFab from "./IntelliFab";

export const getDeltaDateTime = (dateTime1, dateTime2) => {
	let dt1 = moment(dateTime1);
	let dt2 = moment(dateTime2);

	return dt2.diff(dt1, "minutes") / 60;
};

const WizarModalForm = (props) => {
	const { onCloseModal, componentProps } = props;
	const { onCallBack, auth, start, end, selecteds, massiveDelete, massiveAdd, handlerScheduleList } =
		componentProps;

	const { t } = useTranslation();

  const onlyScheduleList = !(moment(start) < moment(moment().format("YYYY-MM-DD")));

	return (
		<div className="component_modal">
			<Grid container className="form">
				<div className="wizard-modal">
          <div className="option">
						<IntelliFab
							title={t("PersonScheduling.schedules")}
							icon="fal fa-address-book fa-2x"
							color="bg_colorAuxiliarAccept"
							action={() => handlerScheduleList(start)}
						/>
						<span>{t("PersonScheduling.schedules")}</span>
					</div>
					{onlyScheduleList && (
            <>
              <div className="option">
                <IntelliFab
                  title={t("General.fabNew")}
                  icon="fal fa-plus fa-2x"
                  color="bg_colorAuxiliarPrimary"
                  action={() => {
                    massiveAdd();
                  }}
                />
                <span>{t("General.fabNew")}</span>
              </div>
              {!!selecteds && !!selecteds.length && (
                <div className="option">
                  <IntelliFab
                    title={t("General.delete")}
                    icon="fal fa-trash fa-2x"
                    action={() => {
                      massiveDelete();
                    }}
                    color="bg_colorAuxiliarDelete"
                  />
                  <span>{t("General.delete")}</span>
                </div>
              )}
            </>
          )}
				</div>
			</Grid>
			<BaseFormBtn
				onCloseModal={onCloseModal}
				viewSave={false}
				textok={t("General.accept")}
				onOk={() => {
					onCallBack();
					onCloseModal();
				}}
				actions={<></>}
			/>
		</div>
	);
};

const WizarModal = (props) => {
	const { openModal, onCloseModal, componentProps } = props;

	const { t } = useTranslation();
  const { start } = componentProps;
  
  const onlyScheduleList = !(moment(start) < moment(moment().format("YYYY-MM-DD")));

	return (
		<IntelliModal
			openModal={openModal}
			onCloseModal={onCloseModal}
			title={t("General.titleSuccess")}
			modalContent={WizarModalForm}
			componentProps={componentProps}
			modalSize={onlyScheduleList ? 4 : 3}
		/>
	);
};

export default connect(({}) => ({}))(WizarModal);
