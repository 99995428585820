import React, { Component } from "react";
import { uid } from "uid";
import { connect } from "react-redux";
import { Checkbox, Paper, Tooltip, Grid } from "@material-ui/core";
import DeleteButton from "../../elements/DeleteButtonRowData";
import utils from "../../../utils";
import IntelliModal from "../../common/IntelliModal";
import DeleteForm from "../../../forms/DeleteForm";
import { withTranslation } from "react-i18next";
import moment from "moment";

class RowData extends Component {
	state = {
		seeActions: false,
		modalDelete: false,
	};

	closeModalDelete = () => {
		this.setState({ modalDelete: false });
	};

	render() {
		const {
			t,
			auth,
			module,
			checked,
			onChecked,
			primaryKey,
			data,
			openItem,
			toggleCard,
			header,
			ComponentForm,
			funGetItem,
			onCallBack,
			addFavorite,
			formIndex,
			blackList,
			favorite,
			actDelete,
			settingHeader,
			checkCell,
			onUpdateSearch,
			clearItemUpdate,
      actDetails,
      onViewDetails
		} = this.props;
		if (header == undefined || header.length == 0) return null;
		const IsOpen = openItem.includes(primaryKey) && ComponentForm != null;
		// let hasDelete = utils.hasPermission(auth, module, 4);
		let hasDelete = true;
		// if(module == 'PERMIT'){
		//   hasDelete = data.status_approval == 4? true:false
		// }
		if (module === "SCHEDULES") {
			hasDelete = !data.used && data.date_in > moment().format("YYYY-MM-DD");
		}
		let size = checkCell ? 11 : 12;

		return (
			<div className="row_list">
				<Paper
					elevation={IsOpen ? 7 : 0}
					onMouseEnter={() => {
						if (actDelete === true || actDetails === true || favorite !== "")
							this.setState({ seeActions: true });
					}}
					onMouseLeave={() => {
						if (actDelete === true || actDetails === true ||  favorite !== "")
							this.setState({ seeActions: false });
					}}
					className={IsOpen ? "itemTableRow" : "itemTableRowA"}>
					<Grid
						container
						direction="row"
						spacing={0}
						className={IsOpen ? "rowsGridA" : "rowsGrid"}>
						{checkCell === true ? (
							<Grid
								item
								xs={1}
								className={
									settingHeader
										? "Intelli-Setting-MuiGrid-grid-xs-1 centerVertical"
										: "Intelli-MuiGrid-grid-xs-1 centerVertical"
								}>
								<Checkbox
									color={"primary"}
									key={uid()}
									checked={checked}
									onClick={(e) => onChecked(e, primaryKey)}
									// className = {classes.checkboxItem}
									classes={{
										root: "headerTable_checkboxList",
									}}
								/>
							</Grid>
						) : null}

						<Grid
							item
							xs={size}
							className={
								settingHeader
									? `Intelli-Setting-MuiGrid-grid-xs-${size} centerVertical`
									: `Intelli-MuiGrid-grid-xs-${size} centerVertical`
							}>
							<Grid
								container
								spacing={0}
								style={{ position: "relative" }}
								alignItems="center"
								onClick={() => {
									toggleCard(primaryKey);
								}}>
								{header
									.filter((item) => item.type === "cell")
									.map((header, index) => {
										// let field = "";
										// if (header.field.includes(".") === true) {
										//   const set = header.field.split(".");
										//   const setting = data[set[0]];
										//   field = setting[set[1]];
										// } else {
										let field = data[header.field];
										// }
										return (
											<Grid
												key={index}
												item
												{...header.size}
												className="item centerVertical">
												{/* <Tooltip title={(header.renderFunction)? header.renderFunction(data,field):field} placement='bottom-start' 
                                                classes = {{'tooltip': 'tooltipTitleColumn'}} > */}
												<div className="intelliTruncate_default">
													{header.renderFunction
														? header.renderFunction(data, field)
														: field}
												</div>
												{/* </Tooltip> */}
											</Grid>
										);
									})}
								{header
									.filter((item) => item.type === "actions")
									.map((header, index) => {
										let validFavorite = false;
										switch (favorite) {
											case "favorite_persons":
												validFavorite =
													auth.data.user.settings_user.favorite_persons.includes(
														data[this.props.header[0].field]
													);
												break;
											case "favorite_work_orders":
												if (
													auth.data.user.settings_user.favorite_work_orders ==
													undefined
												) {
													validFavorite = false;
												} else {
													validFavorite =
														auth.data.user.settings_user.favorite_work_orders.includes(
															data[this.props.header[0].field]
														);
												}
												break;

											default:
												validFavorite = false;
												break;
										}
                    
										return (
											<Grid 
                        key={index}
                        {...header.size} 
                        className="centerVH_Column"
                      >
												{this.state.seeActions && actDelete && hasDelete ? (
													<DeleteButton
														handleClick={(event) => {
															event.preventDefault();
															event.stopPropagation();
															this.setState({
																modalDelete: !this.state.modalDelete,
															});
														}}
													/>
												) : null}
                        {(actDetails) && (
                          <Tooltip
                              enterDelay={500}
                              leaveDelay={0}
                              // title={<div className='modeVerifTooltip'><span>{accessDetails}:</span> <div>{modeDetails}</div></div>}
                              title={t('General.viewDetails')}
                              placement='top' 
                              classes = {{ 'tooltip': 'tooltipCard_tooltip' }}
                          >
                            <span className={`itemModeVerification cursorHand `} onClick={() => onViewDetails(data)}>
                              <i className="fas fa-info-circle"></i>
                            </span>
                          </Tooltip> 
                        )}
											</Grid>
										);
									})}
							</Grid>
						</Grid>
					</Grid>
					{(IsOpen) ? (
						<ComponentForm
							primaryKey={primaryKey}
							// clearItemUpdate={clearItemUpdate}
							key={formIndex}
							// funGetItem={funGetItem}
							onCallBack={onCallBack}
							componentProps={{ obj: data }}
							// module={module}
							onClose={() => {
								toggleCard(primaryKey);
							}}
						/>
					) : null}
					{this.state.modalDelete && (
						<IntelliModal
							openModal={this.state.modalDelete}
							onCloseModal={() => this.setState({ modalDelete: false })}
							title={t("General.attention")}
							module={module}
							// onCallBack={onCallBack}
							funGetItem={this.props.funGetItem}
							modalContent={DeleteForm}
							funDelete={this.props.funDelete}
							componentProps={{ itemCheck: [primaryKey] }}
							modalSize={6}
						/>
					)}
				</Paper>
			</div>
		);
	}
}

RowData.defaultProps = {
	checked: false,
	onChecked: () => console.log("Chequeado. Sin funcion establecida"),
	primaryKey: 1,
	data: {},
	toggleCard: () => console.log("Modificar ficha. Sin funcion establecida"),
	header: {},
	form: null,
	funGetItem: () => console.log("Sin funcion establecida"),
	onCallBack: () => console.log("Sin funcion establecida"),
	alertType: false,
};
const mapStateToProps = ({ auth }) => {
	return { auth };
};

const mapDispatchToProps = {};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation("translations")(RowData));
