import React from "react";
import Header from "./Header";
import SideBar from "./SideBar";

const BasePage = (props) => {
	return (
		<div className="dashboard_page">
			<Header />
			{props.page}
		</div>
	);
};

export default BasePage;
