import React from "react"


const Toolbar = (props) => {
  return (
    <div className="toolbar">
      <div className="fields">{props.fields}</div>
      <div className="buttons">{props.buttons}</div>
    </div>
  );
}

export default Toolbar;