import axios from "axios";
import utils from "../../../../utils";
import {
  PROCESSING,
  PROCESS_COMPLETE,
  ENTITIES_PERMISSIONS_FETCH,
  ENTITIES_PERMISSIONS_INSERT,
  ENTITIES_PERMISSIONS_FETCH_MORE,
} from "../../constants";
import checkTokenAndClient from "./correctUrlToken";
import { url } from "../../../../config";
import _ from "lodash";

export const permissionInsert =
  (data, idEntity, callback = null) =>
  (dispatch) => {
    if (!checkTokenAndClient(dispatch)) return false;
    let headers = utils.headerAxios();
    dispatch({ type: PROCESSING });
    const request = axios
      .post(`${url}/person/${idEntity}/permission`, data, { headers })
      .then((res) => {
        dispatch({ type: PROCESS_COMPLETE });
        utils.insertSuccess();
        if (callback != null) {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: PROCESS_COMPLETE });
        utils.errorHandling(dispatch, error, "Permission");
      });
    dispatch({
      type: ENTITIES_PERMISSIONS_INSERT,
      payload: request,
    });
  };

export const permissionUpdate =
  (data, idEntity, idEntityPermission, callback = null) =>
  (dispatch) => {
    if (!checkTokenAndClient(dispatch)) return false;
    let headers = utils.headerAxios();
    // headers["Content-Type"] = 'multipart/form-data';
    dispatch({ type: PROCESSING });
    const request = axios
      .post(
        `${url}/person/${idEntity}/permission/${idEntityPermission}`,
        data,
        { headers }
      )
      .then((res) => {
        dispatch({ type: PROCESS_COMPLETE });
        // dispatch({
        //   type: ACTIONS_FORM_ADD,
        //   payload: { id: idEntityPermission },
        // });
        utils.updateSuccess();
        if (callback != null) {
          console.info("callback");
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: PROCESS_COMPLETE });
        utils.errorHandling(dispatch, error, "Permission");
      });
  };

export const permissionDelete =
  (idEntity, idEntityPermission) => (dispatch) => {
    if (!checkTokenAndClient(dispatch)) return false;
    const headers = utils.headerAxios();
    return axios
      .delete(`${url}/person/${idEntity}/permission/${idEntityPermission}`, {
        headers,
      })
      .then(() => {
        return true;
      })
      .catch((error) => {
        utils.errorHandling(dispatch, error, "Permission");
        return false;
      });
  };

export const entitiesPermissionsFetch = (Options = {}, callback = null) => {
  return async function (dispatch) {
    if (!checkTokenAndClient(dispatch)) return false;
    let headers = utils.headerAxios();
    Options = _.extend(
      {
        limit: 20,
        offSet: 0,
        search: "",
        where: "",
        orderBy: "date_time_in DESC",
        allItem: false,
        dispatch: true,
        id_novelty_permission: "",
      },
      Options
    );
    // if (
    // 	!Options.allItem &&
    // 	Options.search.indexOf("where[ta_entities_permissions.status]=0") == -1
    // ) {
    // 	Options.search += "&where[ta_entities_permissions.status]=1";
    // }

    let search = "";
    if (Options.search !== "") {
      search = `&${Options.search}`;
    }
    let urlApi = `person/${Options.id_entity}/permissions?limit=${Options.limit}&offset=${Options.offSet}&leftJoin[]=ta_novelties_permissions&orderby=${Options.orderBy}`;

    if (Options.id_novelty_permission != "") {
      urlApi += `&where[ta_entities_permissions.id_novelty_permission]=${Options.id_novelty_permission}`;
    }

    const type =
      Options.offSet == 0
        ? ENTITIES_PERMISSIONS_FETCH
        : ENTITIES_PERMISSIONS_FETCH_MORE;
    const request = axios.get(`${url}/${urlApi}` + search, { headers });
    request.catch((err) => utils.errorHandling(dispatch, err));
    request.then((res) => {
      if (callback != null) {
        callback(res.data.data.results);
      }
      dispatch({
        type,
        payload: res,
      });
    });
  };
};
