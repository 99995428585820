// import {
// 	LOCATION_SCHEDULING_FETCH,
// 	LOCATION_SCHEDULING_FETCH_MORE,
// } from "../../../constants";
import _ from "lodash";
import axios from "axios";
import { url } from "../../../../config/index";
import utils from "../../../../utils";
import checkTokenAndClient from "./correctUrlToken";

export const locationSchedulingFetch = (Options = {}, callback = null) => {
  return async function (dispatch) {
    if (!checkTokenAndClient(dispatch)) return false;
    let headers = utils.headerAxios();
    Options = _.extend(
      {
        limit: 20,
        offSet: 0,
        search: "",
        where: "",
        orderBy: "",
        allItem: false,
      },
      Options
    );

    // const type = LOCATION_SCHEDULING_FETCH
    const request = axios.get(
      `${url}/keralty/availables/location_schedules?where[id_structure]=${Options.id_structure}&where[date]=${Options.date}`,
      { headers }
    );
    request.catch((err) => utils.errorHandling(dispatch, err));
    request.then((res) => {
      if (callback != null) {
        callback(res.data.data.results);
      }
    });
  };
};
