import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles( () => ({
    button: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}))

const DeleteButton = ({
    t, 
    handleClick, 
}) => {
    const [color, setColor] = useState('default')
    const classes = useStyles()
    return (
        <Tooltip 
            title={ t('General.delete') }
            placement='bottom' 
            classes = {{
                'tooltip': 'tooltipCard_tooltip'
            }}
        >
            {/* <IconButton
                className={`${classes.button} ${color}`}
                onClick={handleClick}
                aria-label="Delete"
                onMouseEnter={ () => setColor('font_color09')}
                onMouseLeave={ () => setColor('default')}
            > */}
                {/* <DeleteIcon fontSize="small" /> */}
                <i 
                    className={` fas fa-trash itemActions ${classes.button} ${color}`}
                    onClick={handleClick}
                    aria-label="Delete"
                    onMouseEnter={ () => setColor('font_color09')}
                    onMouseLeave={ () => setColor('default')}
                />
            {/* </IconButton> */}
        </Tooltip>
    )
}

export default withTranslation('translations')(DeleteButton)