import React from 'react';
import { Chip } from '@material-ui/core'

const IntelliChips = ({
    values,
    ...props
}) => {
    return (
        <Chip
            tabIndex={-1}
            size='small'
            label={ values }
            classes = {{
                'root': 'intelliChips_root', 
                'sizeSmall': 'intelliChips_sizeSmall', 
            }}
            {...props}
        />
    )
}

export default IntelliChips