export const colorsElements = {
  workshift:
    "linear-gradient(180deg, rgba(144,202,249,1) 0%, rgba(93,153,198,1) 100%)",
  break: "linear-gradient(180deg, #efdcd5 0%, #bcaaa4  100%)",
  noveltyPermission:
    "linear-gradient(180deg, rgba(255,245,157,1) 0%, rgba(203,194,109,1) 100%)",
  absence: "linear-gradient(rgb(222 222 222) 0%, rgb(152 152 152) 100%)",
  timelogAloneIn: "#ffb74d", //orange
  timelogAloneOut: "#ffb74d", //orange
  timelogOut: "#e57373", //red
  timelogIn: "#81c784", //green
  timelogDisabled: "#fff176", //yellow
  timelogNotVerified: "#b0bec5", //blue grey
  timelogNotRecognized: "#eeeeee", //light grey
};

/**
 * Tipos de elementos draggables en timeLine
 */
export const barDroppedItems = {
  WORKSHIFT_BAR: "Workshift Bar",
  NOVELTY_PERMISSION_BAR: "Novelty-permission bar",
  BREAK_BAR: "Break bar",
  ABSENCE_BAR: "Absence bar",
};

export const formatMomentfull = "YYYY-MM-DD HH:mm:ss";
export const formatMomentHours = "HH:mm";
export const formatMomentHoursSeconds = "HH:mm:ss";
export const formatMomentHoursSecondsAM = "hh:mm:ss a";
export const formatMomentDate = "YYYY-MM-DD";

export const minWidthBarPerson = 935; //px;

/**
 *colores de conceptos con base en code_concept
 */
export const colorsConcepts = {
  HOUR: "linear-gradient(180deg, rgba(165,214,167,1) 0%, rgba(117,164,120,1) 100%)",
  EXTRA:
    "linear-gradient(180deg, rgba(255,204,128,1) 0%, rgba(202,155,82,1) 100%)",
  DELAY: "#2ecc71",
  BREAK: "#e67e22",
  COMPENSATORY:
    "linear-gradient(180deg, rgba(206,147,216,1) 0%, rgba(156,100,166,1) 100%)",
  PERMISSION: "#f1c40f",
  ABSENCE:
    "linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(194,194,194,1) 100%)",
};

/**
 * Constantes para delays de tooltips de menu contextual en TimeLine
 */
export const enterDelaysTooltip = {
  enterDelay: 500,
  enterNextDelay: 300,
  enterTouchDelay: 1000,
};

/**
 * Acciones para modals de timeLine
 */
export const actionTypes = {
  INSERT: "insert",
  EDIT: "edit",
  INFO: "info",
  DELETE: "delete",
  DRAG: "drag",
  ADD: "add",
};

/**gridOptions  */

export const GRID_OPTIONS = {
  DAY: { type: "day", lengthX: 1, momentType: "day" },
  WEEK: { type: "week", lengthX: 6, momentType: "day" },
  BIWEEK: { type: "month", lengthX: 13, momentType: "day" },
  MONTH: { type: "month", lengthX: null, momentType: "month" },
};
