import React from "react";

const IntelliDivider = ({ title, header = false }) => {
	return (
		<div className={`Intelli_divider ${header ? "header" : ""}`}>
			<span>{title}</span>
			<div className="line"></div>
		</div>
	);
};

export default IntelliDivider;
