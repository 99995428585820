import { FormControlLabel, Switch, Fade, Tooltip } from "@material-ui/core";
import React from "react";

const IntelliSwitch = ({
  size,
  colorClass,
  labelPlacement,
  label,
  value,
  color,
  checked,
  onChange,
  isDisabled,
  placementTooltip,
  titleTooltip,
  classTooltip
}) => {
  return (
    <Tooltip
            title={ titleTooltip }
            placement = { placementTooltip }
            TransitionComponent = { Fade }
            classes={{ tooltip: "tooltipCard_tooltip" }}
        >
        <FormControlLabel
          // value="bottom"
          control={
            <Switch
              color={color}
              size={size}
              checked={checked == 1}
              onChange={onChange}
              value={checked}
              disabled={isDisabled}
              className={`Intelli_switch ${colorClass}`}
            />
          }
          label={label}
          labelPlacement={"start"}
          // labelPlacement={labelPlacement}
          classes={{ root: "Intelli_FormControlLabel_root", label: "Intelli_FormControlLabel_label" }}
        />
    </Tooltip>
  );
};

IntelliSwitch.defaultProps = {
  size: "small",
  colorClass: "",
  labelPlacement: "top",
  label: "switch",
  value: "",
  checked: 0,
  onChange: () => {
    console.info("onChange");
  },
  isDisabled: false,
  color: "primary",
  placementTooltip: 'bottom', 
  classTooltip: 'tooltipTitleColumn', 
  titleTooltip: 'Title Tooltip', 
};

export default IntelliSwitch;
