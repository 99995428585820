import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./App.scss";
import { store, persistor } from "./services/redux/Store";
import Routes from "./Routes";
import IntelliLoading from "./components/IntelliLoading";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { keyGoogle } from "./config";
import { I18nextProvider, useTranslation } from "react-i18next";
import { LoadScript } from "@react-google-maps/api";
import "./assets/fonts/digital/DS-DIGI.TTF";
import "./assets/fonts/digital/DS-DIGII.TTF";
import "./assets/fonts/digital/DS-DIGIT.TTF";
import "./assets/fonts/digital/DS-DIGIB.TTF";
import moment from "moment";
import { useEffect, useState } from "react";
import "moment/locale/es";

function App() {
  const [locale, setLocale] = useState(null);

  useEffect(() => {
    if (!!localStorage.intl) {
      if (localStorage.intl.split("-")[0] === "es") {
        moment.locale("es");
        setLocale("es");
      } else {
        moment.locale("en");
        setLocale("en");
      }
    }
  }, [localStorage.intl]);

  useEffect(() => {
    if (!!localStorage.intl) {
      setLocale(localStorage.intl.split("-")[0]);
    }
  }, []);

  const { i18n } = useTranslation();

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <PersistGate loading="null" persistor={persistor}>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            libInstance={moment}
            locale={locale}
          >
            <IntelliLoading />
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              className="Intelli_toast"
            />
            <LoadScript
              googleMapsApiKey={keyGoogle}
              language={i18n.language}
              loadingElement={<></>}
            >
              <Routes />
            </LoadScript>
          </MuiPickersUtilsProvider>
        </PersistGate>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
