import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { languajes } from "../utils";

const styles = {};

class SelectLang extends Component {
  state = {
    language: localStorage.intl || "es-CO",
  };
  handleChange = (event) => {
    const { i18n } = this.props;
    localStorage.setItem("intl", event.target.value);
    this.setState({ language: event.target.value });
    i18n.changeLanguage(event.target.value);
  };

  componentDidMount() {
    localStorage.setItem("intl", this.state.language);
  }

  render() {
    return this.props.decorated === true ? (
      <div className="selectLang">
        <div className="selectLang_icon" aria-label="Menu">
          <i className="fas fa-globe fa-2x" style={{ color: "#29b6f6" }}></i>
        </div>
        <div className="selectLang_select">
          <Select value={this.state.language} onChange={this.handleChange}>
            {languajes.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    ) : (
      <Select value={this.state.language} onChange={this.handleChange}>
        {languajes.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};
export default connect(mapStateToProps)(
  withTranslation("translations")(withStyles(styles)(SelectLang))
);
