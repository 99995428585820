import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { uid } from "uid";
import { withTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";

class TableHeader extends Component {
	handleAllChecked(event) {
		const check = event.target.checked;
		this.props.onClickCheckAll(check);
	}

	onClickHeader(header) {
		let orderBy = [];
		let field = header.field;
		if (header.alterOrderBy) {
			field = header.alterOrderBy;
		}
		if (this.props.orderBy[0] == field) {
			orderBy = [field, this.props.orderBy[1] == "ASC" ? "DESC" : "ASC"];
		} else {
			orderBy = [field, "ASC"];
		}
		this.props.onClickHeader(orderBy);
		this.props.setValueItemOpen([]);
	}

	render() {
		const { t, orderBy, header, settingHeader, checkCell, isOrderBy } =
			this.props;
		let check = header.filter((item) => item.type === "check");
		let size = checkCell ? 11 : 12;
		return (
			<Grid
				container
				direction="row"
				spacing={0}
				className={settingHeader ? "headerTable_setting" : "headerTable"}>
				{checkCell === true ? (
					<Grid
						item
						xs={1}
						className={` ${
							settingHeader
								? "Intelli-Setting-MuiGrid-grid-xs-1 centerVertical"
								: "Intelli-MuiGrid-grid-xs-1 centerVertical"
						} `}>
						<Grid key={uid()} item {...check.size}>
							<Checkbox
								color={"primary"}
								checked={this.props.isCheckAll}
								onClick={(event) => this.handleAllChecked(event)}
								// className = 'headerTable_checkboxItem'
								classes={{
									root: "headerTable_checkboxList",
								}}
							/>
						</Grid>
					</Grid>
				) : null}
				<Grid
					item
					xs={size}
					container
					className={
						settingHeader
							? `Intelli-Setting-MuiGrid-grid-xs-${size} centerVertical`
							: `Intelli-MuiGrid-grid-xs-${size} centerVertical`
					}>
					{this.props.header.map((header) => {
						if (header.type == "cell" || header.type == "actions") {
							return (
								<Grid
									key={uid()}
									item
									{...header.size}
									className="headerTable_colLeft">
									<div
										className="headerTable_button"
										onClick={() => {
											// if(isOrderBy === true && header.notOrderBy !== true){
											//     this.onClickHeader(header)
											// }
										}}>
										<Tooltip
											title={t(header.description)}
											placement="bottom-start"
											classes={{
												tooltip: "tooltipTitleColumn",
											}}>
											<div className="headerTable_item ">
												<span className="intelliTruncate_default">
													{t(header.description)}
												</span>
											</div>
										</Tooltip>
										{/* {isOrderBy === true && header.notOrderBy !== true?
                                                    (orderBy[0]==header.field || orderBy[0] == header.alterOrderBy) ? 
                                                        <span>
                                                            <i className={ ( orderBy[1] == 'ASC' ? 'fas fa-chevron-up headerTable_iconOrderByA' : 'fas fa-chevron-down headerTable_iconOrderByA' ) }></i>
                                                        </span>
                                                    :null
                                                :
                                                null} */}
									</div>
								</Grid>
							);
						} else if (header.type == "img") {
							return (
								<Grid
									key={uid()}
									item
									{...header.size}
									className="headerTable_colLeft headerTable_item">
									<span className=" intelliTruncate_default">
										{t(header.description)}
									</span>
								</Grid>
							);
						}
					})}
				</Grid>
			</Grid>
		);
	}
}

export default withTranslation("translations")(TableHeader);
