import {
	STRUCTURE_TYPES_FETCH,
	STRUCTURE_TYPES_FETCH_MORE,
	STRUCTURE_TYPES_FETCH_PENDING,
	STRUCTURE_TYPES_REJECTED
} from '../../constants';
import { REHYDRATE } from 'redux-persist';
import utils from '../../../../utils';

const initialState = {
	data: {
		results : []
	},
	isFetching: false
};

export default function structuresTypesReducer(state = initialState, action) {
	switch (action.type) {
		case REHYDRATE:
			return utils.actionRehydrate(state,action.payload,'structuresTypes');
		case STRUCTURE_TYPES_FETCH_PENDING:
			return {
				...state,
				isFetching : true
			};
		case STRUCTURE_TYPES_REJECTED:
			return {
				...state,
				isFetching : false
			};
		case STRUCTURE_TYPES_FETCH:
			return { 
				data : action.payload.data.data,
				isFetching : false
			};
		case STRUCTURE_TYPES_FETCH_MORE:
			return {
				...state,
				isFetching : false,
				data : {
					...action.payload.data.data,
					results : utils.mergeObjet(action.payload.data.data.results,state.data.results,'id_structure_type')
				}
			};		

		default:
			return state;
	}
}
