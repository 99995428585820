import {
	SET_ID_ENTITY,
	CLEAR_SESSION,
	SET_CAMERA_DEVICE,
	SET_ENROLL,
} from "../../constants";

const initialState = {
	id_entity: null,
	id_job: null,
	id_location: null,
	cameraDevice:  { value: "", label: "" },
	enrolled: false,
};

export default function sessionReducer(state = initialState, action) {
	switch (action.type) {
		case SET_ID_ENTITY: {
			const { id_entity, id_job, id_location, cameraDevice } = action.payload;
			if (!!cameraDevice) {
				return { ...state, id_entity, id_job, id_location, cameraDevice };
			} else {
				return { ...state, id_entity, id_job, id_location };
			}
		}
		case CLEAR_SESSION: {
			return initialState;
		}
		case SET_CAMERA_DEVICE: {
			return { ...state, cameraDevice: action.payload.cameraDevice };
		}
		case SET_ENROLL: {
			return { ...state, enrolled: action.payload };
		}
		default:
			return state;
	}
}
