import {
  JOB_REJECTED,
  JOB_FETCH,
  JOB_FETCH_MORE,
  JOB_FETCH_PENDING,
} from "../../constants";
import { REHYDRATE } from "redux-persist";
import utils from "../../../../utils";
const initialState = {
  data: {
    results: [],
  },
  isFetching: false,
};

export default function jobsReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      return utils.actionRehydrate(state, action.payload, "jobs");
    case JOB_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case JOB_REJECTED:
      return {
        ...state,
        isFetching: false,
      };
    case JOB_FETCH:
      return {
        data: action.payload.data.data,
        isFetching: false,
      };
    case JOB_FETCH_MORE:
      return {
        ...state,
        isFetching: false,
        data: {
          ...action.payload.data.data,
          results: utils.mergeObjet(
            action.payload.data.data.results,
            state.data.results,
            "id_job"
          ),
        },
      };

    default:
      return state;
  }
}
