import { SET_CAMERA_DEVICE, SET_ENROLL, SET_ID_ENTITY } from "../../constants";

export const setIdEntity =
  ({ id_entity, id_job, id_location, cameraDevice }) =>
  (dispatch) => {
    dispatch({
      type: SET_ID_ENTITY,
      payload: { id_entity, id_job, id_location, cameraDevice },
    });
  };

export const setCameraDevice = (cameraDevice) => (dispatch) => {
  dispatch({ type: SET_CAMERA_DEVICE, payload: cameraDevice });
};

export const setEnrolled = (value) => (dispatch) => {
  dispatch({ type: SET_ENROLL, payload: value });
};
