import React, { Component, useEffect, useState } from "react";
import BaseFormBtn from "../components/common/BaseFormBtn";

import moment from "moment";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withTranslation, useTranslation } from "react-i18next";
import { formatsDates, toCapitalCase } from "../utils/index";
import { Grid, Tooltip } from "@material-ui/core";

import { noveltyPermissionsFetch } from "../services/redux/actions/common/noveltyPermission";
import {
  permissionInsert,
  permissionUpdate,
} from "../services/redux/actions/common/entityPermissions";

import utils from "../utils";
import withFormatter from "../components/containers/FormatWrapper";

import IntelliDatePicker from "../components/common/IntelliDatePicker";

import IntelliTextField from "../components/common/IntelliTextField";
import IntelliSelect from "../components/common/IntelliSelect";
import {
  schedulesInsert,
  personScheduleUpdate,
} from "../services/redux/actions/common/scheduling";
import { getAvailablePlaces } from "../services/redux/actions/common/structure";
import {
  IntelliToastMsg,
  typeToast,
} from "../components/common/IntelliToastMsg";
import { formatMomentHoursSeconds } from "../components/timeline/constants";
import _ from "lodash";
import IntelliSwitch from "../components/common/IntelliSwitch";
import IntelliInfo from "../components/common/IntelliInfo";
import IntelliTimePicker from "../components/common/IntelliTimePicker";
import PlacesComponent from "../components/PlacesComponent";
import AlertIcon from "../components/AlertIcon";
import ModalListUsedPlaces from "../components/common/ModalListUsedPlaces";
import ModalGCalendar from "../components/calendar/ModalGCalendar";

const getLSL = ({ structureTypes, structures, id_structure }) => {
  const filterByParent = (structure, selectedLocation) => {
    const pathArr = structure.path.split(".").map((x) => parseInt(x));

    return pathArr.includes(selectedLocation);
  };

  //Tipo de Estructura Ubicación
  const StTU = structureTypes.find(
    (ST) =>
      !!ST.settings_structure_type.sub_type &&
      ST.settings_structure_type.sub_type === "U"
  );

  //Estructuras Ubicación
  const ItemU = structures.tree
    .filter(
      (tr) =>
        tr.id_structure_type === StTU.id_structure_type &&
        filterByParent(tr, id_structure)
    )
    .map((s) => {
      return {
        value: s.id_structure,
        label: `${s.code} - ${s.structure}`,
      };
    })[0];

  //Tipo de Estructura Ubicación Específica
  const StTUE = structureTypes.find(
    (ST) =>
      !!ST.settings_structure_type.sub_type &&
      ST.settings_structure_type.sub_type === "UE"
  );

  //Estructuras Ubicación Específica
  const ItemUE = structures.tree
    .filter(
      (tr) =>
        tr.id_structure_type === StTUE.id_structure_type &&
        filterByParent(tr, ItemU.value)
    )
    .map((s) => {
      return {
        ...s,
        value: s.id_structure,
        label: `${s.code} - ${s.structure}`,
      };
    })[0];
  return { id_l: ItemU.value, id_sl: ItemUE.value };
};

const SchedulePlaceForm = ({
  componentProps,
  structuresTypes,
  structures,
  schedules,
  auth,
  ...props
}) => {
  const structureTypes = structuresTypes.data.results || [];
  const { t } = useTranslation();
  const { obj } = componentProps;
  const { available_schedules } = obj;
  let itemsSchedules = [];

  itemsSchedules = schedules.data.results
    .filter((s) => s.locations.includes(obj.id_structure))
    .map((s) => {
      const tI = moment(s.time_in, formatMomentHoursSeconds).format(
        auth.data.format_time
      );
      const tO = moment(s.time_out, formatMomentHoursSeconds).format(
        auth.data.format_time
      );
      return {
        time_in: s.time_in,
        time_out: s.time_out,
        value: s.id_schedule,
        label: `${tI} - ${tO}`,
      };
    });

  const [date_in, setdate_in] = useState("");
  const [date_out, setdate_out] = useState("");
  const [id_structure, setid_structure] = useState(null);
  const [id_l, setid_l] = useState(null);
  const [id_sl, setid_sl] = useState(null);
  const [id_schedule, setid_schedule] = useState(
    !itemsSchedules.length ? null : itemsSchedules[0].value
  );
  const [time_in, settime_in] = useState(
    !itemsSchedules.length ? null : itemsSchedules[0].time_in
  );
  const [time_out, settime_out] = useState(
    !itemsSchedules.length ? null : itemsSchedules[0].time_out
  );
  const [place, setPlace] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [used, setUsed] = useState(null);
  const [id_location_scheduling, setid_location_scheduling] = useState(null);
  const [at_created, setat_created] = useState("");
  const [id_person, setid_person] = useState(null);
  const [id_person_scheduling, setid_person_scheduling] = useState(null);
  const [id_user, setid_user] = useState(null);
  const [status, setStatus] = useState(null);
  const [dows, setDows] = useState([]);
  const [defaultDows, setDefaultDows] = useState([]);
  const [overwrite, setOverwrite] = useState(false);
  const [currentSevenDays, setCurrentSevenDays] = useState([]);

  const [DOWItems, setDOWItems] = useState(
    utils.getDOWList().map((item) => ({
      value: item.id,
      label: utils.toCapitalCase(item.value),
    }))
  );

  const [currSubType, setCurrSubType] = useState(null);
  const [UsedModal, setUsedModal] = useState({ obj: null, open: false });

  const [openGModal, setOpenGModal] = useState({ val: null, open: false });

  const isDisabled = !!used || (!!obj && !obj.new);

  const filterByParent = (structure, selectedLocation) => {
    const pathArr = structure.path.split(".").map((x) => parseInt(x));

    return pathArr.includes(selectedLocation);
  };

  const filterUbicationOnlyWithPlaces = (idStructure) => {
    const StTUE = structureTypes.find(
      (ST) =>
        !!ST.settings_structure_type.sub_type &&
        ST.settings_structure_type.sub_type === "UE"
    );

    const ItemsUE = structures.tree.filter(
      (tr) =>
        tr.id_structure_type === StTUE.id_structure_type &&
        !!_.has(tr.settings_structure, "photo_plan") &&
        filterByParent(tr, idStructure)
    );

    const placesActive = ItemsUE.map((s) => {
      let places = structures.tree.filter((item) => item.father === s.path);
      if (
        _.has(s.settings_structure, "only_pr") &&
        s.settings_structure.only_pr === 1
      ) {
        places = places.filter(
          (item) => item.settings_structure_type.sub_type === "PR"
        );
      }
      return places;
    });

    return placesActive.flat().length > 0;
  };

  const getOnlyUEWithAvailablePlaces = (idStructure) => {
    const ItemsUE = structures.tree.find(
      (item) => item.id_structure === idStructure
    );
    let placesActive = structures.tree.filter(
      (item) => item.father === ItemsUE.path
    );

    if (
      _.has(ItemsUE.settings_structure, "only_pr") &&
      ItemsUE.settings_structure.only_pr === 1
    ) {
      placesActive = placesActive.filter(
        (item) => item.settings_structure_type.sub_type === "PR"
      );
    }

    return placesActive.length > 0;
  };

  //Tipo de Estructura Ubicación
  const StTU = structureTypes.find(
    (ST) =>
      !!ST.settings_structure_type.sub_type &&
      ST.settings_structure_type.sub_type === "U"
  );

  //Estructuras Ubicación
  const ItemsU = structures.tree
    .filter(
      (tr) =>
        tr.id_structure_type === StTU.id_structure_type &&
        filterByParent(tr, id_structure) &&
        filterUbicationOnlyWithPlaces(tr.id_structure)
    )
    .map((s) => {
      return {
        value: s.id_structure,
        label: `${s.code} - ${s.structure}`,
      };
    });

  //Tipo de Estructura Ubicación Específica
  const StTUE = structureTypes.find(
    (ST) =>
      !!ST.settings_structure_type.sub_type &&
      ST.settings_structure_type.sub_type === "UE"
  );

  //Estructuras Ubicación Específica
  const ItemsUE = !!id_l
    ? structures.tree
        .filter(
          (tr) =>
            tr.id_structure_type === StTUE.id_structure_type &&
            !!_.has(tr.settings_structure, "photo_plan") &&
            filterByParent(tr, id_l) &&
            getOnlyUEWithAvailablePlaces(tr.id_structure)
        )
        .map((s) => {
          return {
            ...s,
            value: s.id_structure,
            label: `${s.code} - ${s.structure}`,
          };
        })
    : [];

  const validate = (payload) => {
    const { date_in, date_out, id_structure, time_in, time_out } = payload;

    const dI = moment(date_in);
    const dO = moment(date_out);

    const tI = moment(time_in, "HH:mm:ss");
    const tO = moment(time_out, "HH:mm:ss");

    let arrayErrors = [];

    if (!!date_in) {
      if (
        dI.clone().startOf("dates").diff(moment().startOf("dates"), "dates") < 0
      ) {
        IntelliToastMsg({
          type: typeToast.info,
          title: `${t("logicErrors.invalidDateInt")}`,
        });
        arrayErrors.push({ field: "date_in" });
      }
    } else {
      IntelliToastMsg({
        type: typeToast.info,
        title: `${t("PersonScheduling.date_out")}: ${t(`FormErrors.if111`)}`,
      });
      arrayErrors.push({ field: "date_in" });
    }

    if (!!date_out) {
      if (
        dO.clone().startOf("dates").diff(dI.clone().startOf("dates"), "dates") <
        0
      ) {
        IntelliToastMsg({
          type: typeToast.info,
          title: `${t("logicErrors.invalidDateInt")}`,
        });
        arrayErrors.push({ field: "date_out" });
      }
    } else {
      IntelliToastMsg({
        type: typeToast.info,
        title: `${t("PersonScheduling.date_out")}: ${t(`FormErrors.if111`)}`,
      });
      arrayErrors.push({ field: "date_out" });
    }

    if (!id_structure) {
      IntelliToastMsg({
        type: typeToast.info,
        title: `${t("PersonScheduling.id_structure")}: ${t(
          `FormErrors.if111`
        )}`,
      });
      arrayErrors.push({ field: "id_structure" });
    }

    if (!!date_in && !!date_out) {
      if (dO.diff(dI, "days") > 30) {
        IntelliToastMsg({
          type: typeToast.info,
          title: `${t("PersonScheduling.id_schedule")}: ${t(
            `FormErrors.sameMonth`
          )}`,
        });
        arrayErrors.push({ field: "not_same_month" });
      }
    }

    if (!time_in) {
      IntelliToastMsg({
        type: typeToast.info,
        title: `${t("PersonScheduling.time_in")}: ${t(`FormErrors.if111`)}`,
      });
      arrayErrors.push({ field: "time_in" });
    }

    if (!!time_out) {
      if (
        tO.clone().startOf("dates").diff(tI.clone().startOf("dates"), "dates") <
        0
      ) {
        IntelliToastMsg({
          type: typeToast.info,
          title: `${t("logicErrors.invalidTimeInt")}`,
        });
        arrayErrors.push({ field: "time_out" });
      }
    } else {
      IntelliToastMsg({
        type: typeToast.info,
        title: `${t("PersonScheduling.time_out")}: ${t(`FormErrors.if111`)}`,
      });
      arrayErrors.push({ field: "time_out" });
    }

    if (!!arrayErrors.length) {
      return false;
    } else return true;
  };

  const onSave = () => {
    if (
      validate({
        date_in,
        date_out,
        id_structure,
        time_in,
        time_out,
      })
    ) {
      props.schedulesInsert(
        {
          date_in,
          date_out,
          id_structure,
          id_person,
          id_place: selectedPlace.id_structure,
          time_in,
          time_out,
          dows,
          overwrite: !!overwrite ? 1 : 0,
        },
        () => {
          if (selectedPlace.sub_type === "PR") {
            const DateIn = moment(`${date_in} ${time_in}`)
              .toISOString()
              .replace(/[&\/\\#, +()$~%-.'":*?<>{}]/g, "");
            const DateOut = moment(`${date_out} ${time_out}`)
              .toISOString()
              .replace(/[&\/\\#, +()$~%-.'":*?<>{}]/g, "");

            const obj = {
              text: `Reunión | ${utils.capitalize(
                ItemsUE.find((ue) => ue.value === id_sl).structure
              )}`,
              dates: `${DateIn}/${DateOut}`,
              location: "4.6834938,-74.0457797",
              details: `Puesto: ${selectedPlace.structure}`,
            };

            setTimeout(() => {
              utils.openEventGoogleCalendar(obj);
            }, 1000);
          }

          props.onCallBack();
          componentProps.setItemCRUD(selectedPlace);
        }
      );
    }
  };

  const onUpdate = () => {
    if (
      validate({
        date_in,
        date_out,
        id_structure,
        time_in,
        time_out,
      })
    ) {
      const payload = {
        date_in,
        date_out,
        id_structure,
        id_person,
        id_place: selectedPlace.id_structure,
        time_in,
        time_out,
        dows,
        overwrite: !!overwrite ? 1 : 0,
      };

      // this.state;
      // // payload.id_person = id_person;
      // delete payload.divKey;
      // delete payload.primaryKey;
      // delete payload.person;
      // delete payload.schedule;
      // delete payload.structure;

      props.personScheduleUpdate(payload, payload.id_person_scheduling, () => {
        props.onCallBack();
        props.componentProps.setItemCRUD(payload);
      });
    }
  };

  const setDowsSwitches = (date_in, date_out) => {
    const di_m = moment(date_in);
    const do_m = moment(date_out);

    let start = di_m.days();
    let end = do_m.days();

    let i = 0;
    let _dows = [];

    if (do_m.diff(di_m, "days") < 7) {
      if (start > end) {
        end = end + 7;
      }
      while (start + i <= end) {
        if (start + i > 6) {
          _dows.push(di_m.days() + i - 7);
        } else {
          _dows.push(di_m.days() + i);
        }
        i++;
      }
    } else {
      _dows = [0, 1, 2, 3, 4, 5, 6];
    }
    return _dows;
  };

  const mergeInitialData = () => {
    // let data = utils.MergeRecursive(this.state, obj);
    let data = obj;

    data.dows = setDowsSwitches(obj.date_in, obj.date_out);
    setat_created(data.at_created);
    setdate_in(data.date_in);
    setdate_out(data.date_out);
    setDows(data.dows);
    setid_location_scheduling(data.id_location_scheduling);
    setid_person(data.id_person);
    setid_person_scheduling(data.id_person_scheduling);
    setid_structure(data.id_structure);
    setid_l(
      getLSL({ structureTypes, structures, id_structure: data.id_structure })
        .id_l
    );
    setid_sl(
      getLSL({ structureTypes, structures, id_structure: data.id_structure })
        .id_sl
    );
    setid_user(data.id_user);
    setStatus(data.status);
    setUsed(data.used);
    return data;
  };

  const getDefaultDows = () => {
    const from = moment(moment(date_in).format("YYYY-MM-DD"), "YYYY-MM-DD");
    const to = moment(moment(date_out).format("YYYY-MM-DD"), "YYYY-MM-DD");

    const nbDays = to.diff(from, "days") + 1;
    const fullDays = [...Array(nbDays).keys()].map((i) =>
      from.clone().add(i, "d")
    );

    setDefaultDows(fullDays.map((item) => item.day()));
  };

  useEffect(() => {
    if (!obj) {
      let datein = moment().format("YYYY-MM-DD");
      let dateout = moment().format("YYYY-MM-DD");
      let idperson = auth.data.Person.id_person;

      let timein = moment().startOf("day").add(7, "hour").format("HH:mm:ss");
      let timeout = moment().startOf("day").add(12, "hour").format("HH:mm:ss");

      setdate_in(datein);
      setdate_out(dateout);
      setid_person(idperson);
      settime_in(timein);
      settime_out(time_out);
    } else {
      mergeInitialData();
    }
  }, []);

  const getAvailablePlaces = async (id_structure, settings_structure) => {
    if (settings_structure.length == 0) return;
    let plan = null;
    if (!!settings_structure.photo_plan) {
      plan = settings_structure.photo_plan;
    }
    if (!plan) return;

    const data = {
      dates: `${date_in},${date_out}`,
      dows,
      times: `${time_in},${time_out}`,
      id_structure,
    };

    const resp = await props.getAvailablePlaces(data);
    if (!resp) return;

    const available = resp.data.data.results;
    if (!!available.length) {
      const stPlace = {
        available,
        plane: "https://api.keralty.myintelli.net/file/" + plan,
        settings_structure,
      };
      setPlace(stPlace);

      if (selectedPlace) {
        const ItemUpdated = available.find(
          (item) => item.id_structure === selectedPlace.id_structure
        );
        if (selectedPlace.sub_type === "PR" || ItemUpdated.available !== 0) {
          setSelectedPlace(ItemUpdated);
          return;
        }
      }
      setSelectedPlace(null);
    }
  };

  useEffect(() => {
    if (
      !id_sl ||
      !moment(time_in, "HH:mm:ss").isValid() ||
      !moment(time_out, "HH:mm:ss").isValid() ||
      !moment(date_in).isValid() ||
      !moment(date_out).isValid() ||
      !dows.length
    )
      return;
    const timeout = setTimeout(() => {
      const selSTR = ItemsUE.find((ue) => ue.value === id_sl);
      getAvailablePlaces(id_sl, selSTR.settings_structure);
    }, 300);
    return () => clearTimeout(timeout);
  }, [time_in, time_out, id_sl, date_in, date_out, dows]);

  useEffect(() => {
    if (!!id_schedule) {
      settime_in(itemsSchedules.find((s) => s.value === id_schedule).time_in);
      settime_out(itemsSchedules.find((s) => s.value === id_schedule).time_out);
    }
  }, [id_schedule, currSubType]);

  useEffect(() => {
    if (!!id_l) setid_sl(ItemsUE[0].value);
  }, [id_l]);

  useEffect(() => {
    if (!date_in) return;
    let countSundays = 1;

    if (moment(date_in).day() === 0) {
      countSundays = countSundays + 1;
    }

    const from = moment(moment(date_in).format("YYYY-MM-DD"), "YYYY-MM-DD");
    const to = moment(
      moment(date_in)
        .add(12 + countSundays, "days")
        .format("YYYY-MM-DD"),
      "YYYY-MM-DD"
    );

    const nbDays = to.diff(from, "days") + 1;
    const NextSevenDays = [...Array(nbDays).keys()].map((i) =>
      from.clone().add(i, "d")
    );

    setCurrentSevenDays(NextSevenDays.map((item) => item.format("YYYY-MM-DD")));
  }, [date_in]);

  useEffect(() => {
    if (!date_in || !date_out) return;
    getDefaultDows();
  }, [date_in, date_out]);



  const disabledDays = (date) => {
    return (
      date.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") ||
      date.format("YYYY-MM-DD") >= moment().add(14, "d").format("YYYY-MM-DD")
    );
    
    //if (!currentSevenDays.includes(date.format("YYYY-MM-DD"))) return true;
  };
  const disabledFinalDays = (date) => {
    return (
      date.format("YYYY-MM-DD") < moment(date_in).format("YYYY-MM-DD") ||
      date.format("YYYY-MM-DD") >= moment().add(14, "d").format("YYYY-MM-DD")
    );
  };

  const SomeEmptyInput =
    !date_in || !date_out || !time_in || !time_out || !dows.length;

  return (
    <>
      <div className="formRow">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          wrap="wrap"
          spacing={1}
          style={{ marginBottom: "4px" }}
        >
          {!!currSubType && currSubType !== "PT" ? (
            <Grid item sm={6}>
              <IntelliDatePicker
                value={date_in}
                handleDateChange={(val) => {
                  setdate_in(val.format("YYYY-MM-DD"));
                  setdate_out(val.format("YYYY-MM-DD"));
                  setDows([val.day()]);
                }}
                label={t("PersonScheduling.date")}
                required
                disabled={isDisabled}
                disablePast={!isDisabled}
              />
            </Grid>
          ) : (
            <>
              <Grid item sm={3}>
                <IntelliDatePicker
                  value={date_in}
                  handleDateChange={(val) => {
                    setdate_in(val.format("YYYY-MM-DD"));
                    if (val.format("YYYY-MM-DD") > date_out) {
                      setdate_out(val.format("YYYY-MM-DD"));
                    }
                  }}
                  label={t("PersonScheduling.date_in")}
                  required
                  disabled={isDisabled}
                  disablePast={!isDisabled}
                  shouldDisableDate={disabledDays}
                />
              </Grid>
              <Grid item sm={3}>
                <IntelliDatePicker
                  value={date_out}
                  handleDateChange={(val) => {
                    setdate_out(val.format("YYYY-MM-DD"));
                  }}
                  label={t("PersonScheduling.date_out")}
                  required
                  disabled={isDisabled}
                  disablePast={!isDisabled}
                  shouldDisableDate={disabledFinalDays}
                />
              </Grid>
            </>
          )}
          {/* <Grid item sm={3}>
          <IntelliSelect
            isMulti={false}
            items={itemsLocations}
            selected={itemsLocations.find(
              (s) => s.value === this.state.id_structure
            )}
            onChange={(val) => {
              this.setState({ id_structure: val.value });
            }}
            placeholder={t("PersonScheduling.id_structure")}
            required
            isDisabled={isDisabled}
          />
        </Grid> */}
          {!!currSubType && currSubType !== "PT" ? null : (
            <Grid item sm={6}>
              <IntelliSelect
                isMulti
                items={DOWItems}
                selected={dows.map((d) =>
                  DOWItems.find((dI) => dI.value === d)
                )}
                onChange={(val) => {
                  setDows(val);
                }}
                placeholder={t("PersonScheduling.dows")}
                isDisabled={isDisabled}
                required
              />
            </Grid>
          )}
          <Grid item sm={6}>
            <IntelliSelect
              isMulti={false}
              items={ItemsU}
              selected={ItemsU.find((u) => u.value === id_l)}
              onChange={(val) => {
                if (val.value === id_l) return;
                setid_l(val.value);
                setPlace(null);
                setSelectedPlace(null);
                if (!dows.length) setDows(defaultDows);
              }}
              placeholder={toCapitalCase(StTU.structure_type)}
              required
              isDisabled={isDisabled}
            />
          </Grid>
          <Grid item sm={6}>
            <IntelliSelect
              isMulti={false}
              items={ItemsUE}
              selected={ItemsUE.find((ue) => ue.value === id_sl) || null}
              onChange={(val) => {
                if (val.value === id_sl) return;
                setid_sl(val.value);
                setPlace(null);
                setSelectedPlace(null);
                if (!dows.length) setDows(defaultDows);
              }}
              placeholder={toCapitalCase(StTUE.structure_type)}
              required
              isDisabled={isDisabled}
            />
          </Grid>
          {!!currSubType && currSubType !== "PT" ? (
            <>
              <Grid item sm={3}>
                <IntelliTimePicker
                  value={time_in}
                  handleDateChange={(date) => {
                    if (!!date) {
                      settime_in(date.format("HH:mm:ss"));
                    }
                  }}
                  label={t("PersonScheduling.time_in")}
                  required
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item sm={3}>
                <IntelliTimePicker
                  value={time_out}
                  handleDateChange={(date) => {
                    if (!!date) {
                      settime_out(date.format("HH:mm:ss"));
                    }
                  }}
                  label={t("PersonScheduling.time_out")}
                  required
                  disabled={isDisabled}
                />
              </Grid>
            </>
          ) : (
            <Grid item sm={6}>
              <IntelliSelect
                isMulti={false}
                items={itemsSchedules}
                selected={itemsSchedules.find((s) => s.value === id_schedule)}
                onChange={(val) => {
                  setid_schedule(val.value);
                }}
                placeholder={t("PersonScheduling.id_schedule")}
                required
                isDisabled={isDisabled}
              />
            </Grid>
          )}
          <PlacesComponent
            place={place}
            selectedPlace={selectedPlace}
            setSelected={(val) => {
              if (val.sub_type === "PR") {
                setSelectedPlace(null);
                setCurrSubType(null);
                const DateIn = moment(`${date_in} ${time_in}`)
                  .toISOString()
                  .replace(/[&\/\\#, +()$~%-.'":*?<>{}]/g, "");
                const DateOut = moment(`${date_out} ${time_out}`)
                  .toISOString()
                  .replace(/[&\/\\#, +()$~%-.'":*?<>{}]/g, "");

                const obj = {
                  text: `Reunión | ${utils.capitalize(
                    ItemsUE.find((ue) => ue.value === id_sl).structure
                  )}`,
                  dates: `${DateIn}/${DateOut}`,
                  location: "4.6834938,-74.0457797",
                  details: `Puesto: ${val.structure}`,
                };

                setOpenGModal({ val: val, open: true });

                // utils.openEventGoogleCalendar(obj);
              } else {
                setSelectedPlace(val);
                setCurrSubType(val.sub_type);
              }
              // if (val.sub_type === "PR") {
              //   setdate_out(date_in);
              //   if (dows.length !== 1 || moment(date_in).day() !== dows[0])
              //     setDows([moment(date_in).day()]);
              // }
            }}
          />
        </Grid>
        <BaseFormBtn
          actions={
            <>
              {!componentProps.obj.new && (
                <Grid item>
                  <IntelliInfo
                    name={t("User.status")}
                    value={
                      !!componentProps.obj.used
                        ? t("General.used")
                        : t("General.notUsed")
                    }
                    direction={"row"}
                  />
                </Grid>
              )}
              {!!selectedPlace && selectedPlace.available !== 0 && (
                <Grid item>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <IntelliInfo
                      name={t("PersonScheduling.selectedPlace")}
                      value={selectedPlace.structure}
                    />
                    {selectedPlace.available == 2 && (
                      <div>
                        <AlertIcon
                          tooltipTitle={t(
                            "PersonScheduling.placePartiallyUsed"
                          )}
                          onClick={() =>
                            setUsedModal({ open: true, obj: selectedPlace })
                          }
                        />
                      </div>
                    )}
                  </div>
                </Grid>
              )}
            </>
          }
          viewCancel={!isDisabled}
          viewClose={true}
          viewSave={!isDisabled}
          disableSave={
            !selectedPlace || selectedPlace.available === 0 || SomeEmptyInput
          }
          onCloseModal={() => {
            componentProps.obj == null
              ? props.onCloseModal()
              : props.onCloseModal();
            // : this.props.onClose();
          }}
          onOk={() => {
            !!componentProps.obj && componentProps.obj.new
              ? onSave()
              : onUpdate();
          }}
        />
      </div>
      {!!UsedModal.open && (
        <ModalListUsedPlaces
          title={t("PersonScheduling.datesUsed")}
          openModal={UsedModal.open}
          obj={UsedModal.obj}
          onClose={() => setUsedModal({ obj: null, open: false })}
        />
      )}
      {openGModal.open && (
        <ModalGCalendar
          openModal={openGModal}
          onCloseModal={() => setOpenGModal(false)}
          componentProps={{
            onOk: () => {
              const { val } = openGModal;
              const DateIn = moment(`${date_in} ${time_in}`)
                .toISOString()
                .replace(/[&\/\\#, +()$~%-.'":*?<>{}]/g, "");
              const DateOut = moment(`${date_out} ${time_out}`)
                .toISOString()
                .replace(/[&\/\\#, +()$~%-.'":*?<>{}]/g, "");
              const obj = {
                text: `Reunión | ${utils.capitalize(
                  ItemsUE.find((ue) => ue.value === id_sl).structure
                )}`,
                dates: `${DateIn}/${DateOut}`,
                location: "4.6834938,-74.0457797",
                details: `Puesto: ${val.structure}`,
              };

              utils.openEventGoogleCalendar(obj);

              setOpenGModal({ val: null, open: false });
              props.onCallBack();
            },
          }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  noveltyPermissionsFetch,
  permissionUpdate,
  permissionInsert,
  schedulesInsert,
  personScheduleUpdate,
  getAvailablePlaces,
};

const mapStateToProps = ({
  auth,
  actionsForm,
  session,
  structures,
  structuresTypes,
  schedules,
}) => {
  return { auth, actionsForm, session, structures, structuresTypes, schedules };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFormatter(SchedulePlaceForm));
