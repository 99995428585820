import {
  STRUCTURE_TYPES_FETCH,
  STRUCTURE_TYPES_FETCH_MORE,
} from "../../constants";
import _ from "lodash";
import axios from "axios";
import { url } from "../../../../config/index";
import utils from "../../../../utils";
import checkTokenAndClient from "./correctUrlToken";

// export const structureTypeDelete = (id) => (dispatch) => {
//   if (!checkTokenAndClient(dispatch)) return false;
//   const headers = utils.headerAxios();
//   return axios
//     .delete(`${url}/structures_types/${id}`, { headers })
//     .then(() => {
//       return true;
//     })
//     .catch((error) => {
//       utils.errorHandling(dispatch, error, "StructureType");
//       return false;
//     });
// };

// export const structureTypeUpdate = (id, data, formId) => (dispatch) => {
//   if (!checkTokenAndClient(dispatch)) return false;
//   let headers = utils.headerAxios();
//   dispatch({ type: PROCESSING });
//   const request = axios
//     .put(`${url}/structures_types/${id}`, data, { headers })
//     .then((res) => {
//       dispatch({ type: PROCESS_COMPLETE });
//       dispatch({
//         type: ACTIONS_FORM_ADD,
//         payload: { id: formId },
//       });
//       utils.updateSuccess();
//     })
//     .catch((error) => {
//       dispatch({ type: PROCESS_COMPLETE });
//       utils.errorHandling(dispatch, error, "StructureType");
//     });
// };
// export const structureTypeInsert = (data, formId) => (dispatch) => {
//   if (!checkTokenAndClient(dispatch)) return false;
//   let headers = utils.headerAxios();
//   dispatch({ type: PROCESSING });
//   const request = axios
//     .post(`${url}/structures_types`, data, { headers })
//     .then((res) => {
//       dispatch({ type: PROCESS_COMPLETE });
//       dispatch({
//         type: ACTIONS_FORM_ADD,
//         payload: { id: formId, result: res.data.data.result },
//       });
//       utils.insertSuccess();
//     })
//     .catch((error) => {
//       dispatch({ type: PROCESS_COMPLETE });
//       utils.errorHandling(dispatch, error, "StructureType");
//     });
//   dispatch({
//     type: STRUCTURE_TYPES_INSERT,
//     payload: request,
//   });
// };

export const structureTypesFetch = (Options = {}, callback = null) => {
  return async function (dispatch) {
    if (!checkTokenAndClient(dispatch)) return false;
    let headers = utils.headerAxios();
    Options = _.extend(
      {
        limit: 20,
        offSet: 0,
        search: "",
        where: "",
        orderBy: "structure_type ASC",
        allItem: false,
        dispatch: true,
      },
      Options
    );
    if (
      !Options.allItem &&
      Options.search.indexOf("where[ma_structures_types.status]=0") == -1
    ) {
      Options.search += "&where[ma_structures_types.status]=1";
    }

    let search = "";
    if (Options.search !== "") {
      search = `&${Options.search}`;
    }

    const type =
      Options.offSet == 0 ? STRUCTURE_TYPES_FETCH : STRUCTURE_TYPES_FETCH_MORE;
    const request = axios.get(
      `${url}/structures_types?limit=${Options.limit}&offset=${Options.offSet}&orderby=${Options.orderBy}` +
        search,
      { headers }
    );
    request.catch((err) => utils.errorHandling(dispatch, err));
    request.then((res) => {
      if (callback != null) {
        callback(res.data.data.results);
      }
      dispatch({
        type,
        payload: res,
      });
    });
  };
};
