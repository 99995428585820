import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import React, { Suspense, useEffect, useState } from "react";
import BasePage from "./components/BasePage";
import Preloader from "./components/common/Preloader";
import { logOut } from "./services/redux/actions/common/auth";
import { uid } from "uid";
import { useIdleTimer } from "react-idle-timer";
import RecoveryPage from "./pages/RecoveryPage";
import SchedulingPage from "./pages/SchedulingPage";

const DashboardPage = React.lazy(() => import("./pages/DashboardPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));

const Routes = (props) => {
	const { logOut } = props;
	const [Id, setId] = useState(uid());

	const handleOnIdle = (event) => {
		let Sessions = localStorage.getItem("sessions");
		const sessionarray = Sessions == null ? [] : Sessions.split(",");
		const arraySession = sessionarray.filter((i) => i !== Id);
		if (arraySession.length < 1) {
			logOut();
		} else {
			localStorage.setItem("sessions", arraySession.join());
		}
	};

	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: 1000 * 60 * 10,
		onIdle: handleOnIdle,
	});

	useEffect(() => {
		let Sessions = localStorage.getItem("sessions");
		if (!Sessions) {
			localStorage.setItem("sessions", Id);
		} else {
			localStorage.setItem("sessions", `${Sessions},${Id}`);
		}
	}, []);

	window.addEventListener("beforeunload", function (e) {
		let Sessions = localStorage.getItem("sessions");
		const sessionarray = Sessions == null ? [] : Sessions.split(",");
		const arraySession = sessionarray.filter((i) => i !== Id);
		localStorage.setItem("sessions", arraySession.join());
	});

	return (
		<BrowserRouter>
			<Suspense fallback={<Preloader />}>
				<Switch>
					<Route exact path="/login" component={LoginPage} />
					<Route exact path="/recovery_password" component={RecoveryPage} />
					<Route exact path="/" component={SchedulingPage} />
					{/* <Route exact path="/" component={DashboardPage} /> */}
					<Route path="*">
						<BasePage
							page={
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<h3>NOT FOUND</h3>
								</div>
							}
						/>
					</Route>
				</Switch>
			</Suspense>
		</BrowserRouter>
	);
};

const mapStateToProps = ({ auth }) => {
	return { auth };
};

export default connect(mapStateToProps, { logOut })(Routes);
