import React, {
  Children,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

//3rd Party Components
import { Grid, Tooltip, LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import _ from "lodash";
import { toCapitalCase } from "../utils";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const PlaceStatus = ["no-avaliable", "avaliable", "partial-avaliable"];

const Place = ({ data, left, top, selected = false, setSelected }) => {
  const { t } = useTranslation();
  let tooltip = "";
  if (data.structure) tooltip += `${data.structure}`;
  if (Boolean(data.observation)) {
    tooltip += `\n${t("PersonScheduling.observations")}: ${toCapitalCase(
      data.observation
    )}`;
  }
  if (data.sub_type === "PR") {
    tooltip += `\n${t("PersonScheduling.calendarSchedule")}`;
  }

  const clickItem = (item) => {
    if (item.available === 0 && item.sub_type !== "PR") {
      toast.info(t("PersonScheduling.placeNoAvailable"));
      return;
    }
    setSelected(item);
  };

  return (
    <div
      className={`itemAssingPlaceDrag ${PlaceStatus[data.available]} ${
        selected ? "selected" : ""
      }`}
      style={{ left, top }}
      onClick={() => clickItem(data)}
    >
      <Tooltip
        className="iconPointer"
        title={tooltip}
        placement="bottom"
        classes={{ tooltip: "tooltipCard_tooltip" }}
      >
        <div className="itemPlan">
          <div className="itemPlan_icon">
            <i
              className={`fas fa-${
                data.sub_type === "PT" ? "chair-office" : "users-class"
              } fa-lg`}
            />
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

const PlacesComponent = ({
  place,
  selectedPlace,
  setSelected,
  loading,
  setDisabledDateInOut,
}) => {
  const { t } = useTranslation();
  const [planWidth, setPlanWidth] = useState();
  const [planHeight, setPlanHeight] = useState();
  const [width, height] = useWindowSize();
  const [actualPlane, setActualPlane] = useState("");
  const [viewPlaces, setViewPlaces] = useState(false);
  const [availablePlaces, setAvailablePlaces] = useState([]);

  const Ks = { left: planWidth / 970, top: planHeight / 460 };
  const ref = useRef();

  useEffect(() => {
    setPlanWidth(ref.current ? ref.current.clientWidth : 0);
    setPlanHeight(ref.current ? ref.current.clientHeight : 0);
  }, [ref.current, height, width]);

  useEffect(() => {
    if (!!place) {
      let availables = place.available;
      // if (
      //   _.has(place.settings_structure, "only_pr") &&
      //   place.settings_structure.only_pr === 1
      // ) {
      //   availables = availables.filter((item) => item.sub_type === "PR");
      // }
      availables = availables.filter((item) => item.sub_type === "PT");
      setAvailablePlaces(availables);
    }
    if (!!place && place.plane !== actualPlane) {
      setActualPlane(place.plane);
      setViewPlaces(false);
    }
  }, [place]);

  if (!place) return null;

  return (
    <Grid sm={12} className="available-places">
      <>
        <Grid className="plan-places-legend" style={{ width: "80%" }}>
          <div className="legend-item">
            <div className="legend-item-sub">
              <i className="fas fa-chair-office fa-lg" />
            </div>
            <div className="legend-item-text">
              {t("PersonScheduling.legend.working_place")}
            </div>
          </div>
          <div className="legend-item">
            <div className="legend-item-sub">
              <i className="fas fa-users-class fa-lg" />
            </div>
            <div className="legend-item-text">
              {t("PersonScheduling.legend.meet_place")}
            </div>
          </div>
          <div className="legend-item">
            <div className="legend-item-sub coworking-area" />
            <div className="legend-item-text">
              {t("PersonScheduling.legend.coworking_area")}
            </div>
          </div>
          <div className="legend-item">
            <div className="legend-item-sub meet-area" />
            <div className="legend-item-text">
              {t("PersonScheduling.legend.meet_area")}
            </div>
          </div>
          <div className="legend-item">
            <div className="legend-item-sub place-selected" />
            <div className="legend-item-text">
              {t("PersonScheduling.selectedPlace")}
            </div>
          </div>
          <div className="legend-item">
            <div className="legend-item-sub place-available" />
            <div className="legend-item-text">
              {t("PersonScheduling.legend.place_available")}
            </div>
          </div>
          <div className="legend-item">
            <div className="legend-item-sub place-partial-available" />
            <div className="legend-item-text">
              {t("PersonScheduling.placePartiallyUsed")}
            </div>
          </div>
          <div className="legend-item">
            <div className="legend-item-sub place-no-available" />
            <div className="legend-item-text">
              {t("PersonScheduling.legend.place_no_available")}
            </div>
          </div>
        </Grid>
        <div className="plan">
          <img
            src={place.plane}
            ref={ref}
            alt="plan"
            width={"100%"}
            onLoad={() => {
              setPlanWidth(ref.current ? ref.current.clientWidth : 0);
              setPlanHeight(ref.current ? ref.current.clientHeight : 0);
              setViewPlaces(true);
            }}
          />
          {Children.toArray(
            availablePlaces.map(
              (place) =>
                !!place.place &&
                !!viewPlaces && (
                  <Place
                    data={place}
                    left={place.place.left * Ks.left}
                    top={place.place.top * Ks.top}
                    selected={
                      !!selectedPlace
                        ? selectedPlace.id_structure === place.id_structure
                        : false
                    }
                    setSelected={setSelected}
                    activeDateInOut={() => setDisabledDateInOut(true)}
                  />
                )
            )
          )}
        </div>
      </>
    </Grid>
  );
};

export default PlacesComponent;
