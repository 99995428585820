import React from "react";

const IntelliImage = ({ photo, onCallback = () => {}, classes = "" }) => {
  let classNames = "image";

  if (!!classes) {
    classNames += ` ${classes}`;
  }

  return (
    <div className={classNames}>
      <img
        src={photo}
        alt="payload_img"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onCallback();
        }}
      />
    </div>
  );
};

export default IntelliImage;
