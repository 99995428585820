import React from "react";

import { useTranslation } from "react-i18next";

import IntelliModal from "../common/IntelliModal";

import roomPhoto from "../../assets/help_photos/salas.png";
import BaseFormBtn from "../common/BaseFormBtn";

const GCalendarForm = ({ componentProps, ...props }) => {
  const { t } = useTranslation();
  return (
    <div className="formRow">
      <p style={{ textAlign: "center", fontSize: "16px", fontWeight: "500" }}>
        {t("General.rememberText")}
      </p>
      <img src={roomPhoto} alt="GCalendar" />
      <BaseFormBtn
        viewClose
        viewSave
        onCloseModal={props.onCloseModal}
        textok={t("General.goToCalendar")}
        onOk={() => {
          componentProps.onOk();
        }}
      />
    </div>
  );
};

const ModalGCalendar = (props) => {
  const { openModal, onCloseModal, componentProps } = props;

  const { t } = useTranslation();

  return (
    <IntelliModal
      openModal={openModal}
      onCloseModal={onCloseModal}
      title={t("General.attention")}
      modalContent={GCalendarForm}
      componentProps={componentProps}
      modalSize={7}
    />
  );
};

export default ModalGCalendar;
