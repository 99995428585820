import { Tooltip, MenuItem, Menu, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logOut } from "../services/redux/actions/common/auth";
import IntelliModal from "./common/IntelliModal";
import ProfileForm from "../forms/ProfileForm";
import IntelliImage from "./common/IntelliImage";
import utils from "../utils";
import AboutModal from "./AboutModal";

const ItemMenu = ({ handleClose, text, onCallback }) => {
	return (
		<MenuItem
			onClick={(e) => {
				e.preventDefault();
				onCallback();
				handleClose();
			}}>
			<span>{text}</span>
		</MenuItem>
	);
};

const FooterMenu = ({}) => {
	const phone = utils.getPhoneFromLang(localStorage.intl);
	const { t } = useTranslation();
	return (
		<div className="footer_menu">
			<div className="section">
				<span>{`${t("General.phone")}: `}</span>
				<a href={`tel: ${phone.phone}`}>{phone.text}</a>
			</div>
			<div className="section">
				<span> Web: </span>
				<a href="https://www.intelli-next.com/" target="_blank">
					www.intelli-next.com
				</a>
			</div>
		</div>
	);
};

const MenuSupport = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
		marginTop: "32px",
		width: "200px",
	},
})(({ handleClose, handleClick, anchorEl, openAboutModal }) => {
	const { t } = useTranslation();
	return (
		<Menu
			className="support_menu"
			anchorEl={anchorEl}
			anchorReference={"anchorEl"}
			getContentAnchorEl={null}
			open={Boolean(anchorEl)}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 110,
				horizontal: "right",
			}}
			keepMounted
			transformOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}>
			<ItemMenu
				handleClose={handleClose}
				text={t("About.about")}
				onCallback={openAboutModal}
			/>
			<FooterMenu />
		</Menu>
	);
});

const OptionsHeader = (props) => {
	const { t } = useTranslation();

	const [modalEntityTypeState, setModalEntityTypeState] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [aboutModal, setAboutModal] = useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const openAboutModal = () => {
		setAboutModal(true);
	};

	return (
		<>
			<div className="header-options">
				<Tooltip
					title={t("General.user")}
					classes={{ tooltip: "tooltipHeader" }}>
					<div className="user-info">
						<span>{`${props.auth.data.Person.first_name} ${props.auth.data.Person.last_name}`}</span>
						<span>{props.auth.data.ip}</span>
						{/* <span>{props.auth.data.user.ip}</span> */}
					</div>
				</Tooltip>

				<Tooltip
					title={t("Header.support")}
					classes={{ tooltip: "tooltipHeader" }}>
					<div className="user-settings">
						<i className="fas fa-user-headset" onClick={handleClick}></i>
					</div>
				</Tooltip>

				<Tooltip
					title={t("Header.exit")}
					classes={{ tooltip: "tooltipHeader" }}>
					<div className="exit">
						<i
							className="fal fa-sign-out-alt"
							onClick={() => {
								props.logOut();
							}}></i>
					</div>
				</Tooltip>
			</div>

			{!!anchorEl && (
				<MenuSupport
					handleClose={handleClose}
					anchorEl={anchorEl}
					openAboutModal={openAboutModal}
				/>
			)}
			{!!aboutModal && (
				<AboutModal
					openModal={aboutModal}
					onCloseModal={() => setAboutModal(false)}
				/>
			)}
		</>
	);
};

const LogoHeader = ({ actualPath }) => {
	const [selected, setSelected] = useState(false);
	const { t } = useTranslation();
	return (
		<Tooltip
			title={t("General.principal")}
			classes={{ tooltip: "tooltipHeader" }}>
			<div className="brand">
				<Link to="/">
					<img
						className={selected ? "header_logo" : "header_logo_selected"}
						alt={`myIntelli ${
							selected || actualPath === "/" ? "__selected" : ""
						}`}
						onMouseEnter={() => setSelected(true)}
						onMouseLeave={() => setSelected(false)}
					/>
				</Link>
			</div>
		</Tooltip>
	);
};

const Header = (props) => {
	const actualPath = window.location.pathname;
	const { t } = useTranslation();
	return (
		<header className="intelli-header">
			<LogoHeader actualPath={actualPath} />
			<Tooltip
				title={t("EventTask.access_control")}
				classes={{ tooltip: "tooltipHeader" }}>
				<div className="datetime-header">
					<Link to="/">
						<i className="intelli_access"></i>
					</Link>
				</div>
			</Tooltip>
			<OptionsHeader logOut={props.logOut} auth={props.auth} />
		</header>
	);
};

const mapStateToProps = ({ auth }) => {
	return { auth };
};

export default connect(mapStateToProps, { logOut })(Header);
