import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid } from "@material-ui/core";
import BasePage from "../components/BasePage";
import BasePageComponent from "../components/BasePageComponent";

import IntelliFab from "../components/common/IntelliFab";
import withFormatter from "../components/containers/FormatWrapper";
import { noveltyPermissionsFetch } from "../services/redux/actions/common/noveltyPermission";
import { getTree } from "../services/redux/actions/common/structure";
import { structureTypesFetch } from "../services/redux/actions/common/structureType";
import IntelliSelect from "../components/common/IntelliSelect";
import {
  schedulingsFetch,
  schedulesLocationFetch,
  personScheduleDelete,
} from "../services/redux/actions/common/scheduling";
import { getAvailablePlacesCalendar } from "../services/redux/actions/common/structure";
import { locationSchedulingFetch } from "../services/redux/actions/common/locationSchedulingAvailables";
import ScheduleForm from "../forms/ScheduleForm";
import { logOut } from "../services/redux/actions/common/auth";
import moment from "moment";
import _ from "lodash";
import IntelliMonthPicker from "../components/common/IntelliMonthPicker";
import MonthComponent from "../components/calendar/month/MonthComponent";
import ConfirmationModal from "../components/common/ConfirmationModal";
import { formatMomentDate } from "../components/timeline/constants";
import WizarModal from "../components/common/WizarModal";
import ModalScheduleList from "../components/common/ModalScheduleList";
import {
  IntelliToastMsg,
  typeToast,
} from "../components/common/IntelliToastMsg";
import SchedulePlaceForm from "../forms/SchedulePlaceForm";
import RegisteredPlaceForm from "../forms/RegisteredPlaceForm";

const ToolbarSchedulesPage = connect(
  ({ auth, structures }) => ({ auth, structures }),
  {
    noveltyPermissionsFetch,
  }
)(
  ({
    noveltyPermissions,
    handlePermission,
    noveltyPermissionsFetch,
    ...props
  }) => {
    const { t } = useTranslation();
    const locationItems = props.structures.tree
      .filter(
        (s) =>
          s.id_structure_type === 3 &&
          _.uniq(
            props.schedules.data.results.map((s) => s.locations).flat()
          ).includes(s.id_structure)
      )
      .map((s) => {
        return { value: s.id_structure, label: `${s.code} - ${s.structure}` };
      });

    return (
      <>
        <Grid item sm={4}>
          <IntelliSelect
            isMulti={false}
            items={locationItems}
            selected={locationItems.find(
              (l) => props.locationState.location === l.value
            )}
            onChange={(val) => {
              props.locationState.setLocation(val.value);
            }}
            placeholder={t("General.location")}
          />
        </Grid>
        <Grid item sm={2}>
          <IntelliMonthPicker
            label={t("General.month")}
            value={props.dateState.date}
            handleDateChange={(date) => {
              props.dateState.setDate(date.clone().startOf("month"));
            }}
          />
        </Grid>
        {/* <Grid sm={6}>
          <p
            style={{
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            {t("General.toolbarReminder")}
          </p>
        </Grid> */}
      </>
    );
  }
);

const setLengthCalendar = ({ dates }) => {
  const {
    // dateIn,
    //  dateOut,
    selectedDate,
  } = dates;

  const dateIn = selectedDate.clone().days(1);
  const dateOut = selectedDate.clone().endOf("month").days(7);

  let i = 0;

  let dI = dateIn.clone();
  let dO = dateOut.clone();

  let range_i = dO.diff(dI, "days") + 1;

  let days = [];
  while (i < range_i) {
    const di = dI.clone().add(i, "days").format(formatMomentDate);

    let item = { date: di, items: [], ownMonth: true };

    if (
      di.split("-")[1] !==
      selectedDate.clone().format(formatMomentDate).split("-")[1]
    ) {
      item.ownMonth = false;
    }
    days.push(item);
    i++;
  }
  return days;
};

const setAvailableSchedulings = (schedulings, days) => {
  const ds = JSON.parse(JSON.stringify(days));

  schedulings.forEach((s) => {
    days.forEach((d, idx) => {
      if (s.day === d.date) {
        ds[idx].data = s;
      }
    });
  });

  return ds;
};

const setSchedulings = (schedulingsPerson, days) => {
  const ds = JSON.parse(JSON.stringify(days));

  schedulingsPerson.forEach((s) => {
    days.forEach((d, idx) => {
      if (s.day === d.date) {
        ds[idx].items = s;
      }

      if (s.date_out >= d.date && s.date_in <= d.date) {
        ds[idx].items.push(s);
      }
    });
  });

  return ds;
};

const setCalendar = ({ data, dates, setStateCalendar, location }) => {
  if (location === 455) {
    let days = setLengthCalendar({ dates });
    const { dataAvailable, personSchedules, availablePlaces } = data;

    if (!availablePlaces || !availablePlaces.data) return;

    const da = setAvailableSchedulings(dataAvailable, days);
    let ds = setSchedulings(personSchedules, da);

    const DA = availablePlaces.data.data.results;
    ds = ds.map((d) => {
      return {
        ...d,
        data: {
          ...d.data,
          employees_availables: !!DA.days[d.date]
            ? DA.days[d.date].available
            : DA.total_places,
          employees_total: DA.total_places,
        },
      };
    });

    setStateCalendar(ds);
  } else {
    let days = setLengthCalendar({ dates });
    const { dataAvailable, personSchedules } = data;

    const da = setAvailableSchedulings(dataAvailable, days);
    let ds = setSchedulings(personSchedules, da);
    ds = ds.map((d) => ({
      ...d,
      data: d.data || { employees_availables: 0, employees_total: 0 },
    }));
    setStateCalendar(ds);
  }
};

const SchedulingComponent = connect(
  ({ auth, structures, schedules }) => ({ auth, structures, schedules }),
  {
    getTree,
    structureTypesFetch,
    locationSchedulingFetch,
    getAvailablePlacesCalendar,
    schedulingsFetch,
    schedulesLocationFetch,
    personScheduleDelete,
    logOut,
  }
)(
  withFormatter(
    ({
      auth,
      formatDateTime,
      entitiesPermissionsFetch,
      permissionDelete,
      ...props
    }) => {
      const { t } = useTranslation();
      const [personSchedules, setPersonSchedules] = useState([]);
      const [openModal, setOpenModal] = useState({
        open: false,
        item: null,
        new: true,
      });
      const [itemCRUD, setItemCRUD] = useState(null);
      const [finishGetData, setFinishGetData] = useState(false);
      const [dataAvailable, setDataAvailable] = useState([]);
      const [availablePlaces, setAvailablePlaces] = useState(null);

      //estado de toolbar

      const [date, setDate] = useState(moment().startOf("month"));
      const [dateOut, setDateOut] = useState(null);
      const [location, setLocation] = useState(null);

      //modal de confirmación
      const [confirmModal, setConfirmModal] = useState({
        open: false,
        callback: () => {},
      });

      const [modalScheduleList, setModalScheduleList] = useState({
        open: false,
        date: null,
        location: null,
        items: null,
      });

      const [wizardModal, setWizardModal] = useState({
        open: false,
        callback: () => {},
        start: null,
        end: null,
        selecteds: null,
      });

      const [vPlace, setvPlace] = useState({ obj: null, open: false });

      //estado de mouse up - mouse down
      const [mouseUp, setMouseUp] = useState(null);
      const [mouseDown, setMouseDown] = useState(null);

      const [dateRange, setDateRange] = useState({
        start: null,
        end: null,
        selecteds: null,
        available_schedules: null,
      });

      //estado de month
      const [stateCalendar, setStateCalendar] = useState([]);

      const handleMouseUpDown = () => {
        let md_date = JSON.parse(JSON.stringify(mouseDown.date));
        let mu_date = JSON.parse(JSON.stringify(mouseUp.date));

        if (moment(md_date, "YYYY-MM-DD") < date.clone().startOf("month")) {
          md_date = date.clone().startOf("month").format("YYYY-MM-DD");
        }

        // if (moment(mu_date, "YYYY-MM-DD") > date.clone().endOf("month")) {
        // 	mu_date = date.clone().endOf("month").format("YYYY-MM-DD");
        // }

        if (mu_date < md_date) {
          const aux = mu_date;
          mu_date = md_date;
          md_date = aux;
        }

        const greaterThanToday =
          md_date >= moment().format("YYYY-MM-DD") && mu_date >= md_date;

        const greaterThan31Days =
          moment(mu_date, "YYYY-MM-DD").diff(
            moment(md_date, "YYYY-MM-DD"),
            "days"
          ) +
            1 >
          31;

        const _selecteds = stateCalendar.filter(
          (d) => d.date >= md_date && d.date <= mu_date
        );

        if (!_selecteds.filter((s) => s.data).length) {
          IntelliToastMsg({
            type: typeToast.warning,
            title: t("General.warning"),
            text: t("FormErrors.noSchedulings"),
          });
          setMouseDown(null);
          setMouseUp(null);
          return;
        }

        const _available_schedules =
          (!!_selecteds.find((s) => s.data) &&
            _.uniq(
              _selecteds
                .filter((s) => !!s.data)
                .map((s) => s.data.schedules)
                .flat()
            )) ||
          [];

        setDateRange({
          start: md_date,
          end: mu_date,
          selecteds: _selecteds
            .filter((s) => !!s.items.length)
            .map((s) => s.items)
            .flat(),
          available_schedules: _available_schedules,
        });

        setStateCalendar((s) => {
          let i = 0;
          const _s = JSON.parse(JSON.stringify(s));

          while (i < s.length) {
            if (s[i].date >= md_date && s[i].date <= mu_date) {
              _s[i].selected = !!(greaterThanToday && !greaterThan31Days);
            }
            i++;
          }
          return _s;
        });

        if (
          !!_selecteds
            .filter((s) => !!s.items.length)
            .map((s) => s.items)
            .flat().length
        ) {
          setWizardModal((s) => {
            return {
              ...s,
              open: true,
              start: md_date,
              end: mu_date,
              selecteds: _selecteds
                .filter((s) => !!s.items.length)
                .map((s) => s.items)
                .flat(),
            };
          });
        } else if (greaterThanToday && !greaterThan31Days) {
          setOpenModal((s) => {
            return {
              ...s,
              new: true,
              open: true,
              item: {
                date_in: md_date || moment().add(1, "day").format("YYYY-MM-DD"),
                date_out:
                  mu_date || moment().add(1, "day").format("YYYY-MM-DD"),
                id_structure: location,
                id_schedule: null,
                used: null,
                at_created: "",
                id_location_scheduling: null,
                id_person: null,
                id_person_scheduling: null,
                id_user: null,
                status: null,
                new: true,
                available_schedules: _available_schedules,
              },
            };
          });
        }

        setMouseDown(null);
        setMouseUp(null);
      };

      const getDataPersonSchedules = () => {
        props.schedulesLocationFetch(
          {
            search: "",
            date: date
              ? `${date
                  .clone()
                  .startOf("month")
                  .days(1)
                  .format("YYYY-MM-DD")},${date
                  .clone()
                  .endOf("month")
                  .days(7)
                  .format("YYYY-MM-DD")}`
              : "",
            location: location,
          },
          (res) => {
            setPersonSchedules(res);
          }
        );
        props.getAvailablePlacesCalendar(
          {
            dates: `${date
              .clone()
              .startOf("month")
              .days(1)
              .format("YYYY-MM-DD")},${date
              .clone()
              .endOf("month")
              .days(7)
              .format("YYYY-MM-DD")}`,
            id_structure: location,
          },
          (res) => {
            setAvailablePlaces(res);
          }
        );
        props.locationSchedulingFetch(
          {
            id_structure: location,
            date: date
              ? `${date
                  .clone()
                  .startOf("month")
                  .days(1)
                  .format("YYYY-MM-DD")},${date
                  .clone()
                  .endOf("month")
                  .days(7)
                  .format("YYYY-MM-DD")}`
              : "",
          },
          (res) => {
            setDataAvailable(res);
          }
        );
      };

      const getData = () => {
        Promise.all([
          props.getTree({ filter: 0, allItem: true }),
          props.structureTypesFetch({ limit: 0, allItem: true }),
          // props.locationSchedulingFetch({
          // 	id_structure: location,
          // 	date: date ? `${date.format("YYYY-MM-DD")},${date.clone().endOf("month").format("YYYY-MM-DD")}`: "" }),
          props.schedulingsFetch({ limit: 0, allItem: true }),
        ])
          .then((values) => {
            setFinishGetData(true);
          })
          .catch(() => {
            setFinishGetData(false);
          });
      };

      const massiveDelete = () => {
        setConfirmModal((s) => {
          return {
            ...s,
            open: true,
            callback: () => {
              wizardModal.selecteds.forEach((d) => {
                props.personScheduleDelete(d.id_person_scheduling);
                setItemCRUD(d.id_person_scheduling);
              });
              setWizardModal((state) => {
                return {
                  ...state,
                  open: false,
                  selecteds: null,
                  start: null,
                  end: null,
                };
              });
            },
          };
        });
        setWizardModal((s) => {
          return { ...s, open: false };
        });
      };

      const massiveAdd = () => {
        setOpenModal((s) => {
          return {
            ...s,
            new: true,
            open: true,
            item: {
              date_in:
                dateRange.start || moment().add(1, "day").format("YYYY-MM-DD"),
              date_out:
                dateRange.end || moment().add(1, "day").format("YYYY-MM-DD"),
              id_structure: location,
              id_schedule: null,
              used: null,
              at_created: "",
              id_location_scheduling: null,
              id_person: null,
              id_person_scheduling: null,
              id_user: null,
              status: null,
              new: true,
              available_schedules: dateRange.available_schedules,
            },
          };
        });
        setWizardModal((s) => {
          return { ...s, open: false };
        });
      };

      const handlerScheduleList = (date) => {
        setModalScheduleList({
          open: true,
          date: date,
          location: location,
          items: wizardModal.selecteds,
        });
        setWizardModal((s) => {
          return { ...s, open: false };
        });
      };

      useEffect(() => {
        if (!!mouseUp && !!mouseDown) handleMouseUpDown();
      }, [mouseDown, mouseUp]);

      useEffect(() => {
        if (!!confirmModal.open) {
          setWizardModal((s) => ({ ...s, open: false }));
        }
      }, [confirmModal]);

      useEffect(() => {
        setCalendar({
          data: { dataAvailable, personSchedules, availablePlaces },
          dates: { selectedDate: date },
          setStateCalendar,
          location,
        });
      }, [dataAvailable, personSchedules, date, availablePlaces]);

      useEffect(() => {}, [stateCalendar]);

      useEffect(() => {
        if (finishGetData) {
          getDataPersonSchedules();
        }
      }, [finishGetData]);

      useEffect(() => {
        let tO;
        if (location) {
          tO = setTimeout(() => {
            getDataPersonSchedules({ location });
          }, 700);
        }
        return () => clearTimeout(tO);
      }, [location, date, dateOut]);

      useEffect(() => {
        getData();
        document.addEventListener("keydown", (e) => {
          if (e.key === "Escape") {
            setMouseDown(null);
            setMouseUp(null);
            setDateRange({ start: null, end: null, selecteds: null });
            setStateCalendar((state) => {
              const _s = JSON.parse(JSON.stringify(state));
              _s.forEach((d) => {
                d.selected = false;
              });
              return _s;
            });
          }
        });
      }, []);

      useEffect(() => {
        if (props.structures && props.schedules) {
          setLocation(
            !!props.structures.tree.length
              ? props.structures.tree.filter(
                  (s) =>
                    s.id_structure_type === 3 &&
                    _.uniq(
                      props.schedules.data.results
                        .map((s) => s.locations)
                        .flat()
                    ).includes(s.id_structure)
                )[0].id_structure
              : 0
          );
        }
      }, [props.structures, props.schedules]);

      useEffect(() => {
        let timerCRUDITem;
        if (!!itemCRUD) {
          timerCRUDITem = setTimeout(() => {
            getDataPersonSchedules();
            setOpenModal(false);
          }, 700);
        }

        return () => clearTimeout(timerCRUDITem);
      }, [itemCRUD]);

      const showMonth = finishGetData && !!date;

      return (
        <>
          <BasePageComponent
            title={
              !openModal.new ? t("General.info") : t("General.newRegister")
            }
            openModal={openModal.open}
            onCloseModal={() => {
              setOpenModal((s) => {
                return { ...s, open: false };
              });
              setWizardModal((state) => {
                return { ...state, open: false };
              });
              setStateCalendar((state) => {
                const _s = JSON.parse(JSON.stringify(state));
                _s.forEach((d) => {
                  d.selected = false;
                });
                return _s;
              });
            }}
            componentForm={
              !(!!location && location === 455)
                ? SchedulePlaceForm
                : ScheduleForm
            }
            modalSize={10}
            componentProps={{
              obj: !!openModal.item
                ? openModal.item
                : {
                    date_in: moment().format("YYYY-MM-DD"),
                    date_out: moment().format("YYYY-MM-DD"),
                    id_structure: location,
                    id_schedule: null,
                    used: null,
                    at_created: "",
                    id_location_scheduling: null,
                    id_person: null,
                    id_person_scheduling: null,
                    id_user: null,
                    status: null,
                    new: true,
                    available_schedules: [],
                  },
              setItemCRUD,
            }}
            onCallBack={() =>
              // getDataPersonSchedules()
              {}
            }
            fields={
              <ToolbarSchedulesPage
                dateState={{ date, setDate }}
                dateOutState={{ dateOut, setDateOut }}
                locationState={{ location, setLocation }}
                schedules={props.schedules}
              />
            }
            // buttons={
            //   <>
            //     <IntelliFab
            //       title={t("General.fabNew")}
            //       icon="fal fa-plus fa-2x"
            //       color="bg_colorAuxiliarPrimary"
            //       action={() => {
            //         setOpenModal((s) => {
            //           return {
            //             ...s,
            //             new: true,
            //             open: true,
            //             item: {
            //               date_in: moment().add(1, "day").format("YYYY-MM-DD"),
            //               date_out: moment().add(1, "day").format("YYYY-MM-DD"),
            //               id_structure: location,
            //               id_schedule: null,
            //               used: null,
            //               at_created: "",
            //               id_location_scheduling: null,
            //               id_person: null,
            //               id_person_scheduling: null,
            //               id_user: null,
            //               status: null,
            //               new: true,
            //               available_schedules:
            //                 (!!stateCalendar.find(
            //                   (s) =>
            //                     s.date ===
            //                     moment().add(1, "day").format("YYYY-MM-DD")
            //                 ) &&
            //                   !!stateCalendar.find(
            //                     (s) =>
            //                       s.date ===
            //                       moment().add(1, "day").format("YYYY-MM-DD")
            //                   ).data &&
            //                   stateCalendar.find(
            //                     (s) =>
            //                       s.date ===
            //                       moment().add(1, "day").format("YYYY-MM-DD")
            //                   ).data.schedules) ||
            //                 [],
            //             },
            //           };
            //         });
            //       }}
            //     />
            //   </>
            // }
            componentPage={
              <>
                {showMonth && (
                  <MonthComponent
                    dates={{
                      // dateIn: date.clone().days(1),
                      // dateOut: date.clone().endOf("month").days(7),
                      selectedDate: date,
                    }}
                    data={{ dataAvailable, personSchedules }}
                    modalState={{ openModal, setOpenModal }}
                    vPlace={{ get: vPlace, set: setvPlace }}
                    funDelete={(id) => {
                      setConfirmModal((s) => {
                        return {
                          ...s,
                          open: true,
                          callback: () => {
                            props.personScheduleDelete(id);
                            setItemCRUD(id);
                          },
                        };
                      });
                    }}
                    location={location}
                    dateRangeState={{ dateRange, setDateRange }}
                    mouseState={{
                      mouseUp,
                      setMouseUp,
                      mouseDown,
                      setMouseDown,
                    }}
                    stateCalendar={stateCalendar}
                  />
                )}
              </>
            }
            classComponent="schedules-container"
          />
          {confirmModal.open && (
            <ConfirmationModal
              componentProps={{ onCallBack: confirmModal.callback }}
              openModal={confirmModal.open}
              onCloseModal={() =>
                setConfirmModal((s) => {
                  return { ...s, open: false };
                })
              }
            />
          )}
          {wizardModal.open && (
            <WizarModal
              componentProps={{
                onCallBack: wizardModal.callback,
                selecteds: wizardModal.selecteds,
                start: wizardModal.start,
                end: wizardModal.end,
                massiveDelete,
                massiveAdd,
                handlerScheduleList,
              }}
              openModal={wizardModal.open}
              onCloseModal={() => {
                setWizardModal((s) => {
                  return { ...s, open: false };
                });
                setStateCalendar((state) => {
                  const _s = JSON.parse(JSON.stringify(state));
                  _s.forEach((d) => {
                    d.selected = false;
                  });
                  return _s;
                });
              }}
            />
          )}
          {modalScheduleList.open && (
            <ModalScheduleList
              openModal={modalScheduleList.open}
              obj={modalScheduleList}
              openPlace={(obj) => {
                setvPlace({ open: true, obj: obj });
                // setModalScheduleList({
                //   open: false,
                //   date: null,
                //   location: null,
                //   items: null
                //   })
              }}
              onClose={() =>
                setModalScheduleList({
                  open: false,
                  date: null,
                  location: null,
                  items: null,
                })
              }
            />
          )}
          {!!vPlace.open && (
            <RegisteredPlaceForm
              openModal={vPlace.open}
              obj={vPlace.obj}
              onClose={() => setvPlace({ obj: null, open: false })}
              location={location}
            />
          )}
        </>
      );
    }
  )
);

const SchedulingPage = (props) => {
  if (!localStorage.token) {
    return <Redirect from="/" exact to="/login" />;
  }

  return <BasePage page={<SchedulingComponent />} />;
};

export default SchedulingPage;
