import { IconButton, MenuItem,  } from "@material-ui/core";
import React from "react";

const ItemMenuStyled = React.forwardRef(
  ({ iconName, onClick, titleTooltip, disable }, ref) => {
    return (

      <MenuItem
        disabled={disable}
        ref={ref}
        classes={{ root: "item-menu-styled" }}
        onClick={onClick}
      >
        <div className="icon-button">
          <IconButton color="inherit" size="small">
            <i className={`fas fa-${iconName}`}></i>
          </IconButton>
        </div>
        <div className="text-button">
          <span>{titleTooltip}</span>
        </div>
      </MenuItem>
    );
  }
);

ItemMenuStyled.defaultProps = {
  disable: false,
};

export default ItemMenuStyled;
