import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const AlertIcon = ({ tooltipTitle, onClick }) => {
  const [act, setAct] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setAct((val) => !val);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Tooltip
      className="iconPointer"
      title={tooltipTitle}
      placement="top"
      classes={{ tooltip: "tooltipCard_tooltip" }}
    >
      <div className="iconExcl" onClick={onClick}>
        {!!act && <i className={`fas fa-exclamation-circle`} />}
      </div>
    </Tooltip>
  );
};

export default AlertIcon;
