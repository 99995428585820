import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { uriBase } from "../../config";
import utils from "../../utils";
import BaseFormBtn from "../common/BaseFormBtn";
import IntelliChip from "../common/IntelliChip";
import IntelliDivider from "../common/IntelliDivider";
import IntelliInfo from "../common/IntelliInfo";
import IntelliModal from "../common/IntelliModal";

export const getDeltaDateTime = (dateTime1, dateTime2) => {
	let dt1 = moment(dateTime1);
	let dt2 = moment(dateTime2);

	return dt2.diff(dt1, "minutes") / 60;
};

const ModalScheduleForm = (props) => {
	const { onCloseModal, componentProps } = props;
	const { onCallBack, auth } = componentProps;

	const { t } = useTranslation();

	return (
		<div className="component_modal">
			<Grid container className="form">
				<Typography variant="subtitle1">
					{t("General.messageDeleteRecord")}
				</Typography>
			</Grid>
			<BaseFormBtn
				onCloseModal={onCloseModal}
				viewSave={true}
				textok={t("General.accept")}
				onOk={() => {
					onCallBack();
					onCloseModal();
				}}
				actions={<></>}
			/>
		</div>
	);
};

const ConfirmationModal = (props) => {
	const { openModal, onCloseModal, componentProps } = props;

	const { t } = useTranslation();

	return (
		<IntelliModal
			openModal={openModal}
			onCloseModal={onCloseModal}
			title={t("General.attention")}
			modalContent={ModalScheduleForm}
			componentProps={componentProps}
			modalSize={4}
		/>
	);
};

export default connect(({}) => ({}))(ConfirmationModal);
