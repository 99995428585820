import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles, Grid } from "@material-ui/core";

import {
	updateUserSetting,
	userUpdateProfile,
} from "../services/redux/actions/common/auth";

import { uid } from "uid";
import BaseFormBtn from "../components/common/BaseFormBtn";
import IntelliSelect from "../components/common/IntelliSelect";
import utils, {
	formatTimes,
	formatsDates,
	languajes,
	formatNumeric,
} from "../utils/index";
import { EntityComponent } from "../components/common/EntityTypeComponent";
import { setIdEntity } from "../services/redux/actions/common/session";
import IntelliImage from "../components/common/IntelliImage";
import { setCameraDevice } from "../services/redux/actions/common/session";
import IntelliInfo from "../components/common/IntelliInfo";
import { formatMomentDate } from "../components/timeline/constants";
import moment from "moment";
import IntelliDivider from "../components/common/IntelliDivider";
import IntelliButton from "../components/IntelliButton";
import IntelliModal from "../components/common/IntelliModal";
import ChangePasswordForm from "./ChangePasswordForm";
import { ImageModal } from "../components/common/ImageModal";
const styles = (theme) => ({});

class ProfileModalForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalChangePassword: false,
			settings_user: {
				format_date: "",
				format_time: "",
				format_temperature: "",
				format_length: null,
				status_approval: null,
				languaje: "",
				format_date_short: "",
				timezone_pg: "",
				id_format_name: 0,
				cameraDevice: null,
				format_calculation: null,
			},
			camDevices: [],
			modalImage: { open: false, photo: null },
		};
	}

	handleChange = (level1, level2, level3) => (event) => {
		let value = event.target.value;
		if (event.target.type === "checkbox") {
			value = event.target.checked ? 1 : 0;
		}
		if (level3) {
			this.setState({
				[level1]: {
					[level2]: {
						...this.state[level1][level2],
						[level3]: value,
					},
				},
			});
		} else if (level2) {
			this.setState({
				[level1]: {
					...this.state[level1],
					[level2]: value,
				},
			});
		} else {
			this.setState({ [level1]: value });
		}
	};

	assignedPayloadUser() {
		const {
			format_date,
			format_numeric,
			format_temperature,
			format_length,
			status_approval,
			languaje,
			format_time,
			timezone_pg,
			id_event_hour,
			id_format_name,
			format_calculation,
		} = this.state.settings_user;
		const { i18n } = this.props;
		let payload = {
			settings_user: {
				format_date,
				format_numeric,
				format_temperature,
				format_length,
				status_approval,
				languaje,
				format_time,
				timezone_pg,
				id_event_hour,
				id_format_name,
				format_calculation,
			},
		};

		let userReducer = this.props.auth;
		userReducer.data.user.settings_user = {
			...userReducer.data.user.settings_user,
			format_date,
			format_numeric,
			format_temperature,
			format_length,
			status_approval,
			languaje,
			format_time,
			timezone_pg,
			id_event_hour,
			id_format_name,
			format_calculation,
		};

		localStorage.setItem("intl", languaje);
		i18n.changeLanguage(languaje);
		this.props.updateUserSetting(userReducer);
		return payload;
	}

	onUpdate() {
		const { id_user } = this.props.auth.data.user;
		let payload = this.assignedPayloadUser();
		this.props.userUpdateProfile(id_user, payload, uid(), () =>
			this.props.onCloseModal()
		);
		this.props.setCameraDevice(this.state.settings_user.cameraDevice);
	}

	componentDidMount() {
		const { settings_user } = this.props.auth.data.user;
		this.setState((state) => ({
			...state,
			settings_user: { ...state.settings_user, ...settings_user },
		}));
		navigator.mediaDevices
			.enumerateDevices()
			.then(this.handleDevices.bind(this));
	}

	handleDevices(mediaDevices) {
		const mD = mediaDevices
			.filter(({ kind }) => kind === "videoinput")
			.map((device) => {
				return {
					value: device.deviceId,
					label: device.label,
				};
			});
		this.setState((s) => {
			return {
				...s,
				camDevices: mD,
				settings_user: {
					...s.settings_user,
					cameraDevice: !!mD.length ? mD[0] : [],
				},
			};
		});
	}

	renderInfoPerson() {
		const { auth, t, documentTypes } = this.props;
		const { format_date } = this.state.settings_user;
		let personPhoto = utils.setPhoto(null, 1);
		const {
			first_name,
			middle_name,
			last_name1,
			last_name2,
			dui_person,
			email,
			cell_phone,
			id_document_type,
			date_of_birth,
			photo,
			gender,
		} = auth.data.person;

		if (!!photo) {
			personPhoto = utils.setPhoto(photo, gender);
		}
		const name = `${first_name} ${middle_name} ${last_name1} ${last_name2}`;

		const typeDocument = documentTypes.data.results.find(
			(d) => d.id_document_type === id_document_type
		).document_type;
		const birthDate = !!date_of_birth
			? moment(date_of_birth, formatMomentDate).format(format_date)
			: null;
		return (
			<>
				<Grid item sm={12} container style={{ marginBottom: "6px" }}>
					<Grid item style={{ height: "58px", width: "58px" }} container>
						<IntelliImage
							photo={personPhoto}
							onCallback={() => {
								if (!!photo) {
									this.setState((s) => {
										return {
											...s,
											modalImage: { photo: personPhoto, open: true },
										};
									});
								}
							}}
						/>
					</Grid>
					<Grid item style={{ flex: "1 1 50%" }} container wrap="wrap">
						<Grid item sm={4} container>
							<IntelliInfo
								name={`${t("General.name")}`}
								value={name}
								direction={"horizontal"}
								icon={{ icon: "fal fa-user" }}
							/>
						</Grid>
						<Grid item sm={4} container>
							<IntelliInfo
								name={`${t("General.typeDocument")}`}
								value={typeDocument}
								direction={"horizontal"}
								icon={{ icon: "fal fa-address-card" }}
							/>
						</Grid>
						<Grid item sm={4} container>
							<IntelliInfo
								name={`${t("General.document")}`}
								value={dui_person}
								direction={"horizontal"}
								icon={{ icon: "fal fa-address-card" }}
							/>
						</Grid>

						{!!email && (
							<Grid item sm={4} container>
								<IntelliInfo
									name={`${t("General.mail")}`}
									value={email}
									direction={"horizontal"}
									icon={{ icon: "fal fa-envelope" }}
								/>
							</Grid>
						)}
						{!!cell_phone && (
							<Grid item sm={4} container>
								<IntelliInfo
									name={`${t("General.phone")}`}
									value={cell_phone}
									direction={"horizontal"}
									icon={{ icon: "fal fa-phone" }}
								/>
							</Grid>
						)}
						{!!birthDate && (
							<Grid item sm={4} container>
								<IntelliInfo
									name={`${t("General.birthDate")}`}
									value={birthDate}
									direction={"horizontal"}
									icon={{ icon: "fal fa-birthday-cake" }}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
				{this.state.modalImage.open && (
					<ImageModal
						openModal={this.state.modalImage.open}
						onCloseModal={() => {
							this.setState((s) => {
								return { ...s, modalImage: { ...s.modalImage, open: false } };
							});
						}}
						photo={this.state.modalImage.photo}
					/>
				)}
			</>
		);
	}

	render() {
		const {
			auth,
			t,
			timeZones,
			structures,
			setIdEntity,
			session,
			documentTypes,
		} = this.props;
		const {
			format_time,
			format_date,
			format_length,
			languaje,
			format_numeric,
			timezone_pg,
			id_format_name,
		} = this.state.settings_user;
		const { modalChangePassword } = this.state;
		const entities = auth.data.person.entities;
		const times = formatTimes.map((item) => {
			return { value: item.id, label: item.value };
		});
		const timesSelected = times.filter((item) => format_time === item.value);

		const dates = formatsDates.map((item) => {
			return { value: item.id, label: item.value };
		});
		const datesSelected = dates.filter((item) => format_date === item.value);

		const itemLanguajes = languajes.map((item) => {
			return { value: item.id, label: item.value };
		});
		const itemLanguajesSelected = itemLanguajes.filter(
			(item) => languaje === item.value
		);

		const numerics = formatNumeric.map((item) => {
			return { value: item.id, label: item.value };
		});
		const numericsSelected = numerics.filter(
			(item) => format_numeric === item.value
		);

		const itemTimeZones = timeZones.data.results.map((item) => {
			return { value: item.timezone_pg, label: item.timezone };
		});
		const timeZonesSelected = itemTimeZones.filter(
			(item) => timezone_pg === item.value
		);

		const itemsFormatName = [
			{ value: 0, label: t("User.FnLn1") },
			{ value: 1, label: t("User.FnLn1Ln2") },
			{ value: 2, label: t("User.FnMnLn1Ln2") },
			{ value: 3, label: t("User.FnMnLn1") },
			{ value: 4, label: t("User.Ln1Fn") },
			{ value: 5, label: t("User.Ln1Ln2Fn") },
			{ value: 6, label: t("User.Ln1Ln2FnMn") },
			{ value: 7, label: t("User.Ln1FnMn") },
		];
		const formatNameSelected = itemsFormatName.filter(
			(item) => id_format_name === item.value
		);

		const itemsFormatLength = [
			{ value: 1, label: t("General.kilometres").toUpperCase() },
			{ value: 2, label: t("General.miles").toUpperCase() },
		];

		const formatLengthSelected = itemsFormatLength.filter(
			(item) => format_length === item.value
		);

		return (
			<div className="formRow" style={{ paddingBottom: "0px" }}>
				<Grid container spacing={1} wrap="wrap">
					{/* BLOQUE DE CONFIGURACIONES */}
					<Grid item md={4}>
						<IntelliSelect
							isMulti={false}
							items={itemLanguajes}
							selected={itemLanguajesSelected}
							onChange={(val) => {
								this.setState({
									settings_user: {
										...this.state.settings_user,
										languaje: val.value,
									},
								});
							}}
							placeholder={t("User.language")}
						/>
					</Grid>

					<Grid item sm={4}>
						<IntelliSelect
							isMulti={false}
							items={itemTimeZones}
							selected={timeZonesSelected}
							onChange={(val) => {
								this.setState({
									settings_user: {
										...this.state.settings_user,
										timezone_pg: val.value,
									},
								});
							}}
							placeholder={t("User.timeZone")}
						/>
					</Grid>

					<Grid item md={4}>
						<IntelliSelect
							isMulti={false}
							items={dates}
							selected={datesSelected}
							onChange={(val) => {
								this.setState({
									settings_user: {
										...this.state.settings_user,
										format_date: val.value,
									},
								});
							}}
							placeholder={t("User.settings_user_format_date")}
						/>
					</Grid>

					<Grid item md={4}>
						<IntelliSelect
							isMulti={false}
							items={times}
							selected={timesSelected}
							onChange={(val) => {
								this.setState({
									settings_user: {
										...this.state.settings_user,
										format_time: val.value,
									},
								});
							}}
							placeholder={t("User.settings_user_format_hour")}
						/>
					</Grid>

					<Grid item md={4}>
						<IntelliSelect
							isMulti={false}
							items={numerics}
							selected={numericsSelected}
							onChange={(val) => {
								this.setState({
									settings_user: {
										...this.state.settings_user,
										format_numeric: val.value,
									},
								});
							}}
							placeholder={t("User.settings_user_format_numeric")}
						/>
					</Grid>

					<Grid item sm={4}>
						<IntelliSelect
							items={this.state.camDevices}
							selected={this.state.settings_user.cameraDevice}
							onChange={(item) => {
								this.setState((s) => {
									return {
										...s,
										settings_user: {
											...s.settings_user,
											cameraDevice: item,
										},
									};
								});
							}}
							placeholder={t("General.selectCamera")}
							isDisabled={
								!this.state.camDevices.length ||
								(!!this.state.camDevices.length &&
									!this.state.camDevices[0].value)
							}
						/>
					</Grid>

					<Grid item md={4}>
						<IntelliSelect
							isMulti={false}
							items={utils.formatHours}
							selected={utils.formatHours.filter((item) => {
								return (
									item.value === this.state.settings_user.format_calculation
								);
							})}
							onChange={(val) => {
								this.setState({
									settings_user: {
										...this.state.settings_user,
										format_calculation: val.value,
									},
								});
							}}
							placeholder={t("User.settings_user_format_calculation")}
						/>
					</Grid>

					{/* INFORMACION DE LA PERSONA */}
					<IntelliDivider title={t("User.infoPerson")} />
					{this.renderInfoPerson()}
					{/* ENTIDADES */}
					<Grid item sm={12} style={{ marginTop: "-20px" }}>
						<div className="component_modal" style={{ width: "100%" }}>
							<Grid container className="form">
								{entities.map((e) => (
									<EntityComponent
										entity={e}
										structuresReducer={structures.tree}
										sessionReducer={session}
										setIdEntity={setIdEntity}
										key={e.id_entity}
									/>
								))}
							</Grid>
						</div>
					</Grid>
				</Grid>
				<BaseFormBtn
					actions={
						<Grid item className="actionsBtn">
							<IntelliButton
								aria-label="devicesAssociated"
								varTitle={t("User.change_password")}
								// type="especial01"
								color={"colorPrimary"}
								varIcon="key"
								onClick={() => {
									this.setState({ modalChangePassword: true });
								}}
							/>
						</Grid>
					}
					viewSave={true}
					onCloseModal={() => this.props.onCloseModal()}
					onOk={this.onUpdate.bind(this)}
				/>
				{modalChangePassword && (
					<IntelliModal
						openModal={modalChangePassword}
						onCloseModal={() => {
							this.setState({ modalChangePassword: false });
						}}
						title={t("User.change_password")}
						modalContent={ChangePasswordForm}
						modalSize={3}
						onCallBack={() => {
							this.setState({ modalChangePassword: false });
						}}
					/>
				)}
			</div>
		);
	}
}

const mapDispatchToProps = {
	setIdEntity,
	updateUserSetting,
	userUpdateProfile,
	setCameraDevice,
};

const mapStateToProps = ({
	actionsForm,
	timeZones,
	auth,
	structures,
	session,
	documentTypes,
}) => {
	return { actionsForm, timeZones, auth, structures, session, documentTypes };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation("translations")(withStyles(styles)(ProfileModalForm)));
