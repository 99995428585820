import { Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
	IntelliToastMsg,
	typeToast,
} from "../components/common/IntelliToastMsg";
import IntelliButton from "../components/IntelliButton";
import SelectLang from "../components/SelectLang";
import {
	verifiedToken,
	changePassword,
} from "../services/redux/actions/common/auth";
import utils from "../utils";

const RecoveryForm = (props) => {
	const { t } = useTranslation();
	return (
		<Grid container className="login-container__form">
			<Grid item sm={12} className="password-field">
				<Grid item sm={12}>
					<TextField
						id="input-with-icon-grid"
						type="password"
						label={t("Login.password")}
						classes={{ root: "Intelli_textfield" }}
						value={props.newPassword.newPassword}
						onChange={(e) => {
							props.newPassword.setNewPassword(e.target.value);
						}}
					/>
				</Grid>
			</Grid>
			<Grid item sm={12} className="password-field">
				<Grid item sm={12}>
					<TextField
						id="input-with-icon-grid"
						type="password"
						label={t("Login.confirmPassword")}
						classes={{ root: "Intelli_textfield" }}
						value={props.confirmPassword.confirmPassword}
						onChange={(e) => {
							props.confirmPassword.setConfirmPassword(e.target.value);
						}}
						// onKeyPress={(e) => {
						// 	props.process();
						// }}
					/>
				</Grid>
			</Grid>
			<Grid item sm={12} container className="login-container__submit recover">
				<IntelliButton
					onClick={() => {
						props.process();
					}}
					varTitle={t("Login.process")}
					typeIcon="light"
					varIcon="key"
				/>
			</Grid>
		</Grid>
	);
};

const RecoveryPage = (props) => {
	const { t } = useTranslation();
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [stateRecover, setStateRecover] = useState({
		fetch: true,
		notFound: false,
		dataToken: null,
	});

	useEffect(() => {
		const query = utils.getQueryStringParams(props.location.search);
		props
			.verifiedToken(query.token)
			.then((data) => {
				setStateRecover({ fetch: false, dataToken: data.data });
			})
			.catch((error) => {
				setStateRecover({ fetch: false, notFound: true });
			});
	}, []);

	const process = () => {
		const { location, verifiedToken, changePassword } = props;
		const { dataToken } = stateRecover;

		if (newPassword == "") {
			IntelliToastMsg({
				title: t(`General.titleSuccess`),
				text: t(`Login.passwordEmpty`),
				type: typeToast.error,
			});
			return;
		}
		if (newPassword != confirmPassword) {
			IntelliToastMsg({
				title: t(`General.titleSuccess`),
				text: t(`General.passwordNotMatch`),
				type: typeToast.error,
			});
			return;
		}

		if (newPassword.length < 6) {
			IntelliToastMsg({
				title: t(`General.titleSuccess`),
				text: t(`FormErrors.if115`),
				type: typeToast.error,
			});
			return;
		}
		const { token, id_client } = dataToken;
		const query = utils.getQueryStringParams(location.search);
		verifiedToken(query.token)
			.then(() => {
				changePassword({
					password: newPassword,
					token,
				}).then((res) => {
					localStorage.setItem("email", res.data.email);
					IntelliToastMsg({
						title: t(`General.titleSuccess`),
						text: t(`Login.newPasswordSuccess`),
						type: typeToast.success,
					});
					window.location = "/login";
				});
			})
			.catch((error) => {
				setStateRecover({ fetch: false, notFound: true });
			});
	};

	const { notFound } = stateRecover;
	return (
		<div className="login-page">
			<div className="login-header">
				<div className="logoIntellinext"></div>
				<div className="selectLang-container">
					<SelectLang
						decorated={true}
						style={{ position: "absolute", top: 0, right: 0 }}
					/>
				</div>
			</div>
			<div className="login-main recovery">
				{notFound ? (
					<div className="expired_session_recovery">
						<Typography variant="h5" gutterBottom>
							{t(`Token.expired`)}
						</Typography>
						<Link to="/login">
							<IntelliButton
								varTitle={t("Login.btnBackLogin")}
								varIcon="angle-right"
								type="primary"
							/>
						</Link>
					</div>
				) : (
					<>
						<span>{t("Login.newPassword")}</span>
						<div className="login-main__form recovery-form">
							<RecoveryForm
								newPassword={{ newPassword, setNewPassword }}
								confirmPassword={{ confirmPassword, setConfirmPassword }}
								process={process}
							/>
						</div>
					</>
				)}
			</div>
			<div className="login-footer">
				<div>
					<Typography className="login__rights" variant="subtitle1">
						{t("Login.rights")}
					</Typography>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({}) => {
	return {};
};

export default connect(mapStateToProps, { verifiedToken, changePassword })(
	RecoveryPage
);
