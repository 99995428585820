import { combineReducers } from "redux";
import processReducer from "./process";
import authReducer from "./common/authReducer";
import structuresReducer from "./common/structureReducer";
import structuresTypesReducer from "./common/structureTypesReducer";
import jobsReducer from "./common/jobsReducer";
import sessionReducer from "./common/sessionReducer";
import { verificationModesReducer } from "./common/verificationModesReducer";
import entitiesTypesReducer from "./common/entitiesTypesReducer";
import conceptReducer from "./common/conceptsReducer";
import { conceptMasterReducer } from "./common/conceptMasterReducer";
import { timeZonesReducer } from "./common/timeZonesReducer";
import { documentTypesReducer } from "./common/documentTypesReducer";
import schedulesReducer from "./common/schedulesReducer";

export default combineReducers({
	process: processReducer,
	auth: authReducer,
	structures: structuresReducer,
	structuresTypes: structuresTypesReducer,
	jobs: jobsReducer,
	session: sessionReducer,
	verificationModes: verificationModesReducer,
	entitiesTypes: entitiesTypesReducer,
	concepts: conceptReducer,
	conceptsMaster: conceptMasterReducer,
	timeZones: timeZonesReducer,
	documentTypes: documentTypesReducer,
	schedules: schedulesReducer,
});
