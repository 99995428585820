import React, { Component } from "react";
import BaseFormBtn from "../components/common/BaseFormBtn";

import moment from "moment";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { formatsDates } from "../utils/index";
import { Grid } from "@material-ui/core";

import { noveltyPermissionsFetch } from "../services/redux/actions/common/noveltyPermission";
import {
  permissionInsert,
  permissionUpdate,
} from "../services/redux/actions/common/entityPermissions";

import utils from "../utils";
import withFormatter from "../components/containers/FormatWrapper";

import IntelliDatePicker from "../components/common/IntelliDatePicker";

import IntelliTextField from "../components/common/IntelliTextField";
import IntelliSelect from "../components/common/IntelliSelect";
import {
  schedulesInsert,
  personScheduleUpdate,
} from "../services/redux/actions/common/scheduling";
import {
  IntelliToastMsg,
  typeToast,
} from "../components/common/IntelliToastMsg";
import { formatMomentHoursSeconds } from "../components/timeline/constants";
import _ from "lodash";
import IntelliSwitch from "../components/common/IntelliSwitch";
import IntelliInfo from "../components/common/IntelliInfo";

class ScheduleForm extends Component {
  constructor() {
    super();
    this.state = {
      date_in: "",
      date_out: "",
      id_structure: null,
      id_schedule: null,
      used: null,
      at_created: "",
      id_location_scheduling: null,
      id_person: null,
      id_person_scheduling: null,
      id_user: null,
      status: null,
      dows: [],
      overwrite: false,
    };
  }

  setTime(time) {
    const { format_time } = this.props.auth.data;
    return moment(time, format_time).format("HH:mm:ss");
  }

  validate(payload) {
    const { t } = this.props;
    const { date_in, date_out, id_structure, id_schedule } = payload;

    const dI = moment(date_in);
    const dO = moment(date_out);

    let arrayErrors = [];

    if (!!date_in) {
      if (
        dI.clone().startOf("dates").diff(moment().startOf("dates"), "dates") < 0
      ) {
        IntelliToastMsg({
          type: typeToast.info,
          title: `${t("logicErrors.invalidDateInt")}`,
        });
        arrayErrors.push({ field: "date_in" });
      }
    } else {
      IntelliToastMsg({
        type: typeToast.info,
        title: `${t("PersonScheduling.date_out")}: ${t(`FormErrors.if111`)}`,
      });
      arrayErrors.push({ field: "date_in" });
    }

    if (!!date_out) {
      if (
        dO.clone().startOf("dates").diff(dI.clone().startOf("dates"), "dates") <
        0
      ) {
        IntelliToastMsg({
          type: typeToast.info,
          title: `${t("logicErrors.invalidDateInt")}`,
        });
        arrayErrors.push({ field: "date_out" });
      }
    } else {
      IntelliToastMsg({
        type: typeToast.info,
        title: `${t("PersonScheduling.date_out")}: ${t(`FormErrors.if111`)}`,
      });
      arrayErrors.push({ field: "date_out" });
    }

    if (!id_structure) {
      IntelliToastMsg({
        type: typeToast.info,
        title: `${t("PersonScheduling.id_structure")}: ${t(
          `FormErrors.if111`
        )}`,
      });
      arrayErrors.push({ field: "id_structure" });
    }

    if (!id_schedule) {
      IntelliToastMsg({
        type: typeToast.info,
        title: `${t("PersonScheduling.id_schedule")}: ${t(`FormErrors.if111`)}`,
      });
      arrayErrors.push({ field: "id_schedule" });
    }

    if (!!date_in && !!date_out) {
      if (dO.diff(dI, "days") > 30) {
        IntelliToastMsg({
          type: typeToast.info,
          title: `${t("PersonScheduling.id_schedule")}: ${t(
            `FormErrors.sameMonth`
          )}`,
        });
        arrayErrors.push({ field: "not_same_month" });
      }
    }

    if (!!arrayErrors.length) {
      return false;
    } else return true;
  }

  onSave() {
    const { t } = this.props;
    const {
      date_in,
      date_out,
      id_structure,
      id_schedule,
      id_person,
      dows,
      overwrite,
    } = this.state;
    
    if (this.validate({ date_in, date_out, id_structure, id_schedule })) {
      let schedule = this.props.schedules.data.results.find(i=> i.id_schedule == id_schedule)
      const { time_in, time_out } = schedule
      this.props.schedulesInsert(
        {
          date_in,
          date_out,
          time_in, 
          time_out,
          id_structure,
          id_schedule,
          id_person,
          id_place: 0,
          dows,
          overwrite: !!overwrite ? 1 : 0,
        },
        () => {
          this.props.onCallBack();
          this.props.componentProps.setItemCRUD(this.state);
        }
      );
    }
  }

  onUpdate() {
    const { t } = this.props;
    const { date_in, date_out, id_structure, id_schedule, id_person } =
      this.state;
    if (this.validate({ date_in, date_out, id_structure, id_schedule })) {
      const payload = this.state;
      // payload.id_person = id_person;
      delete payload.divKey;
      delete payload.primaryKey;
      delete payload.person;
      delete payload.schedule;
      delete payload.structure;

      this.props.personScheduleUpdate(
        payload,
        payload.id_person_scheduling,
        () => {
          this.props.onCallBack();
          this.props.componentProps.setItemCRUD(payload);
        }
      );
    }
  }

  mergeInitialData() {
    const { t, componentProps } = this.props;
    const { obj } = componentProps;
    let data = utils.MergeRecursive(this.state, obj);

    // data.date_in = moment(data.date_time_in.split(" ")[0]);
    // data.date_out = moment(data.date_time_out.split(" ")[0]);

    data.dows = this.setDowsSwitches(obj.date_in, obj.date_out);
    return data;
  }

  setDowsSwitches(date_in, date_out) {
    const di_m = moment(date_in);
    const do_m = moment(date_out);

    let start = di_m.days();
    let end = do_m.days();

    let i = 0;
    let _dows = [];

    if (do_m.diff(di_m, "days") < 7) {
      if (start > end) {
        end = end + 7;
      }
      while (start + i <= end) {
        if (start + i > 6) {
          _dows.push(di_m.days() + i - 7);
        } else {
          _dows.push(di_m.days() + i);
        }
        i++;
      }
    } else {
      _dows = [0, 1, 2, 3, 4, 5, 6];
    }
    return _dows;
  }

  componentDidMount() {
    this.in_mount = true;
    const { componentProps, auth } = this.props;

    if (componentProps.obj == null) {
      let date_in = moment().format("YYYY-MM-DD");
      let date_out = moment().format("YYYY-MM-DD");
      let id_person = auth.data.Person.id_person;

      this.setState({ date_in, date_out, id_person });
    } else {
      this.setState(this.mergeInitialData());
    }
    this.inicialited = true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { componentProps } = this.props;
    const { componentProps: prevComponentProps } = prevProps;
    if (componentProps.obj !== prevComponentProps.obj) {
      this.in_mount = true;
      if (componentProps.obj == null) {
        let date_in = moment().format("YYYY-MM-DD");
        let date_out = moment().format("YYYY-MM-DD");
        this.setState({ date_in, date_out });
      } else {
        this.setState(this.mergeInitialData());
      }
      this.inicialited = true;
    }
  }

  renderSwitchesDows(isDisabled) {
    const dows = utils.getDOWList();
    return dows.map((d) => {
      return (
        <Grid item key={d.id}>
          <IntelliSwitch
            titleTooltip={utils.toCapitalCase(d.value)}
            label={utils.toCapitalCase(d.value)}
            checked={
              !!this.state.dows.find((_d) => _d === d.id) ||
              this.state.dows.find((_d) => _d === d.id) === 0
            }
            onChange={(val) => {
              this.setState((s) => {
                let ds = JSON.parse(JSON.stringify(s)).dows;
                if (!!val.target.checked) {
                  ds.push(d.id);
                } else {
                  ds = ds.filter((_d) => d.id !== _d);
                }

                return { ...s, dows: ds };
              });
            }}
            isDisabled={isDisabled}
          />
        </Grid>
      );
    });
  }

  render() {
    const { t, componentProps } = this.props;

    const {
      obj: { available_schedules },
    } = componentProps;

    const itemsLocations = this.props.structures.tree
      .filter(
        (s) =>
          s.id_structure_type === 3 &&
          _.uniq(
            this.props.schedules.data.results.map((s) => s.locations).flat()
          ).includes(s.id_structure)
      )
      .map((s) => {
        return { value: s.id_structure, label: `${s.code} - ${s.structure}` };
      });

    let itemsSchedules = [];

    if (!!this.state.id_structure) {
      itemsSchedules = this.props.schedules.data.results
        .filter((s) => s.locations.includes(this.state.id_structure))
        //.filter((s) => available_schedules.includes(s.id_schedule))
        .map((s) => {
          const tI = moment(s.time_in, formatMomentHoursSeconds).format(
            this.props.auth.data.format_time
          );

          const tO = moment(s.time_out, formatMomentHoursSeconds).format(
            this.props.auth.data.format_time
          );

          return {
            value: s.id_schedule,
            label: `${tI} - ${tO}`,
          };
        });
    }

    const isDisabled =
      !!this.state.used || (componentProps.obj && !componentProps.obj.new);
    // (componentProps.obj &&
    // 	!componentProps.obj.new &&
    // 	this.state.date_in <= moment().format("YYYY-MM-DD"));

    return (
      <div className="formRow">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          wrap="wrap"
          spacing={1}
          style={{ marginBottom: "4px" }}
        >
          <Grid item sm={3}>
            <IntelliDatePicker
              value={this.state.date_in}
              handleDateChange={(val) => {
                this.setState({ date_in: val.format("YYYY-MM-DD") });
              }}
              label={t("PersonScheduling.date_in")}
              required
              disabled={isDisabled}
              disablePast={!isDisabled}
            />
          </Grid>
          <Grid item sm={3}>
            <IntelliDatePicker
              value={this.state.date_out}
              handleDateChange={(val) => {
                this.setState({ date_out: val.format("YYYY-MM-DD") });
              }}
              label={t("PersonScheduling.date_out")}
              required
              disabled={isDisabled}
              disablePast={!isDisabled}
            />
          </Grid>
          {/* <Grid item sm={3}>
						<IntelliSelect
							isMulti={false}
							items={itemsLocations}
							selected={itemsLocations.find(
								(s) => s.value === this.state.id_structure
							)}
							onChange={(val) => {
								this.setState({ id_structure: val.value });
							}}
							placeholder={t("PersonScheduling.id_structure")}
							required
							isDisabled={isDisabled}
						/>
					</Grid> */}
          <Grid item sm={6}>
            <IntelliSelect
              isMulti={false}
              items={itemsSchedules}
              selected={itemsSchedules.find(
                (s) => s.value === this.state.id_schedule
              )}
              onChange={(val) => {
                this.setState({ id_schedule: val.value });
              }}
              placeholder={t("PersonScheduling.id_schedule")}
              required
              isDisabled={isDisabled}
            />
          </Grid>

          {/* <Grid item sm={1}>
						<IntelliTextField
                            customProps={{
                                id: "id_person",
                                value: this.state.id_person,
                                label: 'id',
                                onChange: (a) => this.setState({ id_person: a.target.value })
                            }}
                        />
					</Grid> */}
          <Grid container item={12} justify="space-evenly">
            {!isDisabled && this.renderSwitchesDows(isDisabled)}
          </Grid>
        </Grid>
        <BaseFormBtn
          actions={
            <>
              {/* {!isDisabled && (
								<Grid item>
									<IntelliSwitch
										titleTooltip={t("General.overwrite")}
										label={t("General.overwrite")}
										checked={!!this.state.overwrite}
										onChange={(val) => {
											this.setState((s) => {
												return { ...s, overwrite: val.target.checked };
											});
										}}
										isDisabled={isDisabled}
									/>
								</Grid>
							)} */}
              {!this.props.componentProps.obj.new && (
                <Grid item>
                  <IntelliInfo
                    name={t("User.status")}
                    value={
                      !!this.props.componentProps.obj.used
                        ? t("General.used")
                        : t("General.notUsed")
                    }
                    direction={"row"}
                  />
                </Grid>
              )}
            </>
          }
          viewCancel={!isDisabled}
          viewClose={true}
          viewSave={!isDisabled}
          onCloseModal={() => {
            componentProps.obj == null
              ? this.props.onCloseModal()
              : this.props.onCloseModal();
            // : this.props.onClose();
          }}
          onOk={() => {
            !!componentProps.obj && componentProps.obj.new
              ? this.onSave()
              : this.onUpdate();
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  noveltyPermissionsFetch,
  permissionUpdate,
  permissionInsert,
  schedulesInsert,
  personScheduleUpdate,
};

const mapStateToProps = ({
  auth,
  actionsForm,
  session,
  structures,
  schedules,
}) => {
  return { auth, actionsForm, session, structures, schedules };
};

ScheduleForm.defaultProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("translations")(withFormatter(ScheduleForm)));
