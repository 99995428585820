import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";

const ImageModal = ({
  openModal,
  onCloseModal,
  preventClose = false,
  photo,
}) => {
  return (
    <Dialog
      open={openModal}
      onClose={onCloseModal}
      aria-labelledby="form-dialog-title"
      className="Intelli_modal image_modal"
      maxWidth={false}
      disableEscapeKeyDown={preventClose}
      disableBackdropClick={preventClose}
    >
      <DialogContent>
        <img src={photo} alt={"photo_image"} />
      </DialogContent>
      <button onClick={onCloseModal}><i className="fas fa-times"></i></button>
    </Dialog>
  );
};

export { ImageModal };
