import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";

const EnhancedComponent = (OriginalComponent) => {
	return class extends React.Component {
		constructor(props) {
			super(props);
			this.formatDateTime = this.formatDateTime.bind(this);
		}

		formatDateTime(format = null, date = null, type = "date") {
			const { format_date, format_time } = this.props.auth.data;
			const settings_user = { format_date, format_time };
			const typeFormat = `format_${type}`;
			if (format === null) {
				format = settings_user[typeFormat];
			}
			if (type === "time") {
				const formatTime = settings_user.format_time;
				if (date === null) {
					return moment().format(format);
				} else {
					if (date.length == 8) {
						return moment(date, "HH:mm:ss").format(formatTime);
					}
					if (date.length == 5) {
						return moment(date, "HH:mm").format(formatTime);
					}
					return moment(date).format(format);
				}
			}
			return date === null
				? moment().format(format)
				: moment(date).format(format);
		}

		render() {
			return (
				<OriginalComponent
					{...this.props}
					formatDateTime={this.formatDateTime}
				/>
			);
		}
	};
};

export default EnhancedComponent;
