import { REHYDRATE } from "redux-persist/es/constants";
import utils from "../../../../utils";
import {
	CONCEPT_MASTER_FETCH,
	CONCEPT_MASTER_FETCH_PENDING,
	CONCEPT_MASTER_FETCH_REJECTED,
} from "../../constants";
const initialState = {
	data: {
		results: [],
	},
	isFetching: false,
};
export function conceptMasterReducer(state = initialState, action) {
	switch (action.type) {
		case REHYDRATE:
			return utils.actionRehydrate(state, action.payload, "conceptMaster");
		case CONCEPT_MASTER_FETCH_PENDING:
			return {
				...state,
				isFetching: true,
			};
		case CONCEPT_MASTER_FETCH_REJECTED:
			return {
				...state,
				isFetching: false,
			};
		case CONCEPT_MASTER_FETCH:
			return {
				data: action.payload.data.data,
				isFetching: false,
			};
		default:
			return state;
	}
}
