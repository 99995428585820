import { REHYDRATE } from "redux-persist/es/constants";
import utils from "../../../../utils";
import {
	TIME_ZONE_FETCH,
	TIME_ZONE_FETCH_PENDING,
	TIME_ZONE_REJECTED,
} from "../../constants";

const initialState = {
	data: {
		results: [],
	},
	isFetching: false,
};

export function timeZonesReducer(state = initialState, action) {
	switch (action.type) {
		case REHYDRATE:
			return utils.actionRehydrate(state, action.payload, "timeZones");
		case TIME_ZONE_FETCH_PENDING:
			return {
				...state,
				isFetching: true,
			};
		case TIME_ZONE_REJECTED:
			return {
				...state,
				isFetching: false,
			};
		case TIME_ZONE_FETCH:
			return {
				data: action.payload.data.data,
				isFetching: false,
			};
		default:
			return state;
	}
}
