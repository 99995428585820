import { REHYDRATE } from "redux-persist/es/constants";
import utils from "../../../../utils";
import {
	ENTITIES_TYPES_FETCH,
	ENTITIES_TYPES_FETCH_MORE,
	ENTITIES_TYPES_FETCH_PENDING,
	ENTITIES_TYPES_REJECTED,
} from "../../constants";

const initialState = {
	data: {
		results: [],
	},
	isFetching: false,
};
export default function entitiesTypesReducer(state = initialState, action) {
	switch (action.type) {
		case REHYDRATE:
			return utils.actionRehydrate(state, action.payload, "entitiesTypes");
		case ENTITIES_TYPES_FETCH_PENDING:
			return {
				...state,
				isFetching: true,
			};
		case ENTITIES_TYPES_REJECTED:
			return {
				...state,
				isFetching: false,
			};
		case ENTITIES_TYPES_FETCH:
			return {
				data: action.payload.data.data,
				isFetching: false,
			};
		case ENTITIES_TYPES_FETCH_MORE:
			return {
				...state,
				isFetching: false,
				data: {
					...action.payload.data.data,
					results: utils.mergeObjet(
						action.payload.data.data.results,
						state.data.results,
						"id_entity_type"
					),
				},
			};

		default:
			return state;
	}
}
