import { Grid } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import utils from "../../utils";
import { useTranslation } from "react-i18next";

import IntelliChip from "./IntelliChip";
import IntelliInfo from "./IntelliInfo";
import IntelliDivider from "./IntelliDivider";
import { IntelliIcon } from "./IntelliIcon";

export const EntityComponent = ({
  entity: { job, structures, id_entity, entity_type },
  structuresReducer,
  sessionReducer,
  setIdEntity,
  onCallback,
}) => {
  const { t } = useTranslation();
  const entityType = entity_type.entity_type;
  const isActive = id_entity === sessionReducer.id_entity;

  let info = utils.getStructureInfoEntity(structuresReducer, structures);
  const locationName =
    info.location != undefined ? info.location.structure : "N/A";
  const positionName =
    info.position != undefined ? info.position.structure : "N/A";
  let jobName = "N/A";
  let idJob = null;
  if (job == null) {
    jobName = info.job.jobName;
    idJob = info.job.id_job;
  } else {
    jobName = job.job_name;
    idJob = job.id_job;
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIdEntity({
      id_entity,
      id_job: idJob,
      id_location: info.location.id_structure,
    });
    onCallback();
  };

  return (
    <>
      <IntelliDivider title={entityType} />
      <Grid item sm={2} container>
        <IntelliInfo
          name={t("General.Position")}
          value={positionName}
          icon={{ icon: "fal fa-briefcase" }}
          direction="horizontal"
        />
      </Grid>

      <Grid item sm={8} container>
        <IntelliInfo
          name={t("General.Location")}
          value={locationName}
          icon={{ icon: "fal fa-map-marker-alt" }}
          direction="horizontal"
        />
      </Grid>
      <Grid
        item
        sm={2}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <IntelliChip
          label={isActive ? t("General.activeEntity") : t("General.toActivate")}
          onClick={handleClick}
          colorClass={isActive ? "colorPrimary" : "colorSecondary"}
        />
      </Grid>
    </>
  );
};

EntityComponent.defaultProps = {
  onCallback: () => {},
};
