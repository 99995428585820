import { PROCESSING, PROCESS_COMPLETE } from "../constants";

const initialState = {
  mode: false,
};

const processReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROCESSING:
      return {
        ...state,
        mode: true,
      };
    case PROCESS_COMPLETE:
      return {
        ...state,
        mode: false,
      };
    default:
      return state;
  }
};

export default processReducer;
