import {
  ACTIONS_FORM_ADD,
  NOVELTY_PERMISSION_FETCH,
  NOVELTY_PERMISSION_FETCH_MORE,
  PROCESSING,
  PROCESS_COMPLETE,
} from "../../constants";
import _ from "lodash";
import axios from "axios";
import { url } from "../../../../config";
import utils from "../../../../utils";
import checkTokenAndClient from "./correctUrlToken";

export const noveltyPermissionsFetch = (Options = {}, callback = null) => {
  return async function (dispatch) {
    if (!checkTokenAndClient(dispatch)) return false;
    let headers = utils.headerAxios();
    Options = _.extend(
      {
        limit: 20,
        offSet: 0,
        search: "",
        where: "",
        select: "*",
        orderBy: "id_novelty_permission ASC",
        allItem: false,
        dispatch: true,
      },
      Options
    );
    if (
      !Options.allItem &&
      Options.search.indexOf("where[ta_novelties_permissions.status]=0") == -1
    ) {
      Options.search += "&where[ta_novelties_permissions.status]=1";
    }

    let search = "";
    if (Options.search !== "") {
      search = `&${Options.search}`;
    }

    const type =
      Options.offSet == 0
        ? NOVELTY_PERMISSION_FETCH
        : NOVELTY_PERMISSION_FETCH_MORE;
    const request = axios.get(
      `${url}/novelties_permissions?limit=${Options.limit}&offset=${Options.offSet}&${Options.where}&select=${Options.select}&orderby=${Options.orderBy}` +
        search,
      { headers }
    );
    request.catch((err) => utils.errorHandling(dispatch, err));
    request.then((res) => {
      if (callback != null) {
        callback(res.data.data.results);
      }
      dispatch({
        type,
        payload: res,
      });
    });
  };
};
