import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import moment from "moment";

import { connect } from 'react-redux';
import BaseFormBtn from "./BaseFormBtn";
import { makeStyles } from "@material-ui/core";

import TableHeader from "./Tables/TableHeader";
import TableRow from "./Tables/TableRow";
import _ from "lodash";

const ModalScheduleList = ({obj, onClose, openModal, auth, structures, openPlace}) => {

  const [item, setItem] = useState([]);
  const [location, setLocation] = useState(null);
  const [openItem, setOpenItem] = useState([]);

  const useStyles = makeStyles({
    MuiDialogPaper: {
      width: location && _.has(location.settings_structure, "render_plan")
        ? "800px"
        : "fit-content"
    },
  });

  const classes = useStyles();
  useEffect(() => {
    if(!!obj.items){
      setItem(obj.items);
      setLocation(structures.tree.find(item => item.id_structure === obj.location));
    }
  }, [obj, structures]);
  
  const parseSchedule = (data) => {
    return `${moment(data.time_in, "HH:mm:ss").format(auth.data.format_time)} - ${moment(data.time_out, "HH:mm:ss").format(auth.data.format_time)}`;
  }

  const parsePlace = (data) => {
    const fullPlace = data.places.find(item => item.id_structure === data.place.id_structure);
    return (
      <div>
        <i className={`fas fa-${fullPlace.sub_type === "PT" ? "chair-office" : "users-class" }`} style={{ marginRight: "0.3em" }}/>
        <span>{data.place.structure}</span>
      </div>
    );
  }

  const headerPagePlace = [
    {
      type: "cell",
      field: "ubicacion.structure",
      renderFunction: (data) => { return data.ubicacion.structure },
      description: "PersonScheduling.id_L",
      size: { xs: 3 },
    },
    {
      type: "cell",
      field: "ubicacionE.structure",
      renderFunction: (data) => { return data.ubicacionE.structure },
      description: "PersonScheduling.id_SL",
      size: { xs: 4 },
    },
    {
      type: "cell",
      field: "id_schedule",
      renderFunction: parseSchedule.bind(this),
      description: "PersonScheduling.id_schedule",
      size: { xs: 2 },
    },
    {
      type: "cell",
      field: "place",
      renderFunction: parsePlace.bind(this),
      description: "PersonScheduling.place",
      size: { xs: 2 },
    },
    {
      type: "actions",
      field: "details",
      description: "General.details",
      size: { xs: 1 },
    }
  ];

  const headerPage = [
    {
      type: "cell",
      field: "id_schedule",
      renderFunction: parseSchedule.bind(this),
      description: "PersonScheduling.id_schedule",
      size: { xs: 9 },
    },
    {
      type: "actions",
      field: "details",
      description: "General.details",
      size: { xs: 3 },
    }
  ];

  const header = location 
                  ? _.has(location.settings_structure, "render_plan")
                    ? headerPagePlace
                    : headerPage
                  : ""

  return (
    <Dialog
      open={openModal}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className="Intelli_modal"
      maxWidth={false}
      classes={{ paper: classes.MuiDialogPaper }}
    >
    <DialogTitle id="form-dialog-title">{`${location?.structure} | ${moment(obj.date).format("DD-MM-YYYY")}`}</DialogTitle>
    <DialogContent>
      <div
        className="formRow"
        style={{ paddingTop: 0, maxWidth: 1000 }}
      >
        <Grid
          container
          direction="column"
          style={{ height: "100%" }}
          wrap="nowrap"
        >
          <div className="intelliListHeader">
            <TableHeader
              checkCell={false}
              isCheckAll={false}
              header={header}
              setValueItemOpen={setOpenItem}
            />
          </div>
          <div className="intelliTableRow" style={{ maxHeight: "400px" }}>
            <TableRow
              componentForm={null}
              openItem={openItem}
              setValueItemOpen={setOpenItem}
              data={item}
              header={header}
              funGetItem={() => console.log("GetItem")}
              onCallBack={() => console.log("CallBack")}
              funDelete={() => console.log("Delete")}
              blackList={false}
              actDelete={false}
              actDetails={true}
              onViewDetails={(data) => openPlace(data)}
              checkCell={false}
              checkedItem={(a) => console.log("checked " + a)}
              checkeds={[]}
              module={"PersonScheduling"}
              pKey={"id_person_scheduling"}
              favorite={""}
            />
          </div>
        </Grid>
        <BaseFormBtn viewSave={false} onCloseModal={onClose} />
      </div>
    </DialogContent>
  </Dialog>
  )
}
const mapStateToProps = ({ auth, structures }) => {
	return { auth, structures };
};

export default connect(mapStateToProps, { })(ModalScheduleList);