import React from "react";
import Toolbar from "./Toolbar";
import IntelliModal from "./common/IntelliModal";

const BasePageComponent = (props) => {
  return (
    <>
      <div className="basepage-component">
        <Toolbar fields={props.fields} buttons={props.buttons} />
        <div className={`main ${props.classComponent}`}>
          {props.componentPage}
        </div>
        {props.openModal && (
          <IntelliModal
            openModal={props.openModal}
            onCloseModal={props.onCloseModal}
            title={props.title}
            modalContent={props.componentForm}
            componentProps={props.componentProps}
            modalSize={props.modalSize}
            module={props.module}
            onCallBack={props.onCallBack}
          />
        )}
      </div>
    </>
  );
};
BasePageComponent.defaultProps = {
  openModal: false,
  onCloseModal: () => console.info("sin funcion onCloseModal"),
  onCallBack: () => console.info("sin funcion onCallback"),
  funGetItem: () => console.info("sin funcion funGetItem"),
  funDelete: () => console.info("sin funcion funDelete"),
  title: "",
  componentForm: <></>,
  modalSize: 9,
};

export default BasePageComponent;
