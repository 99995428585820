import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import { connect } from "react-redux";

const IntelliDatePicker = connect(({ auth }) => ({ auth }))(
	({
		value,
		handleDateChange,
		auth,
		label,
		required = false,
		disabled = false,
		disablePast = false,
    shouldDisableDate
	}) => {
		const format = auth.data.format_date;
		return (
			<KeyboardDatePicker
				disableToolbar
				variant="inline"
				format={format}
				margin="normal"
				id="date-picker-inline"
				label={label}
				value={value}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					"aria-label": "change date",
				}}
				inputVariant="outlined"
				className="Intelli_datepicker date"
				autoOk
				invalidDateMessage={null}
				required={required}
				disabled={disabled}
				disablePast={disablePast}
        shouldDisableDate={shouldDisableDate}
			/>
		);
	}
);

export default IntelliDatePicker;
